import { createSelector } from 'reselect';

export const getPpeNames = (selectedPpe: any) => selectedPpe.map((ppe: any) => ppe.ppeName);

export const getPpeString = createSelector(
  [getPpeNames],
  (ppeNames) =>
    `You have  selected: ${
      ppeNames.length === 1
        ? ppeNames[0]
        : `${ppeNames.slice(0, -1).join(', ')}, and ${ppeNames.slice(-1)}`
    }.`
);
