import React from 'react';
import { StepHeader, StyledTypography, DividerStep } from './style';

const WorkStepListSubHeader = function () {
  return (
    <StepHeader>
      <StyledTypography variant="h5">NO.</StyledTypography>
      <DividerStep padding="23px">
        <StyledTypography variant="h5">THE STEPS</StyledTypography>
      </DividerStep>
      {/* <DividerStep padding="400px">
        <StyledTypography variant="h5">HAZARDS</StyledTypography>
      </DividerStep>
      <DividerStep padding="27px">
        <StyledTypography variant="h5">RISK</StyledTypography>
      </DividerStep>
      <DividerStep padding="27px">
        <StyledTypography variant="h5">CONTROLS</StyledTypography>
      </DividerStep>
      <DividerStep padding="12.5px">
        <StyledTypography variant="h5">RESIDUE</StyledTypography>
      </DividerStep> */}
    </StepHeader>
  );
};

export default WorkStepListSubHeader;
