import React from 'react';
import { connect, FieldArray, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  GridContainer,
  GridItem,
  Typography,
  AddButton,
} from '../../components';
import { getSelfIsolations, getHazardWithIsolations, getIsolations } from './workpackFormSelectors';
import IsolationsRequiredInput from './IsolationsRequiredInput';
import { AccordionSubText } from '../../components/Accordion/styles';

const IsolationsRequired = () => {
  const [expandedPanels, setExpandedPanels] = React.useState<{
    panel1: boolean;
    panel2: boolean;
  }>({ panel1: true, panel2: true });

  const handleChange = (panel: string) => () => {
    const currentValue = expandedPanels[panel];
    setExpandedPanels({
      ...expandedPanels,
      [panel]: !currentValue,
    });
  };

  const { values }: any = useFormikContext();
  const isolations = getIsolations(values);
  const selfIsolations = getSelfIsolations(values);
  const hazardWithIsolations = getHazardWithIsolations(values);
  const shouldRenderWorkStepIsolations = !isEmpty(selfIsolations) || !isEmpty(hazardWithIsolations);
  const shouldRenderSelfIsolation = !isEmpty(selfIsolations);
  const shouldRenderHazardWithIsolations = !isEmpty(hazardWithIsolations);

  return (
    <Accordion borderTop expanded={expandedPanels.panel1 === true} onChange={handleChange('panel1')}>
      <AccordionSummary>Isolations required</AccordionSummary>
      <AccordionDetails>
        <AccordionSubText>
          You will need an isolation to work on plant and equipment that is electrically or mechanically
          powered. This includes live pipe lines (hydraulic), compressed air systems and all types of
          stored energy which can cause harm if released accidentally.
        </AccordionSubText>
        <GridContainer>
          {shouldRenderWorkStepIsolations && (
            <GridItem xs={8}>
              <Typography variant="body1" paragraph>
                <strong>In section 3, you&rsquo;ve identified you need isolations.</strong>
              </Typography>
              {shouldRenderSelfIsolation && (
                <>
                  <Typography variant="body1">
                    You added the following Self-Isolations (LOTO):
                  </Typography>
                  <ul>
                    {selfIsolations.map(({ hazardName, selfIsolationValue }: any, index: number) => (
                      <Typography variant="body1" key={`selfIsolation-${index}`}>
                        <li>{`${hazardName}: ${selfIsolationValue}`}</li>
                      </Typography>
                    ))}
                  </ul>
                </>
              )}
              {shouldRenderHazardWithIsolations && (
                <>
                  <Typography variant="body1">
                    You need to apply for an isolation certificate for the following hazards:
                  </Typography>
                  <ul>
                    {hazardWithIsolations.map((item: any, index: number) => (
                      <Typography variant="body1" key={`hazardWithIsolation-${index}`}>
                        <li>{item}</li>
                      </Typography>
                    ))}
                  </ul>
                </>
              )}
            </GridItem>
          )}
          <FieldArray
            name="workPack.isolations"
            render={(arrayHelpers: any) => (
              <>
                {map(isolations, (isolation: any, index: number) => (
                  <IsolationsRequiredInput
                    key={`IsolationsRequiredInput-${index}`}
                    index={index}
                    arrayHelpers={arrayHelpers}
                  />
                ))}
                <GridItem xs={12}>
                  <AddButton
                    text="Add Isolations"
                    onClick={() => {
                      const id = uuidv4();
                      const file = { bucket: '', key: '', region: '' };

                      const newValue = {
                        file,
                        id,
                        createdDate: new Date().toISOString(),
                        fileSize: '',
                        fileName: '',
                        isolationType: '',
                        selfIsolation: '',
                      };

                      arrayHelpers.push(newValue);
                    }}
                  />
                </GridItem>
              </>
            )}
          />
        </GridContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default connect(IsolationsRequired);
