import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  itemToDelete: null,
  isHazardOverlayOpen: false,
  isControlsOverlayOpen: false,
  hazardsToSave: null,
  controlsToSave: null,
  stepToUpdate: null,
  initialRisk: null,
  initialLikelihood: null,
  initialSeverity: null,
  isInitialRiskOverlayOpen: false,
  isPpeDrawerOpen: false,
  isRiskMatrixOverlayOpen: false,
  hazardToUpdate: null,
  editingHazards: false,
  categoriesWithCustomHazards: null,
};

const workSteps = createSlice({
  name: 'workSteps',
  initialState,
  reducers: {
    setIsRiskMatrixOverlayOpen: (nextState: any, action: any) => {
      nextState.isRiskMatrixOverlayOpen = action.payload;
    },
    setResidualRisk: (nextState: any, action: any) => {
      nextState.residualRisk = action.payload;
    },
    setResidualSeverity: (nextState: any, action: any) => {
      nextState.residualSeverity = action.payload;
    },
    setResidualLikelihood: (nextState: any, action: any) => {
      nextState.residualLikelihood = action.payload;
    },
    setInitialRisk: (nextState: any, action: any) => {
      nextState.initialRisk = action.payload;
    },
    setInitialSeverity: (nextState: any, action: any) => {
      nextState.initialSeverity = action.payload;
    },
    setInitialLikelihood: (nextState: any, action: any) => {
      nextState.initialLikelihood = action.payload;
    },
    setItemToDelete: (nextState: any, action: any) => {
      nextState.itemToDelete = action.payload; // mutate the state all you want with immer
    },
    setIsHazardOverlayOpen(nextState: any, action: any) {
      nextState.isHazardOverlayOpen = action.payload; // mutate the state all you want with immer
    },
    setIsInitialRiskOverlayOpen(nextState: any, action: any) {
      nextState.isInitialRiskOverlayOpen = action.payload;
    },
    setIsResidualRiskOverlayOpen(nextState: any, action: any) {
      nextState.isResidualRiskOverlayOpen = action.payload;
    },
    setIsPpeDrawerOpen(nextState: any, action: any) {
      nextState.isPpeDrawerOpen = action.payload;
    },
    setHazardsToSave(nextState: any, action: any) {
      nextState.hazardsToSave = action.payload; // mutate the state all you want with immer
    },
    setCustomHazardsToSave(nextState: any, action: any) {
      nextState.categoriesWithCustomHazards = action.payload; // mutate the state all you want with immer
    },
    setEditingHazards(nextState: any, action: any) {
      nextState.editingHazards = action.payload; // mutate the state all you want with immer
    },
    setControlsToSave(nextState: any, action: any) {
      nextState.controlsToSave = action.payload; // mutate the state all you want with immer
    },
    setStepToUpdate(nextState: any, action: any) {
      nextState.stepToUpdate = action.payload; // mutate the state all you want with immer
    },
    setHazardToUpdate(nextState: any, action: any) {
      nextState.hazardToUpdate = action.payload; // mutate the state all you want with immer
    },
    setIsControlsOverlayOpen(nextState: any, action: any) {
      nextState.isControlsOverlayOpen = action.payload; // mutate the state all you want with immer
    },
    resetWorkSteps: () => initialState,
  },
});

export const getIsRiskMatrixOverlayOpen = createSelector(
  (state: any) => state.workSteps.isRiskMatrixOverlayOpen,
  (isRiskMatrixOverlayOpen) => isRiskMatrixOverlayOpen
);

export const getIsInitialRiskOverlayOpen = createSelector(
  (state: any) => state.workSteps.isInitialRiskOverlayOpen,
  (isInitialRiskOverlayOpen) => isInitialRiskOverlayOpen
);
export const getIsResidualRiskOverlayOpen = createSelector(
  (state: any) => state.workSteps.isResidualRiskOverlayOpen,
  (isResidualRiskOverlayOpen) => isResidualRiskOverlayOpen
);
export const getIsPpeDrawerOpen = createSelector(
  (state: any) => state.workSteps.isPpeDrawerOpen,
  (isPpeDrawerOpen) => isPpeDrawerOpen
);
export const getControlsToSave = createSelector(
  (state: any) => state.workSteps.controlsToSave,
  (controlsToSave) => controlsToSave
);

export const getInitialLikelihoodToSave = createSelector(
  (state: any) => state.workSteps.initialLikelihood,
  (initialLikelihood) => initialLikelihood
);

export const getInitialSeverityToSave = createSelector(
  (state: any) => state.workSteps.initialSeverity,
  (initialSeverity) => initialSeverity
);

export const getInitialRiskToSave = createSelector(
  (state: any) => state.workSteps.initialRisk,
  (initialRisk) => initialRisk
);
export const getResidualLikelihoodToSave = createSelector(
  (state: any) => state.workSteps.residualLikelihood,
  (residualLikelihood) => residualLikelihood
);

export const getResidualSeverityToSave = createSelector(
  (state: any) => state.workSteps.residualSeverity,
  (residualSeverity) => residualSeverity
);
export const getEditingHazards = createSelector(
  (state: any) => state.workSteps.editingHazards,
  (editingHazards) => editingHazards
);

export const getResidualRiskToSave = createSelector(
  (state: any) => state.workSteps.residualRisk,
  (residualRisk) => residualRisk
);

export const getHazardsToSave = createSelector(
  (state: any) => state.workSteps.hazardsToSave,
  (hazardsToSave) => hazardsToSave
);

export const getCustomHazardsToSave = createSelector(
  (state: any) => state.workSteps.categoriesWithCustomHazards,
  (categoriesWithCustomHazards) => categoriesWithCustomHazards
);

export const getStepToUpdate = createSelector(
  (state: any) => state.workSteps.stepToUpdate,
  (stepToUpdate) => stepToUpdate
);

export const getHazardToUpdate = createSelector(
  (state: any) => state.workSteps.hazardToUpdate,
  (hazardToUpdate) => {
    if (hazardToUpdate) {
      return hazardToUpdate;
    }
    return {};
  }
);

export const getItemToDelete = createSelector(
  (state: any) => state.workSteps.itemToDelete,
  (itemToDelete) => itemToDelete
);

export const getIsHazardOverlayOpen = createSelector(
  (state: any) => state.workSteps.isHazardOverlayOpen,
  (isHazardOverlayOpen) => isHazardOverlayOpen
);

export const getIsControlsOverlayOpen = createSelector(
  (state: any) => state.workSteps.isControlsOverlayOpen,
  (isControlsOverlayOpen) => isControlsOverlayOpen
);

export default workSteps;
