import React from 'react';
import { TrayNavListItem } from '../../components';
import { useAuth } from 'contexts/AuthProvider';

const AuthButton = function () {
  const { signOut } = useAuth();
  return <TrayNavListItem handleClick={signOut} text="Sign out" iconName="Profile" />;
};

export default AuthButton;
