import React from 'react';
import { ButtonProps } from '@material-ui/core/Button';
import { StyledButton, StyledIcon } from './style';

const MenuButton: React.FC<ButtonProps> = (props) => {
  return (
    <StyledButton {...props}>
      <StyledIcon name="NavGroup" preset="tertiary" />
    </StyledButton>
  );
};

MenuButton.defaultProps = {
  'aria-controls': 'customized-menu',
  'aria-haspopup': 'true',
  variant: 'contained',
  color: 'default',
};

export default MenuButton;
