import { createSelector } from 'reselect';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';
import flatten from 'lodash/fp/flatten';
import isolationOptions from '../../constants/isolationOptions';

// Base selectors
export const getJobSafetyAnalysis = (data: any) => get(data, 'getJobSafetyAnalysis', {});
export const getJsaApplicationTitle = (data: any) =>
  get(data, 'getJobSafetyAnalysis.jsaApplicationTitle', null);
export const getWorkOrderId = (data: any) => get(data, 'getJobSafetyAnalysis.workOrderId', null);
export const getWorkPack = (data: any) => get(data, 'getJobSafetyAnalysis.workPack', {});
export const getWorkSteps = (data: any) => get(data, 'getJobSafetyAnalysis.workSteps', []);

// Computed selectors
export const getWorksafeNotification = createSelector(
  [getWorkPack],
  (workPack) => workPack.worksafeNotification || null
);

export const getPermitsToWork = createSelector(
  [getWorkPack],

  (workPack) => {
    if (!isEmpty(workPack.permits)) {
      return workPack.permits.map((item: any) => {
        if (item.fileType !== '') {
          const fileName = get(item, 'fileName');
          const fileSize = get(item, 'fileSize');
          const bucketName = get(item, 'file.bucket');
          const filePath = get(item, 'file.key');

          return {
            label: get(item, 'fileType.label'),
            fileName: fileName && fileSize ? `${fileName} (${fileSize})` : null,
            filePath,
            bucketName,
          };
        }
        return undefined;
      });
    }

    return [];
  }
);

export const getFilteredPermitsToWork = createSelector([getPermitsToWork], (permits) => {
  if (!isEmpty(permits)) {
    return permits.filter((item: any) => item !== undefined);
  }
});

export const getIsolations = createSelector([getWorkPack], ({ isolations }) => {
  if (!isEmpty(isolations)) {
    return isolations;
  }

  return [];
});

export const getOnlyCompleteIsolations = createSelector([getIsolations], (isolations) =>
  isolations.filter((item: any) => item.isolationType !== ' ')
);

export const getIsolationCertificates = createSelector([getIsolations], (isolations) =>
  flow(
    filter(({ isolationType }: any) => isolationType === 'isolationCertificate'),
    map((item: any) => {
      const fileName = get(item, 'fileName');
      const fileSize = get(item, 'fileSize');
      const isolationType = get(item, 'isolationType');
      const label = get(isolationOptions, `${isolationType}.label`);
      const bucketName = get(item, 'file.bucket');
      const filePath = get(item, 'file.key');

      return {
        label,
        fileName: fileName && fileSize ? `${fileName} (${fileSize})` : null,
        filePath,
        bucketName,
      };
    })
  )(isolations)
);

export const getWorkStepSelfIsolations = createSelector([getWorkSteps], (workSteps) => {
  const selfIsolations = flow(
    map(({ hazards }: any) => hazards),
    flatten,
    filter((item: any) => get(item, 'controls.isolations.selfIsolationValue')),
    map((item: any) => {
      const label = get(isolationOptions, 'selfIsolation.label');
      const selfIsolation = get(item, 'controls.isolations.selfIsolationValue');
      return {
        label,
        selfIsolation,
      };
    })
  )(workSteps);

  return selfIsolations || [];
});

export const getSelfIsolations = createSelector(
  [getIsolations, getWorkStepSelfIsolations],

  (workPackIsolations, workStepSelfIsolations) => {
    const workPackSelfIsolations = flow(
      filter(({ isolationType }: any) => isolationType === 'selfIsolation'),
      map((item: any) => {
        const isolationType = get(item, 'isolationType');
        const label = get(isolationOptions, `${isolationType}.label`);
        const selfIsolation = get(item, 'selfIsolation');

        return {
          label,
          selfIsolation,
        };
      })
    )(workPackIsolations);

    return [...workPackSelfIsolations, ...workStepSelfIsolations];
  }
);
