import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import RenderChildByType from '../utils/RenderChildByType';
import { StyledDialog } from './style';
import ModalTitle from './ModalTitle';
import ModalContent from './ModalContent';

export interface ModalProps extends DialogProps {
  size?: any;
  height?: any;
}

const Modal = function ({ children, size, height, ...rest }: ModalProps) {
  return (
    <StyledDialog size={size} height={height} {...rest}>
      <RenderChildByType childType={ModalTitle}>{children}</RenderChildByType>
      <RenderChildByType childType={ModalContent}>{children}</RenderChildByType>
    </StyledDialog>
  );
};

Modal.defaultProps = {
  maxWidth: 'md',
  onClose: () => ({}),
  open: false,
  size: '1072px',
};

export default Modal;
