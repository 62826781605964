import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FileIcon from './Icons/FileIcon';
import PopupWindow from './popups/PopupWindow';
import { CommentButton } from '../../features/Summary/style';
import { v4 as uuidv4 } from 'uuid';
import { AuthContext } from 'contexts/AuthProvider';

class UserInput extends Component {
  static contextType = AuthContext;
  constructor() {
    super();
    this.state = {
      inputActive: false,
      inputHasText: false,
      emojiPickerIsOpen: false,
      emojiFilter: '',
      data: [],
    };
  }

  handleKeyDown(event) {
    if (event.keyCode === 13 && !event.shiftKey) {
      return this._submitText(event);
    }
  }

  handleKeyUp(event) {
    const inputHasText = event.target.innerHTML.length !== 0 && event.target.innerText !== '\n';
    this.setState({ inputHasText });
  }

  _showFilePicker() {
    this._fileUploadButton.click();
  }

  toggleEmojiPicker = (e) => {
    e.preventDefault();
    if (!this.state.emojiPickerIsOpen) {
      this.setState({ emojiPickerIsOpen: true });
    }
  };

  closeEmojiPicker = (e) => {
    if (this.emojiPickerButton.contains(e.target)) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState({ emojiPickerIsOpen: false });
  };

  getMonthName = (monthNum) => {
    switch (monthNum) {
      case 0:
        return 'Jan';
      case 1:
        return 'Feb';
      case 2:
        return 'Mar';
      case 3:
        return 'Apr';
      case 4:
        return 'May';
      case 5:
        return 'Jun';
      case 6:
        return 'Jul';
      case 7:
        return 'Aug';
      case 8:
        return 'Sep';
      case 9:
        return 'Oct';
      case 10:
        return 'Nov';
      case 11:
        return 'Dec';
      default:
        return 'Dec';
    }
  };

  async _submitText(event) {
    event.preventDefault();
    
    const submittedBy =
      this.props.data.getJobSafetyAnalysis.submittedBy.firstName +
      ' ' +
      this.props.data.getJobSafetyAnalysis.submittedBy.lastName;

    const resolved = this.context.user.firstName  + ' ' + this.context.user.lastName === submittedBy;

    const text = this.userInput.textContent;
    const date = new Date();
    if (text && text.length > 0) {
      this.props.onSubmit({
        author:  this.context.user ? this.context.user.firstName + ' ' + this.context.user.lastName : '',
        type: 'text',
        date: String(date.getDate()) + ' ' + this.getMonthName(date.getMonth()),
        text: text,
        resolved: resolved,
        createdDate: new Date().toISOString(),
        id: uuidv4(),
      });
      this.userInput.innerHTML = '';
    }
  }

  _onFilesSelected(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.props.onFilesSelected(event.target.files);
    }
  }

  _handleEmojiPicked = (emoji) => {
    this.setState({ emojiPickerIsOpen: false });
    if (this.state.inputHasText) {
      this.userInput.innerHTML += emoji;
    } else {
      this.props.onSubmit({
        author: 'me',
        type: 'emoji',
        data: { emoji },
      });
    }
  };

  handleEmojiFilterChange = (event) => {
    const emojiFilter = event.target.value;
    this.setState({ emojiFilter });
  };

  _renderEmojiPopup = () => (
    <PopupWindow
      isOpen={this.state.emojiPickerIsOpen}
      onClickedOutside={this.closeEmojiPicker}
      onInputChange={this.handleEmojiFilterChange}
    ></PopupWindow>
  );

  _renderSendOrFileIcon() {
    return (
      <div className="sc-user-input--button">
        {/* <FileIcon onClick={this._showFilePicker.bind(this)} />
        <input
          type="file"
          name="files[]"
          multiple
          ref={(e) => {
            this._fileUploadButton = e;
          }}
          onChange={this._onFilesSelected.bind(this)}
        /> */}
      </div>
    );
  }

  render() {
    const { inputActive } = this.state;
    const name = this.context.user ? this.context.user.firstName + ' ' + this.context.user.lastName : '';
    const splitActive = name.split(' ');
    const initials = splitActive[0][0] + splitActive[1][0];
    return (
      <div className={`sc-user-input ${inputActive ? 'active' : ''}`}>
        <div style={{ display: 'flex' }}>
          <svg className={`sc-message--avatar input-icon`}>
            <g>
              <circle style={{ fill: '#AFCE82', cx: '15', cy: '15', r: '15' }}></circle>
              <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dy=".3em"
                style={{ font: 'bold 12px sans-serif' }}
              >
                {initials}
              </text>
            </g>
          </svg>
          <div
            role="button"
            tabIndex="0"
            onFocus={() => {
              this.setState({ inputActive: true });
            }}
            onBlur={() => {
              this.setState({ inputActive: false });
            }}
            ref={(e) => {
              this.userInput = e;
            }}
            onKeyDown={this.handleKeyDown.bind(this)}
            onKeyUp={this.handleKeyUp.bind(this)}
            contentEditable="true"
            placeholder="Type your comment"
            className="sc-user-input--text"
          ></div>
        </div>
        <div className="sc-user-input--buttons">
          {this._renderSendOrFileIcon()}
          <CommentButton
            onClick={(event) => {
              this._submitText(event);
            }}
            className="screen-only"
            themeColour="blue"
          >
            POST
          </CommentButton>
        </div>
      </div>
    );
  }
}

UserInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onFilesSelected: PropTypes.func.isRequired,
  showEmoji: PropTypes.bool,
};

export default UserInput;
