import React from 'react';
import { Menu, MenuButton, MenuItem } from '../../components';
import { StyledValidationIcon } from './style';

const WorkStepMenuContent = function ({ isFirst, editStep, deleteStep }: any) {
  /*
   * We wrap MenuItems in <div> to fix forwardRefs bug
   * https://stackoverflow.com/questions/56307332/how-to-use-custom-functional-components-within-material-ui-menu
   */
  return (
    <div>
      <MenuItem onClick={editStep}>Edit description</MenuItem>
      <MenuItem disabled={isFirst} onClick={deleteStep}>
        Delete this step
      </MenuItem>
    </div>
  );
};

const WorkStepMenu = function (props: any) {
  const { isMenuOpen, anchorEl, handleClose, handleClick, isErrored } = props;
  return (
    <>
      {isErrored && <StyledValidationIcon name="ValidationErrorRed" />}
      <MenuButton className="work-step-menu-button" onClick={handleClick} />
      <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleClose}>
        {isMenuOpen && <WorkStepMenuContent {...props} />}
      </Menu>
    </>
  );
};

export default WorkStepMenu;
