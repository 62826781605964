import React from 'react';
import { connect, FieldArray, getIn } from 'formik';
import map from 'lodash/map';
import { v4 as uuidv4 } from 'uuid';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  GridContainer,
  GridItem,
  AddButton,
} from '../../components';
import SupportingDocumentInput from './SupportingDocumentInput';

const SupportingDocuments = function (props: any) {
  const [expandedPanels, setExpandedPanels] = React.useState<{
    panel1: boolean;
    panel2: boolean;
  }>({ panel1: true, panel2: true });

  const handleChange = (panel: string) => () => {
    const currentValue = expandedPanels[panel];

    setExpandedPanels({
      ...expandedPanels,
      [panel]: !currentValue,
    });
  };

  const supportDocuments = getIn(props.formik.values, 'workPack.supportDocuments');

  return (
    <Accordion borderTop expanded={expandedPanels.panel1 === true} onChange={handleChange('panel1')}>
      <AccordionSummary>Supporting documents</AccordionSummary>
      <AccordionDetails>
        <GridContainer>
          <FieldArray
            name="workPack.supportDocuments"
            render={(arrayHelpers: any) => (
              <>
                {map(supportDocuments, (item: any, index: any) => (
                  <SupportingDocumentInput
                    key={`SupportingDocumentInput-${index}`}
                    index={index}
                    arrayHelpers={arrayHelpers}
                    supportDocument={item}
                  />
                ))}
                <GridItem xs={12}>
                  <AddButton
                    text="Add Documents"
                    onClick={() => {
                      const id = uuidv4();
                      const file = { bucket: '', key: '', region: '' };
                      const fileType = {
                        id: '',
                        label: '',
                        value: '',
                      };
                      const newValue = {
                        file,
                        fileName: '',
                        id,
                        createdDate: new Date().toISOString(),
                        fileSize: '',
                        fileType,
                      };
                      arrayHelpers.push(newValue);
                    }}
                  />
                </GridItem>
              </>
            )}
          />
        </GridContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default connect(SupportingDocuments);
