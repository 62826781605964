import React from 'react';
import styled from 'styled-components';
import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';

const StyledButton = styled(({ ...rest }) => <Button {...rest} />)`
  height: 40px;
  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;

    path,
    polygon {
      fill: #fff;
      stroke: #fff;
    }
  }

  &:hover {
    & path,
    & polygon {
      fill: white;
      stroke: white;
    }
  }
`;

interface Props {
  onClick?: () => any;
}

const AddButton: React.FC<Props> = function ({ ...rest }) {
  return (
    <StyledButton id="add_hazards_button" preset="primary" themeColour="blue" size="small" {...rest}>
      <Icon name="Add" />
      Add Hazards
    </StyledButton>
  );
};

export default AddButton;
