import React from 'react';
import { ButtonBase } from './styles';
import { PresetType, ThemeColourType } from './button-types';

interface ButtonProps {
  [key: string]: any;
  preset?: PresetType;
  themeColour?: ThemeColourType;
  isInputEmpty?: boolean;
  bold?: boolean;
  fullWidth?: boolean;
}

const Button = function ({ preset = 'primary', themeColour = 'leaf', bold, ...rest }: ButtonProps) {
  return <ButtonBase preset={preset} themeColour={themeColour} bold={bold} {...rest} />;
};

export default Button;
