import React from 'react';
import Modal from '../Modal/Modal';
import ModalTitle from '../Modal/ModalTitle';
import ModalContent from '../Modal/ModalContent';
import ModalContentText from '../Modal/ModalContentText';
import ModalActions from '../Modal/ModalActions';
import GridContainer from '../Grid/GridContainer';
import { GridItem, Button } from '..';

const ControlsSuggestionDeleteModal = function ({ isOpen, handleClose, handleDelete }: any) {
  return (
    <Modal open={isOpen}>
      <ModalTitle onClose={handleClose}>Remove this control measure?</ModalTitle>
      <ModalContent>
        <ModalContentText>By deleting this control measure your risk may change.</ModalContentText>
        <ModalActions>
          <GridContainer>
            <GridItem xs={6}>
              <Button preset="secondary" onClick={handleClose} themeColour="grey" fullWidth>
                Cancel
              </Button>
            </GridItem>
            <GridItem xs={6}>
              <Button preset="primary" themeColour="red" fullWidth onClick={handleDelete}>
                Remove
              </Button>
            </GridItem>
          </GridContainer>
        </ModalActions>
      </ModalContent>
    </Modal>
  );
};

export default ControlsSuggestionDeleteModal;
