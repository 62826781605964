import React from 'react';
import { useFormikContext, getIn } from 'formik';
import Typography from '../Typography/Typography';
import { PpeSectionBase } from './styles';
import IconCardInputGroup from '../IconCardInputGroup/IconCardInputGroup';
import DrawerActions from './DrawerActions';
import { getOptionalPpe, getRequiredPpe } from './ppeSelectors';
import useClientState from '../../hooks/useClientState';

export default function PpeSection({ onClose, data }: any) {
  const optionalPPE = getOptionalPpe(data as never);
  const requiredPPE = getRequiredPpe(data as never);
  const { setFieldValue, values } = useFormikContext();
  const { setIsPpeDrawerOpen } = useClientState();

  function handleSubmit() {
    setIsPpeDrawerOpen(false);
    const draftPpe = getIn(values, 'controls.draftPpe');
    const filterPpe = draftPpe.filter((ppe: any) => !ppe.required);
    const unified = filterPpe.concat(requiredPPE);
    setFieldValue('controls.draftPpe' as never, filterPpe);
    setFieldValue('controls.ppe' as never, unified);
  }

  return (
    <PpeSectionBase data-scroll-lock-scrollable>
      <Typography variant="h4">Mandatory PPE List</Typography>
      {requiredPPE !== [] && (
        <IconCardInputGroup name="ppe" items={requiredPPE} cardType="ppe" mandatory />
      )}
      <Typography variant="h4">Select PPE</Typography>
      {optionalPPE !== [] && (
        <IconCardInputGroup name="controls.draftPpe" items={optionalPPE} cardType="ppe" />
      )}
      <DrawerActions onClose={onClose} handleSubmit={handleSubmit} />
    </PpeSectionBase>
  );
}
