import React, { useEffect, useState } from 'react';
import { InputAdornment, Select } from '@material-ui/core';
import Arrow from '@material-ui/icons/ExpandMore';
import { split } from 'lodash';
import { StyledInput, StyledMenuItem, useStyles } from './styles';
import { useScreenOrientation } from '../../hooks';

const EmailInput = function (props: any) {
  const [role, setRole] = useState('Collaborator');
  const classes = useStyles();
  const {
    valid,
    initEmail,
    setInitEmail,
    setEmails,
    setIsDisabled,
    hasError,
    errorText,
    setHasError,
    setErrorText,
  } = props;
  const { ref, screenOrientation } = useScreenOrientation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (screenOrientation === 'portrait') {
        if (ref.current !== null) {
          // @ts-check
          ref.current.blur();
        }
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [screenOrientation]);

  useEffect(() => {
    if (initEmail !== '') {
      const str = initEmail.trim();
      const emailsArray = split(str, ',');
      const result: { email: string; role: string }[] = [];
      emailsArray.forEach((item) => {
        result.push({ email: item.trim(), role });
      });
      setEmails(result);

      if (result.length > 0) {
        setIsDisabled(false);
      }
    }
  }, [role, initEmail, setEmails, setIsDisabled]);

  const handleRoleChange = (event: any) => {
    setRole(event.target.value);
  };

  const onChangeHandler = (event: any) => {
    setInitEmail(event.target.value);

    setHasError(false);
    setErrorText('');

    if (event.target.value === '') {
      setIsDisabled(true);
    }
  };

  const roleIconAdornment = (
    <InputAdornment position="end">
      <Select
        className={classes.select}
        style={{ marginTop: '9px', fontSize: '0.75em', paddingRight: '0px' }}
        labelId="role-type-select-label"
        id="role-type-select"
        value={role}
        onChange={handleRoleChange}
        IconComponent={Arrow}
        disableUnderline
      >
        <StyledMenuItem value="Collaborator">Collaborator</StyledMenuItem>
        <StyledMenuItem value="Reviewer">Reviewer</StyledMenuItem>
        <StyledMenuItem value="Confirmer">Confirmer</StyledMenuItem>
      </Select>
    </InputAdornment>
  );

  return (
    <StyledInput
      valid={valid}
      variant="outlined"
      value={initEmail}
      inputRef={ref}
      placeholder="Use commas to separate email addresses"
      InputProps={{ endAdornment: roleIconAdornment }}
      onChange={onChangeHandler}
      error={hasError}
      helperText={errorText}
    />
  );
};

export default EmailInput;
