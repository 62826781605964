import React from 'react';
import { StyledLinkButton, StyledLinkIcon } from './styles';

const LinkButton = function ({ text, icon, ...rest }: any) {
  return (
    <StyledLinkButton preset="tertiary" themeColour="blue" {...rest}>
      <StyledLinkIcon name={icon} preset="tertiary" />
      {text}
    </StyledLinkButton>
  );
};

export default LinkButton;
