import React from 'react';
import {
  StyledSummaryPpeCard,
  StyledSummaryPpeCardIconWrapper,
  StyledSummaryPpeCardTextWrapper,
  StyledPpeText,
} from './style';
import PpeIcon from '../Icon/PpeIcon/PpeIcon';

const SummaryPpeCard = function ({ code, name, colour }: any) {
  return (
    <StyledSummaryPpeCard>
      <StyledSummaryPpeCardIconWrapper colour={colour}>
        <PpeIcon name={code} />
      </StyledSummaryPpeCardIconWrapper>
      <StyledSummaryPpeCardTextWrapper>
        <StyledPpeText variant="body2">{name}</StyledPpeText>
      </StyledSummaryPpeCardTextWrapper>
    </StyledSummaryPpeCard>
  );
};

export default SummaryPpeCard;
