import React, { memo } from 'react';
import { Form } from 'formik';
import JsaForm from '../JsaForm/JsaForm';
import setInitialValues from '../JsaForm/setInitialValues';
import { useJsaQuery } from '../../hooks';
import WorkContacts from './WorkContacts';
import WorkDescription from './WorkDescription';
import { ContentLoader } from '../../components';
import WorkTeam from './WorkTeam';

const WorkDetailsForm = memo(() => {
  const { data, loading } = useJsaQuery();
  const initialValues = setInitialValues(data);

  if (!data) {
    return <ContentLoader />;
  }

  return (
    <JsaForm initialValues={initialValues} isLoading={loading}>
      <Form>
        <WorkContacts createdBy={data.getJobSafetyAnalysis.createdBy} />
        <WorkTeam />
        <WorkDescription />
      </Form>
    </JsaForm>
  );
});

WorkDetailsForm.displayName = 'WorkDetailsForm';

export default WorkDetailsForm;
