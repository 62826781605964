import React from 'react';
import Accordion from '../Accordion/Accordion';
import Typography from '../Typography/Typography';
import { AccordionSummary, AccordionDetails } from '../Accordion/styles';
import Tooltip from '../Tooltip/Tooltip';
import IsolationsInputs from './IsolationsInputs';

const Isolations = function () {
  const [expandedPanels, setExpandedPanels] = React.useState<{
    panel1: boolean;
    panel2: boolean;
  }>({ panel1: true, panel2: true });

  const handleChange = (panel: string) => () => {
    // @ts-check
    const currentValue = expandedPanels[panel];
    setExpandedPanels({
      ...expandedPanels,
      [panel]: !currentValue,
    });
  };

  return (
    <Accordion borderTop expanded={expandedPanels.panel1 === true}>
      <AccordionSummary>
        <Typography variant="h3">
          Isolations{' '}
          <Tooltip
            style={{ marginLeft: '8px', marginRight: '6px' }}
            placement="bottom"
            text="HazardIsolations"
          />
          <div
            style={{
              width: '100px',
              display: 'inline-flex',
              height: '24px',
              float: 'right',
              right: '-5px',
              position: 'absolute',
              top: '25px',
              zIndex: 1000,
            }}
            onClick={handleChange('panel1')}
          />
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <IsolationsInputs />
      </AccordionDetails>
    </Accordion>
  );
};

export default Isolations;
