import React from 'react';
// @ts-check
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

function useLockBodyScroll() {
  React.useEffect(() => disablePageScroll(), []);
  React.useEffect(
    () => () => {
      enablePageScroll();
    },
    []
  );
}

export default useLockBodyScroll;
