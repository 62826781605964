import React from 'react';
import styled from 'styled-components';
import Typography from '../Typography/Typography';

export const SummarySectionTitleIconWrapper = styled.div`
  position: relative;
  width: 40px;
  height: 39px;
  margin-right: 14px;

  & svg {
    width: 40px;
    height: 39px;
  }
`;

export const StyledSummarySectionTitle = styled(({ id, workDetails, ...rest }) => (
  <Typography {...rest} />
)).attrs(() => ({
  variant: 'h2',
  gutterBottom: true,
}))`
  @media print and (max-device-width: 1366px) and (orientation: landscape) {
    ${({ workDetails }) =>
      workDetails &&
      `
  position: absolute;
top: -45px;
left: 40px;
`}
  }

  @media print and (orientation: portrait) {
    ${({ workDetails }) =>
      workDetails &&
      `
      position: absolute;
  top: 40px;
left: 40px;
`}
  }
  display: flex;
  align-items: center;
`;

export const Text = styled(Typography).attrs(() => ({
  variant: 'body1',
}))`
  &&.MuiTypography-body1 {
    position: absolute;
    left: 14px;
    top: 6px;
    color: ${(props) => props.theme.colors.primary.darkBlue};
    font-size: 18px;
    line-height: 27px;
  }
`;
