import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Explosives } from '../svgs/hazards/explosives.svg';
import { ReactComponent as LiftingEquipment } from '../svgs/hazards/lifting-equipment.svg';
import { ReactComponent as RodentInsects } from '../svgs/hazards/rodent-insects.svg';
import { ReactComponent as Tunnelling } from '../svgs/hazards/tunnelling.svg';
import { ReactComponent as Animals } from '../svgs/hazards/animals.svg';
import { ReactComponent as Asbestos } from '../svgs/hazards/asbestos.svg';
import { ReactComponent as BoatsPontoons } from '../svgs/hazards/boats-pontoons.svg';
import { ReactComponent as ChemEnviroDust } from '../svgs/hazards/chem-enviro-dust.svg';
import { ReactComponent as Chemicals } from '../svgs/hazards/chemicals.svg';
import { ReactComponent as ConfinedSpace } from '../svgs/hazards/confined-space.svg';
import { ReactComponent as Covid } from '../svgs/hazards/covid-19.svg';
import { ReactComponent as CustomIcon } from '../svgs/hazards/custom-icon.svg';
import { ReactComponent as CustomHazard } from '../svgs/hazards/custom-hazard.svg';
import { ReactComponent as DamsLakesPonds } from '../svgs/hazards/dams-lakes-ponds.svg';
import { ReactComponent as Eeha } from '../svgs/hazards/eeha.svg';
import { ReactComponent as Electrical } from '../svgs/hazards/electrical.svg';
import { ReactComponent as EntryPoint } from '../svgs/hazards/entry-point.svg';
import { ReactComponent as Fatigue } from '../svgs/hazards/fatigue.svg';
import { ReactComponent as FixedPlantEquipment } from '../svgs/hazards/fixed-plant-equipment.svg';
import { ReactComponent as FlammableSubstances } from '../svgs/hazards/flammable-substances.svg';
import { ReactComponent as HandTools } from '../svgs/hazards/hand-tools.svg';
import { ReactComponent as HazardousSurfaces } from '../svgs/hazards/hazardous-surfaces.svg';
import { ReactComponent as HeavyLifting } from '../svgs/hazards/heavy-lifting.svg';
import { ReactComponent as HotColdTemp } from '../svgs/hazards/hot-cold-temp.svg';
import { ReactComponent as HotWorks } from '../svgs/hazards/hot-works.svg';
import { ReactComponent as Hydraullic } from '../svgs/hazards/hydraullic.svg';
import { ReactComponent as Impairment } from '../svgs/hazards/impairment.svg';
import { ReactComponent as Ladder } from '../svgs/hazards/ladder.svg';
import { ReactComponent as LoadingUnloading } from '../svgs/hazards/loading-unloading.svg';
import { ReactComponent as Mechanical } from '../svgs/hazards/mechanical.svg';
import { ReactComponent as Mhf } from '../svgs/hazards/mhf.svg';
import { ReactComponent as MobilePlantEquipment } from '../svgs/hazards/mobile-plant-equipment.svg';
import { ReactComponent as Noise } from '../svgs/hazards/noise.svg';
import { ReactComponent as Penetration } from '../svgs/hazards/penetration.svg';
import { ReactComponent as Pedestrians } from '../svgs/hazards/pedestrians.svg';
import { ReactComponent as PoorVisibility } from '../svgs/hazards/poor-visibility.svg';
import { ReactComponent as Public3rdParty } from '../svgs/hazards/public-3rd-party.svg';
import { ReactComponent as Repetitive } from '../svgs/hazards/repetitive.svg';
import { ReactComponent as ReservoirsWells } from '../svgs/hazards/reservoirs-wells.svg';
import { ReactComponent as RoadCorridorTraffic } from '../svgs/hazards/road-corridor-traffic.svg';
import { ReactComponent as SharpsBrokenItems } from '../svgs/hazards/sharps-broken-items.svg';
import { ReactComponent as SiteTraffic } from '../svgs/hazards/site-traffic.svg';
import { ReactComponent as Skylight } from '../svgs/hazards/skylight.svg';
import { ReactComponent as StackingRacking } from '../svgs/hazards/stacking-racking.svg';
import { ReactComponent as StructureWoBarrier } from '../svgs/hazards/structure-wo-barrier.svg';
import { ReactComponent as SuspendedLoads } from '../svgs/hazards/suspended-loads.svg';
import { ReactComponent as TrenchExcavation } from '../svgs/hazards/trench-excavation.svg';
import { ReactComponent as Ultraviolet } from '../svgs/hazards/ultraviolet.svg';
import { ReactComponent as UnderOverServices } from '../svgs/hazards/under-over-services.svg';
import { ReactComponent as UnstableGround } from '../svgs/hazards/unstable-ground.svg';
import { ReactComponent as Vibration } from '../svgs/hazards/vibration.svg';
import { ReactComponent as ViralBacterial } from '../svgs/hazards/viral-bacterial.svg';
import { ReactComponent as Weather } from '../svgs/hazards/weather.svg';
import { ReactComponent as WorkingAlone } from '../svgs/hazards/working-alone.svg';
import { ReactComponent as WorkingAtHeight } from '../svgs/hazards/working-at-height.svg';
import { ReactComponent as WorkingDownstream } from '../svgs/hazards/working-downstream.svg';
import { ReactComponent as ExplosiveGases } from '../svgs/hazards/explosive-gases.svg';
import { ReactComponent as HazardousAtmosphere } from '../svgs/hazards/hazardous-atmosphere.svg';

export const hazardNames = {
  Explosives,
  LiftingEquipment,
  RodentInsects,
  Tunnelling,
  Animals,
  Asbestos,
  BoatsPontoons,
  ChemEnviroDust,
  Chemicals,
  ConfinedSpace,
  Covid,
  CustomIcon,
  CustomHazard,
  DamsLakesPonds,
  Eeha,
  Electrical,
  EntryPoint,
  Fatigue,
  FixedPlantEquipment,
  FlammableSubstances,
  HandTools,
  HazardousSurfaces,
  HeavyLifting,
  HotColdTemp,
  HotWorks,
  Hydraullic,
  Impairment,
  Ladder,
  LoadingUnloading,
  Mechanical,
  Mhf,
  MobilePlantEquipment,
  Noise,
  Penetration,
  Pedestrians,
  PoorVisibility,
  Public3rdParty,
  Repetitive,
  ReservoirsWells,
  RoadCorridorTraffic,
  SharpsBrokenItems,
  SiteTraffic,
  Skylight,
  StackingRacking,
  StructureWoBarrier,
  SuspendedLoads,
  TrenchExcavation,
  Ultraviolet,
  UnderOverServices,
  UnstableGround,
  Vibration,
  ViralBacterial,
  Weather,
  WorkingAlone,
  WorkingAtHeight,
  WorkingDownstream,
  ExplosiveGases,
  HazardousAtmosphere,
};

export interface HazardIconProps {
  name: string;
  viewBox?: string;
}

// eslint-disable-next-line @typescript-eslint/no-use-before-define
const StyledHazardIcon = styled(({ ...rest }) => <HazardIcon {...rest} />)`
  width: 100px;
  height: 100px;
`;

const HazardIcon = ({ name, ...rest }: HazardIconProps) => {
  if (!hazardNames[name]) {
    return <StyledHazardIcon as={hazardNames['CustomHazard']} {...rest} />;
  }

  return <StyledHazardIcon as={hazardNames[name]} {...rest} />;
};

export default HazardIcon;
