export default {
  changesRequiredJsa: {
    id: process.env.REACT_APP_EMAIL_CHANGES_REQUIRED_JSA_ID,
    version: process.env.REACT_APP_EMAIL_CHANGES_REQUIRED_JSA_VERSION,
  },
  confirmed: {
    id: process.env.REACT_APP_EMAIL_CONFIRMED_ID,
    version: process.env.REACT_APP_EMAIL_CONFIRMED_VERSION,
  },
  reviewJsa: {
    id: process.env.REACT_APP_EMAIL_REVIEW_JSA_ID,
    version: process.env.REACT_APP_EMAIL_REVIEW_JSA_VERSION,
  },
  submittedJsa: {
    id: process.env.REACT_APP_EMAIL_SUBMITTED_JSA_ID,
    version: process.env.REACT_APP_EMAIL_SUBMITTED_JAS_VERSION_ID,
  },
  awaitingConfirmation: {
    id: process.env.REACT_APP_EMAIL_AWAITING_CONFIRM_ID,
    version: process.env.REACT_APP_EMAIL_AWAITING_CONFIRM_VERSION,
  },
  sentBack: {
    id: process.env.REACT_APP_EMAIL_SENT_BACK_ID,
    version: process.env.REACT_APP_EMAIL_SENT_BACK_VERSION,
  },
  cancelJsa: {
    id: process.env.REACT_APP_EMAIL_CANCEL_JSA_ID,
    version: process.env.REACT_APP_EMAIL_CANCEL_JSA_VERSION,
  },
  shareJsa: {
    id: process.env.REACT_APP_SHARE_JSA_ID,
    version: process.env.REACT_APP_SHARE_JSA_VERSION,
  },
};
