import React from 'react';
import { ReactComponent as Slider } from './svgs/slider.svg';
import { ReactComponent as Add } from './svgs/add-btn.svg';
import { ReactComponent as AddLight } from './svgs/add-btn.svg';
import { ReactComponent as AddressPin } from './svgs/address-pin.svg';
import { ReactComponent as ArrowDown } from './svgs/arrow-down.svg';
import { ReactComponent as ArrowDownDark } from './svgs/arrow-down-dark.svg';
import { ReactComponent as ArrowDownLight } from './svgs/arrow-down-light.svg';
import { ReactComponent as ArrowLeft } from './svgs/arrow-left.svg';
import { ReactComponent as ArrowRight } from './svgs/arrow-right.svg';
import { ReactComponent as ArrowUp } from './svgs/arrow-up.svg';
import { ReactComponent as Attachment } from './svgs/attachment.svg';
import { ReactComponent as Burger } from './svgs/burger.svg';
import { ReactComponent as ClearText } from './svgs/clear-text.svg';
import { ReactComponent as Close } from './svgs/close.svg';
import { ReactComponent as Controls } from './svgs/controls.svg';
import { ReactComponent as DateIcon } from './svgs/date.svg';
import { ReactComponent as Download } from './svgs/download.svg';
import { ReactComponent as Dragger } from './svgs/dragger.svg';
import { ReactComponent as DropletLightBlue } from './svgs/droplet-light-blue.svg';
import { ReactComponent as EditLight } from './svgs/edit-light.svg';
import { ReactComponent as Empty } from './svgs/radio/empty.svg';
import { ReactComponent as Expand } from './svgs/expand.svg';
import { ReactComponent as Flask } from './svgs/flask.svg';
import { ReactComponent as GoTop } from './svgs/go-top.svg';
import { ReactComponent as HazardList } from './svgs/hazard-list.svg';
import { ReactComponent as Hidden } from './svgs/hidden.svg';
import { ReactComponent as Home } from './svgs/home.svg';
import { ReactComponent as KnowledgeBase } from './svgs/knowledge-base.svg';
import { ReactComponent as MapIcon } from './svgs/map.svg';
import { ReactComponent as Minimize } from './svgs/minimize.svg';
import { ReactComponent as NavGroup } from './svgs/nav-group.svg';
import { ReactComponent as ReadyReview } from './svgs/need-review.svg';
import { ReactComponent as NewJsa } from './svgs/new-jsa.svg';
import { ReactComponent as NormalTick } from './svgs/normal-tick.svg';
import { ReactComponent as Notifications } from './svgs/notifications.svg';
import { ReactComponent as PlusBig } from './svgs/plus-big.svg';
import { ReactComponent as PPE } from './svgs/ppe.svg';
import { ReactComponent as Profile } from './svgs/profile.svg';
import { ReactComponent as Questionnaire } from './svgs/questionnaire.svg';
import { ReactComponent as Review } from './svgs/review.svg';
import { ReactComponent as Search } from './svgs/search.svg';
import { ReactComponent as Selected } from './svgs/selected.svg';
import { ReactComponent as Settings } from './svgs/settings.svg';
import { ReactComponent as Sorting } from './svgs/sorting.svg';
import { ReactComponent as Stop } from './svgs/stop.svg';
import { ReactComponent as TakeFive } from './svgs/take-five.svg';
import { ReactComponent as TakePhoto } from './svgs/take-photo.svg';
import { ReactComponent as Tick } from './svgs/tick.svg';
import { ReactComponent as Toolbox } from './svgs/toolbox.svg';
import { ReactComponent as Tooltip } from './svgs/tooltip.svg';
import { ReactComponent as Trash } from './svgs/trash.svg';
import { ReactComponent as TrashInactive } from './svgs/trash-inactive.svg';
import { ReactComponent as TypeCursor } from './svgs/type-cursor.svg';
import { ReactComponent as Unselected } from './svgs/unselected.svg';
import { ReactComponent as UnTick } from './svgs/checkbox-unchecked.svg';
import { ReactComponent as Uploading } from './svgs/uploading.svg';
import { ReactComponent as Warning } from './svgs/warning.svg';
import { ReactComponent as Connector } from './svgs/connector.svg';
import { ReactComponent as ExternalLink } from './svgs/external-link.svg';
import { ReactComponent as Confirmed } from './svgs/jsa-confirmed.svg';
import { ReactComponent as ConnectorArrow } from './svgs/connector-arrow.svg';
import { ReactComponent as TabsValidationError } from './svgs/tabs-validation-error.svg';
import { ReactComponent as TitleDot } from './svgs/title-dot.svg';
import { ReactComponent as JobcareLogo } from './svgs/jobcare-logo.svg';
import { ReactComponent as Undo } from './svgs/undo.svg';
import { ReactComponent as Droplet1 } from './svgs/droplets/no.1.svg';
import { ReactComponent as Droplet2 } from './svgs/droplets/no.2.svg';
import { ReactComponent as Droplet3 } from './svgs/droplets/no.3.svg';
import { ReactComponent as Droplet4 } from './svgs/droplets/no.4.svg';
import { ReactComponent as HowTo } from './svgs/how-to.svg';
import { ReactComponent as UrlLink } from './svgs/url-link.svg';
import { ReactComponent as TickLightBackground } from './svgs/tick-light-background.svg';
import { ReactComponent as ValidationErrorRed } from './svgs/validation-error-red.svg';
import { ReactComponent as GreenValidationTick } from './svgs/green-validation-tick.svg';

import { StyledIcon } from './style';

export const iconNames = {
  Add,
  AddLight,
  AddressPin,
  ArrowDown,
  ArrowDownDark,
  ArrowDownLight,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  Attachment,
  Burger,
  Connector,
  ConnectorArrow,
  Confirmed,
  ClearText,
  Close,
  Controls,
  DateIcon,
  Download,
  Dragger,
  DropletLightBlue,
  EditLight,
  Empty,
  Expand,
  ExternalLink,
  Flask,
  GoTop,
  HazardList,
  Hidden,
  Home,
  JobcareLogo,
  KnowledgeBase,
  MapIcon,
  Minimize,
  NavGroup,
  ReadyReview,
  NewJsa,
  NormalTick,
  Notifications,
  PlusBig,
  PPE,
  Profile,
  Questionnaire,
  Review,
  Search,
  Selected,
  Settings,
  Slider,
  Sorting,
  Stop,
  TakeFive,
  TakePhoto,
  Tick,
  TitleDot,
  Toolbox,
  Tooltip,
  Trash,
  TrashInactive,
  TypeCursor,
  Undo,
  Unselected,
  UnTick,
  Uploading,
  Warning,
  Droplet1,
  Droplet2,
  Droplet3,
  Droplet4,
  HowTo,
  UrlLink,
  TickLightBackground,
  TabsValidationError,
  ValidationErrorRed,
  GreenValidationTick,
};

export interface IconProps {
  name: string;
  preset?: any;
  isSelected?: boolean;
}

function Icon({ name, preset, ...rest }: IconProps) {
  if (name) {
    return <StyledIcon as={iconNames[name]} preset={preset} {...rest} />;
  }

  return null;
}

export default Icon;
