import { useEffect, useContext } from 'react';
import { getIn } from 'formik';
import isEqual from 'lodash/isEqual';
import { JsaFormContext } from '../features/JsaForm/JsaForm';
import usePrevious from './usePrevious';

function useAutoPopulateByValue(
  field1Path: string,
  field2Path: string,
  condition?: boolean,
  value?: string
) {
  const formikContext = useContext(JsaFormContext);
  // @ts-check
  const field1Value = getIn(formikContext.values, field1Path);

  const previousValues = usePrevious(field1Value);

  useEffect(() => {
    if (field1Value && condition) {
      if (value !== undefined) {
        if (previousValues && !isEqual(previousValues, field1Value)) {
          // @ts-check
          formikContext.setFieldValue(field2Path, value);
        }
      }
    }
  }, [field1Value, condition]);

  return null;
}

export default useAutoPopulateByValue;
