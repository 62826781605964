import React from 'react';
import { SummarySubSection, SummarySubSectionTitle } from './style';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Fallback from '../../components/Fallback/Fallback';
import SummaryContentBlock from './SummaryContentBlock';
import { getWorksafeNotification } from './summaryWorkpackSelectors';

const SummaryWorkpackSupportingDocuments = function ({ data }: any) {
  const worksafeNotification = getWorksafeNotification(data);

  return (
    <SummarySubSection>
      <GridContainer>
        <GridItem xs={12}>
          <SummarySubSectionTitle>Worksafe notification form</SummarySubSectionTitle>
        </GridItem>
        <GridItem xs={12}>
          <SummaryContentBlock wide title="Worksafe notification no.">
            <Fallback value={worksafeNotification} />
          </SummaryContentBlock>
        </GridItem>
      </GridContainer>
    </SummarySubSection>
  );
};

export default SummaryWorkpackSupportingDocuments;
