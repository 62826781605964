import { createSelector } from 'reselect';
import get from 'lodash/get';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

// Base selectors
export const getJobSafetyAnalysis = (data: any) => get(data, 'getJobSafetyAnalysis', {});
export const getJsaApplicationTitle = (data: any) =>
  get(data, 'getJobSafetyAnalysis.jsaApplicationTitle', null);
export const getWorkOrderId = (data: any) => get(data, 'getJobSafetyAnalysis.workOrderId', null);
export const getWorkDetails = (data: any) => get(data, 'getJobSafetyAnalysis.workDetails', {});

// Computed selectors
export const getCreatedDate = createSelector([getJobSafetyAnalysis], ({ createdDate }) => {
  if (createdDate) {
    return format(parseISO(createdDate), 'd MMM yyyy, h:m a');
  }

  return null;
});

export const getAssignedTo = createSelector([getJobSafetyAnalysis], (jsa) => jsa.assignedTo);

export const getReviewedBy = createSelector([getJobSafetyAnalysis], (jsa) => jsa.reviewedBy);

export const getConfirmedBy = createSelector([getJobSafetyAnalysis], (jsa) => jsa.confirmedBy);

export const getServiceProvider = createSelector(
  [getWorkDetails],
  (workDetails) => workDetails.serviceProvider
);

export const getPersonInCharge = createSelector(
  [getWorkDetails],
  (workDetails) => workDetails.personInCharge || {}
);

export const getPersonInChargeName = createSelector([getPersonInCharge], ({ firstName, lastName }) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return null;
});

export const getPersonInChargeNumber = createSelector(
  [getPersonInCharge],
  ({ contactNumber }) => contactNumber
);

export const getPersonInChargeEmail = createSelector([getPersonInCharge], ({ email }) => email);

export const getWatercareRepresentative = createSelector(
  [getWorkDetails],
  (workDetails) => workDetails.watercareRepresentative || {}
);

export const getWatercareRepresentativeName = createSelector(
  [getWatercareRepresentative],
  ({ firstName, lastName }) => {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }

    return null;
  }
);

export const getWatercareRepresentativeNumber = createSelector(
  [getWatercareRepresentative],
  ({ contactNumber }) => contactNumber
);

export const getWatercareRepresentativeEmail = createSelector(
  [getWatercareRepresentative],
  ({ email }) => email
);

export const getWorkLocationProcessArea = createSelector(
  [getWorkDetails],
  ({ workLocationProcessArea }) => workLocationProcessArea
);

export const getPlannedWorkDate = createSelector(
  [getWorkDetails],
  ({ plannedWorkDate }) => plannedWorkDate || {}
);

export const getStartDate = createSelector([getPlannedWorkDate], ({ startDate }) => {
  if (startDate) {
    return format(parseISO(startDate), 'd MMM yyyy');
  }
  return null;
});

export const getEndDate = createSelector([getPlannedWorkDate], ({ endDate }) => {
  if (endDate) {
    return format(parseISO(endDate), 'd MMM yyyy');
  }
  return null;
});

export const getSiteName = createSelector(
  [getWorkDetails],
  (workDetails) => get(workDetails, 'siteName.value') || get(workDetails, 'siteName.label')
);

export const getSiteAddress = createSelector([getWorkDetails], ({ siteAddress }) => siteAddress);
export const getOperatingArea = createSelector([getWorkDetails], ({ operatingArea }) => operatingArea);

export const getJsaWrittenBy = createSelector(
  [getWorkDetails],
  ({ jsaWrittenBy }) => jsaWrittenBy || {}
);

export const getJsaWrittenByName = createSelector([getJsaWrittenBy], ({ firstName, lastName }) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return null;
});

export const getJsaWrittenByNumber = createSelector(
  [getJsaWrittenBy],
  ({ contactNumber }) => contactNumber
);

export const getWrittenByEmailAddress = createSelector([getJsaWrittenBy], ({ email }) => email);

export const getDescriptionOfWork = createSelector(
  [getWorkDetails],
  ({ descriptionOfWork }) => descriptionOfWork
);

export const getScopeOfWork = createSelector([getWorkDetails], ({ scopeOfWork }) => scopeOfWork);
export const getAssignedToDate = createSelector([getJobSafetyAnalysis], ({ assignedToDate }) => {
  if (assignedToDate) {
    return format(parseISO(assignedToDate), 'd MMM yyyy, h:mm a');
  }
  return null;
});
export const getAssignedToName = createSelector([getAssignedTo], ({ firstName, lastName }) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return null;
});

export const getReviewedByDate = createSelector([getJobSafetyAnalysis], ({ reviewedByDate }) => {
  if (reviewedByDate) {
    return format(parseISO(reviewedByDate), 'd MMM yyyy, h:mm a');
  }
  return null;
});
export const getReviewedByName = createSelector([getReviewedBy], (user) => {
  const { firstName, lastName } = user || {};
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return null;
});

export const getConfirmedDate = createSelector([getJobSafetyAnalysis], ({ confirmedDate }) => {
  if (confirmedDate) {
    return format(parseISO(confirmedDate), 'd MMM yyyy, h:mm a');
  }
  return null;
});
export const getConfirmedByName = createSelector([getConfirmedBy], (user) => {
  const { firstName, lastName } = user || {};
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return null;
});
export const getJsaId = createSelector([getJobSafetyAnalysis], ({ jsaId }) => jsaId);
