import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import Typography from '../../components/Typography/Typography';

export const WorkStepListHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const WorkStepListHeaderActions = styled.div`
  display: flex;
  align-items: center;

  & button {
    margin-left: 25px;
  }
`;

export const StyledIconButton = styled(IconButton)`
  color: #1c7b97;
  font-family: 'Nunito Sans';
  font-size: 16px;
  line-height: 24px;
  padding-right: 24.3px;
`;
export const StepHeader = styled.div`
  display: flex;
`;

export const StyledTypography = styled(Typography)`
  color: #8b9799;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.4px;
  margin-bottom: 10px;
`;

export const DividerStep = styled(({ padding, ...rest }) => <div {...rest} />)`
  padding-left: ${({ padding }) => padding};
`;
