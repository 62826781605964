import React from 'react';
import styled from 'styled-components';
import AppHeader from '../AppHeader/AppHeader';

const ContentLayout = styled.div`
  padding: 41px;
  padding-top: 31px;
  margin-bottom: 68px;

  @media print {
    padding: 0;
  }
`;

const CommonLayout = function (props: any) {
  return (
    <>
      <AppHeader shouldRenderTabs={props.shouldRenderTabs} />
      <ContentLayout>{props.children}</ContentLayout>
      {props.footerControls}
    </>
  );
};

CommonLayout.defaultProps = {
  shouldRenderTabs: false,
};

export default CommonLayout;
