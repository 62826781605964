const doubleClickMaxDelay = 300;

let latestClick = {
  time: 0,
  target: null,
};

function isDoubleClick(event: any) {
  const click = {
    time: new Date().getTime(),
    target: event.currentTarget,
  };
  const isFastDblClick =
    click.target === latestClick.target && click.time - latestClick.time < doubleClickMaxDelay;

  latestClick = click;
  return isFastDblClick;
}

export default isDoubleClick;
