import workFlowRoutes from '../../constants/workFlowRoutes';

export const getNextPage = (currentPage: string) => {
  switch (currentPage) {
    case 'work-details':
      return workFlowRoutes[1];
    case 'emergency-details':
      return workFlowRoutes[2];
    case 'hazards-controls':
      return workFlowRoutes[3];
    case 'workpack':
      return workFlowRoutes[4];
    case 'summary':
      return workFlowRoutes[4];
  }
};

export const getPreviousPage = (currentPage: string) => {
  switch (currentPage) {
    case 'emergency-details':
      return workFlowRoutes[0];
    case 'hazards-controls':
      return workFlowRoutes[1];
    case 'workpack':
      return workFlowRoutes[2];
    case 'summary':
      return workFlowRoutes[3];
  }
};
