import React from 'react';
import { uniqueId } from 'lodash';
import Label from '../Label/Label';
import IconCardInputGroup from '../IconCardInputGroup/IconCardInputGroup';

const HazardCategory = function ({ hazardCategory: { name, hazards }, onAddCategory }: any) {
  return (
    <>
      <Label label={name} />
      <IconCardInputGroup
        name="draftSelectedHazards"
        items={hazards}
        cardType="hazard"
        newItem={{
          isVisible: true,
          input: {
            name: uniqueId('CustomHazard-'),
            placeholder: 'Hazard name',
          },
          button: {
            label: 'CUSTOM HAZARD',
            onClick: onAddCategory,
          },
        }}
      />
    </>
  );
};

export default HazardCategory;
