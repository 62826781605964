import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import Icon from '../Icon/Icon';
import {
  StyledCard,
  CardLabel,
  StyledHazardIcon,
  StyledPpeIcon,
  IconWraper,
  CardLabelWrapper,
  DeleteIcon,
} from './styles';

// @TODO: Find a way to name type from Hazards & PPE
interface IconCardProps {
  name?: string;
  isSelected?: boolean;
  canDelete?: boolean;
  cardType?: 'hazard' | 'ppe';
  label?: string;
  colour?: string;
  children?: any;
  deleteHandler?: () => void;
}

const IconCard = function ({
  name,
  isSelected = false,
  cardType = 'hazard',
  label,
  colour,
  canDelete = false,
  deleteHandler,
  children,
}: IconCardProps) {
  return (
    <StyledCard
      colour={colour}
      isSelected={isSelected}
      cardType={cardType}
      canDelete={canDelete}
      onClick={deleteHandler}
    >
      <CardContent>
        <IconWraper>
          {cardType === 'hazard' && <StyledHazardIcon name={name} isSelected={isSelected} />}
          {cardType === 'ppe' && <StyledPpeIcon name={name} isSelected={isSelected} colour={colour} />}
        </IconWraper>
        {label && (
          <CardLabelWrapper>
            <CardLabel colour={colour} cardType={cardType} isSelected={isSelected} label={label}>
              {label}
            </CardLabel>
          </CardLabelWrapper>
        )}
      </CardContent>
      {canDelete && (
        <div className="delete-icon-wrapper">
          <DeleteIcon>
            <Icon name="Trash" preset="tertiary" />
          </DeleteIcon>
        </div>
      )}
      {children}
    </StyledCard>
  );
};

export default IconCard;
