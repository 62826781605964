import styled from 'styled-components';

export const StyledLabel = styled.label<{ alignLabel?: boolean }>`
  display: inline-block;
  font-family: 'Nunito Sans';
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.greyScale['65']};
  margin-right: ${({ alignLabel }) => (alignLabel ? '20px' : '')};
  margin-top: ${({ alignLabel }) => (alignLabel ? '5px' : '')};
`;

export const LabelWrapper = styled.div`
  padding-bottom: 8px;
  display: inline-flex;
  padding-right: 8px;
`;
