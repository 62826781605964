import React from 'react';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { ThemeProvider } from 'styled-components';
import { JobCareTheme } from './styles/theme';
import { AnyProps } from './types';
import muiTheme from './styles/muiTheme';
import './index.css';
import 'typeface-nunito-sans';
import 'normalize.css';

const AppProvider = (props: AnyProps) => {
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={{ ...muiTheme, ...JobCareTheme }}>
          <DndProvider backend={HTML5Backend}>
            <div>{props.children}</div>
          </DndProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};

export default AppProvider;
