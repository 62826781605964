import React from 'react';
import { Field } from 'formik';
import format from 'date-fns/format';
import { get } from 'lodash';
import { StyledAgreedButton, StyledAgreedText, StyledAgreedTick } from './style';
import Icon from '../Icon/Icon';
import { useJsaQuery } from '../../hooks';

interface AgreedButtonProps {
  id?: string;
  name?: string;
  isFirst?: boolean;
  agreeArray?: string[];
}

function handleClick(field: any, form: any, date: any, agreeArray: string[]) {
  const fieldIndex = field.name.match(/\d+/g);
  form.setFieldValue(field.name, true);
  form.setFieldValue(`${field.name}Date`, date);

  agreeArray[fieldIndex] = 'true';
}

const agreedButton = ({ field, form, ...props }: any) => {
  const currentDate = format(new Date(), 'd MMM yyyy');
  const formDate = form.getFieldProps(`${field.name}Date`).value;

  if (!field.value || field.value === 'false') {
    return (
      // @ts-check
      <StyledAgreedButton
        disabled={props.status !== 'agreed'}
        $isfirst={props.isFirst}
        onClick={() => handleClick(field, form, currentDate, props.agreeArray)}
        preset="secondary"
        themeColour="mediumBlue"
      >
        AGREE
      </StyledAgreedButton>
    );
  }
  return (
    <StyledAgreedText>
      <StyledAgreedTick isFirst={props.isFirst}>
        <Icon name="NormalTick" />
        <div style={{ paddingLeft: '6px' }}>Agreed</div>
      </StyledAgreedTick>
      <div>{formDate}</div>
    </StyledAgreedText>
  );
};

const AgreedButton = function ({ id, name, isFirst, agreeArray }: AgreedButtonProps) {
  const { data } = useJsaQuery();
  const status = get(data, 'getJobSafetyAnalysis.status');
  return (
    <Field
      name={name}
      id={id}
      component={agreedButton}
      isFirst={isFirst}
      status={status}
      agreeArray={agreeArray}
    />
  );
};

export default AgreedButton;
