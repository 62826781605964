import React, { Fragment, memo } from 'react';
import get from 'lodash/get';
import { useFormikContext, getIn } from 'formik';
import { GridItem, FormikAutocomplete, LinkButton, ContentLoader } from '../../components';
import { StyledRemoveButton, UploadControlsGridItem, UploadControl } from './styles';
import UploadInput from '../UploadInput/UploadInput';
import getPermitDocumentUrl from './getPermitDocumentUrl';
import { Storage } from 'aws-amplify';

const PermitsToWorkInput = memo(
  ({
    index,
    permitMasterData,
    permitFileTypeOptions,
    arrayHelpers,
    permit,
    permitDocumentData,
  }: any) => {
    const { values } = useFormikContext();
    const filePath = get(permit, 'file.key');
    const fileTypeLabel = get(permit, 'fileType.label');
    const isInputDisabled = !fileTypeLabel;
    const isSelectInputDisabled = Boolean(fileTypeLabel) && Boolean(filePath);
    const isPermitSelected = permit.fileType === '' || permit.fileType === null;
    let permitUrl: string | undefined;

    const handleDeleteClick = async () => {
      const awsFileKey = getIn(values, `workPack.permits[${index}].file.key`);
      await Storage.remove(awsFileKey).then(() => arrayHelpers.remove(index));
    };

    if (!permitDocumentData.loading && permit.fileType) {
      permitUrl = getPermitDocumentUrl(permit.fileType.id, permitDocumentData);
    }

    if (permitDocumentData.loading) {
      return <ContentLoader />;
    }

    return (
      <Fragment key={index}>
        <GridItem xs={5}>
          {permitMasterData && (
            <FormikAutocomplete
              id={`workPack.permits[${index}].fileType`}
              name={`workPack.permits[${index}].fileType`}
              options={permitFileTypeOptions}
              label=""
              isCreatable={false}
              isDisabled={isSelectInputDisabled}
            />
          )}
        </GridItem>

        <UploadControlsGridItem xs={7}>
          <UploadControl>
            <LinkButton
              disabled={isPermitSelected}
              text="Download template"
              href={permitUrl}
              target="_blank"
              icon="Download"
            />
          </UploadControl>
          <UploadControl>
            <UploadInput
              name={`workPack.permits[${index}]`}
              label="Upload filled permit"
              isInputDisabled={isInputDisabled}
            />
          </UploadControl>
          <UploadControl>
            <StyledRemoveButton onClick={handleDeleteClick} />
          </UploadControl>
        </UploadControlsGridItem>
      </Fragment>
    );
  }
);

PermitsToWorkInput.displayName = 'PermitsToWorkInput';

export default PermitsToWorkInput;
