import React from 'react';
import Typography from '../Typography/Typography';
import { Heading, HeaderDivider } from './styles';
import ModalOverlayCloseButton from '../ModalOverlayCloseButton/ModalOverlayCloseButton';

export default function DrawerHeader({ onClose }: any) {
  return (
    <>
      <ModalOverlayCloseButton onClose={onClose} />
      <Heading variant="h2">Personal Protective Equipment List</Heading>
      <Typography style={{ textAlign: 'center' }} variant="body1">
        Choose the PPE that will be used as a hazard control for this step.
      </Typography>
      <HeaderDivider />
    </>
  );
}
