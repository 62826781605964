import React, { ReactNode } from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import OverlayTitle from './OverlayTitle';
import { StyledOverlay } from './styles';
import OverlayContext from './OverlayContext';

export interface OverlayProps extends DialogProps {
  subTitle: ReactNode;
  tooltip?: string;
}

const Overlay = function ({ id, title, subTitle, children, tooltip, onClose, ...rest }: OverlayProps) {
  const [trigger, setTrigger] = React.useState(false);
  const [contentRef, setContentRef] = React.useState(false);

  return (
    <StyledOverlay fullScreen scroll="paper" onClose={onClose} {...rest}>
      <OverlayContext.Provider
        value={{
          trigger,
          setTrigger,
          contentRef,
          setContentRef,
        }}
      >
        <OverlayTitle id={id} title={title} subTitle={subTitle} onClose={onClose} tooltip={tooltip} />
        {children}
      </OverlayContext.Provider>
    </StyledOverlay>
  );
};

export default Overlay;
