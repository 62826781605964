import React, { memo } from 'react';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { get } from 'lodash';
import AppHeaderTab from '../../components/AppHeaderTab/AppHeaderTab';
import workFlowRoutes from '../../constants/workFlowRoutes';
import useJsaQuery from '../../hooks/useJsaQuery';
import { useSubmitValidation } from '../../contexts/useSubmitValidation';
import { Icon } from 'components';
import { useAuth } from 'contexts/AuthProvider';

function a11yProps(index: any) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
}

const StyledTabs = styled(Tabs)`
  & .MuiTab-wrapper {
    white-space: pre;
    padding-top: 20px;
    padding-bottom: 20px;
    color: ${(props) => props.theme.colors.primary.darkBlue};
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
  }
`;

const TabLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & svg {
    height: 16px;
    width: 16px;
    margin-right: 10px;
  }
`;

function getValueFromPath(path: any) {
  switch (path) {
    case 'work-details':
      return 0;
    case 'emergency-details':
      return 1;
    case 'hazards-controls':
      return 2;
    case 'workpack':
      return 3;
    case 'summary':
      return 4;
    default:
      return 0;
  }
}

const AppHeaderTabs = memo(() => {
  const path = useLocation().pathname.split('/')[3];
  const pathStateValue = getValueFromPath(path);
  const navigate = useNavigate();
  const { jsa: jsaId }: any = useParams();
  const { data } = useJsaQuery();

  const {
    hasUserAttemptedSubmission,
    numberOfEmergencyDetailsErrors,
    numberOfWorkDetailsErrors,
    isHazardsAndControlsValid,
    showEmailClashMessaging,
  } = useSubmitValidation();

  const { user } = useAuth();
  let disabled = false;
  if (data && data.getJobSafetyAnalysis.status !== 'draft') {
    disabled = true;
  }
  if (data && data.getJobSafetyAnalysis.status === 'changesRequired') {
    if (get(data, 'getJobSafetyAnalysis.assignedTo.email', undefined) !== get(user, 'email')) {
      disabled = false;
    }
  }

  const handleTabClick = (route: string): void => {
    navigate(`/jsa/${jsaId}/${route}`);
  };

  const isPageErrored = (route): boolean => {
    switch (route) {
      case 'emergency-details':
        return numberOfEmergencyDetailsErrors > 0;
      case 'work-details':
        return numberOfWorkDetailsErrors > 0 || showEmailClashMessaging !== null;
      case 'hazards-controls':
        return !isHazardsAndControlsValid();
      default:
        return false;
    }
  };

  return (
    <StyledTabs value={pathStateValue} indicatorColor="primary" centered aria-label="action tabs">
      {workFlowRoutes.map(({ headerLabel, route, label }, index) => {
        if (disabled && path === 'summary' && route === 'summary') disabled = false;
        return (
          <AppHeaderTab
            key={index}
            disabled={disabled}
            onClick={() => handleTabClick(route)}
            label={
              <TabLabel>
                {hasUserAttemptedSubmission && isPageErrored(route) && (
                  <Icon name="TabsValidationError" />
                )}
                {hasUserAttemptedSubmission && isPageErrored(route) ? label : headerLabel}
              </TabLabel>
            }
            {...a11yProps(index)}
          />
        );
      })}
    </StyledTabs>
  );
});

AppHeaderTabs.displayName = 'AppHeaderTabs';

export default AppHeaderTabs;
