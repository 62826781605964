import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoSvg } from './Logo.svg';

const StyledLogo = styled(LogoSvg)`
  height: auto;
  width: 101px;
  display: none;
`;

interface LogoProps {
  [key: string]: any;
}

const Logo: React.FC<LogoProps> = () => {
  return <StyledLogo />;
};

export default Logo;
