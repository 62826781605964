import React, { memo } from 'react';
import { default as Chip } from '../Chip/Chip';
import getStatusAttributes from './getStatusAttributes';

export type StatusProps = {
  value: string;
};

const Status = memo(({ value = 'draft' }: StatusProps) => {
  const { color = 'lightGreen', text } = getStatusAttributes(value);

  if (!color || !text) {
    return null;
  }

  return (
    <div>
      <Chip color={color} text={text} />
    </div>
  );
});

Status.displayName = 'StatusChip';

export default Status;
