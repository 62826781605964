import colorString from 'color-string';
import Color from 'color';

const colors = {
  misc: {
    headerBlue: '#cfedf1',
  },
  primary: {
    darkBlue: '#213D58',
    mediumBlue: '#40B3DB',
    dullBlue: '#213C57',
    leaf: '#648001',
  },
  greyScale: {
    0: '#FFF',
    3: '#F6F7F7',
    6: '#F0F0F0',
    10: '#E1E5E5',
    25: '#B6BEBF',
    40: '#8B9799',
    50: '#6C7D80',
    65: '#43575A',
    80: '#263133',
    95: '#0A0D0D',
  },
  greyScaleClean: {
    greyScale_0: '#FFF',
    greyScale_3: '#F6F7F7',
    greyScale_6: '#F0F0F0',
    greyScale_10: '#E1E5E5',
    greyScale_25: '#B6BEBF',
    greyScale_40: '#8B9799',
    greyScale_50: '#6C7D80',
    greyScale_65: '#43575A',
    greyScale_80: '#263133',
    greyScale_95: '#0A0D0D',
  },
  complementary: {
    lightGreen: '#AFCE82',
    green: '#366412',
    lightLeaf: '#799900',
    lightMint: '#5DDEDC',
    mint: '#9EDAE2',
    lightBlue: '#9EDAE2',
    lightestBlue: '#CFEDF1',
    blue: '#1C7B97',
    moss: '#A6BFAA',
    yellow: '#F9C83A',
    pink: '#FF8E81',
    sunrise: '#F37021',
    tomato: '#BE581A',
    red: '#B4432F',
    darkRed: '#741F10',
    lightPurple: '#A56BBE',
    purple: '#8131A4',
    magenta: '#AD3769',
    summerSky: '#40b3db',
    white: '#ffffff',
    darkCyan: '#18A2A2',
    veryDarkCyan: '#0A0D0D',
  },
};

export type MiscColors = keyof typeof colors.misc;
export type PrimaryColors = keyof typeof colors.primary;
export type GreyScale = keyof typeof colors.greyScaleClean;
export type ComplementaryColors = keyof typeof colors.complementary;

const findColor = (color: MiscColors | PrimaryColors | GreyScale | ComplementaryColors) => {
  const mergedColors = {
    ...colors.misc,
    ...colors.primary,
    ...colors.greyScaleClean,
    ...colors.complementary,
  };

  return mergedColors[color];
};

const mixHoverColor = (color: MiscColors | PrimaryColors | GreyScale | ComplementaryColors) => {
  const rgbColor = colorString.get(findColor(color));

  if (rgbColor) {
    return Color.rgb(rgbColor.value)
      .mix(Color([33, 61, 88, 1.5]))
      .hex();
  }
};

const toExport = { ...colors, findColor, mixHoverColor };

export default toExport;
