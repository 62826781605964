import { createSelector } from 'reselect';
import get from 'lodash/get';
import isArray from 'lodash/isArray';

// Base selectors
export const getJobSafetyAnalysis = (data: any) => get(data, 'getJobSafetyAnalysis', {});
export const getEmergencyDetails = (data: any) => get(data, 'getJobSafetyAnalysis.emergencyDetails', {});

// Computed selectors
export const getEmergencyContacts = createSelector([getEmergencyDetails], (emergencyDetails) => {
  if (isArray(emergencyDetails.emergencyContacts)) {
    return emergencyDetails.emergencyContacts.map(({ firstName, lastName, contactNumber }: any) => ({
      fullName: firstName && lastName ? `${firstName} ${lastName}` : null,
      contactNumber,
    }));
  }

  return [];
});

export const getFacilityControlRoom = createSelector(
  [getEmergencyDetails],
  (emergencyDetails) =>
    get(emergencyDetails, 'facilityControlRoom.value') ||
    get(emergencyDetails, 'facilityControlRoom.label')
);

export const getFacilityControlRoomNumber = createSelector(
  [getEmergencyDetails],
  (emergencyDetails) => emergencyDetails.facilityControlRoomNumber
);

export const getNearestMedicalFacility = createSelector(
  [getEmergencyDetails],
  (emergencyDetails) => emergencyDetails.nearestMedicalFacility
);

export const getNearestMedicalFacilityContactDetails = createSelector(
  [getEmergencyDetails],
  (emergencyDetails) => emergencyDetails.nearestMedicalFacilityContactDetails
);

export const getEvacuationPoint = createSelector(
  [getEmergencyDetails],
  (emergencyDetails) => emergencyDetails.evacuationPoint
);

export const getFirstAidKitLocation = createSelector(
  [getEmergencyDetails],
  (emergencyDetails) =>
    get(emergencyDetails, 'firstAidKitLocation.value') ||
    get(emergencyDetails, 'firstAidKitLocation.label')
);
