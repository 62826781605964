import React from 'react';
import { DialogActionsProps } from '@material-ui/core/DialogActions';
import { Divider, StyledDialogActions } from './styles';

export interface OverlayProps extends DialogActionsProps {
  children?: React.ReactNode;
  trigger?: any;
}

const OverlayActions = function ({ children, trigger, ...rest }: OverlayProps) {
  return (
    <StyledDialogActions trigger={trigger} {...rest}>
      <Divider />
      {children}
    </StyledDialogActions>
  );
};

export default OverlayActions;
