import React from 'react';
import styled from 'styled-components';
import { Chip as MuiChip } from '@material-ui/core';

// We need to disbale the next line to allow the color prop to be omitted from the spread operator
// This allow us to use our custom colors and not throw the MUI warning.
// eslint-disable-next-line
export const StyledMuiChip = styled(({ color, ...rest }) => <MuiChip {...rest} />)`
  background-color: ${({ color, theme }) => {
    switch (color) {
      case 'yellow':
        return theme.colors.complementary.yellow;
      case 'leaf':
        return theme.colors.primary.leaf;
      case 'lightGreen':
        return theme.colors.complementary.lightGreen;
      case 'mint':
        return theme.colors.complementary.mint;
      case 'mediumBlue':
        return theme.colors.primary.mediumBlue;
      case 'darkCyan':
        return theme.colors.complementary.darkCyan;
      case 'darkBlue':
        return theme.colors.primary.darkBlue;
      case 'sunrise':
        return theme.colors.complementary.sunrise;
      case 'magenta':
        return theme.colors.complementary.magenta;
      case 'red':
        return theme.colors.complementary.red;
      case 'lightBlue':
        return theme.colors.complementary.lightBlue;
      case 'blue':
        return theme.colors.complementary.blue;
      case 'tomato':
        return theme.colors.complementary.tomato;
      default:
        return theme.colors.primary.leaf;
    }
  }};
  color: ${({ color, theme }) => {
    switch (color) {
      case 'yellow':
        return theme.colors.complementary.veryDarkCyan;
      case 'leaf':
        return theme.colors.complementary.white;
      case 'lightGreen':
        return theme.colors.complementary.veryDarkCyan;
      case 'mint':
        return theme.colors.complementary.veryDarkCyan;
      case 'mediumBlue':
        return theme.colors.complementary.veryDarkCyan;
      case 'darkCyan':
        return theme.colors.complementary.white;
      case 'darkBlue':
        return theme.colors.complementary.white;
      case 'sunrise':
        return theme.colors.complementary.veryDarkCyan;
      case 'magenta':
        return theme.colors.complementary.white;
      case 'red':
        return theme.colors.complementary.white;
      default:
        return theme.colors.complementary.veryDarkCyan;
    }
  }};
  -webkit-print-color-adjust: exact;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 4px;
  font-family: 'Nunito Sans';
  min-width: 80px;
  & .MuiChip-label {
    padding-right: 0px;
    padding-left: 0px;
    padding-top: 1px;
  }

  @media print {
    background-color: ${({ color, theme }) => {
      switch (color) {
        case 'yellow':
          return theme.colors.complementary.yellow;
        case 'leaf':
          return theme.colors.primary.leaf;
        case 'lightGreen':
          return theme.colors.complementary.lightGreen;
        case 'mint':
          return theme.colors.complementary.mint;
        case 'mediumBlue':
          return theme.colors.primary.mediumBlue;
        case 'darkCyan':
          return theme.colors.complementary.darkCyan;
        case 'darkBlue':
          return theme.colors.primary.darkBlue;
        case 'sunrise':
          return theme.colors.complementary.sunrise;
        case 'magenta':
          return theme.colors.complementary.magenta;
        case 'red':
          return theme.colors.complementary.red;
        case 'lightBlue':
          return theme.colors.complementary.lightBlue;
        case 'blue':
          return theme.colors.complementary.blue;
        case 'tomato':
          return theme.colors.complementary.tomato;
        default:
          return theme.colors.primary.leaf;
      }
    }};
    color: ${({ color, theme }) => {
      switch (color) {
        case 'yellow':
          return theme.colors.complementary.veryDarkCyan;
        case 'leaf':
          return theme.colors.complementary.white;
        case 'lightGreen':
          return theme.colors.complementary.veryDarkCyan;
        case 'mint':
          return theme.colors.complementary.veryDarkCyan;
        case 'mediumBlue':
          return theme.colors.complementary.veryDarkCyan;
        case 'darkCyan':
          return theme.colors.complementary.white;
        case 'darkBlue':
          return theme.colors.complementary.white;
        case 'sunrise':
          return theme.colors.complementary.veryDarkCyan;
        case 'magenta':
          return theme.colors.complementary.white;
        case 'red':
          return theme.colors.complementary.white;
        default:
          return theme.colors.complementary.veryDarkCyan;
      }
    }};
    -webkit-print-color-adjust: exact;
  }
`;
export interface ChipProps {
  color:
    | 'yellow'
    | 'leaf'
    | 'lightGreen'
    | 'mint'
    | 'mediumBlue'
    | 'darkCyan'
    | 'darkBlue'
    | 'sunrise'
    | 'magenta'
    | 'red'
    | 'lightBlue'
    | 'tomato'
    | 'blue';
  text: string;
}

const Chip: React.FC<ChipProps> = ({ text, color }) => {
  return (
    <div>
      <StyledMuiChip color={color} label={text} />
    </div>
  );
};

export default Chip;
