import React from 'react';
import MuiRadio from '@material-ui/core/Radio';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import styled from 'styled-components';
import Icon from '../Icon/Icon';

const ThemeIcon = styled(Icon)`
  height: 24px;
  width: 24px;
  border-radius: 12px;
`;

export const RadioFormControlLabel = styled(MuiFormControlLabel)`
  height: 24px;
  width: 250px;
  color: #0a0d0d;
  font-family: 'Nunito Sans';
  font-size: 18px;
  letter-spacing: 0.2px;
  line-height: 24px;
  margin-left: 0;
  & .MuiTypography-body1 {
    text-transform: none;
  }
`;

export const Radio = function (props: any) {
  return (
    <MuiRadio checkedIcon={<ThemeIcon name="Selected" />} icon={<ThemeIcon name="Empty" />} {...props} />
  );
};
