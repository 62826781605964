import styled from 'styled-components';
import React from 'react';
import { Accordion } from '../../components/Accordion/Accordion';
import { GridContainer } from '../../components';

export const StyledTitleAndButtonBar = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledGridContainer = styled(GridContainer)`
  margin-top: 10px;
`;
export const StyledAccordion = styled(Accordion)`
  border: 2px solid #cfedf1;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;

  & .MuiAccordionSummary-expandIcon {
    transition-duration: 0s;
  }

  & .MuiAccordionSummary-content {
    color: #213d58;
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 12px;
    cursor: context-menu;
  }

  & .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.MuiIconButton-edgeEnd {
    margin-bottom: 20px;
  }

  & .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6 {
    padding-bottom: 0px;
    padding-top: 5px;
  }
`;

export const StyledDiv = styled.div`
  & .MuiCollapse-container {
    transition-duration: 0s !important;
  }
`;

export const StyledDivContainer = styled.div`
  display: flex;
`;

export const StyledDivIcon = styled.div`
  flex: 0 0 40px;
`;

export const StyledShowHideButton = styled(({ children, ...rest }) => (
  <button {...rest}>{children}</button>
))`
  font-size: 16px;
  margin: 0px 0px;
  cursor: pointer;
  border: none;
  background-color: #f6f7f7;
  color: #1c7b97;
  cursor: pointer;
`;
