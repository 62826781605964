import React from 'react';
import { isEmpty } from 'lodash';
import { SummarySubSection, SummarySubSectionTitle, WorkpackList } from './style';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Fallback from '../../components/Fallback/Fallback';
import { getFilteredPermitsToWork } from './summaryWorkpackSelectors';
import SummaryItemLink from './SummaryItemLink';

const SummaryWorkpackPermitsToWork = function ({ data }: any) {
  const permitsToWork = getFilteredPermitsToWork(data as never);
  return (
    <SummarySubSection>
      <GridContainer>
        <GridItem xs={12}>
          <SummarySubSectionTitle>Permits to work</SummarySubSectionTitle>
        </GridItem>

        <GridItem xs={8}>
          <Fallback
            value={permitsToWork}
            message={
              !isEmpty(permitsToWork) ? 'Permits to Work not completed yet' : 'Permits to Work not added'
            }
            isText={false}
          >
            <WorkpackList>
              {permitsToWork &&
                permitsToWork.map((item: any, index: number) => (
                  <li key={`permitsToWork-${index}`}>
                    <SummaryItemLink item={item} key={index} />
                  </li>
                ))}
            </WorkpackList>
          </Fallback>
        </GridItem>
      </GridContainer>
    </SummarySubSection>
  );
};

export default SummaryWorkpackPermitsToWork;
