import React, { Component } from 'react';

class Header extends Component {
  render() {
    return (
      <div className="sc-header">
        <div className="sc-header--team-name"> {this.props.teamName} </div>
      </div>
    );
  }
}

export default Header;
