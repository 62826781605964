import React from 'react';
import { DialogContentProps } from '@material-ui/core/DialogContent';
import { StyledModalContent } from './style';

interface ModalContentProps extends DialogContentProps {
  padding?: string;
  overflowy?: boolean;
}

const ModalContent = function (props: ModalContentProps) {
  return <StyledModalContent {...props} />;
};

ModalContent.defaultProps = {
  padding: '',
};

export default ModalContent;
