import styled from 'styled-components';

export const PaginationDiv = styled.div`
  display: inline-flex;
  height: 40px;
  height: 40px;
  width: fit-content;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  p {
    margin: 8px;
    line-height: 24px;
    font-family: 'Nunito Sans';
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
  }
  .MuiIconButton-root {
    color: #1c7b97;
    padding: 8px;
  }
  a {
    text-decoration: none;
    cursor: pointer;
    width: 32px;
    height: 40px;
  }
  .active {
    border-bottom: 4px solid #40b3db;
    width: 32px;
    height: 36px;
    a {
      font-weight: bold;
    }
  }
  li {
    width: 32px;
    height: 40px;
    line-height: 40px;
    margin-right: 5px;
  }
  .Mui-disabled {
    color: #b6bebf;
  }
  .disableTextL {
    color: #b6bebf;
    margin-left: 0px;
    margin-right: 15px;
  }
  .disableTextR {
    color: #b6bebf;
    margin-left: 9px;
    margin-right: 0px;
  }
  .textL {
    color: #1c7b97;
    margin-left: 0px;
    margin-right: 15px;
  }
  .textR {
    color: #1c7b97;
    margin-left: 9px;
    margin-right: 0px;
  }

  @media screen and (max-width: 990px) and (min-width: 725px) {
    margin-left: 165px;
    li {
      width: 24px;
      text-align: center;
      margin-right: 2px;
    }
    button {
      padding-left: 2px !important;
      padding-right: 2px !important;
    }
    .active {
      width: 24px;
    }
    a {
      margin-left: 0px;
      margin-right: 0px;
    }
    nav {
      p {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
    .textL {
      margin-right: 10px;
    }
    .textR {
      margin-left: 10px;
    }
  }
`;

export const PaginationUl = styled.ul`
  margin: 0;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
  align-items: center;
`;
export const PaginationNav = styled.nav`
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  font-size: 0.875rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  p {
    height: 19px;
    width: 18px;
    color: #1c7b97;
    text-align: center;
  }
`;

export const PaginationItem = styled.button`
  color: rgba(0, 0, 0, 0.87);
  height: 32px;
  margin: 0 6px;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  min-width: 32px;
  box-sizing: border-box;
  text-align: center;
  color: #1c7b97;
  font-family: 'Nunito Sans';
  line-height: 1.43;
  letter-spacing: 0;
`;
