import React from 'react';
import { connect } from 'formik';
import Modal from '../../components/Modal/Modal';
import ModalTitle from '../../components/Modal/ModalTitle';
import ModalContent from '../../components/Modal/ModalContent';
import ModalContentText from '../../components/Modal/ModalContentText';
import ModalActions from '../../components/Modal/ModalActions';
import GridContainer from '../../components/Grid/GridContainer';
import { GridItem, Button } from '../../components';

const WorkStepDeleteHazard = function ({ handleClose, handleDelete, isOpen }: any) {
  return (
    <Modal open={isOpen}>
      <ModalTitle onClose={handleClose}>Remove this hazard?</ModalTitle>
      <ModalContent>
        <ModalContentText>
          By removing this hazard, any risk assessment and controls will be removed.
        </ModalContentText>
        <ModalActions>
          <GridContainer>
            <GridItem xs={6}>
              <Button preset="secondary" onClick={handleClose} themeColour="grey" fullWidth>
                Cancel
              </Button>
            </GridItem>
            <GridItem xs={6}>
              <Button
                preset="primary"
                themeColour="red"
                fullWidth
                onClick={() => {
                  handleDelete();
                }}
              >
                Remove
              </Button>
            </GridItem>
          </GridContainer>
        </ModalActions>
      </ModalContent>
    </Modal>
  );
};

export default connect(WorkStepDeleteHazard);
