import { Children, cloneElement } from 'react';

interface MyProps {
  [key: string]: any;
}
// @ts-check
const RenderChildByType: React.FC<MyProps> = function ({ children, childType, ...rest }) {
  if (Children.count(children) > 0) {
    return Children.map(children, (child: any) => {
      if (child.type === childType) {
        return cloneElement(child, {
          ...rest,
        });
      }
      return null;
    });
  }
  return null;
};

export default RenderChildByType;
