import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { useFormikContext, getIn } from 'formik';
import { uniqueId } from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import {
  InputIcon,
  StyledDatePicker,
  FieldWrapper,
  InputWrapper,
  StyledIcon,
  StyledLabel,
} from './style';
import Label from '../Label/Label';

type DatePickerProps = {
  name: string;
  label: string;
};

const DatePicker = function ({ name, label }: DatePickerProps) {
  const id = uniqueId(`${name}-`);
  const { values, setFieldValue } = useFormikContext();
  const value = getIn(values, name);

  return (
    <FieldWrapper>
      {label && (
        <StyledLabel>
          {' '}
          <Label htmlFor={id} label={label} />{' '}
        </StyledLabel>
      )}
      <InputWrapper>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <StyledDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            name={name}
            value={value}
            rightArrowIcon={<StyledIcon name="ArrowRight" />}
            leftArrowIcon={<StyledIcon name="ArrowLeft" />}
            InputProps={{
              disableUnderline: true,
            }}
            autoOk
            onChange={(date: Date | null) =>
              setFieldValue(name as never, date ? date.toISOString() : null)
            }
          />
        </MuiPickersUtilsProvider>
        <InputIcon>
          <StyledIcon name="DateIcon" />
        </InputIcon>
      </InputWrapper>
    </FieldWrapper>
  );
};

export default DatePicker;
