import React from 'react';
import { isMobile } from 'react-device-detect';
import MuiInputAdornment from '@material-ui/core/InputAdornment';
import styled from 'styled-components';
import { Icon, TextInput, Typography, LinkButton } from '../../components';
import InputAdornmentButton from '../../components/Button/InputAdornmentButton';

export const StartAdornmentContent = styled(({ isErrored, ...rest }) => <div {...rest} />)`
  width: 38px;
  ${({ isErrored }) => isErrored && `color: #fff;`}
`;

export const WorkStepInitialRiskWrapper = styled.div`
  margin-left: 40px;
  margin-top: 39px;
`;

export const WorkStepHazardControlsList = styled.ul`
  max-width: 800px;
  padding-left: 18px;
  margin: 0;
`;

export const StyledValidationIcon = styled(({ ...rest }) => <Icon {...rest} />)`
  height: 24px;
  width: 24px;
  margin-right: 5px;
`;

export const MediumStyledValidationIcon = styled(({ ...rest }) => <Icon {...rest} />)`
  height: 18px;
  width: 28px;
  margin-left: 8px;
  margin-top: 4px;
`;

export const StyledIcon = styled(Icon)`
  & path {
    fill: ${(props) => props.theme.colors.greyScale['40']};
  }
`;

export const StyledAttachmentIcon = styled(Icon)`
  margin-right: 20px;
  height: 24px;
  width: 24px;
  & path {
    fill: ${(props) => props.theme.colors.greyScale['65']};
  }
`;

export const WorkStepDetailsWrapperEmpty = styled.div`
  display: block;
  text-align: center;
  padding: 60px;
  background: #fff;
`;

export const WorkStepDetailsWrapper = styled.div`
  display: block;
  padding: 15px;
  background: #fff;
`;

export const WorkStepInputWrapper = styled(({ isMenuOpen, isExpanded, isErrored, ...rest }) => (
  <div {...rest} />
))`
  margin-bottom: 10px;
  touch-action: manipulation;
  ${({ isErrored, theme }) =>
    isErrored && `border: 1px solid ${theme.colors.complementary.red}; border-radius: 3px;`}
  & .work-step-menu-button {
    visibility: ${({ isExpanded, isMenuOpen }) =>
      isExpanded || isMenuOpen || isMobile ? 'visible' : 'hidden'};
  }

  & :hover {
    & .work-step-menu-button {
      visibility: visible;
    }
  }

  ${({ isExpanded, isErrored }) =>
    isExpanded &&
    !isErrored &&
    `
      border: 2px solid #40b3db;
      border-radius: 4px;
      box-shadow: 0 2px 12px 0 rgba(33, 61, 88, 0.3);
  `}
`;

export const InputAdornment = styled(MuiInputAdornment)`
  & .empty {
    padding-right: 15px !important;
  }
`;

export const StartAdornment = styled(({ isErrored, ...rest }) => <MuiInputAdornment {...rest} />)`
  display: flex;
  justify-content: center;
  width: 41px;
  text-align: center;
  margin-right: 0;
  height: 50px;
  ${({ isErrored, theme }) =>
    `background-color: ${isErrored ? theme.colors.complementary.red : '#e1e5e5'}`};
  max-height: unset;
  color: #0a0d0d;
`;

export const DoneAdornment = styled(MuiInputAdornment)`
  margin-left: 0;
  height: 50px;
  max-height: unset;
`;

export const WorkStepTextInput = styled(TextInput)`
  ${({ isExpanded }) =>
    isExpanded &&
    `
      & fieldset {
        border: none;
      }
    `}

  & .MuiInputBase-root {
    padding-right: 0;
    padding-left: 0;
    background: white;
  }

  & .MuiInputBase-input {
    border-radius: 0;
    border: none;
  }

  & .Mui-disabled {
    background-color: #f0f0f0;
  }
`;

export const workStepHazardGrid = () => `
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 150px 142px minmax(200px, 1fr) 182px;
  `;

export const WorkStepHazardHeaderText = styled(Typography)`
  && {
    color: #8b9799;
    font-size: 12px;
    font-weight: bold;
  }
`;

export const WorkStepHazardHeader = styled.div`
  ${workStepHazardGrid()}
`;

export const WorkStepHazardWrapper = styled.div`
  padding: 15px 0 15px 0;
  border-top: 1px solid;
  border-top-color: ${(props) => props.theme.colors.findColor('greyScale_6')};
  ${workStepHazardGrid()}

  & + & {
    border-bottom: 1px solid;
    border-bottom-color: ${(props) => props.theme.colors.findColor('greyScale_6')};
  }
`;

export const WorkStepHazardCol = styled(({ center = true, ...rest }) => <div {...rest} />).attrs(
  (props) => ({
    center: true,
    ...props,
    // @TODO: move  & > button into LinkButton as a prop
  })
)`
  display: flex;
  align-items: center;
  justify-content: ${({ center }) => (center ? 'center' : 'start')};

  & > button {
    padding: 0;
  }
`;

export const WorkStepHazardControlsCol = styled.div`
  & :hover {
    background: ${(props) => props.theme.colors.findColor('greyScale_6')};
    cursor: pointer;
  }
`;

export const WorkStepHazards = styled.div`
  margin-bottom: 30px;
`;

export const AddButtonWrapper = styled.div`
  text-align: center;
  margin-bottom: 15px;
`;
export const StyledLinkButton = styled(LinkButton)`
  &:disabled {
    & .MuiButton-label {
      color: ${(props) => props.theme.colors.findColor('greyScale_25')};
    }
  }
`;

export const StyledInputAdornmentButton = styled(InputAdornmentButton)`
  margin-top: 2px;
  margin-bottom: 3px;
  margin-right: 5px;
  box-shadow: none;
  font-family: 'Nunito Sans';
`;

export const EmptyWrapper = styled.div`
  padding-right: 12px;
`;
