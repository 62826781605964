import React from 'react';
import styled from 'styled-components';
import Typography from '../Typography/Typography';
import Icon from '../Icon/Icon';

export const AlertContainer = styled(({ status, ...rest }) => <div {...rest} />)`
  width: max-content;
  border-radius: 5px;
  border: ${({ status }) => (status === 'readyreview' ? '2px solid #AFCE82' : '2px solid #9edae2')};
  display: flex;
  vertical-align: middle;
  padding-top: 12px;
  padding-left: 20px;
  padding-bottom: 12px;
  padding-right: 20px;
`;
export const StyledIcon = styled(({ status, ...rest }) => <Icon {...rest} />)`
  height: 24px;
  width: 24px;
  padding-right: 6px;
  & path {
    fill: ${({ status }) => (status === 'readyreview' ? '#648001' : ' #40b3db')};
  }
`;

export const StyledTypography = styled(({ status, ...rest }) => <Typography {...rest} />)`
  line-height: 22px !important;
  color: ${({ status }) => (status === 'readyreview' ? '#366412 !important' : '#213d58 !important')};
`;
