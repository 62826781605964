import { createSelector } from 'reselect';
import get from 'lodash/get';

// Base selectors
export const getPpeData = (data: any) => get(data, 'data.items', []);

export const getReducedPpe = createSelector([getPpeData], (fullPPE) =>
  fullPPE.map(({ fields }: any) => ({
    ppeName: fields.ppeName,
    code: fields.code,
    colour: fields.colour,
    required: fields.required,
  }))
);
export const getRequiredPpe = createSelector([getReducedPpe], (reducedPpe) =>
  reducedPpe.filter((ppe: any) => ppe.required === true)
);
export const getOptionalPpe = createSelector([getReducedPpe], (reducedPpe) =>
  reducedPpe.filter((ppe: any) => ppe.required !== true)
);
