import React from 'react';
import { DialogTitleProps } from '@material-ui/core/DialogTitle';
import { CloseButton, StyledCloseIcon } from './style';

export interface ModalOverlayCloseButtonProps extends DialogTitleProps {
  onClose: () => void;
  trigger?: any;
}

const ModalOverlayCloseButton = function ({ onClose, trigger }: ModalOverlayCloseButtonProps) {
  return (
    <CloseButton aria-label="close" onClick={onClose} trigger={trigger}>
      <StyledCloseIcon name="Close" />
    </CloseButton>
  );
};

export default ModalOverlayCloseButton;
