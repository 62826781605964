import React, { Component } from 'react';
import TextMessage from './TextMessage';
import FileMessage from './FileMessage';
import { AuthContext } from 'contexts/AuthProvider';

class Message extends Component {
  static contextType = AuthContext;
  constructor() {
    super();
    this.state = { data: [] };
  }
  _renderMessageOfType(type, name) {
    const name2 = this.state.data.given_name + ' ' + this.state.data.family_name;

    switch (type) {
      case 'text':
        return (
          <TextMessage
            sentOrReceived={this.props.message.author === name2 ? 'sent' : 'received'}
            onClickResolve={this.props.onClickResolve}
            onMessageDeleted={this.props.onMessageDeleted}
            userData={this.state.data}
            section={this.props.section}
            name={name}
            {...this.props.message}
          />
        );
      case 'file':
        return (
          <FileMessage
            data={this.props.data}
            userData={this.state.data}
            onMessageDeleted={this.props.onMessageDeleted}
            {...this.props.message}
          />
        );
      default:
        console.error(`Attempting to load message with unsupported file type '${type}'`);
    }
  }

  render() {
    const name =  this.context.user ? this.context.user.firstName  + ' ' + this.context.user.lastName : '';
    const splitAuthor = this.props.message.author.split(' ');
    const initials = splitAuthor[0][0] + splitAuthor[1][0];
    const sentOrReceived = this.props.message.author === name ? 'sent' : 'received';
    const colour = sentOrReceived === 'sent' ? '#AFCE82' : '#03b1fc';

    let contentClassList = ['sc-message--content', sentOrReceived];
    return (
      <div className="sc-message">
        <div className={contentClassList.join(' ')}>
          <svg className={`sc-message--avatar ${sentOrReceived}`}>
            <g>
              <circle style={{ fill: colour, cx: '15', cy: '15', r: '15' }}></circle>
              <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dy=".3em"
                style={{ font: 'bold 12px sans-serif' }}
              >
                {initials}
              </text>
            </g>
          </svg>
          {this._renderMessageOfType(this.props.message.type, this.props.message.author)}
        </div>
      </div>
    );
  }
}

export default Message;
