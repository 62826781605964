import React, { useEffect } from 'react';
import { useFormikContext, getIn } from 'formik';
import { SliderSectionWrapper, SliderRow } from './styles';
import Slider from '../Slider/Slider';
import { marksSeverity, marksLikelihood } from './marks';
import calculateRisk from './calculateRisk';

const SliderSection = function ({ setDisabledSubmit }: any) {
  const { values, setFieldValue } = useFormikContext();
  const likelihoodUsed = getIn(values, 'likelihoodUsed');
  const severityUsed = getIn(values, 'severityUsed');
  const likelihood = getIn(values, 'likelihood');
  const severity = getIn(values, 'severity');
  const risk = calculateRisk(likelihood, severity);

  useEffect(() => {
    setFieldValue('risk' as never, risk);
  }, [risk]);

  setDisabledSubmit(!(likelihoodUsed && severityUsed));

  return (
    <SliderSectionWrapper>
      <SliderRow>
        <Slider
          name="likelihood"
          marks={marksLikelihood}
          label="LIKELIHOOD"
          color={risk}
          tooltip="HazardLikelihood"
          isFieldDisabled={likelihoodUsed}
        />
      </SliderRow>
      <SliderRow>
        <Slider
          name="severity"
          marks={marksSeverity}
          label="POTENTIAL SEVERITY"
          color={risk}
          isFieldDisabled={severityUsed}
          tooltip="HazardPotentialSeverity"
        />
      </SliderRow>
    </SliderSectionWrapper>
  );
};

export default SliderSection;
