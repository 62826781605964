import React from 'react';
import {
  SummaryWorkStepsHazardCardWrapper,
  SummaryWorkStepsHazardCardIconWrapper,
  SummaryWorkStepsHazardCardTextWrapper,
} from './style';
import HazardIcon from '../Icon/HazardIcon/HazardIcon';
import Typography from '../Typography/Typography';

const SummaryWorkStepsHazardCard = function ({ code, name, dataTestid }: any) {
  return (
    <SummaryWorkStepsHazardCardWrapper data-testid={dataTestid}>
      <SummaryWorkStepsHazardCardIconWrapper>
        <HazardIcon viewBox="0 0 100 100" name={code} />
      </SummaryWorkStepsHazardCardIconWrapper>
      <SummaryWorkStepsHazardCardTextWrapper>
        <Typography variant="h5">{name}</Typography>
      </SummaryWorkStepsHazardCardTextWrapper>
    </SummaryWorkStepsHazardCardWrapper>
  );
};

export default SummaryWorkStepsHazardCard;
