// tslint:disable
export const getDashboardList = `query GetDashboardList(
  $filter: TableJobSafetyAnalysisFilterInput
  $limit: Int
  $nextToken: String
) {
  listJobSafetyAnalyses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      jsaApplicationTitle
      jsaId
      id
      workOrderId
      confirmedDate
      workDetails {
        operatingArea
        siteName {
          id
          label
          value
        }
        personInCharge {
          firstName
          lastName
          email
        }
        watercareRepresentative {
          firstName
          lastName
          email
        }
        jsaWrittenBy {
            firstName
            lastName
            email
        }
      }
      createdDate
      status
      createdBy {
        firstName
        lastName
        email
      }
      submittedBy {
          firstName
          lastName
          email
      }
      assignedTo {
          firstName
          lastName
          email
      }
      reviewedBy {
          firstName
          lastName
          email
      }
      toConfirmEmail
      confirmedBy {
          firstName
          lastName
          email
      }
      cancelledBy {
          firstName
          lastName
          email
      }
      collaboratorDetails {
        collaborators {
          email
        }
      }
    }
    nextToken
  }
}
`;
