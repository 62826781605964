import React from 'react';
import get from 'lodash/get';
import { Form, getIn, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import ControlMeasures from '../ControlMeasures/ControlMeasures';
import Permits from './Permits';
import OverlayContent from '../Overlay/OverlayContent';
import { getHazardToUpdate, getIsPpeDrawerOpen } from '../../redux/workStepsSlice';
import PpeAccordion from './PpeAccordion';
import Drawer from '../PpeDrawer/Drawer';
import DrawerContents from '../PpeDrawer/DrawerContents';
import useClientState from '../../hooks/useClientState';
import Isolations from './Isolations';
import { useHazardData } from '../../hooks';
import OverlayContext from '../Overlay/OverlayContext';

const ControlsOverlayContent = function () {
  const { hazard } = useSelector(getHazardToUpdate);
  const permitName = get(hazard, 'permitName');
  const permitCode = get(hazard, 'permitCode');
  const { setIsPpeDrawerOpen } = useClientState();
  const isPpeDrawerOpen = useSelector(getIsPpeDrawerOpen);
  const { setFieldValue } = useFormikContext();
  const { values } = useFormikContext();
  const { trigger } = React.useContext(OverlayContext);

  const { data: dataHazard } = useHazardData();
  const suggestedControls = get(
    get(dataHazard, 'hazardData.includes.Entry', [])
      .map((entry: { fields: any }) => entry.fields)
      .find((field: { code: any }) => field.code === get(hazard, 'code')),
    'suggestedControls',
    []
  );
  function onClose() {
    setIsPpeDrawerOpen(false);
    const ppe = getIn(values, 'controls.ppe');
    setFieldValue('controls.draftPpe' as never, ppe);
  }

  return (
    <OverlayContent shouldSetScrollTrigger>
      <Form>
        <ControlMeasures hazard={hazard} trigger={trigger} suggestedControls={suggestedControls} />
        <PpeAccordion hazard={hazard} trigger={trigger} />
        {permitName && <Permits permitName={permitName} permitCode={permitCode} />}

        <Drawer open={isPpeDrawerOpen}>
          <DrawerContents onClose={onClose} />
        </Drawer>
        {hazard && hazard.isolationRequired && <Isolations />}
      </Form>
    </OverlayContent>
  );
};

export default ControlsOverlayContent;
