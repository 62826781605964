import React from 'react';
import { FormFieldWrapper, TopFieldWrapper, BottomWrapper } from './style';
import { Accordion } from '../../components/Accordion/Accordion';
import { AccordionSummary, AccordionDetails } from '../../components/Accordion/styles';
import GridContainer from '../../components/Grid/GridContainer';
import TextField from '../../components/TextField/TextField';
import { useMasterData, useMedicalFacility } from '../../hooks';
import get from 'lodash/get';
import FormikAutocomplete from '../../components/Autocomplete/FormikAutocomplete';
import FieldValidationWrapper from 'components/FieldValidationWrapper/FieldValidationWrapper';

const suggestionData = [
  { id: 'vehicle', label: 'In your vehicle' },
  { id: 'facility', label: 'Facility control room' },
  { id: 'kitchen', label: 'Kitchen' },
  { id: 'medical', label: 'Medical room' },
];
const validate = (event: any) => {
  if (event.target.value.indexOf('or') !== -1) {
    return event.preventDefault();
  } else {
    if ((event.keyCode >= 65 && event.keyCode <= 90) || (event.keyCode > 105 && event.keyCode <= 122)) {
      return event.preventDefault();
    }
  }
};
const EmergencyLocations = () => {
  const [expandedPanels, setExpandedPanels] = React.useState<{
    panel1: boolean;
    panel2: boolean;
  }>({ panel1: true, panel2: true });

  const handleChange = (panel: string) => () => {
    const currentValue = expandedPanels[panel];

    setExpandedPanels({
      ...expandedPanels,
      [panel]: !currentValue,
    });
  };

  const { data: masterData } = useMasterData();

  const medicalData = get(masterData, 'medicalFacility.items', []).map(({ fields }: any) => {
    return {
      id: fields.code,
      label: `${fields.jobcareName} `,
    };
  });
  useMedicalFacility('emergencyDetails.nearestMedicalFacilityContactDetails', 'fields.contactNumber');

  return (
    <Accordion borderTop expanded={expandedPanels.panel2 === true} onChange={handleChange('panel2')}>
      <AccordionSummary>Emergency locations</AccordionSummary>
      <AccordionDetails>
        <GridContainer>
          <TopFieldWrapper xs={8}>
            <FieldValidationWrapper
              validationLabel="medical facility"
              name="emergencyDetails.nearestMedicalFacility"
            >
              <FormikAutocomplete
                id="emergencyDetails.nearestMedicalFacility"
                name="emergencyDetails.nearestMedicalFacility"
                options={medicalData}
                label="Nearest medical facility *"
              />
            </FieldValidationWrapper>
          </TopFieldWrapper>
          <TopFieldWrapper xs={3}></TopFieldWrapper>
          <FormFieldWrapper xs={4}>
            <TextField
              fullWidth
              id="emergencyDetails.nearestMedicalFacilityContactDetails"
              name="emergencyDetails.nearestMedicalFacilityContactDetails"
              onKeyDown={validate}
              isRequired
              validationLabel="contact number"
              label="Medical facility contact no. *"
            />
          </FormFieldWrapper>
        </GridContainer>
        <BottomWrapper>
          <FormFieldWrapper xs={8}>
            <TextField
              fullWidth
              id="emergencyDetails.evacuationPoint"
              name="emergencyDetails.evacuationPoint"
              label="Evacuation point *"
              isRequired
              validationLabel="evacuation point"
            />
          </FormFieldWrapper>
          <FormFieldWrapper xs={3}></FormFieldWrapper>
          <FormFieldWrapper xs={8}>
            <FieldValidationWrapper
              validationLabel="first aid kit location"
              name="emergencyDetails.firstAidKitLocation"
            >
              <FormikAutocomplete
                id="emergencyDetails.firstAidKitLocation"
                name="emergencyDetails.firstAidKitLocation"
                options={suggestionData}
                label="First aid kit location *"
                isFirstAid={true}
              />
            </FieldValidationWrapper>
          </FormFieldWrapper>
        </BottomWrapper>
      </AccordionDetails>
    </Accordion>
  );
};

export default EmergencyLocations;
