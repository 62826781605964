import { useParams } from 'react-router-dom';
import { useQuery, QueryResult } from '@apollo/client';
import deepReplaceInObject from 'deep-replace-in-object';
import { Queries } from '../api-queries';
export interface UseJsaQueryResult extends QueryResult {
  jsaId: string | undefined;
}

function useJsaQuery(): UseJsaQueryResult {
  const { jsa } = useParams();

  const { data, ...rest } = useQuery(Queries.GET_JSA_BY_ID, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: jsa,
    },
  });

  if (data) {
    return {
      jsaId: jsa,
      data: deepReplaceInObject(null, '', data),
      ...rest,
    };
  }

  return {
    jsaId: jsa,
    data,
    ...rest,
  };
}

export default useJsaQuery;
