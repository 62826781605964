import React, { useState } from 'react';
import { useField, useFormikContext, getIn } from 'formik';
import { produce } from 'immer';
import { IconCard } from '../../components';
import { useClientState } from '../../hooks';
import WorkStepHazardControls from './WorkStepHazardControls';
import { WorkStepHazardWrapper, WorkStepHazardCol } from './style';
import WorkStepRisk from './WorkStepRisk';
import { getInitialRisk, getResidualRisk, getHazardControls } from './workStepSelectors';
import WorkStepDeleteHazard from './WorkStepDeleteHazard';

const WorkStepHazard = function ({ hazard, name }: any) {
  const [isOpen, setIsOpen] = useState<any>(false);

  const {
    setIsControlsOverlayOpen,
    setHazardToUpdate,
    setIsInitialRiskOverlayOpen,
    setIsResidualRiskOverlayOpen,
  } = useClientState();

  const [field] = useField(name);
  const initialRisk = getInitialRisk(field);
  const residualRisk = getResidualRisk(field);
  const hazardControls = getHazardControls(field);
  const { values, setFieldValue } = useFormikContext();

  const addControls = () => {
    setHazardToUpdate({ hazard, hazardToUpdateFormName: name });
    setIsControlsOverlayOpen(true);
  };

  const addInitialRisk = () => {
    setHazardToUpdate({ hazard, hazardToUpdateFormName: name });
    setIsInitialRiskOverlayOpen(true);
  };

  const addResidualRisk = () => {
    setHazardToUpdate({ hazard, hazardToUpdateFormName: name });
    setIsResidualRiskOverlayOpen(true);
  };

  const handleDelete = () => {
    setIsOpen(false);
    const workstepIndex = Number(name.split('.')[1]);
    const hazardIndex = Number(name.split('.')[3]);
    const hazards = getIn(values, `workSteps.${workstepIndex}.hazards`);

    setFieldValue(
      `workSteps.${workstepIndex}.hazards` as never,
      produce(hazards, (draft: any) => {
        draft.splice(hazardIndex, 1);
      })
    );
  };

  return (
    <WorkStepHazardWrapper>
      <WorkStepHazardCol>
        <IconCard
          name={hazard.code}
          label={hazard.name}
          isSelected
          canDelete
          deleteHandler={() => setIsOpen(true)}
        />
      </WorkStepHazardCol>
      <WorkStepRisk
        riskObject={initialRisk}
        name={name}
        handleClick={() => addInitialRisk()}
        riskType="initial"
      />
      <WorkStepHazardControls name={name} handleClick={() => addControls()} initialRisk={initialRisk} />
      <WorkStepRisk
        riskObject={residualRisk}
        name={name}
        handleClick={() => addResidualRisk()}
        disabled={!hazardControls}
        riskType="residual"
      />
      <WorkStepDeleteHazard
        handleClose={() => setIsOpen(false)}
        handleDelete={handleDelete}
        isOpen={isOpen}
      />
    </WorkStepHazardWrapper>
  );
};

export default WorkStepHazard;
