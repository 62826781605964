import React from 'react';
import Modal from '../../components/Modal/Modal';
import ModalTitle from '../../components/Modal/ModalTitle';
import ModalContent from '../../components/Modal/ModalContent';
import { ModalContentText } from '../../components';
import ShareJsaForm from './ShareJsaForm';

const ShareJsaModal = function ({ isModalOpen, handleModalClose }: any) {
  const titleStyle = 'padding-top:50px; padding-bottom: 0px;';

  return (
    <Modal size="900px" open={isModalOpen}>
      <ModalTitle onClose={handleModalClose} styles={titleStyle}>
        Share JSA
      </ModalTitle>
      <ModalContent padding="0px 0px 0px 0px" overflowy style={{ backgroundColor: '#FFFFFF' }}>
        <div style={{ backgroundColor: '#FFFFFF' }}>
          <ModalContentText styles="color:#43575A; font-weight: bold; font-size: 16px; padding-top: 20px; padding-left: 10%; margin-bottom: 0px">
            Share JSA with
          </ModalContentText>
        </div>
        <ShareJsaForm handleModalClose={handleModalClose} />
      </ModalContent>
    </Modal>
  );
};

export default ShareJsaModal;
