import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import get from 'lodash/get';
import { useMasterData, useComponentVisible } from '../../hooks';
import { StyledIcon, TooltipDiv } from './styles';

interface TooltipProps {
  isSelected?: boolean;
  text?: string;
  style?: any;
  placement?: any;
}

const Tooltip = function ({ isSelected, text = '', style, placement = 'top' }: TooltipProps) {
  const [show, setShow] = useState('');
  const { data: masterData } = useMasterData();
  const { ref, isComponentVisible } = useComponentVisible(true);

  const tooltips = get(masterData, 'tooltip.items', []).filter(
    ({ fields }: any) => fields.code === text
  );
  const handleShow = () => {
    if (show === ' show') {
      setShow('');
    } else if (show === '') {
      setShow(' show');
    }
  };

  if (!isComponentVisible && show !== '') {
    setShow('');
  }

  return (
    <>
      {tooltips.length > 0 && (
        <TooltipDiv className={text} thisRef={ref} onClick={handleShow} placement={placement}>
          <IconButton
            style={
              style
                ? { backgroundColor: 'transparent', padding: '0px', ...style }
                : { marginRight: '6px', padding: '0px', backgroundColor: 'transparent' }
            }
          >
            <StyledIcon name="Tooltip" isSelected={isSelected} />
          </IconButton>
          <span className={`tooltiptext ${placement}${show}`}>{tooltips[0].fields.description}</span>
        </TooltipDiv>
      )}
    </>
  );
};

Tooltip.defaultProps = {
  overlayHeading: false,
};

export default Tooltip;
