import React from 'react';
import styled from 'styled-components';
import Icon from '../Icon/Icon';

export const StyledIcon = styled(({ isSelected, ...rest }) => <Icon {...rest} />)`
  width: 24px;
  && path {
    fill: ${({ isSelected, theme }) => (isSelected ? theme.colors.primary.darkBlue : '')};
  }
`;
export const TooltipDiv = styled(({ placement, thisRef, ...rest }) => <div ref={thisRef} {...rest} />)`
  position: relative;
  display: inline-block;

  .tooltiptext {
    visibility: hidden;
    width: max-content;
    max-width: 284px;
    border-radius: 5px;
    background-color: #213d58;
    box-shadow: 0 3px 25px 0 rgba(33, 61, 88, 0.5);
    color: #f6f7f7;
    font-family: 'Nunito Sans';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    padding: 11px;
    text-align: center;
    position: absolute;
    z-index: 1000;
  }

  .show {
    visibility: visible;
  }
  .top {
    bottom: 140%;
    margin-left: -22px;
    transform: translateX(-50%);
    ::after {
      content: ' ';
      position: absolute;
      top: 100%; /* At the bottom of the tooltip */
      left: 50%;
      margin-left: -5px;
      border-width: 8px;
      border-style: solid;
      border-color: #213d58 transparent transparent transparent;
    }
  }
  .left {
    right: 105%;
    transform: translateY(-40%);
    ::after {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 100%; /* To the right of the tooltip */
      margin-top: -5px;
      border-width: 8px;
      border-style: solid;
      border-color: transparent transparent transparent #213d58;
    }
  }
  .right {
    left: 105%;
    transform: translateY(-40%);
    ::after {
      content: ' ';
      position: absolute;
      top: 50%;
      right: 100%; /* To the left of the tooltip */
      margin-top: -5px;
      border-width: 8px;
      border-style: solid;
      border-color: transparent #213d58 transparent transparent;
    }
  }
  .bottom {
    top: 100%;
    margin-left: -22px;
    transform: translateX(-50%);
    ::after {
      content: ' ';
      position: absolute;
      bottom: 100%; /* At the top of the tooltip */
      left: 50%;
      margin-left: -5px;
      border-width: 8px;
      border-style: solid;
      border-color: transparent transparent #213d58 transparent;
    }
  }
`;
