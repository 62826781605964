import React, { useState, useEffect } from 'react';
import produce from 'immer';
import { Field, useFormikContext } from 'formik';
import { useKeyPress } from '../../hooks';
import ControlsSuggestionInput from '../ControlsSuggestionInput/ControlsSuggestionInput';
import { ControlsSuggestionFieldWrapper } from './styles';
import { isDoubleClick } from '../../utils';
import ControlsSuggestionDeleteModal from './ControlsSuggestionDeleteModal';
import EndAdornment from './EndAdornment';

const ControlsSuggestionField = function ({ name, placeholder, controlMeasures, id, ...rest }: any) {
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const enterPress = useKeyPress('Enter');
  const isMenuOpen = Boolean(anchorEl);
  const inputWrapperElement: React.RefObject<HTMLInputElement> = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState<any>(false);
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (isEditing) {
      if (inputWrapperElement.current !== null) {
        inputWrapperElement.current.focus();
      }
    }
  }, [isEditing]);

  useEffect(() => {
    if (enterPress && isEditing) {
      if (inputWrapperElement.current) {
        inputWrapperElement.current.blur();
      }
    }
  }, [enterPress, isEditing]);

  function handleClose() {
    setAnchorEl(null);
  }

  function editMeasure() {
    setIsEditing(true);
    handleClose();
  }

  function handleDeleteMeasure() {
    setFieldValue(
      'controls.controlMeasures' as never,
      produce(controlMeasures, (draft: any) => {
        const itemToDelete = draft.findIndex((controlMeasure: any) => controlMeasure.id === id);
        draft.splice(itemToDelete, 1);
      })
    );

    handleClose();
  }

  function toggleDeleteModal() {
    setIsOpen(!isOpen);
  }

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  return (
    <>
      <ControlsSuggestionFieldWrapper>
        <Field name={name} {...rest}>
          {(fieldProps: any) => {
            const { value, onBlur } = fieldProps.field;

            function handleInputClick(e: any) {
              if (value) {
                if (isDoubleClick(e)) {
                  setIsEditing(true);
                }
              } else {
                setIsEditing(true);
              }
            }

            function handleBlur(e: any) {
              setIsEditing(false);
              onBlur(e);
            }

            function isDisabled() {
              if (isEditing) {
                return false;
              }

              if (value && !isEditing) {
                return true;
              }

              return false;
            }

            return (
              <ControlsSuggestionInput
                onClick={handleInputClick}
                disabled={isDisabled()}
                id={name}
                inputRef={inputWrapperElement}
                inputProps={{ 'data-testid': name }}
                InputProps={{
                  onBlur: handleBlur,
                  endAdornment: (
                    <EndAdornment
                      {...{
                        isEditing,
                        value,
                        isMenuOpen,
                        handleClick,
                        anchorEl,
                        handleClose,
                        toggleDeleteModal,
                        editMeasure,
                      }}
                    />
                  ),
                }}
                name={name}
                fullWidth
                placeholder={placeholder}
                {...fieldProps}
              />
            );
          }}
        </Field>
      </ControlsSuggestionFieldWrapper>

      <ControlsSuggestionDeleteModal
        handleClose={toggleDeleteModal}
        handleDelete={handleDeleteMeasure}
        isOpen={isOpen}
      />
    </>
  );
};

export default ControlsSuggestionField;
