import React from 'react';
import { FieldArray, useFormikContext, getIn } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  GridContainer,
  GridItem,
  Typography,
  AddButton,
} from '../../components';

import { getRequiredPermits, getPermitFileTypeOptions } from './getPermitsSelector';
import usePermitData from '../../hooks/usePermitData';
import usePermitDocumentData from '../../hooks/usePermitDocumentData';
import PermitsToWorkInput from './PermitsToWorkInput';
import { AccordionSubText } from '../../components/Accordion/styles';

const PermitsToWork = function (props: any) {
  const [expandedPanels, setExpandedPanels] = React.useState<{
    panel1: boolean;
    panel2: boolean;
  }>({ panel1: true, panel2: true });

  const handleChange = (panel: string) => () => {
    const currentValue = expandedPanels[panel];
    setExpandedPanels({
      ...expandedPanels,
      [panel]: !currentValue,
    });
  };

  const { values } = useFormikContext();
  const defaultPermits = getIn(values, 'workPack.permits');
  const permits = getRequiredPermits(props);
  const { data } = usePermitData();
  const permitDocumentData = usePermitDocumentData();
  const permitMasterData = data ? data.permitData.items : [];
  const permitFileTypeOptions = getPermitFileTypeOptions(data);
  const shouldRenderWorkStepPermits = !isEmpty(permits);

  return (
    <Accordion borderTop expanded={expandedPanels.panel1 === true} onChange={handleChange('panel1')}>
      <AccordionSummary>Permits to work</AccordionSummary>
      <AccordionDetails>
        <AccordionSubText>
          This is a checklist of all the permits you have identified. Add others as required.
        </AccordionSubText>
        <GridContainer>
          {shouldRenderWorkStepPermits && (
            <GridItem xs={12}>
              <Typography variant="body1">
                In section 3, you’ve identified you need permits for these hazards:{' '}
              </Typography>
              {permits && (
                <ul>
                  {permits.map((permit: any, index: number) => (
                    <li key={index} data-testid={index}>
                      <Typography variant="body1" align="left">
                        {permit.name}
                      </Typography>
                    </li>
                  ))}
                </ul>
              )}
            </GridItem>
          )}

          <FieldArray
            name="workPack.permits"
            render={(arrayHelpers: any) => (
              <>
                {map(defaultPermits, (permit: any, index: any) => (
                  <PermitsToWorkInput
                    key={`PermitsToWorkInput-${permit.id}`}
                    index={index}
                    permitMasterData={permitMasterData}
                    permitFileTypeOptions={permitFileTypeOptions}
                    arrayHelpers={arrayHelpers}
                    permit={permit}
                    data-testid={`PermitsToWorkInput-${permit.id}`}
                    permitDocumentData={permitDocumentData}
                  />
                ))}
                <GridItem xs={12}>
                  {' '}
                  <AddButton
                    text="Add Permits"
                    onClick={() => {
                      const id = uuidv4();
                      const file = { bucket: '', key: '', region: '' };

                      const newValue = {
                        file,
                        fileName: '',
                        id,
                        createdDate: new Date().toISOString(),
                        fileSize: '',
                        fileType: '',
                      };

                      arrayHelpers.push(newValue);
                    }}
                  />
                </GridItem>
              </>
            )}
          />
        </GridContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default PermitsToWork;
