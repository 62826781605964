import React from 'react';
import { default as Chip } from './Chip';

export interface ChipProps {
  color:
    | 'yellow'
    | 'leaf'
    | 'lightGreen'
    | 'mint'
    | 'mediumBlue'
    | 'darkCyan'
    | 'darkBlue'
    | 'sunrise'
    | 'magenta'
    | 'red';
  text: string;
}

const Risk: React.FC<ChipProps> = function ({ text, color }) {
  return (
    <div>
      <Chip color={color} text={text} />
    </div>
  );
};

export default Risk;
