import React, { useEffect, useState, memo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';
import { useCookies } from 'react-cookie';
import CommonLayout from '../CommonLayout/CommonLayout';
import GridItem from '../../components/Grid/GridItem';
import { getNextPage, getPreviousPage } from '../CommonLayout/routingFunctions';
import { Mutations, Queries } from '../../api-queries';
import footerPointer from './image/footer-pointer.svg';
import Button from '../../components/Button/Button';
import JsaQueryWrapper from '../JsaQueryWrapper/JsaQueryWrapper';
import { ButtonContainer, StyledGridContainer } from './styles';
import { helper } from './helper';
import { InputVariable } from './model';
import ReviewButton from '../../components/Button/ReviewButton';
import { Outlet } from 'react-router-dom';
import { useSendGrid, useJsaQuery, useClientState } from '../../hooks';
import { getIsSaving } from '../../redux/jsaFormSlice';
import { useSelector } from 'react-redux';
import { useSubmitValidation } from 'contexts/useSubmitValidation';
import { useAuth } from 'contexts/AuthProvider';

const WorkflowControls = () => {
  const { sendStatusChangeEmail } = useSendGrid();
  const cookies = useCookies(['doNotShowSubmit']);
  const doNotShowSubmitCookie = cookies[0].doNotShowSubmit || false;
  const mutationQuery = Mutations.UpdateJsa;
  const [assignedToFlag, setAssignedToFlag] = useState(false);
  const params: { jsa?: string } = useParams();
  const { user } = useAuth();
  const { validateUserSubmission } = useSubmitValidation();
  const { data } = useJsaQuery();
  const jsaData = helper.getJsaData(data);
  const isSaving = useSelector(getIsSaving);
  const currentUserRole = helper.getCurrentUserRole(user.email, jsaData);
  const navigate = useNavigate();
  const pathName = useLocation().pathname.split('/')[3];
  const nextPage = getNextPage(pathName) || { label: '', route: '' };
  const previousPage = getPreviousPage(pathName) || { route: '' };
  const status = get(data, 'getJobSafetyAnalysis.status');
  const reviewedByDate = get(data, 'getJobSafetyAnalysis.reviewedByDate');
  const {
    setAssignToConflictModalOpen,
    setSendForConfirmModalOpen,
    setConfirmModalOpen,
    setIsValidationModalOpen,
  } = useClientState();
  const disabled = helper.checkIfDisabled(currentUserRole, status);
  const displayBack = helper.shouldDisplayBack(status, pathName, currentUserRole.isReviewer);
  const buttonText = helper.getButtonText(status, currentUserRole);
  const isSingleButton =
    pathName === 'work-details' || (buttonText !== 'Submit JSA' && buttonText !== 'Confirm / Send');
  const isThreeButtons =
    (status === 'waitingconfirmation' || status === 'review') && currentUserRole.isReviewer;
  const showRequestChangesButton =
    (status === 'review' && currentUserRole.isReviewer) ||
    (status === 'waitingconfirmation' && !currentUserRole.isToConfirm && !currentUserRole.isSubmitter);

  const [updateStatusMutation] = useMutation(mutationQuery, {
    onCompleted(data: any) {
      sendStatusChangeEmail(data);
    },
    refetchQueries: () => [
      {
        query: Queries.GET_JSA_BY_ID,
        variables: {
          id: params.jsa,
        },
      },
    ],
  });

  function buildInputVariable(customInput?: string) {
    const inputVariable: InputVariable = {};
    inputVariable.id = params.jsa;
    inputVariable.status = helper.getNextStatusId(status, currentUserRole.isReviewer);
    switch (customInput) {
      case 'changesRequired':
        inputVariable.status = 'changesRequired';
        return inputVariable;
      case 'undoReview':
        inputVariable.status = 'review';
        inputVariable.reviewedBy = {};
        inputVariable.reviewedByDate = null;
        inputVariable.toConfirmEmail = '';
        inputVariable.toConfirmDate = null;
        return inputVariable;
      case 'sendBack':
        inputVariable.status = 'review';
    }

    if (currentUserRole.isReviewer) {
      switch (status) {
        case 'changesRequired':
          inputVariable.status = status;
          break;
        case 'review':
          inputVariable.reviewedBy = user;
          inputVariable.reviewedByDate = new Date().toISOString();
          break;
        case 'waitingconfirmation':
          if (customInput !== 'sendBack') {
            inputVariable.confirmedBy = user;
            inputVariable.confirmedDate = new Date().toISOString();
          }
          break;
      }
    } else {
      switch (status) {
        case 'draft':
        case 'changesRequired':
          inputVariable.submittedBy = user;
          inputVariable.submittedDate = new Date().toISOString();
          break;
        case 'readyreview':
          if (assignedToFlag === false) {
            inputVariable.status = 'review';
            inputVariable.assignedTo = user;
            inputVariable.assignedToDate = new Date().toISOString();
            setAssignedToFlag(true);
          }
          break;
        case 'waitingconfirmation':
          if (customInput !== 'sendBack') {
            inputVariable.confirmedBy = user;
            inputVariable.confirmedDate = new Date().toISOString();
          }
          break;
      }
    }

    return inputVariable;
  }

  const pushUpdateStatus = (customInput?: string) => {
    updateStatusMutation({
      variables: {
        input: buildInputVariable(customInput),
      },
    });
  };

  const handleClickingSubmit = () => {
    if ((status === 'draft' || status === 'changesRequired') && !doNotShowSubmitCookie) {
      if (validateUserSubmission()) {
        setConfirmModalOpen(true);
      } else {
        setIsValidationModalOpen(true);
      }
    } else if (
      status !== 'draft' &&
      status !== 'waitingconfirmation' &&
      jsaData.assignedToEmail !== undefined &&
      !currentUserRole.isReviewer
    ) {
      if (status === 'changesRequired') {
        pushUpdateStatus();
      } else {
        setAssignToConflictModalOpen(true);
      }
    } else {
      if (status === 'review') {
        setSendForConfirmModalOpen(true);
      } else if (status === 'waitingconfirmation') {
        setSendForConfirmModalOpen(true);
        return;
      }
      pushUpdateStatus();
    }
  };

  function isRequestChangesDisabled() {
    return (
      status === 'waitingconfirmation' && currentUserRole.isReviewer && reviewedByDate !== undefined
    );
  }

  function undoReview() {
    pushUpdateStatus('undoReview');
  }

  function handleReviewDone() {
    if (status === 'review') {
      handleClickingSubmit();
    } else {
      undoReview();
    }
  }

  function handleDelay(path: any) {
    if (isSaving) {
      setTimeout(() => {
        navigate(path);
      }, 1000);
    } else {
      navigate(path);
    }
  }

  function renderBackButton() {
    return (
      <ButtonContainer
        xs={
          (status === 'waitingconfirmation' || status === 'review') && currentUserRole.isReviewer ? 3 : 4
        }
      >
        <Button
          id="summary_back_button"
          fullWidth
          preset="secondary"
          themeColour="grey"
          onClick={() => {
            handleDelay(`/jsa/${params.jsa}/${previousPage.route}`);
          }}
        >
          Back
        </Button>
      </ButtonContainer>
    );
  }

  function renderSendBackButton() {
    return (
      <ButtonContainer xs={4}>
        <Button
          id="send_back_button"
          fullWidth
          preset="secondary"
          themeColour="lightLeaf"
          onClick={() => {
            pushUpdateStatus('sendBack');
          }}
        >
          SEND BACK
        </Button>
      </ButtonContainer>
    );
  }

  function renderRequestChangesButton() {
    return (
      <ButtonContainer xs={3}>
        <Button
          id="summary_request_changes_button"
          disabled={isRequestChangesDisabled()}
          fullWidth
          preset="secondary"
          themeColour="lightLeaf"
          onClick={() => {
            pushUpdateStatus('changesRequired');
          }}
        >
          REQUEST CHANGES
        </Button>
      </ButtonContainer>
    );
  }

  return (
    <div>
      <StyledGridContainer className="screen-only">
        <GridItem
          style={{ marginRight: isThreeButtons ? '40px' : '' }}
          xs={
            isSingleButton
              ? 4
              : (status === 'waitingconfirmation' || status === 'review') && currentUserRole.isReviewer
              ? 1
              : 2
          }
        />
        {displayBack && renderBackButton()}
        {currentUserRole.isToConfirm && status === 'waitingconfirmation' && renderSendBackButton()}
        {showRequestChangesButton && renderRequestChangesButton()}

        {isThreeButtons && (
          <>
            <ButtonContainer xs={3}>
              {status !== 'review' ? (
                <ReviewButton onClick={handleReviewDone} />
              ) : (
                <Button
                  id="summary_review_done_button"
                  fullWidth
                  preset="primary"
                  themeColour="leaf"
                  onClick={() => {
                    pathName === 'summary'
                      ? handleClickingSubmit()
                      : handleDelay(`/jsa/${params.jsa}/${nextPage.route}`);
                  }}
                >
                  {' '}
                  Review Is Done
                </Button>
              )}
            </ButtonContainer>
            <img alt="icon" src={footerPointer} />
          </>
        )}

        <ButtonContainer xs={isThreeButtons ? 3 : 4}>
          <Button
            id="summary_submit_button"
            disabled={disabled}
            fullWidth
            preset="primary"
            themeColour="leaf"
            onClick={() => {
              pathName === 'summary'
                ? handleClickingSubmit()
                : handleDelay(`/jsa/${params.jsa}/${nextPage.route}`);
            }}
          >
            {pathName === 'summary' ? buttonText : `Next: ${nextPage.label}`}
          </Button>
        </ButtonContainer>
        <ButtonContainer xs={isSingleButton ? 4 : isThreeButtons ? 1 : 2} />
      </StyledGridContainer>
    </div>
  );
};

const WorkflowLayout = memo(() => {
  const params: { jsa?: string } = useParams();
  const { user } = useAuth();
  const { setUserDefinedEmails, setCurrentJsaId, currentJsaId } = useSubmitValidation();
  useEffect(() => {
    setUserDefinedEmails({ currentUser: user.email });

    if (params.jsa && currentJsaId !== params.jsa) {
      setCurrentJsaId(params.jsa);
    }
  }, []);

  return (
    <CommonLayout
      shouldRenderTabs
      footerControls={
        <JsaQueryWrapper>
          <WorkflowControls />
        </JsaQueryWrapper>
      }
    >
      <Outlet />
    </CommonLayout>
  );
});

WorkflowLayout.displayName = 'WorkflowLayout';

export default WorkflowLayout;
