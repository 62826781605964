import React, { useEffect, HTMLProps, memo, ChangeEvent, useState } from 'react';
import { Field } from 'formik';
import Label from '../Label/Label';
import TextInput from '../TextInput/TextInput';
import { TextFieldWrapper, ErrorMessage } from './styles';
import BodyText from '../BodyText/BodyText';
import Tooltip from '../Tooltip/Tooltip';
import { useFormikContext, getIn } from 'formik';
import { useSubmitValidation } from '../../contexts/useSubmitValidation';
import Icon from '../Icon/Icon';

type TextFieldProps = Omit<
  HTMLProps<HTMLInputElement>,
  'onChange' | 'required' | 'disabled' | 'minRows' | 'maxRows'
> & {
  isRequired?: boolean;
  validationLabel?: string;
  type?: 'phone' | 'email' | 'text' | 'textarea';
  disabled?: boolean;
  label: string;
  bodyText?: string;
  fullWidth?: boolean;
  tooltip?: string;
  alignLabel?: boolean;
  inputProps?: any;
  placeholder?: string;
  id: string;
  name: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

const TextField = memo(
  ({
    id,
    name,
    type = 'text',
    isRequired,
    validationLabel,
    label,
    bodyText,
    placeholder,
    alignLabel,
    inputProps,
    tooltip,
    ...rest
  }: TextFieldProps) => {
    const [isErrored, setIsErrored] = useState(false);
    const { values, setFieldValue } = useFormikContext();
    const { hasUserAttemptedSubmission, setUserDefinedEmails } = useSubmitValidation();
    const currentValue = getIn(values, name);

    const format = (event: any, id: any, label: any, placeholder: any) => {
      if (event.target.value.indexOf('or') === -1) {
        let next = false;
        if (label) {
          if (label.indexOf('no.') !== -1) {
            next = true;
          }
        } else if (placeholder.indexOf('Mobile') !== -1) {
          next = true;
        }
        if (event.target.value.length >= 9 && next) {
          let phone = event.target.value.replace(/[^\d]/g, '');
          if (
            phone.substring(0, 3) === '021' ||
            phone.substring(0, 3) === '027' ||
            phone.substring(0, 3) === '026' ||
            phone.substring(0, 3) === '022' ||
            phone.substring(0, 3) === '028'
          ) {
            phone = `${phone.substring(0, 3)}-${phone.substring(3)}`;
          } else {
            phone = `(${phone.substring(0, 2)}) ${phone.substring(2, 5)} ${phone.substring(5)}`;
          }
          setFieldValue(id, phone);
        }
      }
      return true;
    };

    if (isRequired && !validationLabel) {
      throw new Error(
        `TextField: ${name} is marked as required but doesn't have a validationLabel provided, please correct this.`
      );
    }

    useEffect(() => {
      if (isRequired && hasUserAttemptedSubmission && currentValue === '') {
        setIsErrored(true);
      } else if (isRequired && hasUserAttemptedSubmission && currentValue) {
        setIsErrored(false);
      }

      if (type === 'email') {
        setUserDefinedEmails({ [name]: currentValue });
      }
    }, [hasUserAttemptedSubmission, currentValue]);

    return (
      <TextFieldWrapper alignLabel={alignLabel} isErrored={isErrored}>
        {label && (
          <>
            <Label alignLabel={alignLabel} htmlFor={id} label={label} />
            <Tooltip style={{ margin: '0px 6px 0px 0px' }} placement="top" text={tooltip} />
          </>
        )}

        {bodyText && <BodyText text={bodyText} />}
        <Field
          name={name}
          id={id}
          type={type}
          onBlur={(event: any) => format(event, id, label, placeholder)}
          inputProps={{
            'data-testid': `${id}-value`,
            ...inputProps,
          }}
          component={TextInput}
          placeholder={placeholder}
          {...rest}
        />
        {isErrored && (
          <ErrorMessage>
            <Icon name="ValidationErrorRed" />
            {`Missing ${validationLabel || 'field value'}`}
          </ErrorMessage>
        )}
      </TextFieldWrapper>
    );
  }
);

TextField.displayName = 'TextField';

export default TextField;
