import React, { MouseEvent } from 'react';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import Button from './Button';
import { PresetType, ThemeColourType } from './button-types';

export const StyledSpinner = styled(CircularProgress)`
  & svg {
    color: #fff;
    opacity: 0.4;
  }
  margin-right: 8px;
`;

export const SpinnerContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

interface Props {
  id: string;
  preset?: PresetType;
  themeColour?: ThemeColourType;
  actionInProgress: boolean;
  text: string;
}

const AsyncButton: React.FC<Props> = ({
  id,
  text,
  preset = 'primary',
  themeColour = 'primary',
  actionInProgress,
}) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (actionInProgress) {
      e.preventDefault();
    }
  };

  return (
    <Button id={id} type="submit" color={themeColour} preset={preset} fullWidth onClick={handleClick}>
      {actionInProgress ? (
        <SpinnerContainer>
          <StyledSpinner size={20} thickness={6} />
          {text}
        </SpinnerContainer>
      ) : (
        text
      )}
    </Button>
  );
};

export default AsyncButton;
