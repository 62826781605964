import React from 'react';
import { DialogActionsProps } from '@material-ui/core/DialogActions';
import { StyledModalActions } from './style';

export interface ModalActionsProps extends DialogActionsProps {
  styles?: any;
}

const ModalActions = function ({ children, styles }: ModalActionsProps) {
  return <StyledModalActions styles={styles}>{children}</StyledModalActions>;
};

export default ModalActions;
