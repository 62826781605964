import React, { FC } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Icon, { IconNameType } from '../Icon/Icon';
import { StyledCardButton, CardLabel, IconWraper, CardLabelWrapper } from './styles';

interface IconCardButtonProps {
  name?: IconNameType;
  label?: string;
  onClick?: any;
}

const IconCardButton: FC<IconCardButtonProps> = ({ name = 'AddLight', label = 'ADD NEW', onClick }) => {
  return (
    <StyledCardButton onClick={onClick}>
      <CardContent>
        <IconWraper>
          <Icon name={name} />
        </IconWraper>
        <CardLabelWrapper>
          <CardLabel>{label}</CardLabel>
        </CardLabelWrapper>
      </CardContent>
    </StyledCardButton>
  );
};

export default IconCardButton;
