import get from 'lodash/get';
import statusAttributes from '../../constants/statusAttributes';
import { CurrentUserRole, JsaData } from './model';

export const helper = {
  getCurrentUserRole(userEmail: any, jsaData: JsaData) {
    if (userEmail) {
      userEmail = userEmail.toLowerCase();
    }

    return {
      isSubmitter:
        userEmail === jsaData.submittedByEmail ||
        userEmail === jsaData.personInChargeEmail ||
        userEmail === jsaData.createdByEmail ||
        userEmail === jsaData.jsaWrittenByEmail,
      isReviewer: userEmail === jsaData.assignedToEmail,
      isConfirmer: userEmail === jsaData.confirmedByEmail,
      isToConfirm: userEmail === jsaData.toConfirmEmail,
      isWatercareRepresentative: userEmail === jsaData.watercareRepresentativeEmail,
    };
  },

  getJsaData(data: any) {
    const jsa: any = {
      id: get(data, 'getJobSafetyAnalysis.id'),
      jsaId: get(data, 'getJobSafetyAnalysis.jsaId'),
      jsaApplicationTitle: get(data, 'getJobSafetyAnalysis.jsaApplicationTitle'),
      createdByEmail: get(data, 'getJobSafetyAnalysis.createdBy.email', undefined),
      jsaWrittenByEmail: get(data, 'getJobSafetyAnalysis.workDetails.jsaWrittenBy.email', undefined),
      assignedToEmail: get(data, 'getJobSafetyAnalysis.assignedTo.email', undefined),
      submittedByEmail: get(data, 'getJobSafetyAnalysis.submittedBy.email', undefined),
      submittedByName: get(data, 'getJobSafetyAnalysis.submittedBy.firstName', undefined),
      submittedDate: get(data, 'getJobSafetyAnalysis.submittedDate', undefined),
      watercareRepresentativeEmail: get(
        data,
        'getJobSafetyAnalysis.workDetails.watercareRepresentative.email',
        undefined
      ),
      watercareRepresentativeFirstName: get(
        data,
        'getJobSafetyAnalysis.workDetails.watercareRepresentative.firstName',
        undefined
      ),
      reviewedByName: get(data, 'getJobSafetyAnalysis.reviewedBy.firstName', undefined),
      reviewedByDate: get(data, 'getJobSafetyAnalysis.reviewedByDate', undefined),
      reviewedByEmail: get(data, 'getJobSafetyAnalysis.reviewedBy.email', undefined),
      confirmedByEmail: get(data, 'getJobSafetyAnalysis.confirmedBy.email', undefined),
      confirmedByFirstName: get(data, 'getJobSafetyAnalysis.confirmedBy.firstName', undefined),
      toConfirmEmail: get(data, 'getJobSafetyAnalysis.toConfirmEmail', undefined),
      toConfirmDate: get(data, 'getJobSafetyAnalysis.toConfirmDate', undefined),
      personInChargeName: get(
        data,
        'getJobSafetyAnalysis.workDetails.personInCharge.firstName',
        undefined
      ),
      personInChargeEmail: get(data, 'getJobSafetyAnalysis.workDetails.personInCharge.email', undefined),
    };

    const keys = Object.keys(jsa);
    keys.forEach((key: string) => {
      if (jsa[key] && key.includes('Email')) {
        jsa[key] = jsa[key].toLowerCase();
      }
    });

    return jsa;
  },

  checkIfDisabled(currentUserRole: CurrentUserRole, status: string) {
    const isComplete = status === 'agreed' || status === 'closed' || status === 'cancelled';
    const isSubmittedAndSubmitter =
      currentUserRole.isSubmitter &&
      (status === 'readyreview' || status === 'review' || status === 'waitingconfirmation');
    const isChangesRequestedAndReviewer = currentUserRole.isReviewer && status === 'changesRequired';
    const isUnderReviewAndReviewer = currentUserRole.isReviewer && status === 'review';
    const isSentBack = currentUserRole.isToConfirm && status === 'review';
    // const isDraftAndWatercareRep =
    //   currentUserRole.isWatercareRepresentative && status === 'draft' && pathName === 'summary';

    return (
      isComplete ||
      isSubmittedAndSubmitter ||
      isChangesRequestedAndReviewer ||
      isUnderReviewAndReviewer ||
      isSentBack
      // isDraftAndWatercareRep
    );
  },

  shouldDisplayBack(status: string, pathName: string, isReviewer: boolean) {
    let displayBack = false;
    const isWorkDetailsPage = pathName === 'work-details';
    const isSummaryPage = pathName === 'summary';

    if (!isWorkDetailsPage && !isSummaryPage) {
      displayBack = true;
    } else if (isSummaryPage && !isReviewer && (status === 'draft' || status === 'changesRequired')) {
      displayBack = true;
    }

    // Edge case if in draft, then the Watercare rep should see but have button greyed out
    if (isSummaryPage && isReviewer && status === 'draft') {
      displayBack = true;
    }

    return displayBack;
  },

  getButtonText(status: string, currentUserRole: CurrentUserRole) {
    let buttonText;

    switch (status) {
      case 'draft':
        buttonText = 'Submit JSA';
        break;
      case 'cancelled':
        buttonText = 'JSA Cancelled';
        break;
      case 'readyreview':
        if (currentUserRole.isSubmitter) {
          buttonText = 'Submitted';
        } else {
          buttonText = 'Assign to Me';
        }
        break;
      case 'review':
        if (currentUserRole.isSubmitter) {
          buttonText = 'Submitted';
        } else if (currentUserRole.isReviewer) {
          buttonText = 'Confirm / Send';
        } else if (currentUserRole.isToConfirm) {
          buttonText = 'Sent Back';
        } else {
          buttonText = 'Assign to Me';
        }
        break;
      case 'changesRequired':
        if (currentUserRole.isSubmitter) {
          buttonText = 'Submit JSA';
        } else if (currentUserRole.isReviewer) {
          buttonText = 'Awaiting Changes';
        } else {
          buttonText = 'Submit JSA';
        }
        break;
      case 'waitingconfirmation':
        if (currentUserRole.isSubmitter) {
          buttonText = 'Submitted';
        } else {
          buttonText = 'Confirm / Send';
        }
        break;
      case 'agreed':
        buttonText = 'JSA Confirmed';
        break;
      case 'closed':
        buttonText = 'JSA Closed';
        break;
    }

    return buttonText;
  },

  getNextStatusId(status: string, isReviewer: boolean) {
    // if (buttonText === "Assign to Me") return statusAttributes.review.id
    switch (status) {
      case 'draft':
        return statusAttributes.readyreview.id;
      case 'readyreview':
        if (isReviewer) {
          return statusAttributes.review.id;
        }
        return statusAttributes.readyreview.id;

      case 'review':
        return statusAttributes.waitingconfirmation.id;
      case 'waitingconfirmation':
        return statusAttributes.agreed.id;
      default:
        return statusAttributes.readyreview.id;
    }
  },
};
