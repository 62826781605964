import React from 'react';
import SummarySectionTitleIcon from './SummarySectionTitleIcon';
import { StyledSummarySectionTitle } from './styles';

interface Props {
  sectionNumber: string;
  children: any;
  workDetails?: boolean;
  id?: string;
}

const SummarySectionTitle = ({ id, children, sectionNumber, workDetails }: Props) => {
  return (
    <StyledSummarySectionTitle id={id} workDetails={workDetails}>
      <SummarySectionTitleIcon>{sectionNumber}</SummarySectionTitleIcon>
      {children}
    </StyledSummarySectionTitle>
  );
};

export default SummarySectionTitle;
