import React from 'react';
import MuiToolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

export const StyledMuiToolbar = styled(MuiToolbar)`
  display: flex;
  justify-content: space-between;
`;

// @TODO: Move all these icons into our own component
export const StyledAppToolbar = styled(Paper)`
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0 1px 5px 0 rgba(33, 61, 88, 0.4);
`;

export const JsaNumber = styled(({ children, ...rest }) => (
  <p id="app-toolbar-jsa-id-identifier" {...rest}>
    {children}
  </p>
))`
  font-size: 14px;
  display: inline-block;
  padding-right: 20px;
`;

export const LastSaved = styled.p`
  display: inline-block;
  color: ${(props) => props.theme.colors.greyScale['65']};
  font-family: 'Nunito Sans';
  font-size: 14px;
  min-width: 222px;
  text-align: center;
`;

export const VerticalAligner = styled.div`
  display: flex;
  align-items: center;

  & p,
  & h2 {
    margin: 0;
  }

  & h2 {
    color: #213d58;
    font-size: 18px;
  }
`;

export const StyledIconButton = styled(IconButton)`
  margin-left: 5px;
`;

export const DotWrapper = styled.span`
  display: inline-block;

  &:before {
    color: ${(props) => props.theme.colors.greyScale['25']};
    content: ' • ';
  }
`;
