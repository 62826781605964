import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '../Icon/Icon';

export const ThemeIcon = styled(Icon)`
  height: 24px;
  width: 24px;
  border-radius: 5px;
`;

export const StyledIcon = styled(ThemeIcon)`
  background-color: ${(props) => props.theme.colors.complementary.summerSky};
  & path {
    fill: ${(props) => props.theme.colors.complementary.white};
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 0px;

  .MuiTypography-body1 {
    height: 24px;
    color: ${(props) => props.theme.colors.greyScale['95']};
    font-family: 'Nunito Sans';
    font-size: 18px;
    letter-spacing: 0.2px;
    line-height: 24px;
    padding-left: 15px;
  }
`;
