import MuiMenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';

const StyledMenuItem = styled(MuiMenuItem)`
  height: 50px;
  color: ${(props) => props.theme.colors.greyScale['95']};
  font-size: 16px;
  line-height: 25px;

  &:hover {
    background-color: ${(props) => props.theme.colors.complementary.lightestBlue};
  }
`;

export default StyledMenuItem;
