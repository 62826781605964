import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { Icon } from '../../components';

export const StyledDrawer = styled(Drawer)`
  & .MuiPaper-root {
    background-color: #213d58;
    width: 232px;
  }
`;

export const StyledIcon = styled(Icon)`
  & path {
    fill: ${(props) => props.theme.colors.primary.darkBlue};
  }
`;

export const StyledIconButton = styled(IconButton)`
  color: #213d58;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.2px;
  line-height: 22px;
  text-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.25);
`;

export const DrawerInner = styled.div`
  width: 230px;
`;

export const StyledDivider = styled(Divider)`
  background: #979797;
  opacity: 0.2;
  height: 1px;
  margin-left: 15px;
`;
