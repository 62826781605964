import { createAppSyncLink, AUTH_TYPE } from 'aws-appsync';
import { ApolloClient, InMemoryCache, concat, HttpLink } from '@apollo/client';
import Amplify, { Auth } from 'aws-amplify';
import { AuthOptions } from 'aws-appsync-auth-link';
import AppSyncConfig from './aws-exports';

Amplify.configure(AppSyncConfig);

const httpLink = new HttpLink({
  uri: AppSyncConfig.aws_appsync_graphqlEndpoint,
});

const awsLinkAuth: AuthOptions = {
  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
};

const awsLink = createAppSyncLink({
  url: AppSyncConfig.aws_appsync_graphqlEndpoint || '',
  region: AppSyncConfig.aws_appsync_region || '',
  auth: awsLinkAuth,
  complexObjectsCredentials: () => Auth.currentCredentials(),
});

export const apolloClient = new ApolloClient({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  link: concat(awsLink, httpLink),
  cache: new InMemoryCache(),
  resolvers: {},
});
