import React from 'react';
import styled from 'styled-components';
import Typography from '../../components/Typography/Typography';
import { GridItem, GridContainer, Icon, Button } from '../../components';

export const StyledSummaryPpe = styled.div`
  margin-left: 80px;
  color: #444;
  padding-top: 25px;
  padding-bottom: 17px;

  @media print {
    padding-top: 10px;
    padding-bottom: 0px;
  }
`;

export const StyledSummaryAlertWrapper = styled.div`
  margin-top: -40px !important;
  padding-bottom: 30px;
`;

export const StyledSummaryPpeGridItem = styled(GridItem)`
  margin-right: 65px;
  padding: 0px !important;
`;

export const StyledSummaryPpeGridContainer = styled(GridContainer)`
  margin-bottom: 25px;
  page-break-inside: avoid;
`;

export const EmergencyNoticeWrapper = styled.div`
  text-align: center;
`;

export const SummaryContentBlockTitle = styled(Typography)`
  color: ${(props) => props.theme.colors.findColor('greyScale_50')};
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;

  @media print {
    font-size: 14px !important;
  }
`;

export const SummaryWrapper = styled.div`
  margin-bottom: 90px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 1px 5px 0 rgba(33, 61, 88, 0.4);

  @media print {
    border-radius: unset;
    box-shadow: unset;
  }
`;

export const SummarySubSection = styled.div`
  border-bottom: 2px solid #e1e5e5;
  padding-bottom: 90px;
  margin-bottom: 25px;

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

export const SummarySubSectionTitle = styled(Typography).attrs(() => ({
  variant: 'h3',
}))`
  && {
    color: #213d58;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
  }
  @media print {
    margin-bottom: -10px !important;
  }
`;

export const SummarySection = styled(
  ({ hasSidebar, workDetails, hazardsOnThisJob, workpack, ...rest }: any) => <div {...rest} />
)`
  ${({ hasSidebar, workDetails, workpack }) =>
    hasSidebar &&
    !workDetails &&
    !workpack &&
    `

      display: grid;
      grid-template-columns: minmax(200px, 1fr) 260px;
      @media print and (orientation: landscape){
        grid-template-columns: 900px 250px;
      }

      @media print and (max-device-width: 1366px) and (orientation: landscape){
        grid-template-columns: minmax(200px, 1fr) 260px;
      }

  `}
  ${({ hasSidebar, workDetails }) =>
    hasSidebar &&
    workDetails &&
    `
    @media screen{
      display: grid;
      grid-template-columns: minmax(200px, 1fr) 310px;
      #sidebar {order: 2};

    }
    @media print and (min-device-width: 1367px) and (orientation: landscape){
      display: grid;
      grid-template-columns: minmax(200px, 1fr) 310px;
      #sidebar {order: 2}
    }

    @media print and (max-device-width: 1366px) and (orientation: landscape), print and(orientation:portrait){
      position: relative;
    }
  `}

  ${({ hasSidebar, workpack }) =>
    hasSidebar &&
    workpack &&
    `
  @media screen{
    display: grid;
    grid-template-columns: minmax(200px, 1fr) 260px;
  }


    @media print and  (min-device-width: 1367px) and (orientation: landscape){
      display: grid;
      grid-template-columns: 900px 250px;
    }

  @media print and (orientation: portrait),  print and (max-device-width: 1366px) and (orientation: landscape){
    display: inherited;
  }

`}

  @media print {
    page-break-after: ${({ workDetails, workpack }) =>
      workDetails ? 'auto' : workpack ? 'never' : 'always'};
    page-break-before: ${({ hazardsOnThisJob }) => (hazardsOnThisJob ? 'always' : 'auto')};
  }
`;

export const SummarySectionContent = styled(
  ({ hazardsOnThisJob, hazardsAndControls, workDetails, workpack, ...rest }: any) => <div {...rest} />
)`
  padding: 40px;
  padding-bottom: ${({ hazardsAndControls }) => (hazardsAndControls ? '0px' : '40px')};
  padding-left: 0px;
  margin-left: 40px;
  @media screen {
    border-bottom: ${({ workDetails }) => (workDetails ? 'none' : 'solid 2px #cfedf1')};
    border-top: ${({ hazardsOnThisJob }) => (hazardsOnThisJob ? 'solid 2px #cfedf1' : 'none')};
  }
  @media print and (orientation: portrait),
    print and (max-device-width: 1366px) and (orientation: landscape) {
    padding-bottom: ${({ workpack }) => (workpack ? '0px' : '40px')};
  }
`;
export const SummarySectionContentPpe = styled.div`
  padding: 40px 40px 0px 40px;
  padding-left: 0;
  margin-left: 40px;
  @media screen {
    border-bottom: solid 2px #cfedf1;
  }
`;

export const SummarySectionContentInner = styled(({ workDetails, ...rest }: any) => <div {...rest} />)`
  padding-left: 50px;
  @media print and (max-device-width: 1366px) and (orientation: landscape),
    print and (orientation: portrait) {
    padding-left: ${({ workDetails }) => (workDetails ? '0px' : '50px')};
  }
  margin-top: 25px;
`;

export const SummarySidebar = styled(({ workDetails, workpack, ...rest }: any) => <div {...rest} />)`
  @media screen {
    background: #cfedf1;
    padding-right: 35px;
  }
  padding-left: 40px;
  padding-top: 105px;

  @media print and (max-device-width: 1366px) and (orientation: landscape),
    print and (orientation: portrait) {
    padding-top: ${({ workDetails }) => (workDetails ? '0px' : '105px')};
    margin-top: ${({ workDetails }) => (workDetails ? '350px' : '0px')};
    margin-left: ${({ workDetails }) => (workDetails ? '0px' : '0px')};
  }

  @media print and (orientation: portrait),
    print and (max-device-width: 1366px) and (orientation: landscape) {
    padding-top: ${({ workpack }) => (workpack ? '0px' : '105px')};
    margin-left: ${({ workpack }) => (workpack ? '65px' : '0px')};
    padding-right: ${({ workpack }) => (workpack ? '40px' : '0px')};
  }
`;

export const SummaryContentBlockWrapper = styled(({ wide, title, ...rest }: any) => <div {...rest} />)`
  @media screen {
    max-width: ${({ wide }) => (wide ? '800px' : '700px')};
  }

  @media print and (max-device-width: 1366px) and (orientation: landscape),
    print and (orientation: portrait) {
    h5 {
      page-break-inside: avoid;
    }
    h5::after {
      content: '';
      display: block;
      height: 100px;
      margin-bottom: -100px;
    }
  }
`;

export const StyledSummaryHazardCategory = styled.div`
  border: 1px solid white;
  h4 {
    margin-top: 50px;
  }
  background-color: #cfedf1;
  display: grid;
  grid-template-columns: 150px auto;
  grid-gap: 0;
  page-break-inside: avoid;

  &:nth-of-type(2n) {
    background-color: #fff;
  }
`;

export const HazardGridWrapper = styled.div`
  display: grid !important;
  grid-template-columns: repeat(auto-fill, 150px);
  grid-gap: 0;
  page-break-inside: avoid;
  && {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledSummaryHazard = styled.div`
  && {
    h4 {
      justify-content: center;
      text-align: center;
      margin-left: 12px;
      color: #43575a;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 0.5px;
      line-height: 15px;
      text-transform: uppercase;
    }
  }
`;

export const StyledSummaryHazardsAndControlsWorkStep = styled.div`
  border: 2px solid #cfedf1;
  border-radius: 5px;
  margin-bottom: 18px;
`;

export const StyledSummaryHazardsAndControlsWorkStepTitle = styled.div`
  background-color: #cfedf1;
  height: 44px;
  display: flex;
  align-items: center;
  page-break-inside: avoid;

  && h4 {
    color: #0a0d0d;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 22px;
    margin-left: 22px;
  }
`;

export const StyledSummaryHazardsAndControlsWorkStepDetails = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 150px minmax(200px, 1fr) 142px;

  @media print {
    div:nth-child(1)::after {
      content: '';
      display: block;
      height: 261px;
      margin-bottom: -261px;
      page-break-inside: avoid;
    }

    .under-heading {
      margin-top: -10px;
    }
  }

  padding: 22px;
  border-bottom: 1px solid #e1e5e5;

  & ul {
    max-width: 800px;
    margin-top: -5px;
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

export const StyledSummaryHazardsAndControlsWorkStepRisk = styled.div`
  text-align: center;
  width: 150px;
`;

export const UtlilityList = styled.ul`
  padding-left: 18px;
  & li {
    color: #40b3db;
    & div {
      display: flex;
      justify-content: space-between;
    }
  }
`;
export const UtilityGridItem = styled(GridItem)`
  padding-left: 0px !important;
  & p {
    text-align: left !important;
  }
`;
export const EnergyArrangementsHeading = styled(Typography)`
  color: #1c7b97 !important;
  font-size: 15px !important;
  font-weight: bold !important;
  line-height: 20px !important;
  padding-bottom: 5px;
`;

export const WorkpackList = styled.ul`
  margin: 0;
  padding-left: 18px;

  & li {
    margin-bottom: 10px;
    color: #40b3db;
    & div {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 250px 300px;
    }
  }
`;

export const WorkpackListIsolationCertificate = styled.li`
  & div {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 250px 250px;
  }
`;

export const WorkpackListSupportingDocuments = styled.ul`
  margin: 0;
  padding-left: 18px;

  & li {
    & div {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 250px 300px;
    }
  }
`;

export const FileNameWrapper = styled.a`
  color: ${(props) => props.theme.colors.findColor('greyScale_65')};
  font-size: 14px;
  line-height: 21px;
`;

export const SupportingDocumentsLi = styled.li`
  color: #40b3db;
`;

export const DateWrapper = styled.div`
  display: flex;
  > div,
  svg {
    @media screen {
      padding-right: 30px;
    }
  }
`;
export const ArrowAndEndDateWrapper = styled.div`
  display: flex;
  @media print and (max-device-width: 1366px) and (orientation: landscape),
    print and (orientation: portrait) {
    margin-left: 30px;
    > svg {
      padding-right: 30px;
    }
  }
  @media print and (orientation: landscape) and (min-device-width: 1367px) {
    margin-left: -630px;
    > svg {
      padding-right: 30px;
    }
  }
`;

export const Divider = styled(({ position, ...rest }: any) => <hr {...rest} />)`
  margin-top: ${({ position }) => (position === 'top' ? '104px' : '80px')};
  margin-left: 40px;
  margin-right: 40px;
  border-top: 2px solid #9edae2;
`;

export const StyledTypography = styled(Typography)``;
export const TitleIdWrapper = styled.div`
  @media print and (max-device-width: 1366px) and (orientation: landscape),
    print and (orientation: portrait) {
    display: flex;
    padding-left: 0px;
    text-align: center;
    > p {
      padding-right: 11px;
    }
  }
`;

export const TitleWrapper = styled(GridItem)`
  margin-top: -96px;
  > div {
    padding-top: 5px;
  }
`;
export const PrintPortraitContainer = styled(GridContainer)`
  margin-top: -60px;
`;
export const StyledIcon = styled(Icon)`
  width: 6px;
  padding-right: 10px;
`;
export const StyledLogo = styled(Icon)`
  width: 200px;
  height: 100px;
  position: absolute;
  margin-top: -15px;
  margin-left: 245px;
`;
export const CommentButtonWrapper = styled.div`
  position: absolute;
  right: 70px;
  z-index: 5;
  margin-top: -80px;
`;

export const CommentButton = styled(Button)`
  min-width: 100px !important;
  height: 40px !important;
`;
