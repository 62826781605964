import React, { memo } from 'react';
import { withCookies } from 'react-cookie';
import Modal from '../Modal/Modal';
import ModalTitle from '../Modal/ModalTitle';
import ModalContent from '../Modal/ModalContent';
import ModalContentText from '../Modal/ModalContentText';
import ModalActions from '../Modal/ModalActions';
import GridContainer from '../Grid/GridContainer';
import { GridItem, Button } from '..';
import useClientState from '../../hooks/useClientState';
import { StyledText } from './styles';

const ValidationModal = memo(({ isOpen }: any) => {
  const { setIsValidationModalOpen } = useClientState();

  const titleStyle = 'padding-top: 50px; padding-bottom: 0px;';

  return (
    <Modal open={isOpen}>
      <ModalTitle styles={titleStyle} onClose={() => setIsValidationModalOpen(false)}>
        Review JSA
      </ModalTitle>
      <ModalContent padding="13px 80px 52px 80px">
        <ModalContentText styles="{text-align: center;}">
          <StyledText>You have issues that need resolving before you can submit.</StyledText>
        </ModalContentText>
        <ModalActions>
          <GridContainer>
            <GridItem xs={12}>
              <Button
                preset="primary"
                themeColour="leaf"
                fullWidth
                onClick={() => setIsValidationModalOpen(false)}
              >
                review issues
              </Button>
            </GridItem>
          </GridContainer>
        </ModalActions>
      </ModalContent>
    </Modal>
  );
});

ValidationModal.displayName = 'ValidationModal';

export default withCookies(ValidationModal);
