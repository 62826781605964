export const marksLikelihood = [
  {
    value: 0,
    label: 'Rare',
  },
  {
    value: 25,
    label: 'Unlikely',
  },
  {
    value: 50,
    label: 'Possible',
  },
  {
    value: 75,
    label: 'Likely',
  },
  {
    value: 99,
    label: 'Almost certain',
  },
];

export const marksSeverity = [
  {
    value: 0,
    label: 'Minimal',
  },
  {
    value: 25,
    label: 'Minor',
  },
  {
    value: 50,
    label: 'Moderate',
  },
  {
    value: 75,
    label: 'Major',
  },
  {
    value: 99,
    label: 'Catastrophic',
  },
];
