import React, { memo, useEffect } from 'react';
import { connect, getIn, Field, useFormikContext } from 'formik';
import { RadioGroup } from 'formik-material-ui';
import { useAutoPopulate, useAutoPopulateByValue } from '../../hooks';
import {
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  RadioFormControlLabel,
  Radio,
  StyledLabel,
  GridContainer,
  GridItem,
} from '../../components';
import Tooltip from '../../components/Tooltip/Tooltip';
import {
  JsaWrittenByWrapper,
  PersonInChargeWrapper,
  PersonInChargeLabel,
  RadioWrapper,
  WatercareRepWrapper,
  WatercareRepSameWrapper,
  TopFieldWrapper,
  JSAEmailItemWrapper,
} from './styles';
import FieldValidationWrapper from 'components/FieldValidationWrapper/FieldValidationWrapper';
import { useSubmitValidation } from 'contexts/useSubmitValidation';

const WorkContacts = memo(({ formik, createdBy }: any) => {
  const [expandedPanels, setExpandedPanels] = React.useState<{
    panel1: boolean;
    panel2: boolean;
  }>({ panel1: true, panel2: true });

  const { hasUserAttemptedSubmission, setWorkDetailsState, setUserDefinedEmails } =
    useSubmitValidation();
  const { values, setFieldValue } = useFormikContext();
  const workDetails = getIn(values, 'workDetails');

  const validate = (event: any) => {
    if (event.target.value.indexOf('or') !== -1) {
      return event.preventDefault();
    }
    if ((event.keyCode >= 65 && event.keyCode <= 90) || (event.keyCode > 105 && event.keyCode <= 122)) {
      return event.preventDefault();
    }
  };

  useEffect(() => {
    if (getIn(values, 'workDetails.jsaWrittenBy.firstName') === '') {
      setFieldValue('workDetails.jsaWrittenBy.firstName', createdBy.firstName);
    }
    if (getIn(values, 'workDetails.jsaWrittenBy.lastName') === '') {
      setFieldValue('workDetails.jsaWrittenBy.lastName', createdBy.lastName);
    }
    if (getIn(values, 'workDetails.jsaWrittenBy.email') === '') {
      setFieldValue('workDetails.jsaWrittenBy.email', createdBy.email);
      setUserDefinedEmails({ ['workDetails.jsaWrittenBy.email']: createdBy.email });
    }
  }, []);

  useEffect(() => {
    if (hasUserAttemptedSubmission) {
      setWorkDetailsState(workDetails);
    }
  }, []);

  useEffect(() => {
    if (hasUserAttemptedSubmission) {
      setWorkDetailsState(workDetails);
    }
  }, [workDetails]);

  const handleChange = (panel: string) => () => {
    const currentValue = expandedPanels[panel || 0];

    setExpandedPanels({
      ...expandedPanels,
      [panel]: !currentValue,
    });
  };

  const personInChargeOfWork = getIn(formik.values, 'workDetails.personInChargeOfWork');
  const differentPerson = personInChargeOfWork === 'A different Person';
  const samePerson = personInChargeOfWork === 'Same as the JSA Writer';

  useAutoPopulate(
    'workDetails.jsaWrittenBy.firstName',
    'workDetails.personInCharge.firstName',
    samePerson
  );
  useAutoPopulate(
    'workDetails.jsaWrittenBy.lastName',
    'workDetails.personInCharge.lastName',
    samePerson
  );
  useAutoPopulate(
    'workDetails.jsaWrittenBy.contactNumber',
    'workDetails.personInCharge.contactNumber',
    samePerson
  );
  useAutoPopulate('workDetails.jsaWrittenBy.email', 'workDetails.personInCharge.email', samePerson);

  useAutoPopulateByValue(
    'workDetails.personInChargeOfWork',
    'workDetails.personInCharge.firstName',
    differentPerson,
    ''
  );

  useAutoPopulateByValue(
    'workDetails.personInChargeOfWork',
    'workDetails.personInCharge.contactNumber',
    differentPerson,
    ''
  );

  useAutoPopulateByValue(
    'workDetails.personInChargeOfWork',
    'workDetails.personInCharge.lastName',
    differentPerson,
    ''
  );
  useAutoPopulateByValue(
    'workDetails.personInChargeOfWork',
    'workDetails.personInCharge.email',
    differentPerson,
    ''
  );

  return (
    <Accordion
      borderTop
      borderBottom
      expanded={expandedPanels.panel1 === true}
      onChange={handleChange('panel1')}
    >
      <AccordionSummary>Work contacts</AccordionSummary>
      <AccordionDetails>
        <GridContainer>
          <TopFieldWrapper xs={8}>
            <TextField
              isRequired
              validationLabel="service provider"
              fullWidth
              id="workDetails.serviceProvider"
              name="workDetails.serviceProvider"
              label="Service Provider *"
              tooltip="ServiceProvider"
            />
          </TopFieldWrapper>
        </GridContainer>
        <JsaWrittenByWrapper>
          <GridItem xs={4}>
            <TextField
              isRequired
              validationLabel="first name"
              fullWidth
              id="workDetails.jsaWrittenBy.firstName"
              name="workDetails.jsaWrittenBy.firstName"
              label="JSA written by *"
              placeholder="First name"
            />
          </GridItem>
          <GridItem xs={4}>
            <TextField
              isRequired
              validationLabel="last name"
              fullWidth
              id="workDetails.jsaWrittenBy.lastName"
              name="workDetails.jsaWrittenBy.lastName"
              label="&nbsp;"
              placeholder="Last name"
            />
          </GridItem>
          <GridItem xs={4}>
            <TextField
              isRequired
              validationLabel="contact number"
              fullWidth
              id="workDetails.jsaWrittenBy.contactNumber"
              name="workDetails.jsaWrittenBy.contactNumber"
              label="Contact no. *"
              onKeyDown={validate}
              placeholder="Mobile or landline no."
            />
          </GridItem>
        </JsaWrittenByWrapper>
        <GridContainer>
          <GridItem xs={8}>
            <TextField
              fullWidth
              isRequired
              type="email"
              validationLabel="email address"
              id="workDetails.jsaWrittenBy.email"
              name="workDetails.jsaWrittenBy.email"
              label="JSA writer email address *"
              placeholder="Email address"
            />
          </GridItem>
        </GridContainer>
        <FieldValidationWrapper
          isPICOfWork
          name="workDetails.personInChargeOfWork"
          validationLabel="Missing selection for person in charge of work"
        >
          <PersonInChargeWrapper>
            <PersonInChargeLabel xs={12}>
              <StyledLabel as="span">Person in charge of the work *</StyledLabel>
              <Tooltip
                style={{ margin: '0px 6px 0px 8px' }}
                placement="top"
                text="PersonInChargeOfWork"
              />
            </PersonInChargeLabel>

            <RadioWrapper paddingBottom={differentPerson} xs={3}>
              <Field name="workDetails.personInChargeOfWork" label="Radio Group" component={RadioGroup}>
                <RadioFormControlLabel
                  value="Same as the JSA Writer"
                  control={<Radio />}
                  label="Same as the JSA Writer"
                />
              </Field>
            </RadioWrapper>
            <RadioWrapper paddingBottom={differentPerson} xs={3}>
              <Field name="workDetails.personInChargeOfWork" label="Radio Group" component={RadioGroup}>
                <RadioFormControlLabel
                  value="A different Person"
                  control={<Radio />}
                  label="A different Person"
                />
              </Field>
            </RadioWrapper>
          </PersonInChargeWrapper>
        </FieldValidationWrapper>
        <GridContainer>
          {differentPerson && (
            <>
              <WatercareRepWrapper xs={4}>
                <TextField
                  fullWidth
                  isRequired
                  id="workDetails.personInCharge.firstName"
                  name="workDetails.personInCharge.firstName"
                  label="&nbsp;"
                  placeholder="First name *"
                  validationLabel="first name"
                />
              </WatercareRepWrapper>
              <WatercareRepWrapper xs={4}>
                <TextField
                  fullWidth
                  isRequired
                  id="workDetails.personInCharge.lastName"
                  name="workDetails.personInCharge.lastName"
                  label="&nbsp;"
                  placeholder="Last name *"
                  validationLabel="last name"
                />
              </WatercareRepWrapper>
              <WatercareRepWrapper xs={4}>
                <TextField
                  fullWidth
                  isRequired
                  id="workDetails.personInCharge.contactNumber"
                  name="workDetails.personInCharge.contactNumber"
                  label="Contact no. *"
                  onKeyDown={validate}
                  placeholder="Mobile or landline no. *"
                  validationLabel="contact number"
                />
              </WatercareRepWrapper>
              <JSAEmailItemWrapper xs={8}>
                <TextField
                  fullWidth
                  isRequired
                  type="email"
                  id="workDetails.personInCharge.email"
                  name="workDetails.personInCharge.email"
                  label="Person in charge of the work email address *"
                  placeholder="Email address *"
                  validationLabel="email address"
                />
              </JSAEmailItemWrapper>
              <GridItem xs={4} />
            </>
          )}
          <WatercareRepSameWrapper xs={4}>
            <TextField
              fullWidth
              isRequired
              validationLabel="first name"
              id="workDetails.watercareRepresentative.firstName"
              name="workDetails.watercareRepresentative.firstName"
              label="Watercare representative *"
              tooltip="WatercareRepresentative"
              placeholder="First name"
            />
          </WatercareRepSameWrapper>
          <WatercareRepSameWrapper xs={4}>
            <TextField
              fullWidth
              isRequired
              validationLabel="last name"
              id="workDetails.watercareRepresentative.lastName"
              name="workDetails.watercareRepresentative.lastName"
              label="&nbsp;"
              placeholder="Last name"
            />
          </WatercareRepSameWrapper>
          <WatercareRepSameWrapper xs={4}>
            <TextField
              fullWidth
              isRequired
              validationLabel="contact number"
              id="workDetails.watercareRepresentative.contactNumber"
              name="workDetails.watercareRepresentative.contactNumber"
              label="Contact no. *"
              onKeyDown={validate}
              placeholder="Mobile or landline no."
              type="phone"
            />
          </WatercareRepSameWrapper>
          <GridItem xs={8}>
            <TextField
              type="email"
              fullWidth
              isRequired
              validationLabel="email address"
              id="workDetails.watercareRepresentative.email"
              name="workDetails.watercareRepresentative.email"
              label="Watercare representative email address *"
              placeholder="Email address"
            />
          </GridItem>
        </GridContainer>
      </AccordionDetails>
    </Accordion>
  );
});

WorkContacts.displayName = 'WorkContacts';

export default connect(WorkContacts);
