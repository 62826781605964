import React from 'react';
import Accordion from '../Accordion/Accordion';
import { AccordionDetails, AccordionSummary } from '../Accordion/styles';
import CheckBox from '../CheckBox/CheckBox';
import { StyledPermitsTypography, StyledPermitLink } from '../ControlsSuggestionField/styles';
import Icon from '../Icon/Icon';
import myPdf from './Permit_Decision_Tree.pdf';

const Permits = function ({ permitName }: any) {
  const [expandedPanels, setExpandedPanels] = React.useState<{
    panel1: boolean;
    panel2: boolean;
  }>({ panel1: true, panel2: true });

  const handleChange = (panel: string) => () => {
    const currentValue = expandedPanels[panel];
    setExpandedPanels({
      ...expandedPanels,
      [panel]: !currentValue,
    });
  };

  return (
    <Accordion borderTop expanded={expandedPanels.panel1 === true} onChange={handleChange('panel1')}>
      <AccordionSummary>Permits</AccordionSummary>
      <AccordionDetails>
        <StyledPermitsTypography variant="body1">
          Identify the permit that you need for this hazard and upload them in the next section.
        </StyledPermitsTypography>
        <CheckBox id="permitsCheckbox" name="controls.permits.required" label={permitName} />

        <StyledPermitLink preset="tertiary" themeColour="blue" href={myPdf} download>
          <Icon name="Questionnaire" preset="tertiary" />
          Do I need this permit?
        </StyledPermitLink>
      </AccordionDetails>
    </Accordion>
  );
};

export default Permits;
