import React from 'react';
import { LabelWrapper, StyledLabel } from './style';

interface LabelProps {
  label?: string;
  htmlFor?: string;
  alignLabel?: boolean;
}

const Label = function ({ label, alignLabel, htmlFor, ...rest }: LabelProps) {
  return (
    <LabelWrapper {...rest}>
      <StyledLabel alignLabel={alignLabel} htmlFor={htmlFor}>
        {label}
      </StyledLabel>
    </LabelWrapper>
  );
};

Label.defaultProps = {
  alignLabel: false,
};

export default Label;
