import React from 'react';
import { GridContainer, GridItem, Typography } from '../../components';
import WorkpackForm from '../../features/WorkpackForm/WorkpackForm';

const Workpack: React.FC = function () {
  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <Typography variant="h1" gutterBottom>
            Workpack
          </Typography>
          <Typography variant="body1" paragraph>
            Based on the controls you have identified, complete and upload all supporting documentation.
          </Typography>
        </GridItem>
      </GridContainer>
      <WorkpackForm />
    </>
  );
};

export default Workpack;
