import React from 'react';
import { Form } from 'formik';
import JsaForm from '../JsaForm/JsaForm';
import setInitialValues from '../JsaForm/setInitialValues';
import { useJsaQuery } from '../../hooks';
import ContentLoader from '../../components/ContentLoader/ContentLoader';
import ShareJsaFormDetails from './ShareJsaFormDetails';

const ShareJsaForm = function ({ handleModalClose }: any) {
  const { data, loading } = useJsaQuery();

  if (!data) {
    return <ContentLoader />;
  }

  const initialValues = setInitialValues(data);

  return (
    <JsaForm initialValues={initialValues} isLoading={loading} shouldAutosave={false}>
      <Form style={{ overflow: 'hidden' }}>
        <ShareJsaFormDetails handleModalClose={handleModalClose} />
      </Form>
    </JsaForm>
  );
};

export default ShareJsaForm;
