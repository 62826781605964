import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ChatDialog from './ChatDialog';
import './styles';
import aws from '../../../src/aws-exports';
import humanFileSize from '../../utils/humanFileSize';
import { v4 as uuidv4 } from 'uuid';
import { AuthContext } from 'contexts/AuthProvider';
class Launcher extends Component {
  static contextType = AuthContext;
  constructor() {
    super();
    this.state = {
      newMessagesCount: 0,
      isOpen: false,
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.isOpen === true) {
      this._closeWindow();
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  onClickResolve(message) {
    const currentMessages = this.props.data.getJobSafetyAnalysis.summaryComments[this.props.name];
    const indexOfMessage = currentMessages.findIndex((obj) => obj.id === message.id);
    currentMessages[indexOfMessage].resolved = true;
    this.props.setFieldValue(`summaryComments.${this.props.name}`, currentMessages);
  }

  onMessageDeleted(message) {
    const currentMessages = this.props.data.getJobSafetyAnalysis.summaryComments[this.props.name];
    const newCurrentMessages = currentMessages.filter(
      (currentMessage) => currentMessage.id !== message.id
    );
    this.props.setFieldValue(`summaryComments.${this.props.name}`, newCurrentMessages);
  }

  _onMessageWasSent(message) {
    if (this.props.data.getJobSafetyAnalysis.summaryComments[this.props.name]) {
      this.props.setFieldValue(`summaryComments.${this.props.name}`, [
        ...this.props.data.getJobSafetyAnalysis.summaryComments[this.props.name],
        message,
      ]);
    } else {
      this.props.setFieldValue(`summaryComments.${this.props.name}`, [message]);
    }
  }

  getMonthName = (monthNum) => {
    switch (monthNum) {
      case 0:
        return 'Jan';
      case 1:
        return 'Feb';
      case 2:
        return 'Mar';
      case 3:
        return 'Apr';
      case 4:
        return 'May';
      case 5:
        return 'Jun';
      case 6:
        return 'Jul';
      case 7:
        return 'Aug';
      case 8:
        return 'Sep';
      case 9:
        return 'Oct';
      case 10:
        return 'Nov';
      case 11:
        return 'Dec';
      default:
        return 'Dec';
    }
  };

  async _onFilesSelected(fileList) {
    const jsaData = this.props.data;
    const date = new Date();
    const id = uuidv4();
    const message = {
      type: 'file',
      author: this.context.user.firstName + ' ' + this.context.user.lastName,
      date: String(date.getDate()) + ' ' + this.getMonthName(date.getMonth()),
      resolved: true,
      createdDate: new Date().toISOString(),
      fileName: fileList[0].name,
      fileSize: humanFileSize(fileList[0].size, true),
      id: id,
      file: {
        mimeType: fileList[0].type,
        localUri: fileList[0],
        bucket: aws.aws_user_files_s3_bucket,
        region: aws.aws_user_files_s3_bucket_region,
        key: `public/jsa/${jsaData.getJobSafetyAnalysis.id}/${id}${fileList[0].name.substring(
          fileList[0].name.lastIndexOf('.')
        )}`,
      },
    };

    if (this.props.data.getJobSafetyAnalysis.summaryComments[this.props.name]) {
      this.props.setFieldValue(`summaryComments.${this.props.name}`, [
        ...this.props.data.getJobSafetyAnalysis.summaryComments[this.props.name],
        message,
      ]);
    } else {
      this.props.setFieldValue(`summaryComments.${this.props.name}`, [message]);
    }
  }

  _handleClick() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  _closeWindow() {
    this.setState({
      isOpen: false,
    });
  }

  render() {
    return (
      <div ref={this.setWrapperRef}>
        <ChatDialog
          agentProfile={{
            teamName: `${this.props.displayName} COMMENTS`,
            imageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
          }}
          onClickResolve={this.onClickResolve.bind(this)}
          onMessageDeleted={this.onMessageDeleted.bind(this)}
          onMessageWasSent={this._onMessageWasSent.bind(this)}
          onFilesSelected={this._onFilesSelected.bind(this)}
          newMessagesCount={this.state.newMessagesCount}
          handleClick={this._handleClick.bind(this)}
          isOpen={this.state.isOpen}
          section={this.props.section}
          data={this.props.data}
          name={this.props.name}
        />
      </div>
    );
  }
}

export default Launcher;
