import React, { memo } from 'react';
import { Routes, Navigate, Route, Outlet } from 'react-router-dom';
import { WorkDetails, EmergencyDetails, HazardsControls, Workpack, Dashboard, Summary } from './routes';
import { useScrollToTop } from './hooks';
import WorkflowLayout from './features/WorkflowLayout/WorkflowLayout';

const JsaRouteStub = memo(() => {
  return (
    <>
      <Outlet />
    </>
  );
});

JsaRouteStub.displayName = 'JsaRouteStub';

const AppRouter = memo(() => {
  useScrollToTop();
  return (
    <Routes>
      <Route path="*" element={<Navigate replace to="/dashboard" />} />
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="jsa" element={<JsaRouteStub />}>
        <Route path=":jsa" element={<WorkflowLayout />}>
          <Route path={`work-details`} element={<WorkDetails />} />
          <Route path={`emergency-details`} element={<EmergencyDetails />} />
          <Route path={`hazards-controls`} element={<HazardsControls />} />
          <Route path={`workpack`} element={<Workpack />} />
          <Route path={`summary`} element={<Summary />} />
        </Route>
      </Route>
      <Route path="/" element={<Navigate replace to="/dashboard" />} />
    </Routes>
  );
});

AppRouter.displayName = 'AppRouter';

export default AppRouter;
