import React from 'react';
import { useFormikContext } from 'formik';
import { SummarySidebar, EmergencyNoticeWrapper, CommentButtonWrapper } from './style';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import EmergencyNotice from '../../components/EmergencyNotice/EmergencyNotice';
import Typography from '../../components/Typography/Typography';
import Launcher from '../../components/ChatWindow/Launcher';

const SummaryEmergencyDetailsSidebar = function ({ data }: any) {
  const { setFieldValue } = useFormikContext();

  return (
    <SummarySidebar>
      <GridContainer>
        <GridItem xs={12}>
          <EmergencyNoticeWrapper>
            <EmergencyNotice />
          </EmergencyNoticeWrapper>
        </GridItem>
        <GridItem xs={12}>
          <Typography variant="body1">IMPORTANT</Typography>
          <Typography variant="body1" paragraph>
            Injuries, incidents and near misses must be reported to your Watercare Representative without
            delay. For serious events, the incident scene must not be changed (unless to make it safe)
            and all work in the incident area must stop until permission to restart has been given by
            your Watercare Representative.
          </Typography>
        </GridItem>
        <GridItem xs={12}>
          <CommentButtonWrapper className="screen-only">
            <Launcher
              section={2}
              setFieldValue={setFieldValue}
              data={data}
              displayName="EMERGENCY DETAILS"
              name="EmergencyDetails"
            />
          </CommentButtonWrapper>
        </GridItem>
      </GridContainer>
    </SummarySidebar>
  );
};

export default SummaryEmergencyDetailsSidebar;
