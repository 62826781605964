import React from 'react';
import {
  StyledCard,
  CardLabel,
  StyledHazardIcon,
  IconWraper,
  CardLabelWrapper,
  StyledCardContent,
} from './styles';

// @TODO: Find a way to name type from Hazards & PPE
interface HazardChipProps {
  name?: string;
  label: string;
}

const HazardChip = function ({ name, label }: HazardChipProps) {
  return (
    <StyledCard>
      <StyledCardContent>
        <IconWraper>
          <StyledHazardIcon name={name} />
        </IconWraper>
        <CardLabelWrapper>
          <CardLabel label={label}>{label}</CardLabel>
        </CardLabelWrapper>
      </StyledCardContent>
    </StyledCard>
  );
};

export default HazardChip;
