import React, { useState, createContext, memo, ReactNode, useContext, useEffect } from 'react';

export type AppRouteNames =
  | 'emergency-details'
  | 'hazards-controls'
  | 'work-details'
  | 'workpack'
  | 'summary';

type UserDefinedEmailsKeys =
  | 'workDetails.jsaWrittenBy.email'
  | 'workDetails.watercareRepresentative.email'
  | 'workDetails.personInCharge.email'
  | 'currentUser';

type UserDefinedEmails = {
  [key in UserDefinedEmailsKeys]: string;
};

type WorkDetailsState = {
  hasServiceProvider: boolean;
  hasSiteName: boolean;
  hasSiteAddress: boolean;
  hasOperatingArea: boolean;
  hasJsaWriterFirstName: boolean;
  hasJsaWriterLastName: boolean;
  hasJsaWriterContactNumber: boolean;
  hasJsaWriterEmail: boolean;
  hasWatercareRepFirstName: boolean;
  hasWatercareRepLastName: boolean;
  hasWatercareRepContactNumber: boolean;
  hasWatercareRepEmail: boolean;
  hasPicwSelection: boolean;
  hasPicwFirstName: boolean;
  hasPicwLastName: boolean;
  hasPicwEmail: boolean;
  hasPicwContactNumber: boolean;
  hasDescriptionOfWork: boolean;
};

type EmergencyDetailsState = {
  hasFacilityControlRoomNumber: boolean;
  hasFacilityControlRoom: boolean;
  hasNearestMedicalFacility: boolean;
  hasNearestMedicalFacilityContactDetails: boolean;
  hasEvacuationPoint: boolean;
  hasFirstAidKitLocation: boolean;
};

type DefaultStates = {
  workDetails: WorkDetailsState;
  emergencyDetails: EmergencyDetailsState;
  userDefinedEmails: UserDefinedEmails;
};

type ValidateWorkStep = {
  hasTitle: boolean;
  id: string;
  hasHazards: boolean;
  hasCompletedRisk: boolean;
  hasCompletedControls: boolean;
  hasCompletedResidual: boolean;
};

type PicwStrings = 'Same as the JSA Writer' | 'A different Person';

type EmailClashMessages =
  | 'The Watercare representative email cannot be the same as the JSA written by or person in charge of the work email'
  | 'You cannot submit the JSA because your email has been added as the Watercare representative email';

const DEFAULT_STATES: DefaultStates = {
  workDetails: {
    hasServiceProvider: false,
    hasSiteName: false,
    hasSiteAddress: false,
    hasOperatingArea: false,
    hasJsaWriterFirstName: false,
    hasJsaWriterLastName: false,
    hasJsaWriterContactNumber: false,
    hasJsaWriterEmail: false,
    hasWatercareRepFirstName: false,
    hasWatercareRepLastName: false,
    hasWatercareRepContactNumber: false,
    hasWatercareRepEmail: false,
    hasPicwSelection: false,
    hasPicwFirstName: false,
    hasPicwLastName: false,
    hasPicwEmail: false,
    hasPicwContactNumber: false,
    hasDescriptionOfWork: false,
  },
  emergencyDetails: {
    hasFacilityControlRoomNumber: false,
    hasFacilityControlRoom: false,
    hasNearestMedicalFacility: false,
    hasNearestMedicalFacilityContactDetails: false,
    hasEvacuationPoint: false,
    hasFirstAidKitLocation: false,
  },
  userDefinedEmails: {
    'workDetails.jsaWrittenBy.email': '',
    'workDetails.watercareRepresentative.email': '',
    'workDetails.personInCharge.email': '',
    currentUser: '',
  },
};

export type ValidationContext = {
  currentJsaId: string | null;
  validateUserSubmission: () => boolean;
  hasUserAttemptedSubmission: boolean;
  setCurrentJsaId: (jsaId: string | null) => void;
  showEmptyValidationForHazardsAndControls: boolean;
  setShowEmptyValidationForHazardsAndControls: (show: boolean) => void;
  isWorkStepErroredByIndex: (index: number) => boolean;
  setHazardAndControlState: (workSteps: any) => void;
  getRouteNamesWithErrors: () => string[];
  getNumberOfErrorsForHazardsPage: () => number;
  isHazardsAndControlsEmpty: () => boolean;
  showEmailClashMessaging: EmailClashMessages | null;
  setUserDefinedEmails: (emails: Partial<UserDefinedEmails>) => void;
  setWorkDetailsState: (workDetails: any) => void;
  numberOfWorkDetailsErrors: number;
  setEmergencyDetailsState: (emergencyDetails: any) => void;
  numberOfEmergencyDetailsErrors: number;
  isHazardsAndControlsValid: () => boolean;
};

const ValidationContext = createContext<ValidationContext | null>(null);

type Props = {
  children: ReactNode;
};

export const ValidationProvider = memo(({ children }: Props) => {
  const [showEmailClashMessaging, setShowEmailClashMessaging] = useState<EmailClashMessages | null>(
    null
  );
  const [userDefinedEmails, _setUserDefinedEmails] = useState<UserDefinedEmails>(
    DEFAULT_STATES.userDefinedEmails
  );

  const [hazardAndControlState, _setHazardAndControlState] = useState<ValidateWorkStep[]>([]);
  const [showEmptyValidationForHazardsAndControls, setShowEmptyValidationForHazardsAndControls] =
    useState(false);
  const [currentJsaId, setCurrentJsaId] = useState<string | null>(null);
  const [hasUserAttemptedSubmission, setHasUserAttemptedSubmission] = useState<boolean>(false);

  const [numberOfWorkDetailsErrors, setNumberOfWorkDetailsErrors] = useState<number>(0);

  const [workDetailsState, _setWorkDetailsState] = useState<WorkDetailsState>(
    DEFAULT_STATES.workDetails
  );

  const [numberOfEmergencyDetailsErrors, setNumberOfEmergencyDetailsErrors] = useState<number>(0);

  const [emergencyDetailsState, _setEmergencyDetailsState] = useState<EmergencyDetailsState>(
    DEFAULT_STATES.emergencyDetails
  );

  useEffect(() => {
    // reset all state to default if the JSA id changes.
    if (!currentJsaId) {
      setHasUserAttemptedSubmission(false);
      setShowEmptyValidationForHazardsAndControls(false);
      _setUserDefinedEmails(DEFAULT_STATES.userDefinedEmails);
      _setHazardAndControlState([]);
      _setEmergencyDetailsState(DEFAULT_STATES.emergencyDetails);
      _setWorkDetailsState(DEFAULT_STATES.workDetails);
      setNumberOfEmergencyDetailsErrors(0);
      setNumberOfWorkDetailsErrors(0);
    }
  }, [currentJsaId]);

  const setEmergencyDetailsState = (emergencyDetails: any) => {
    _setEmergencyDetailsState({
      hasFacilityControlRoomNumber: emergencyDetails && !!emergencyDetails.facilityControlRoomNumber,
      hasFacilityControlRoom: emergencyDetails && !!emergencyDetails.facilityControlRoom,
      hasNearestMedicalFacility: emergencyDetails && !!emergencyDetails.nearestMedicalFacility,
      hasNearestMedicalFacilityContactDetails:
        emergencyDetails && !!emergencyDetails.nearestMedicalFacilityContactDetails,
      hasEvacuationPoint: emergencyDetails && !!emergencyDetails.evacuationPoint,
      hasFirstAidKitLocation: emergencyDetails && !!emergencyDetails.firstAidKitLocation,
    });
  };

  const setWorkDetailsState = (workDetails) => {
    const picwSelection: PicwStrings | null = workDetails.personInChargeOfWork;

    const validateWatercareRep = () => {
      return {
        hasWatercareRepFirstName:
          workDetails.watercareRepresentative && !!workDetails.watercareRepresentative.firstName,
        hasWatercareRepLastName:
          workDetails.watercareRepresentative && !!workDetails.watercareRepresentative.lastName,
        hasWatercareRepContactNumber:
          workDetails.watercareRepresentative && !!workDetails.watercareRepresentative.contactNumber,
        hasWatercareRepEmail:
          workDetails.watercareRepresentative && !!workDetails.watercareRepresentative.email,
      };
    };

    const validatePicw = () => {
      return picwSelection && picwSelection === 'A different Person'
        ? {
            hasPicwFirstName: workDetails.personInCharge && !!workDetails.personInCharge.firstName,
            hasPicwLastName: workDetails.personInCharge && !!workDetails.personInCharge.lastName,
            hasPicwEmail: workDetails.personInCharge && !!workDetails.personInCharge.email,
            hasPicwContactNumber:
              workDetails.personInCharge && !!workDetails.personInCharge.contactNumber,
          }
        : {
            hasPicwFirstName: true,
            hasPicwLastName: true,
            hasPicwEmail: true,
            hasPicwContactNumber: true,
          };
    };

    const result = {
      hasServiceProvider: !!workDetails.serviceProvider,
      hasSiteName: !!workDetails.siteName,
      hasSiteAddress: !!workDetails.siteAddress,
      hasOperatingArea: !!workDetails.operatingArea,
      hasJsaWriterFirstName: workDetails.jsaWrittenBy && !!workDetails.jsaWrittenBy.firstName,
      hasJsaWriterLastName: workDetails.jsaWrittenBy && !!workDetails.jsaWrittenBy.lastName,
      hasJsaWriterContactNumber: workDetails.jsaWrittenBy && !!workDetails.jsaWrittenBy.contactNumber,
      hasJsaWriterEmail: workDetails.jsaWrittenBy && !!workDetails.jsaWrittenBy.email,
      hasPicwSelection: !!workDetails.personInChargeOfWork,
      hasDescriptionOfWork: !!workDetails.descriptionOfWork,
      ...validateWatercareRep(),
      ...validatePicw(),
    };

    _setWorkDetailsState(result);
  };

  const setUserDefinedEmails = (emails: Partial<UserDefinedEmails>) => {
    _setUserDefinedEmails((prevState) => {
      return {
        ...prevState,
        ...emails,
      };
    });
  };

  const isWorkStepErroredByIndex = (index: number) => {
    const { hasTitle, hasHazards, hasCompletedRisk, hasCompletedControls, hasCompletedResidual } =
      hazardAndControlState[index] || {};

    const isValid = hasTitle
      ? hasHazards && hasCompletedRisk && hasCompletedControls && hasCompletedResidual
      : true;

    return !isValid;
  };

  const isHazardsAndControlsEmpty = () => {
    return hazardAndControlState.filter(({ hasTitle }) => hasTitle).length === 0;
  };

  const validateHazardsAndControls = (): boolean => {
    if (hazardAndControlState.length === 0) {
      setShowEmptyValidationForHazardsAndControls(true);
      return false;
    }

    const invalidSteps: number[] = [];
    hazardAndControlState.map((step, index) => {
      if (isWorkStepErroredByIndex(index)) {
        invalidSteps.push(index);
      }
    });

    const isValid = invalidSteps.length === 0;

    setShowEmptyValidationForHazardsAndControls(isHazardsAndControlsEmpty());

    return isValid && !isHazardsAndControlsEmpty();
  };

  const isHazardsAndControlsValid = () => {
    if (hazardAndControlState.length === 0) {
      return false;
    }

    const invalidSteps: number[] = [];
    hazardAndControlState.map((step, index) => {
      if (isWorkStepErroredByIndex(index)) {
        invalidSteps.push(index);
      }
    });

    const isValid = invalidSteps.length === 0;

    return isValid && !isHazardsAndControlsEmpty();
  };

  const getNumberOfErrorsForHazardsPage = () => {
    const invalidSteps: number[] = [];
    hazardAndControlState.map((step, index) => {
      if (isWorkStepErroredByIndex(index)) {
        invalidSteps.push(index);
      }
    });

    return invalidSteps.length;
  };

  const setHazardAndControlState = (workSteps: any) => {
    if (!workSteps) {
      return;
    }

    const newState: ValidateWorkStep[] = [];

    const checkHazardsForRiskCompletion = (hazards: any): boolean => {
      return (
        hazards.filter(({ initialRisk }) => initialRisk && initialRisk.likelihood >= 0).length ===
        hazards.length
      );
    };

    const checkHazardsForControlsCompletion = (hazards: any): boolean => {
      return (
        hazards.filter(
          ({ controls }) => controls && (controls.controlMeasures.length > 0 || controls.ppe.length > 0)
        ).length === hazards.length
      );
    };

    const checkHazardsForRisidualCompletion = (hazards: any): boolean => {
      return (
        hazards.filter(({ residualRisk }) => residualRisk && residualRisk.likelihood >= 0).length ===
        hazards.length
      );
    };

    workSteps.map(({ text, id, hazards }) => {
      newState.push({
        id,
        hasTitle: !!text,
        hasHazards: !!hazards && hazards.length > 0,
        hasCompletedRisk: hazards && hazards.length ? checkHazardsForRiskCompletion(hazards) : false,
        hasCompletedControls:
          hazards && hazards.length ? checkHazardsForControlsCompletion(hazards) : false,
        hasCompletedResidual:
          hazards && hazards.length ? checkHazardsForRisidualCompletion(hazards) : false,
      });
    });

    _setHazardAndControlState(newState);
  };

  const validateEmailAddresses = (): boolean => {
    const jsaWrittenByEmail = userDefinedEmails['workDetails.jsaWrittenBy.email'].toLowerCase();
    const personInChargeEmail = userDefinedEmails['workDetails.personInCharge.email'].toLowerCase();
    const watercareRepresentativeEmail =
      userDefinedEmails['workDetails.watercareRepresentative.email'].toLowerCase();
    const currentUser = userDefinedEmails['currentUser'].toLowerCase();

    if (currentUser === watercareRepresentativeEmail) {
      setShowEmailClashMessaging(
        'You cannot submit the JSA because your email has been added as the Watercare representative email'
      );
      return false;
    } else if (
      watercareRepresentativeEmail === jsaWrittenByEmail ||
      watercareRepresentativeEmail === personInChargeEmail
    ) {
      setShowEmailClashMessaging(
        'The Watercare representative email cannot be the same as the JSA written by or person in charge of the work email'
      );
      return false;
    } else {
      setShowEmailClashMessaging(null);
      return true;
    }
  };

  useEffect(() => {
    if (
      userDefinedEmails['currentUser'] !== '' &&
      userDefinedEmails['workDetails.watercareRepresentative.email'] !== ''
    ) {
      validateEmailAddresses();
    }
  }, [userDefinedEmails]);

  const validateWorkDetails = () => {
    const invalidItems: string[] = [];
    Object.keys(workDetailsState).map((key) => {
      if (!workDetailsState[key]) {
        invalidItems.push(key);
      }
    });

    setNumberOfWorkDetailsErrors(invalidItems.length);

    return invalidItems.length === 0 && validateEmailAddresses();
  };

  useEffect(() => {
    validateWorkDetails();
  }, [workDetailsState]);

  const validateEmergencyDetails = () => {
    const invalidItems: string[] = [];
    Object.keys(emergencyDetailsState).map((key) => {
      if (!emergencyDetailsState[key]) {
        invalidItems.push(key);
      }
    });
    setNumberOfEmergencyDetailsErrors(invalidItems.length);

    return invalidItems.length === 0;
  };

  useEffect(() => {
    validateEmergencyDetails();
  }, [emergencyDetailsState]);

  const validateUserSubmission = (): boolean => {
    setHasUserAttemptedSubmission(true);

    const workDetailsResult = validateWorkDetails();
    const emergencyDetailsResult = validateEmergencyDetails();
    const validateHazardsAndControlsResult = validateHazardsAndControls();
    return workDetailsResult && emergencyDetailsResult && validateHazardsAndControlsResult;
  };

  useEffect(() => {
    validateHazardsAndControls();
  }, [hazardAndControlState]);

  const getRouteNamesWithErrors = () => {
    const erroredRoutes: AppRouteNames[] = [];
    if (numberOfWorkDetailsErrors > 0 || showEmailClashMessaging !== null) {
      erroredRoutes.push('work-details');
    }

    if (numberOfEmergencyDetailsErrors > 0) {
      erroredRoutes.push('emergency-details');
    }

    if (!isHazardsAndControlsValid()) {
      erroredRoutes.push('hazards-controls');
    }

    return erroredRoutes;
  };

  return (
    <ValidationContext.Provider
      value={{
        currentJsaId,
        validateUserSubmission,
        hasUserAttemptedSubmission,
        setCurrentJsaId,
        showEmptyValidationForHazardsAndControls,
        setShowEmptyValidationForHazardsAndControls,
        isWorkStepErroredByIndex,
        setHazardAndControlState,
        getRouteNamesWithErrors,
        getNumberOfErrorsForHazardsPage,
        isHazardsAndControlsEmpty,
        showEmailClashMessaging,
        setUserDefinedEmails,
        setWorkDetailsState,
        numberOfWorkDetailsErrors,
        setEmergencyDetailsState,
        numberOfEmergencyDetailsErrors,
        isHazardsAndControlsValid,
      }}
    >
      {children}
    </ValidationContext.Provider>
  );
});

ValidationProvider.displayName = 'ValidationProvider';

export const useSubmitValidation = () => {
  const context = useContext(ValidationContext);

  if (!context) {
    throw new Error('This component must be used within a <ValidationProvider> component.');
  }

  return context;
};
