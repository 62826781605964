import React from 'react';
import Modal from '../Modal/Modal';
import ModalContent from '../Modal/ModalContent';
import footerPointer from './Overlays_take5.png';
import { Wave, Footer, ModalText } from './styles';

const LockModal = function () {
  return (
    <Modal className="lockModel" open>
      <ModalContent style={{ textAlign: 'center', maxWidth: 693, padding: 0 }}>
        <img
          alt=""
          style={{
            width: 150,
            height: 150,
            paddingTop: 60,
            paddingBottom: 30,
          }}
          src={footerPointer}
        />
        <ModalText>
          Jobcare is best used in landscape mode. Please rotate your tablet to continue.
        </ModalText>
        <Wave />
        <Footer />
      </ModalContent>
    </Modal>
  );
};

export default LockModal;
