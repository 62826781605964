import styled from 'styled-components';
import { Button, Icon, RemoveButton, GridItem, GridContainer } from '../../components';

export const RemoveWrapper = styled.div`
  position: relative;
`;

export const StyledButton = styled(Button)`
  margin-top: 35px;

  & .MuiButton-label {
    & svg {
      margin-right: 5px;
    }
  }
`;

export const StyledIcon = styled(Icon)`
  padding-right: 3px;
`;

export const StyledRemoveButton = styled(RemoveButton)`
  position: absolute;
  right: -48px;
  bottom: 2px;
`;

export const FormFieldWrapper = styled(GridItem)`
  padding-top: 30px !important;
  padding-bottom: 0px !important;
`;
export const AddButtonWrapper = styled(GridItem)`
  padding-top: 29px !important;
`;

export const TopFieldWrapper = styled(GridItem)`
  padding-bottom: 0px !important;
  margin-top: -12.5px !important;
`;
export const BottomWrapper = styled(GridContainer)`
  margin-top: 13px;
  margin-bottom: 20px;
`;
