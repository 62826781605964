import React, { Fragment, useState } from 'react';
import { get, map } from 'lodash';
import { connect, FieldArray, getIn } from 'formik';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router';
import { Divider, FormFieldWrapper, AddButtonWrapper } from './styles';
import TextField from '../../components/TextField/TextField';
import { AddButton, Button, GridContainer, RemoveButton } from '../../components';
import { RemoveWrapper } from '../EmergencyDetailsForm/style';
import TeamRoleInput from '../../components/TeamRoleInput/TeamRoleInput';
import useTeamRolesData from '../../hooks/useTeamRolesData';
import AgreedButton from '../../components/AgreedButton/AgreedButton';
import { Mutations, Queries } from '../../api-queries';
import { useSendGrid, useJsaQuery } from 'hooks';

const MyTeam = function (props: any) {
  const { sendStatusChangeEmail } = useSendGrid();
  const mutationQuery = Mutations.UpdateJsa;
  const params: { jsa?: string } = useParams();
  const { data } = useJsaQuery();
  const personInCharge = get(data, 'getJobSafetyAnalysis.workDetails.personInCharge');
  const myTeam = getIn(props.formik.values, 'teamDetails.teamContacts');
  const [agreeArray] = useState(myTeam.map((obj: any) => obj.agreed));
  const { data: teamRolesData } = useTeamRolesData();
  const teamRoles = get(teamRolesData, 'teamRolesData.items', []).map(({ fields }: any) => ({
    label: fields.name,
  }));

  // Set first row of Work Team to be defaulted to the PICW.
  if (personInCharge) {
    myTeam[0].firstName = personInCharge.firstName;
    myTeam[0].lastName = personInCharge.lastName;
  }

  const [updateStatusMutation] = useMutation(mutationQuery, {
    onCompleted(data: any) {
      sendStatusChangeEmail(data);
    },
    refetchQueries: () => [
      {
        query: Queries.GET_JSA_BY_ID,
        variables: {
          id: params.jsa,
        },
      },
    ],
  });

  const pushUpdate = (_input: any) => {
    updateStatusMutation({
      variables: {
        input: _input,
      },
    });
  };

  function handleDone() {
    const input = {
      id: params.jsa,
      teamDetails: {
        teamContacts: myTeam,
      },
    };
    pushUpdate(input);
    props.handleModalClose();
  }

  return (
    <>
      <GridContainer>
        <FieldArray
          name="teamDetails.teamContacts"
          render={(arrayHelpers: any) => (
            <>
              {map(myTeam, (teamContact: any, index: any) => {
                const isFirst = index === 0;
                return (
                  <Fragment key={index}>
                    <FormFieldWrapper xs={3} lg={4}>
                      <TextField
                        disabled={isFirst || agreeArray[index] === 'true'}
                        fullWidth
                        name={`teamDetails.teamContacts[${index}].firstName`}
                        id={`teamDetails.teamContacts[${index}].firstName`}
                        label={index === 0 ? 'First name' : undefined}
                        placeholder="First name"
                      />
                    </FormFieldWrapper>
                    <FormFieldWrapper xs={3} lg={4}>
                      <TextField
                        disabled={isFirst || agreeArray[index] === 'true'}
                        fullWidth
                        id={`teamDetails.teamContacts[${index}].lastName`}
                        name={`teamDetails.teamContacts[${index}].lastName`}
                        label={index === 0 ? 'Last name' : undefined}
                        placeholder="Last name"
                      />
                    </FormFieldWrapper>

                    <FormFieldWrapper xs={4} lg={2}>
                      <RemoveWrapper>
                        <TeamRoleInput
                          disabled={isFirst || agreeArray[index] === 'true'}
                          name={`teamDetails.teamContacts[${index}].role`}
                          teamroles={teamRoles}
                          label={isFirst ? 'Role' : undefined}
                          placeholder="Choose role"
                        />
                      </RemoveWrapper>
                    </FormFieldWrapper>
                    <FormFieldWrapper style={{ display: 'flex', alignItems: 'center' }} xs={2} lg={2}>
                      <AgreedButton
                        id={`teamDetails.teamContacts[${index}].agreed`}
                        name={`teamDetails.teamContacts[${index}].agreed`}
                        isFirst={isFirst}
                        agreeArray={agreeArray}
                      />

                      {!isFirst && (
                        <RemoveButton
                          style={{ marginLeft: '8px' }}
                          preset="tertiary"
                          disabled={agreeArray[index] === 'true'}
                          onClick={() => {
                            arrayHelpers.remove(index);
                            agreeArray.pop(index);
                          }}
                        />
                      )}
                    </FormFieldWrapper>
                  </Fragment>
                );
              })}

              <AddButtonWrapper xs={12}>
                <AddButton
                  text="Add Member"
                  onClick={() => {
                    arrayHelpers.push({
                      firstName: '',
                      lastName: '',
                      role: 'Team Member',
                      agreed: 'false',
                    });
                    agreeArray.push('false');
                  }}
                />
              </AddButtonWrapper>

              <Divider margin="17.5px 0 20px 0" />
            </>
          )}
        />
      </GridContainer>
      <div style={{ marginTop: '40px', display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '327px', marginRight: '25px' }}>
          <Button
            id="send_button"
            preset="secondary"
            themeColour="grey"
            onClick={props.handleModalClose}
            fullWidth
          >
            CANCEL
          </Button>
        </div>
        <div style={{ width: '327px' }}>
          <Button id="cancel_button" preset="primary" onClick={handleDone} themeColour="blue" fullWidth>
            DONE
          </Button>
        </div>
      </div>
    </>
  );
};

export default connect(MyTeam);
