import React, { useState } from 'react';
import { withRouter } from 'utils/withRouter';
import List from '@material-ui/core/List';
import { useCookies } from 'react-cookie';
import { TrayNavListItem } from '../../components';
import AuthButton from './AuthButton';
import { StyledDrawer, StyledIcon, StyledIconButton, DrawerInner, StyledDivider } from './style';
import useClientState from '../../hooks/useClientState';
import { useNavigate } from 'react-router-dom';

const sideNav = [
  { text: 'Home', route: 'dashboard', iconName: 'Home' },
  { text: 'Create New JSA', iconName: 'NewJsa', openModal: true },
];

type TrayNavProps = {
  isOpen?: boolean;
};

const TrayNav = function (props: TrayNavProps) {
  const [isOpen, setIsNavOpen] = useState(props.isOpen);
  const { setIsModalOpen } = useClientState();
  const [, , removeCookie] = useCookies(['jsa_redirect_url']);
  const navigate = useNavigate();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setIsNavOpen(open);
  };

  function handleTabClick(route: any, openModal: any) {
    if (route) {
      removeCookie('jsa_redirect_url', { path: '/' });
      navigate(`/${route}`);
    }

    if (openModal) {
      setIsModalOpen(true);
    }
  }

  return (
    <>
      <StyledIconButton
        id="burger_menu_button"
        edge="start"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <StyledIcon name="Burger" /> &nbsp; MENU
      </StyledIconButton>
      <StyledDrawer open={isOpen} onClick={toggleDrawer(false)}>
        <DrawerInner role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
          <List>
            <TrayNavListItem variant="closeItem" text="Menu" iconName="Close" />
          </List>
          <StyledDivider />
          <List>
            {sideNav.map(({ text, iconName, route, openModal }) => (
              <TrayNavListItem
                id={`menu_button_${iconName}`}
                handleClick={() => handleTabClick(route, openModal)}
                text={text}
                iconName={iconName}
                key={text}
              />
            ))}
            <AuthButton />
          </List>
        </DrawerInner>
      </StyledDrawer>
    </>
  );
};

TrayNav.defaultProps = {
  isOpen: false,
};

export default withRouter(TrayNav);
