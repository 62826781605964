import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  isSaving: false,
};

const workSteps = createSlice({
  name: 'jsaForm',
  initialState,
  reducers: {
    setIsSaving: (nextState: any, action: any) => {
      nextState.isSaving = action.payload; // mutate the state all you want with immer
    },
  },
});

export const getIsSaving = createSelector(
  (state: any) => state.jsaForm.isSaving,
  (isSaving) => isSaving
);

export default workSteps;
