import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import Icon from '../Icon/Icon';

export const StyledMenuItem = styled(MenuItem)`
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 15px;
  padding-bottom: 15px;
  &:hover {
    background-color: #cfedf1;
  }
` as React.ComponentType<MenuItemProps>;

export const StyledButton = styled(Button)`
  & {
    min-width: 32px;
    padding: 6px 0;
  }

  box-shadow: none;
  background-color: transparent;

  &:hover {
    box-shadow: none;
    background-color: transparent;
  }
`;

export const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    border: 1px solid #d3d4d5;
  }
`;

export const StyledIcon = styled(Icon)`
  & path {
    fill: ${(props) => props.theme.colors.greyScale['40']};
  }
`;
