import React from 'react';
import get from 'lodash/get';
import {
  TextField,
  FormikAutocomplete,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DatePicker,
  GridContainer,
} from '../../components';
import { useMasterData, useFacilityField, useFacilityControlField } from '../../hooks';
import { StyledIcon, FormFieldWrapper, TopFieldWrapper, SiteAddressWrapper } from './styles';
import OperatingAreaInput from '../../components/OperatingAreaInput.tsx/OperatingAreaInput';
import useOperatingFacilityData from '../../hooks/useOperatingFacilityData';
import styled from 'styled-components';
import FieldValidationWrapper from 'components/FieldValidationWrapper/FieldValidationWrapper';

const StyledDatePickerWrapper = styled.div`
  > .MuiPickersDay-daySelected {
    background-color: #40b3db !important;
  }
`;

const WorkDescription = () => {
  const [expandedPanels, setExpandedPanels] = React.useState<{
    panel1: boolean;
    panel2: boolean;
  }>({ panel1: true, panel2: true });

  const handleChange = (panel: string) => () => {
    const currentValue = expandedPanels[panel];

    setExpandedPanels({
      ...expandedPanels,
      [panel]: !currentValue,
    });
  };

  const { data: masterData } = useMasterData();
  const { data: operatingAreaData } = useOperatingFacilityData();

  const suggestionData = get(masterData, 'masterData.items', []).map(({ fields }: any) => ({
    id: fields.code,
    label: `${fields.description} (  ${fields.code}  )`,
  }));

  const operatingAreas = get(operatingAreaData, 'operatingFacilityData.items', []).map(
    ({ fields }: any) => ({
      label: fields.name,
    })
  );

  const facilityControlRoomSelector = (
    selectedFacility: any,
    facilities: any,
    facilityControls: any
  ) => {
    const selectedFacilityControlRoomId = get(selectedFacility, 'fields.facilityControlRoom.sys.id');

    const selectedFacilityControl = facilityControls.find((facilityControl: any) => {
      const id = get(facilityControl, 'sys.id');

      return id === selectedFacilityControlRoomId;
    });

    if (selectedFacilityControl) {
      return {
        label: get(selectedFacilityControl, 'fields.facilityControlRoom'),
        id: get(selectedFacilityControl, 'fields.code'),
      };
    }
  };

  useFacilityField('workDetails.siteAddress', 'fields.facilityControlAddress');
  useFacilityField(
    'emergencyDetails.facilityControlRoom',
    'fields.facilityControl',
    facilityControlRoomSelector
  );
  useFacilityControlField(
    'emergencyDetails.facilityControlRoomNumber',
    'fields.facilityControlRoomNumber'
  );

  return (
    <Accordion borderTop expanded={expandedPanels.panel1 === true} onChange={handleChange('panel1')}>
      <AccordionSummary>Work description</AccordionSummary>
      <AccordionDetails>
        <GridContainer>
          <TopFieldWrapper xs={8}>
            <FieldValidationWrapper validationLabel="site name" name="workDetails.siteName">
              <FormikAutocomplete
                id="workDetails.siteName"
                name="workDetails.siteName"
                options={suggestionData}
                label="Site name *"
              />
            </FieldValidationWrapper>
          </TopFieldWrapper>
        </GridContainer>
        <SiteAddressWrapper>
          <FormFieldWrapper xs={8}>
            <TextField
              fullWidth
              isRequired
              validationLabel="site address"
              id="workDetails.siteAddress"
              name="workDetails.siteAddress"
              label="Site address *"
            />
          </FormFieldWrapper>
        </SiteAddressWrapper>
        <GridContainer>
          <FormFieldWrapper xs={8}>
            <FieldValidationWrapper validationLabel="operating area" name="workDetails.operatingArea">
              <OperatingAreaInput name="workDetails.operatingArea" operatingareas={operatingAreas} />
            </FieldValidationWrapper>
          </FormFieldWrapper>
        </GridContainer>
        <GridContainer>
          <FormFieldWrapper xs={8}>
            <TextField
              fullWidth
              id="workDetails.workLocationProcessArea"
              name="workDetails.workLocationProcessArea"
              label="Work location / process area"
              tooltip="WorkLocationProcessArea"
            />
          </FormFieldWrapper>
        </GridContainer>
        <GridContainer>
          <FormFieldWrapper xs={3}>
            <StyledDatePickerWrapper>
              <DatePicker name="workDetails.plannedWorkDate.startDate" label="Planned work date *" />
            </StyledDatePickerWrapper>
          </FormFieldWrapper>
          <StyledIcon name="Connector" />
          <FormFieldWrapper xs={3}>
            <StyledDatePickerWrapper>
              <DatePicker name="workDetails.plannedWorkDate.endDate" label="Planned end date *" />
            </StyledDatePickerWrapper>
          </FormFieldWrapper>
        </GridContainer>
        <GridContainer>
          <FormFieldWrapper xs={12}>
            <TextField
              fullWidth
              isRequired
              minRows={5.75}
              maxRows={5.75}
              multiline
              validationLabel="description of work"
              id="workDetails.descriptionOfWork"
              name="workDetails.descriptionOfWork"
              label="Description of work *"
              placeholder="Explain in a simple way the work to be done under this JSA."
            />
          </FormFieldWrapper>
        </GridContainer>
        <GridContainer>
          <FormFieldWrapper xs={12}>
            <TextField
              fullWidth
              minRows={5.75}
              maxRows={5.75}
              multiline
              id="workDetails.scopeOfWork"
              name="workDetails.scopeOfWork"
              label="Scope of Work"
              placeholder="Include the project name and the parcel of work to which this JSA applies."
              bodyText="A Scope of Work is needed if this JSA is one of multiple JSAs for the job."
            />
          </FormFieldWrapper>
        </GridContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default WorkDescription;
