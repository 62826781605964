import React from 'react';
import { useFormikContext } from 'formik';
import { getWorkStepsAndControls } from './summaryHazardsAndControlsSelectors';
import SummaryHazardsAndControlsWorkStep from './SummaryHazardsAndControlsWorkStep';
import { CommentButtonWrapper, SummarySection, SummarySectionContent } from './style';
import SummarySectionTitle from '../../components/SummarySectionTitle/SummarySectionTitle';
import Fallback from '../../components/Fallback/Fallback';
import Launcher from '../../components/ChatWindow/Launcher';

const SummaryHazardsAndControls = function ({ data }: any) {
  const workStepsAndControls = getWorkStepsAndControls(data);
  const { setFieldValue } = useFormikContext();

  return (
    <SummarySection>
      <SummarySectionContent hazardsAndControls>
        <SummarySectionTitle id="hazards_controls_title" sectionNumber="3">
          Hazards &amp; Controls
        </SummarySectionTitle>
        <Fallback
          value={workStepsAndControls}
          message="Hazards &amp; Controls not completed yet"
          isText={false}
        >
          {workStepsAndControls.map((workStep: any, key: number) => (
            <SummaryHazardsAndControlsWorkStep
              key={`SummaryHazardsAndControlsWorkStep-${key}`}
              dataTestid={`SummaryHazardsAndControlsWorkStep-${key}`}
              workStep={workStep}
              stepNumber={key + 1}
            />
          ))}
        </Fallback>
      </SummarySectionContent>
      <CommentButtonWrapper className="screen-only">
        <Launcher
          section={3}
          setFieldValue={setFieldValue}
          data={data}
          displayName="HAZARDS AND CONTROLS"
          name="HazardsControls"
        />
      </CommentButtonWrapper>
    </SummarySection>
  );
};

export default SummaryHazardsAndControls;
