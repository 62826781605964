import React from 'react';
import { Typography, GridContainer, GridItem, PageValidationErrorCard } from '../../components';
import { HazardsAndControlsForm } from '../../features';
import { AccordionSummary, AccordionDetails } from '../../components/Accordion/styles';
import Icon from '../../components/Icon/Icon';
import {
  StyledAccordion,
  StyledGridContainer,
  StyledDiv,
  StyledDivIcon,
  StyledDivContainer,
  StyledShowHideButton,
  StyledTitleAndButtonBar,
} from './style';

const HazardsControls = function () {
  const [expandedPanels, setExpandedPanels] = React.useState<{
    instructionPanel: boolean;
  }>({ instructionPanel: true });

  const handleClick = () => {
    const currentValue = expandedPanels['instructionPanel'];

    setExpandedPanels({
      ...expandedPanels,
      ['instructionPanel']: !currentValue,
    });
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={8}>
          <Typography id="hazard_controls_title" variant="h1" gutterBottom>
            Hazards &amp; Controls
          </Typography>
        </GridItem>
      </GridContainer>

      <StyledDiv>
        <StyledAccordion
          hazardinstructions="true"
          expanded={expandedPanels.instructionPanel === true}
          title={'Hazards &amp; Controls '}
        >
          <AccordionSummary>
            <StyledTitleAndButtonBar>
              <Typography variant="h4">How to fill Hazards &amp; Controls</Typography>
              <StyledShowHideButton onClick={handleClick}>
                {expandedPanels.instructionPanel ? 'Hide' : 'Show'}
              </StyledShowHideButton>
            </StyledTitleAndButtonBar>
          </AccordionSummary>
          <AccordionDetails>
            <GridContainer>
              <GridItem xs={6}>
                <StyledDivContainer>
                  <StyledDivIcon>
                    <Icon name="Droplet1" />
                  </StyledDivIcon>
                  <div>
                    <Typography variant="body1">
                      List all the steps involved in the work task.
                    </Typography>
                  </div>
                </StyledDivContainer>
              </GridItem>
              <GridItem xs={6}>
                <StyledDivContainer>
                  <StyledDivIcon>
                    <Icon name="Droplet3" />
                  </StyledDivIcon>
                  <div>
                    <Typography variant="body1">Assess the risk for each hazard.</Typography>
                  </div>
                </StyledDivContainer>
              </GridItem>
              <GridItem xs={6}>
                <StyledDivContainer>
                  <StyledDivIcon>
                    <Icon name="Droplet2" />
                  </StyledDivIcon>
                  <div>
                    <Typography variant="body1">Identify the possible hazards for each step.</Typography>
                  </div>
                </StyledDivContainer>
              </GridItem>
              <GridItem xs={6}>
                <StyledDivContainer>
                  <StyledDivIcon>
                    <Icon name="Droplet4" />
                  </StyledDivIcon>
                  <div>
                    <Typography variant="body1">
                      List controls to lower the risk of each hazard.
                    </Typography>
                  </div>
                </StyledDivContainer>
              </GridItem>
              <GridItem xs={12} />
            </GridContainer>
          </AccordionDetails>
        </StyledAccordion>
      </StyledDiv>

      <PageValidationErrorCard route="hazards-controls" />

      <StyledGridContainer>
        <GridItem xs={12}>
          <HazardsAndControlsForm />
        </GridItem>
      </StyledGridContainer>
    </>
  );
};

export default HazardsControls;
