import React from 'react';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import ContentLoader from 'react-content-loader';
import Overlay from '../Overlay/Overlay';
import HazardOverlayActions from './HazardOverlayActions';
import { useClientState, useJsaQuery } from '../../hooks';
import HazardOverlaySubTitle from './HazardOverlaySubTitle';
import HazardOverlayContent from './HazardOverlayContent';
import { getStepToUpdate } from '../../redux/workStepsSlice';
import { getUniqueDraftSelectedHazards } from './selectors';
import HazardsOverlayDeleteModal from '../HazardsOverlayDeleteModal/HazardsOverlayDeleteModal';

const HazardOverlay = function ({ onClose, open }: any) {
  const { data } = useJsaQuery();
  const stepToUpdate = useSelector(getStepToUpdate);
  const { setHazardsToSave, setIsHazardOverlayOpen, setEditingHazards, setCustomHazardsToSave } =
    useClientState();
  const editingHazards = get(data, `getJobSafetyAnalysis.${stepToUpdate}.editingHazards`);
  const hazards = get(data, `getJobSafetyAnalysis.${stepToUpdate}.hazards`, []);
  const [isOpen, setIsOpen] = React.useState<any>(false);

  function toggleDeleteModal() {
    setIsOpen(!isOpen);
  }

  const setInitialValues = () => ({
    categoriesWithCustomHazards: get(data, 'getJobSafetyAnalysis.categoriesWithCustomHazards', []),
    selectedHazards: get(data, `getJobSafetyAnalysis.${stepToUpdate}.hazards`, []),
    draftSelectedHazards: [],
    selectedHazardsToKeep: [],
  });

  function handleHazardRemoval(draftSelectedHazards: any, selectedHazards: any) {
    setIsHazardOverlayOpen(false);
    if (selectedHazards.length > 0) {
      setHazardsToSave([...selectedHazards, ...draftSelectedHazards]);
    } else {
      setHazardsToSave(draftSelectedHazards);
    }
  }

  function handleSubmit(values: any) {
    const draftSelectedHazards = getUniqueDraftSelectedHazards(values);
    const { selectedHazards } = values;
    if (editingHazards === false) setEditingHazards(true);

    if (selectedHazards.length !== hazards.length) {
      const codeArray = hazards.map((hazard: any) => hazard.code);
      const indexArray = draftSelectedHazards.map((item: any) => {
        if (codeArray.indexOf(item.code) !== -1) {
          return item;
        }
        return undefined;
      });
      const filteredArray = indexArray.filter((hazard: any) => hazard !== undefined);
      const lookupArray = filteredArray.map((hazard: any) =>
        hazards.filter((selectedHazard: any) => selectedHazard.code === hazard.code)
      );

      const flattenedArray = lookupArray.flat();

      if (flattenedArray.length === 0) {
        toggleDeleteModal();
      } else {
        setHazardsToSave([...flattenedArray, ...draftSelectedHazards, ...selectedHazards]);
      }
    } else {
      handleHazardRemoval(draftSelectedHazards, selectedHazards);
    }
    setCustomHazardsToSave(values.categoriesWithCustomHazards);
  }

  if (!data) {
    return <ContentLoader />;
  }

  return (
    <Overlay
      id="add_hazards_overlay_title"
      title="Add Hazards"
      subTitle={<HazardOverlaySubTitle />}
      open={open}
    >
      <Formik initialValues={setInitialValues()} onSubmit={handleSubmit}>
        <>
          <HazardOverlayContent />
          <HazardOverlayActions
            editingHazards={editingHazards}
            data={data}
            stepToUpdate={stepToUpdate}
            onClose={onClose}
          />
          <HazardsOverlayDeleteModal toggleDeleteModal={toggleDeleteModal} isOpen={isOpen} />
        </>
      </Formik>
    </Overlay>
  );
};

export default HazardOverlay;
