import React from 'react';
import styled from 'styled-components';

interface BodyTextProps {
  text: string;
}

const BodyTextWrapper = styled.div`
  margin-bottom: 10px;
`;

const BodyText = function ({ text }: BodyTextProps) {
  return <BodyTextWrapper>{text}</BodyTextWrapper>;
};

export default BodyText;
