import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  GridContainer,
  GridItem,
  TextField,
} from '../../components';
import { AccordionSubText } from '../../components/Accordion/styles';
import UtilityInputRow from './UtilityInputRow';

const electricityOptions = [
  {
    id: '110',
    label: '110 volts',
  },
  {
    id: '230/240',
    label: '230/240 volts',
  },
];

const waterOptions = [
  {
    id: 'main',
    label: 'Main',
  },
  {
    id: 'service',
    label: 'Service',
  },
];

const InteractionWithUtilities = function () {
  const [expandedPanels, setExpandedPanels] = React.useState<{
    panel1: boolean;
    panel2: boolean;
  }>({ panel1: true, panel2: true });

  const handleChange = (panel: string) => () => {
    // @ts-check
    const currentValue = expandedPanels[panel];

    setExpandedPanels({
      ...expandedPanels,
      [panel]: !currentValue,
    });
  };

  return (
    <Accordion
      borderTop
      borderBottom
      expanded={expandedPanels.panel1 === true}
      onChange={handleChange('panel1')}
    >
      <AccordionSummary>Interaction with utilities</AccordionSummary>
      <AccordionDetails>
        <AccordionSubText>
          Select any utilities that you need to interact with and add the relevant information
        </AccordionSubText>
        <GridContainer>
          <UtilityInputRow
            name="workPack.utilities.electricity"
            checkboxLabel="Electricity"
            valueLabel="Voltage:"
            options={electricityOptions}
            hasInput
          />
          <UtilityInputRow
            name="workPack.utilities.gas"
            checkboxLabel="Gas"
            valueLabel="Pressure:"
            hasInput
          />
          <UtilityInputRow
            name="workPack.utilities.water"
            checkboxLabel="Water"
            valueLabel="Type:"
            options={waterOptions}
            hasInput
          />
          <UtilityInputRow
            name="workPack.utilities.wastewater"
            checkboxLabel="Wastewater"
            valueLabel="Type:"
            options={waterOptions}
            hasInput
          />
          <UtilityInputRow
            name="workPack.utilities.stormwater"
            checkboxLabel="Stormwater"
            valueLabel="Type:"
            options={waterOptions}
            hasInput
          />
          <UtilityInputRow
            name="workPack.utilities.telco"
            checkboxLabel="Telephone / Internet"
            valueLabel="Type:"
          />
          <GridItem xs={12}>
            <TextField
              minRows={6}
              maxRows={6}
              multiline
              fullWidth
              id="workPack.energyArrangements.value"
              name="workPack.energyArrangements"
              label="Record energy isolation/arrangements made with Watercare Operations & utility providers:"
              placeholder="Example: I have arranged with Vector to turn off power on Sat, 14 Sep between 9-11 AM."
            />
          </GridItem>
        </GridContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default InteractionWithUtilities;
