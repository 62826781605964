import React from 'react';
import { StyledHazardOverlaySubTitle } from './styles';

const HazardOverlaySubTitle = function () {
  return (
    <StyledHazardOverlaySubTitle>
      Choose all the hazards that apply to &rdquo;<strong>Step 1: Setting up the work site</strong>
      &rdquo;. Extra hazards can be added.
    </StyledHazardOverlaySubTitle>
  );
};

export default HazardOverlaySubTitle;
