import React from 'react';
import { DialogContentProps } from '@material-ui/core/DialogContent';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import OverlayContext from './OverlayContext';
import { StyledDialogContent } from './styles';

interface OverlayContentProps extends DialogContentProps {
  shouldSetScrollTrigger: boolean;
}

const OverlayContent = function ({ shouldSetScrollTrigger, children, ...rest }: OverlayContentProps) {
  const [scrollTarget, setScrollTarget] = React.useState(undefined);
  const { trigger, setTrigger, setContentRef } = React.useContext(OverlayContext);

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: scrollTarget,
  });

  const scrollDownTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 200,
    target: scrollTarget,
  });

  React.useEffect(() => {
    if (shouldSetScrollTrigger) {
      !trigger ? setTrigger(scrollDownTrigger) : setTrigger(scrollTrigger);
    }
  }, [scrollDownTrigger, scrollTrigger, setTrigger, shouldSetScrollTrigger]);

  return (
    <StyledDialogContent
      data-scroll-lock-scrollable
      ref={(node: any) => {
        if (node) {
          setScrollTarget(node);
          setContentRef(node);
        }
      }}
      {...rest}
    >
      {children}
    </StyledDialogContent>
  );
};

OverlayContent.defaultProps = {
  shouldSetScrollTrigger: false,
};

export default OverlayContent;
