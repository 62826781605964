import React from 'react';
import styled from 'styled-components';
import Icon from '../Icon/Icon';
import Button from './Button';

const StyledButton = styled(({ ...rest }) => <Button {...rest} />)`
  height: 40px;
  padding-top: 10px;

  & .MuiButton-label {
    display: flex;
    margin-top: -5px;
    display: inline-flex;
  }

  svg {
    width: 18px;
    height: 18px;
    margin-right: 9.5px;
    margin-top: -2px;

    path,
    polygon {
      fill: #40b3db;
      stroke: #40b3db;
    }
  }
  div {
    display: inline-flex;
    color: #1c7b97;
    font-family: 'Nunito Sans';
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 20px;
  }
  &:hover {
    & path,
    & polygon {
      fill: white;
      stroke: white;
    }
  }
`;

interface Props {
  onClick?: (e: any) => any;
  text?: string;
  id?: string;
}

const AddButton: React.FC<Props> = function ({ id, onClick, text }) {
  return (
    <StyledButton id={id} preset="secondary" themeColour="mediumBlue" onClick={onClick}>
      <Icon name="Add" />
      <div>{text}</div>
    </StyledButton>
  );
};

export default AddButton;
