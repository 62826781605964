import styled from 'styled-components';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';

export const StyledGridContainer = styled(GridContainer)`
  padding-bottom: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
  background-color: #f6f7f7;
  border-top: 2px solid #e1e5e5;
  left: 15px;
`;

export const ButtonContainer = styled(GridItem)`
  padding: 10px 12.5px 15px 12.5px !important;
`;
