import React from 'react';
import {
  getFacilityControlRoomNumber,
  getEmergencyContacts,
  getFacilityControlRoom,
} from './summaryEmergencyDetailsSelectors';
import SummaryContentBlock from './SummaryContentBlock';
import { SummarySubSection, SummarySubSectionTitle } from './style';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Fallback from '../../components/Fallback/Fallback';

const SummaryEmergencyDetailsContacts = function ({ data }: any) {
  const facilityControlRoom = getFacilityControlRoom(data);
  const facilityControlRoomNumber = getFacilityControlRoomNumber(data);
  const emergencyContacts = getEmergencyContacts(data);

  return (
    <SummarySubSection>
      <GridContainer>
        <GridItem xs={12}>
          <SummarySubSectionTitle>Emergency Contacts</SummarySubSectionTitle>
        </GridItem>

        {emergencyContacts.map(({ fullName, contactNumber }: any, index: number) => (
          <GridItem xs={6} key={`Emergency-contact-${index}`}>
            <SummaryContentBlock title={`Emergency contact ${index + 1}`}>
              <Fallback value={fullName} message="Name not completed yet" />
              <Fallback value={contactNumber} message="Number not completed yet" />
            </SummaryContentBlock>
          </GridItem>
        ))}
        <GridItem xs={8}>
          <SummaryContentBlock title="Facility control">
            <Fallback value={facilityControlRoom} message="Facility control not completed yet" />
            <Fallback value={facilityControlRoomNumber} message="Number not completed yet" />
          </SummaryContentBlock>
        </GridItem>
      </GridContainer>
    </SummarySubSection>
  );
};

export default SummaryEmergencyDetailsContacts;
