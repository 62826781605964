import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  GridContainer,
  GridItem,
  Typography,
  TextField,
  LinkButton,
} from '../../components';
import { LinkButtonWrapper } from './styles';

const WorksafeNotification = function () {
  const [expandedPanels, setExpandedPanels] = React.useState<{
    panel1: boolean;
    panel2: boolean;
  }>({ panel1: true, panel2: true });

  const handleChange = (panel: string) => () => {
    // @ts-check
    const currentValue = expandedPanels[panel];

    setExpandedPanels({
      ...expandedPanels,
      [panel]: !currentValue,
    });
  };

  return (
    <Accordion borderTop expanded={expandedPanels.panel1 === true} onChange={handleChange('panel1')}>
      <AccordionSummary>Worksafe notification form</AccordionSummary>
      <AccordionDetails>
        <GridContainer>
          <GridItem xs={12}>
            <Typography variant="body1">
              If you need to fill a worksafe notification form, please include the application number
              below.
            </Typography>
          </GridItem>
          <GridItem xs={5}>
            <TextField
              id="workPack.worksafeNotification"
              name="workPack.worksafeNotification"
              label="Worksafe notification no."
              fullWidth
              placeholder="6-digit number"
              tooltip="WorksafeNotificationForm"
              inputProps={{ maxLength: 6 }}
            />
          </GridItem>
          <GridItem xs={5}>
            <LinkButtonWrapper>
              <LinkButton
                text="Fill worksafe notification form"
                icon="ExternalLink"
                href="https://forms.worksafe.govt.nz/hazardous-work-notification"
                target="_blank"
              />
            </LinkButtonWrapper>
          </GridItem>
        </GridContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default WorksafeNotification;
