import get from 'lodash/get';

const mapEmptyDate = (object: any, path: string) => {
  const value = get(object, path);

  if (value === undefined) {
    return new Date().toISOString();
  }

  if (value === '') {
    return null;
  }

  return value;
};

export default mapEmptyDate;
