import React from 'react';
import { connect, getIn } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { useClientState } from '../../hooks';
import { Typography } from '../../components';
import AddButton from './AddButton';
import Tooltip from '../../components/Tooltip/Tooltip';
import WorkStepHazard from './WorkStepHazard';
import {
  WorkStepDetailsWrapperEmpty,
  WorkStepDetailsWrapper,
  WorkStepHazards,
  WorkStepHazardHeader,
  WorkStepHazardHeaderText,
  AddButtonWrapper,
} from './style';

const WorkStepDetails = function ({ name, formik }: any) {
  const { setIsHazardOverlayOpen, setStepToUpdate, setCustomHazardsToSave } = useClientState();
  const hazards = getIn(formik.values, `${name}.hazards`);

  const onClick = () => {
    setStepToUpdate(name);
    setIsHazardOverlayOpen(true);
    setCustomHazardsToSave(null);
  };

  if (!isEmpty(hazards)) {
    return (
      <WorkStepDetailsWrapper>
        <WorkStepHazards>
          <WorkStepHazardHeader>
            <WorkStepHazardHeaderText variant="h5" gutterBottom align="center">
              HAZARDS
            </WorkStepHazardHeaderText>
            <WorkStepHazardHeaderText variant="h5" gutterBottom align="center">
              RISK LEVEL
            </WorkStepHazardHeaderText>
            <WorkStepHazardHeaderText variant="h5" gutterBottom align="left">
              CONTROLS & ISOLATIONS
            </WorkStepHazardHeaderText>
            <WorkStepHazardHeaderText variant="h5" gutterBottom align="center">
              RESIDUAL RISK LEVEL
              <Tooltip
                style={{ margin: '-3px 6px 0px 8px' }}
                placement="left"
                text="HazardResidualRiskLevel"
              />
            </WorkStepHazardHeaderText>
          </WorkStepHazardHeader>
          {hazards.map((hazard: any, index: number) => (
            <WorkStepHazard
              name={`${name}.hazards.${index}`}
              hazard={hazard}
              key={`WorkStepHazard-${index}`}
            />
          ))}
        </WorkStepHazards>
        <AddButtonWrapper>
          <AddButton onClick={onClick} />
        </AddButtonWrapper>
      </WorkStepDetailsWrapper>
    );
  }

  return (
    <WorkStepDetailsWrapperEmpty>
      <Typography id="work_step_detail_title" variant="h3" gutterBottom>
        What could cause harm?
      </Typography>
      <Typography id="work_step_detail_subtext" variant="body1" paragraph>
        Choose all the hazards related to this step.
      </Typography>

      <AddButton onClick={onClick} />
    </WorkStepDetailsWrapperEmpty>
  );
};

export default connect(WorkStepDetails);
