import React from 'react';
import ReactContentLoader from 'react-content-loader';

const ContentLoader = () => {
  return (
    <ReactContentLoader
      height={480}
      width={900}
      speed={1}
      viewBox="0 0 900 480"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="15" rx="12" ry="12" width="900" height="30" />
      <rect x="0" y="65" rx="12" ry="12" width="400" height="30" />
      <rect x="0" y="110" rx="12" ry="12" width="900" height="30" />
      <rect x="0" y="155" rx="12" ry="12" width="700" height="30" />
      <rect x="0" y="200" rx="24" ry="24" width="900" height="350" />
    </ReactContentLoader>
  );
};

export default ContentLoader;
