import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import workStepsSlice from './redux/workStepsSlice';
import jsaFormSlice from './redux/jsaFormSlice';
import activeUserSlice from './redux/activeUserSlice';
import jsaModalSlice from './redux/jsaModalSlice';

const reducer = combineReducers({
  [workStepsSlice.name]: workStepsSlice.reducer,
  [jsaFormSlice.name]: jsaFormSlice.reducer,
  [activeUserSlice.name]: activeUserSlice.reducer,
  [jsaModalSlice.name]: jsaModalSlice.reducer,
});

const store = configureStore({
  reducer,
});

export default store;
