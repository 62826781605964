import React from 'react';
import { Typography, AddButton } from '../../components';
import { WorkStepListHeaderActions, WorkStepListHeaderWrapper } from './style';

const WorkStepListHeader = function ({ addStep }: any) {
  return (
    <WorkStepListHeaderWrapper>
      <Typography id="work_step_title" variant="h2">
        What are we doing?
      </Typography>

      <WorkStepListHeaderActions>
        {/* <StyledIconButton>
          <Icon name="HazardList" />
          Asset Hazards
        </StyledIconButton> */}
        <AddButton id="work_step_add_button" text="Add a step" onClick={addStep} />
      </WorkStepListHeaderActions>
    </WorkStepListHeaderWrapper>
  );
};

export default WorkStepListHeader;
