import { useEffect, useContext } from 'react';
import { getIn } from 'formik';
import get from 'lodash/get';
import useDebounce from './useDebounce';
import useMasterData from './useMasterData';
import { JsaFormContext } from '../features/JsaForm/JsaForm';

function useMedicalFacility(fieldPath: string, facilityFieldPath: string) {
  const formikContext: any = useContext(JsaFormContext);
  const { data: medicalFacilityData } = useMasterData();
  const field1Value = getIn(formikContext.values, 'emergencyDetails.nearestMedicalFacility.id');
  const debouncedValues = useDebounce(field1Value, 300);

  useEffect(() => {
    if (field1Value && medicalFacilityData) {
      const medicalFacilitys = get(medicalFacilityData, 'medicalFacility.items', []);
      const selectedFacility = medicalFacilitys.find(({ fields }: any) => fields.code === field1Value);
      if (selectedFacility) {
        const fieldValue = getIn(selectedFacility, facilityFieldPath);

        fieldValue && formikContext.setFieldValue(fieldPath, fieldValue);
      }
    } else if (field1Value === null) {
      formikContext.setFieldValue(fieldPath, '');
    }
  }, [debouncedValues]);

  return null;
}

export default useMedicalFacility;
