import styled from 'styled-components';
import Tab from '@material-ui/core/Tab';

const AppHeaderTab = styled(Tab)`
  & .MuiTab-wrapper {
    color: #213d58;
    text-transform: none;
  }
`;

export default AppHeaderTab;
