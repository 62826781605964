import { produce } from 'immer';

function moveItemInArray(array: [], from: number, to: number) {
  return produce(array, (draft: []) => {
    // Splice logic explained here: https://stackoverflow.com/a/2440723
    draft.splice(to, 0, draft.splice(from, 1)[0]);
  });
}

export default moveItemInArray;
