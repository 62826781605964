import React from 'react';
import { Form } from 'formik';
import JsaForm from '../JsaForm/JsaForm';
import setInitialValues from '../JsaForm/setInitialValues';
import { useJsaQuery } from '../../hooks';
import EmergencyContacts from './EmergencyContacts';
import EmergencyLocations from './EmergencyLocations';
import ContentLoader from '../../components/ContentLoader/ContentLoader';

const EmergencyDetailsForm = function () {
  const { data, loading } = useJsaQuery();

  if (!data) {
    return <ContentLoader />;
  }

  const initialValues = setInitialValues(data);

  return (
    <JsaForm initialValues={initialValues} isLoading={loading}>
      <Form>
        <EmergencyContacts />
        <EmergencyLocations />
      </Form>
    </JsaForm>
  );
};

export default EmergencyDetailsForm;
