import React from 'react';
import { useSelector } from 'react-redux';
import { connect, getIn } from 'formik';
import { produce } from 'immer';
import { getItemToDelete } from '../../redux/workStepsSlice';
import Modal from '../../components/Modal/Modal';
import ModalTitle from '../../components/Modal/ModalTitle';
import ModalContent from '../../components/Modal/ModalContent';
import ModalContentText from '../../components/Modal/ModalContentText';
import ModalActions from '../../components/Modal/ModalActions';
import GridContainer from '../../components/Grid/GridContainer';
import { GridItem, Button } from '../../components';

const WorkStepDeleteModal = function ({ formik: { values, setFieldValue }, handleClose }: any) {
  const workSteps = getIn(values, 'workSteps');
  const itemToDelete = useSelector(getItemToDelete);

  const handleDelete = () => {
    const workstepId = itemToDelete;

    setFieldValue(
      'workSteps',
      produce(workSteps, (draft: any) => {
        const itemToDelete = draft.findIndex((workStep: any) => workStep.id === workstepId);
        draft.splice(itemToDelete, 1);
      })
    );
    handleClose();
  };

  return (
    <Modal open={Boolean(itemToDelete)}>
      <ModalTitle onClose={handleClose}>Delete this step?</ModalTitle>
      <ModalContent>
        <ModalContentText>You are about to delete a step from your work plan.</ModalContentText>
        <ModalActions>
          <GridContainer>
            <GridItem xs={6}>
              <Button preset="secondary" onClick={handleClose} themeColour="grey" fullWidth>
                Cancel
              </Button>
            </GridItem>
            <GridItem xs={6}>
              <Button
                preset="primary"
                themeColour="red"
                fullWidth
                onClick={() => {
                  handleDelete();
                }}
              >
                Delete
              </Button>
            </GridItem>
          </GridContainer>
        </ModalActions>
      </ModalContent>
    </Modal>
  );
};

export default connect(WorkStepDeleteModal);
