import React from 'react';
import { useFormikContext, getIn } from 'formik';
import { RadioGroup } from 'formik-material-ui';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import IsolationTypeInput from '../IsolationTypeInput/IsolationTypeInput';
import { Radio, RadioFormControlLabel } from '../RadioButton/RadioButton';
import Label from '../Label/Label';
import { StyledGridItem, StyledField } from '../ControlsSuggestionField/styles';
import TextField from '../TextField/TextField';
import { useFieldReset } from '../../hooks';

const IsolationsInputs = function () {
  const { values } = useFormikContext();

  const isIsolationsRequired = getIn(values, 'controls.isolations.required') === 'Yes';
  useFieldReset('controls.isolations.type', !isIsolationsRequired, ' ');
  useFieldReset('controls.isolations.selfIsolationValue', !isIsolationsRequired, '');

  return (
    <>
      <Label label="Do you need isolations?" />
      <StyledField name="controls.isolations.required" label="Radio Group" component={RadioGroup} row>
        <RadioFormControlLabel value="Yes" control={<Radio />} label="Yes" />
        <RadioFormControlLabel value="No" control={<Radio />} label="No" />
      </StyledField>
      <ErrorMessage name="controls.isolations.required" />
      {getIn(values, 'controls.isolations.required') === 'Yes' && (
        <>
          <StyledGridItem xs={5}>
            <IsolationTypeInput name="controls.isolations.type" />
            <ErrorMessage name="controls.isolations.type" />
          </StyledGridItem>
          {getIn(values, 'controls.isolations.type') === 'selfIsolation' && (
            <StyledGridItem xs={10}>
              <TextField
                helperText="Self Isolations (LOTO) can only be completed by Watercare staff"
                fullWidth
                minRows={5.75}
                maxRows={5.75}
                multiline
                name="controls.isolations.selfIsolationValue"
                id="controls.isolations.selfIsolationValue"
                label="How are you planning to isolate?"
                placeholder="Example: I will turn off the gas valve first."
              />
            </StyledGridItem>
          )}
        </>
      )}
    </>
  );
};

export default IsolationsInputs;
