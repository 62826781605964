import React, { ReactElement, memo } from 'react';
import get from 'lodash/get';
import { useQuery } from '@apollo/client';
import { Queries } from '../../api-queries';
import { useParams } from 'react-router-dom';

const JsaQueryWrapper = memo(({ children }: any) => {
  const { jsa: jsaId }: any = useParams();

  const { loading, error, data } = useQuery(Queries.GET_JSA_BY_ID, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: jsaId,
    },
  });

  if (error) {
    return null;
  }

  const jsa = get(data, 'getJobSafetyAnalysis');

  if (jsa) {
    const childrenWithProps = React.Children.map(children, (child) =>
      React.cloneElement(child as ReactElement, { jsa })
    );

    return <>{childrenWithProps}</>;
  } else {
    if (loading) {
      return <div></div>;
    }
  }

  return <div>No JSA found</div>;
});

JsaQueryWrapper.displayName = 'JsaQueryWrapper';

export default JsaQueryWrapper;
