import React from 'react';
import { useFormikContext } from 'formik';
import {
  SummarySidebar,
  UtlilityList,
  UtilityGridItem,
  EnergyArrangementsHeading,
  CommentButtonWrapper,
} from './style';
import GridContainer from '../../components/Grid/GridContainer';
import Typography from '../../components/Typography/Typography';
import {
  getElectricity,
  getGas,
  getStormwater,
  getTelco,
  getWastewater,
  getWater,
  getEnergyArrangements,
} from './SummaryWorkpackSidebarSelectors';
import Fallback from '../../components/Fallback/Fallback';
import Launcher from '../../components/ChatWindow/Launcher';

const SummaryWorkpackDetailsSidebar = function ({ data }: any) {
  const { setFieldValue } = useFormikContext();
  const electricity = getElectricity(data);
  const gas = getGas(data);
  const stormwater = getStormwater(data);
  const telco = getTelco(data);
  const wastewater = getWastewater(data);
  const water = getWater(data);
  const energyArrangements = getEnergyArrangements(data);
  const electricityChecked = electricity ? electricity.isChecked : false;
  const gasChecked = gas ? gas.isChecked : false;
  const stormwaterChecked = stormwater ? stormwater.isChecked : false;
  const telcoChecked = telco ? telco.isChecked : false;
  const wastewaterChecked = wastewater ? wastewater.isChecked : false;
  const waterChecked = water ? water.isChecked : false;
  const energyArrangementsNotEmpty = energyArrangements !== '';
  const isAllEmpty =
    electricityChecked ||
    gasChecked ||
    stormwaterChecked ||
    telcoChecked ||
    wastewaterChecked ||
    waterChecked ||
    energyArrangementsNotEmpty;

  return (
    <SummarySidebar workpack>
      <GridContainer>
        <UtilityGridItem xs={12}>
          <Typography variant="h4">Interaction with utilities</Typography>
          {isAllEmpty === true && (
            <div>
              <UtlilityList>
                {electricity && electricity.isChecked && (
                  <li>
                    <div>
                      <Typography variant="body1">Electricity</Typography>{' '}
                      <Fallback value={electricity.value.id} message="Incomplete">
                        <Typography variant="body2">{electricity.value.id}V</Typography>
                      </Fallback>
                    </div>
                  </li>
                )}
                {gas && gas.isChecked && (
                  <li>
                    <div>
                      <Typography variant="body1">Gas</Typography>
                      <Fallback value={gas.value.id} message="Incomplete">
                        <Typography variant="body2">
                          {gas.value.id}
                          kPa
                        </Typography>
                      </Fallback>
                    </div>
                  </li>
                )}
                {stormwater && stormwater.isChecked && (
                  <li>
                    <div>
                      <Typography variant="body1">Stormwater</Typography>
                      <Fallback value={stormwater.value.label} message="Incomplete">
                        <Typography variant="body2">{stormwater.value.label}</Typography>
                      </Fallback>
                    </div>
                  </li>
                )}
                {telco && telco.isChecked && (
                  <li>
                    <div>
                      <Typography variant="body1">Telephone / Internet</Typography>
                    </div>
                  </li>
                )}
                {wastewater && wastewater.isChecked && (
                  <li>
                    <div>
                      <Typography variant="body1">Wastewater</Typography>
                      <Fallback value={wastewater.value.label} message="Incomplete">
                        <Typography variant="body2">{wastewater.value.label}</Typography>
                      </Fallback>
                    </div>
                  </li>
                )}
                {water && water.isChecked && (
                  <li>
                    <div>
                      <Typography variant="body1">Water</Typography>
                      <Fallback value={water.value.label} message="Incomplete">
                        <Typography variant="body2">{water.value.label}</Typography>
                      </Fallback>
                    </div>
                  </li>
                )}
              </UtlilityList>
              {energyArrangements && (
                <div>
                  <EnergyArrangementsHeading variant="body2">
                    Energy isolation / safety arrangements
                  </EnergyArrangementsHeading>
                  <Typography variant="body2" paragraph>
                    {energyArrangements}
                  </Typography>
                </div>
              )}
            </div>
          )}
          {isAllEmpty === false && <Fallback message="Incomplete" />}
        </UtilityGridItem>
        <UtilityGridItem xs={12}>
          <CommentButtonWrapper
            style={{ position: 'relative', bottom: '-720px', right: '0px' }}
            className="screen-only"
          >
            <Launcher
              section={4}
              setFieldValue={setFieldValue}
              data={data}
              displayName="WORKPACK DETAILS"
              name="Workpack"
            />
          </CommentButtonWrapper>
        </UtilityGridItem>
      </GridContainer>
    </SummarySidebar>
  );
};

export default SummaryWorkpackDetailsSidebar;
