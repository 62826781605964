// tslint:disable
// this is an auto generated file. This will be overwritten

export const onCreateJobSafetyAnalysis = `subscription OnCreateJobSafetyAnalysis($id: ID!) {
  onCreateJobSafetyAnalysis(id: $id) {
      summaryComments {
        WorkDetails {
          type
          author
          date
          resolved
          createdDate
          fileName
          fileSize
          id
          file {
            bucket
            key
            region
          }
          text
        }
        EmergencyDetails {
          type
          author
          date
          resolved
          createdDate
          fileName
          fileSize
          id
          file {
            bucket
            key
            region
          }
          text
        }
        HazardsControls {
          type
          author
          date
          resolved
          createdDate
          fileName
          fileSize
          id
          file {
            bucket
            key
            region
          }
          text
        }
        Workpack {
          type
          author
          date
          resolved
          createdDate
          fileName
          fileSize
          id
          file {
            bucket
            key
            region
          }
          text
        }
      }
    categoriesWithCustomHazards {
      code
      hazards {
        category {
          code
          name
        }
        code
        controls {
          controlMeasures {
            id
            value
          }
          isolations {
            required
            selfIsolationValue
            type
          }
          permits {
            code
            name
            required
          }
          ppe {
            code
            colour
            ppeName
            required
          }
        }
        initialRisk {
          likelihood
          risk
          severity
        }
        isolationRequired
        name
        permitCode
        permitName
        residualRisk {
          likelihood
          risk
          severity
        }
      }
      name
    }
    createdDate
    emergencyDetails {
      emergencyContacts {
        contactNumber
        firstName
        lastName
        email
      }
      evacuationPoint
      facilityControlRoom {
        id
        label
        value
      }
      facilityControlRoomNumber
      firstAidKitLocation {
        id
        label
        value
      }
      nearestMedicalFacility {
        id
        label
        value
      }
      nearestMedicalFacilityContactDetails
    }
    id
    jsaApplicationTitle
    jsaId
    ppe {
      code
      colour
      ppeName
      required
    }
    status
    updatedDate
    version
    workDetails {
      descriptionOfWork
      jsaWrittenBy {
        contactNumber
        firstName
        lastName
        email
      }
      operateAddress
      operatingArea
      personInCharge {
        contactNumber
        firstName
        lastName
        email
      }
      personInChargeOfWork
      plannedWorkDate {
        endDate
        startDate
      }
      scopeOfWork
      serviceProvider
      siteAddress
      siteName {
        id
        label
        value
      }
      watercareRepresentative {
        contactNumber
        firstName
        lastName
        email
      }
      workLocationProcessArea
    }
    workOrderId
    workPack {
      energyArrangements
      isolations {
        createdDate
        file {
          bucket
          key
          region
        }
        fileName
        fileSize
        id
        isolationName
        isolationType
        selfIsolation
      }
      permits {
        createdDate
        file {
          bucket
          key
          region
        }
        fileName
        fileSize
        fileType {
          id
          label
          value
        }
        id
      }
      supportDocuments {
        createdDate
        file {
          bucket
          key
          region
        }
        fileName
        fileSize
        fileType {
          id
          label
          value
        }
        id
      }
      utilities {
        electricity {
          isChecked
          value {
            id
            label
            value
          }
        }
        gas {
          isChecked
          value {
            id
            label
            value
          }
        }
        stormwater {
          isChecked
          value {
            id
            label
            value
          }
        }
        telco {
          isChecked
          value {
            id
            label
            value
          }
        }
        wastewater {
          isChecked
          value {
            id
            label
            value
          }
        }
        water {
          isChecked
          value {
            id
            label
            value
          }
        }
      }
      worksafeNotification
    }
    workSteps {
      editingHazards
      hazards {
        category {
          code
          name
        }
        code
        controls {
          controlMeasures {
            id
            value
          }
          isolations {
            required
            selfIsolationValue
            type
          }
          permits {
            code
            name
            required
          }
          ppe {
            code
            colour
            ppeName
            required
          }
        }
        initialRisk {
          likelihood
          risk
          severity
        }
        isolationRequired
        name
        permitCode
        permitName
        residualRisk {
          likelihood
          risk
          severity
        }
      }
      id
      text
    }
    createdBy {
      firstName
      lastName
      email
      department
      jobTitle
    }
    assignedTo {
      firstName
      lastName
      email
      department
      jobTitle
    }
    assignedToDate
    reviewedBy {
      firstName
      lastName
      email
      department
      jobTitle
    }
    reviewedByDate
    cancelledBy {
      firstName
      lastName
      email
      department
      jobTitle
    }
    cancelledDate
    confirmedBy {
      firstName
      lastName
      email
      department
      jobTitle
    }
    confirmedDate
    toConfirmEmail
	  toConfirmDate
    submittedBy {
      firstName
      lastName
      email
      department
      jobTitle
    }
    submittedDate
    teamDetails {
      teamContacts {
        agreed
        email
        firstName
        lastName
        role
        agreedDate
      }
    }
    collaboratorDetails {
      collaborators {
        email
        role
      }
    }
  }
}
`;
export const onDeleteJobSafetyAnalysis = `subscription OnDeleteJobSafetyAnalysis($id: ID) {
  onDeleteJobSafetyAnalysis(id: $id) {
    summaryComments {
      WorkDetails {
        type
        author
        date
        resolved
        createdDate
        fileName
        fileSize
        id
        file {
          bucket
          key
          region
        }
        text
      }
      EmergencyDetails {
        type
        author
        date
        resolved
        createdDate
        fileName
        fileSize
        id
        file {
          bucket
          key
          region
        }
        text
      }
      HazardsControls {
        type
        author
        date
        resolved
        createdDate
        fileName
        fileSize
        id
        file {
          bucket
          key
          region
        }
        text
      }
      Workpack {
        type
        author
        date
        resolved
        createdDate
        fileName
        fileSize
        id
        file {
          bucket
          key
          region
        }
        text
      }
    }
    categoriesWithCustomHazards {
      code
      hazards {
        category {
          code
          name
        }
        code
        controls {
          controlMeasures {
            id
            value
          }
          isolations {
            required
            selfIsolationValue
            type
          }
          permits {
            code
            name
            required
          }
          ppe {
            code
            colour
            ppeName
            required
          }
        }
        initialRisk {
          likelihood
          risk
          severity
        }
        isolationRequired
        name
        permitCode
        permitName
        residualRisk {
          likelihood
          risk
          severity
        }
      }
      name
    }
    createdDate
    emergencyDetails {
      emergencyContacts {
        contactNumber
        firstName
        lastName
        email
      }
      evacuationPoint
      facilityControlRoom {
        id
        label
        value
      }
      facilityControlRoomNumber
      firstAidKitLocation {
        id
        label
        value
      }
      nearestMedicalFacility {
        id
        label
        value
      }
      nearestMedicalFacilityContactDetails
    }
    id
    jsaApplicationTitle
    jsaId
    ppe {
      code
      colour
      ppeName
      required
    }
    status
    updatedDate
    version
    workDetails {
      descriptionOfWork
      jsaWrittenBy {
        contactNumber
        firstName
        lastName
        email
      }
      operateAddress
      operatingArea
      personInCharge {
        contactNumber
        firstName
        lastName
        email
      }
      personInChargeOfWork
      plannedWorkDate {
        endDate
        startDate
      }
      scopeOfWork
      serviceProvider
      siteAddress
      siteName {
        id
        label
        value
      }
      watercareRepresentative {
        contactNumber
        firstName
        lastName
        email
      }
      workLocationProcessArea
    }
    workOrderId
    workPack {
      energyArrangements
      isolations {
        createdDate
        file {
          bucket
          key
          region
        }
        fileName
        fileSize
        id
        isolationName
        isolationType
        selfIsolation
      }
      permits {
        createdDate
        file {
          bucket
          key
          region
        }
        fileName
        fileSize
        fileType {
          id
          label
          value
        }
        id
      }
      supportDocuments {
        createdDate
        file {
          bucket
          key
          region
        }
        fileName
        fileSize
        fileType {
          id
          label
          value
        }
        id
      }
      utilities {
        electricity {
          isChecked
          value {
            id
            label
            value
          }
        }
        gas {
          isChecked
          value {
            id
            label
            value
          }
        }
        stormwater {
          isChecked
          value {
            id
            label
            value
          }
        }
        telco {
          isChecked
          value {
            id
            label
            value
          }
        }
        wastewater {
          isChecked
          value {
            id
            label
            value
          }
        }
        water {
          isChecked
          value {
            id
            label
            value
          }
        }
      }
      worksafeNotification
    }
    workSteps {
      editingHazards
      hazards {
        category {
          code
          name
        }
        code
        controls {
          controlMeasures {
            id
            value
          }
          isolations {
            required
            selfIsolationValue
            type
          }
          permits {
            code
            name
            required
          }
          ppe {
            code
            colour
            ppeName
            required
          }
        }
        initialRisk {
          likelihood
          risk
          severity
        }
        isolationRequired
        name
        permitCode
        permitName
        residualRisk {
          likelihood
          risk
          severity
        }
      }
      id
      text
    }
    createdBy {
      firstName
      lastName
      email
      department
      jobTitle
    }
    assignedTo {
      firstName
      lastName
      email
      department
      jobTitle
    }
    assignedToDate
    reviewedBy {
      firstName
      lastName
      email
      department
      jobTitle
    }
    reviewedByDate
    cancelledBy {
      firstName
      lastName
      email
      department
      jobTitle
    }
    cancelledDate
    confirmedBy {
      firstName
      lastName
      email
      department
      jobTitle
    }
    confirmedDate
    toConfirmEmail
	  toConfirmDate
    submittedBy {
      firstName
      lastName
      email
      department
      jobTitle
    }
    submittedDate
    teamDetails {
      teamContacts {
        agreed
        email
        firstName
        lastName
        role
        agreedDate
      }
    }
    collaboratorDetails {
      collaborators {
        email
        role
      }
    }
  }
}
`;
export const onUpdateJobSafetyAnalysis = `subscription OnUpdateJobSafetyAnalysis($id: ID) {
  onUpdateJobSafetyAnalysis(id: $id) {
    summaryComments {
      WorkDetails {
        type
        author
        date
        resolved
        createdDate
        fileName
        fileSize
        id
        file {
          bucket
          key
          region
        }
        text
      }
      EmergencyDetails {
        type
        author
        date
        resolved
        createdDate
        fileName
        fileSize
        id
        file {
          bucket
          key
          region
        }
        text
      }
      HazardsControls {
        type
        author
        date
        resolved
        createdDate
        fileName
        fileSize
        id
        file {
          bucket
          key
          region
        }
        text
      }
      Workpack {
        type
        author
        date
        resolved
        createdDate
        fileName
        fileSize
        id
        file {
          bucket
          key
          region
        }
        text
      }
    }
    categoriesWithCustomHazards {
      code
      hazards {
        category {
          code
          name
        }
        code
        controls {
          controlMeasures {
            id
            value
          }
          isolations {
            required
            selfIsolationValue
            type
          }
          permits {
            code
            name
            required
          }
          ppe {
            code
            colour
            ppeName
            required
          }
        }
        initialRisk {
          likelihood
          risk
          severity
        }
        isolationRequired
        name
        permitCode
        permitName
        residualRisk {
          likelihood
          risk
          severity
        }
      }
      name
    }
    createdDate
    emergencyDetails {
      emergencyContacts {
        contactNumber
        firstName
        lastName
        email
      }
      evacuationPoint
      facilityControlRoom {
        id
        label
        value
      }
      facilityControlRoomNumber
      firstAidKitLocation {
        id
        label
        value
      }
      nearestMedicalFacility {
        id
        label
        value
      }
      nearestMedicalFacilityContactDetails
    }
    id
    jsaApplicationTitle
    jsaId
    ppe {
      code
      colour
      ppeName
      required
    }
    status
    updatedDate
    version
    workDetails {
      descriptionOfWork
      jsaWrittenBy {
        contactNumber
        firstName
        lastName
        email
      }
      operateAddress
      operatingArea
      personInCharge {
        contactNumber
        firstName
        lastName
        email
      }
      personInChargeOfWork
      plannedWorkDate {
        endDate
        startDate
      }
      scopeOfWork
      serviceProvider
      siteAddress
      siteName {
        id
        label
        value
      }
      watercareRepresentative {
        contactNumber
        firstName
        lastName
        email
      }
      workLocationProcessArea
    }
    workOrderId
    workPack {
      energyArrangements
      isolations {
        createdDate
        file {
          bucket
          key
          region
        }
        fileName
        fileSize
        id
        isolationName
        isolationType
        selfIsolation
      }
      permits {
        createdDate
        file {
          bucket
          key
          region
        }
        fileName
        fileSize
        fileType {
          id
          label
          value
        }
        id
      }
      supportDocuments {
        createdDate
        file {
          bucket
          key
          region
        }
        fileName
        fileSize
        fileType {
          id
          label
          value
        }
        id
      }
      utilities {
        electricity {
          isChecked
          value {
            id
            label
            value
          }
        }
        gas {
          isChecked
          value {
            id
            label
            value
          }
        }
        stormwater {
          isChecked
          value {
            id
            label
            value
          }
        }
        telco {
          isChecked
          value {
            id
            label
            value
          }
        }
        wastewater {
          isChecked
          value {
            id
            label
            value
          }
        }
        water {
          isChecked
          value {
            id
            label
            value
          }
        }
      }
      worksafeNotification
    }
    workSteps {
      editingHazards
      hazards {
        category {
          code
          name
        }
        code
        controls {
          controlMeasures {
            id
            value
          }
          isolations {
            required
            selfIsolationValue
            type
          }
          permits {
            code
            name
            required
          }
          ppe {
            code
            colour
            ppeName
            required
          }
        }
        initialRisk {
          likelihood
          risk
          severity
        }
        isolationRequired
        name
        permitCode
        permitName
        residualRisk {
          likelihood
          risk
          severity
        }
      }
      id
      text
    }
    createdBy {
      firstName
      lastName
      email
      department
      jobTitle
    }
    assignedTo {
      firstName
      lastName
      email
      department
      jobTitle
    }
    assignedToDate
    reviewedBy {
      firstName
      lastName
      email
      department
      jobTitle
    }
    reviewedByDate
    cancelledBy {
      firstName
      lastName
      email
      department
      jobTitle
    }
    cancelledDate
    confirmedBy {
      firstName
      lastName
      email
      department
      jobTitle
    }
    confirmedDate
    toConfirmEmail
	  toConfirmDate
    submittedBy {
      firstName
      lastName
      email
      department
      jobTitle
    }
    submittedDate
    teamDetails {
      teamContacts {
        agreed
        email
        firstName
        lastName
        role
        agreedDate
      }
    }
    collaboratorDetails {
      collaborators {
        email
        role
      }
    }
  }
}
`;
