import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '../../components/Typography/Typography';
import Icon from '../../components/Icon/Icon';

export const StyledAhref = styled.a`
  text-decoration: none;
  color: black;
  font-weight: bold;
`;

export const FileName = styled(Typography).attrs(() => ({
  variant: 'h5',
}))`
  margin-left: 5px;
  color: black;
  font-weight: bold;
`;

export const StyledLabel = styled(({ ...rest }: any) => <label {...rest} />)`
  input {
    display: none;
  }
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary.mediumBlue};
`;

export const StyledLabelDiv = styled.div`
  margin-top: 6px;
  margin-left: 5px;
`;

export const SpinDiv = styled.div`
  height: 40px;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  width: fit-content;
`;

export const SaveFileDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledCircularProgress = styled(CircularProgress)`
  margin-left: 5px;
  margin-right: 5px;
`;
export const StyledAttachementIcon = styled(({ ...rest }: any) => <Icon {...rest} />)`
  margin-right: 5px;
`;
