import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';
import AppProvider from './AppProvider';
import AppRouter from './AppRouter';
import store from './configureStore';
import { snippet } from './walkme';
import LockModal from './components/LockModal/LockScreen';
import { GlobalStyle } from './styles/theme';
import { apolloClient } from 'ApolloService';
import { CookiesProvider } from 'react-cookie';
import { ValidationProvider } from 'contexts/useSubmitValidation';
import { AuthProvider } from 'contexts/AuthProvider';

const App = function () {
  const existingScript = document.getElementById('walkMe');

  if (!existingScript) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'walkMe';
    script.innerHTML = snippet;
    document.head.appendChild(script);
  }

  return (
    <CookiesProvider>
      <ReduxProvider store={store}>
        <ApolloProvider client={apolloClient}>
          <BrowserRouter>
            <AuthProvider>
              <AppProvider>
                <ValidationProvider>
                  <Helmet>
                    <title>Jobcare</title>
                  </Helmet>
                  <GlobalStyle />
                  <AppRouter />
                  <LockModal />
                </ValidationProvider>
              </AppProvider>
            </AuthProvider>
          </BrowserRouter>
        </ApolloProvider>
      </ReduxProvider>
    </CookiesProvider>
  );
};

export default App;
