import React, { useState, memo } from 'react';
import { Form } from 'formik';
import { useSelector } from 'react-redux';
import JsaForm from '../JsaForm/JsaForm';
import AsyncButton from '../../components/Button/AsyncButton';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import ModalTitle from '../../components/Modal/ModalTitle';
import ModalContent from '../../components/Modal/ModalContent';
import ModalContentText from '../../components/Modal/ModalContentText';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import TextField from '../../components/TextField/TextField';
import ModalActions from '../../components/Modal/ModalActions';
import statusAttributes from '../../constants/statusAttributes';
import ContentLoader from '../../components/ContentLoader/ContentLoader';
import { getIsModalOpen } from '../../redux/jsaModalSlice';
import useClientState from '../../hooks/useClientState';
import usePpeData from '../../hooks/usePpeMasterData';
import { getRequiredPpe } from '../../components/PpeDrawer/ppeSelectors';

const CreateJsaModal = memo(() => {
  const titleStyle =
    'height: 43px;color: #213D58;font-family: "Nunito Sans";font-size: 32px;font-weight: 300;letter-spacing: 0;line-height: 43px;text-align: center;padding-top:51px;padding-bottom:0px;';
  const contentStyle =
    ' height: 52px;width: 679px;margin-bottom:18px;color: #0A0D0D;font-family: "Nunito Sans";font-size: 16px;letter-spacing: 0;line-height: 26px;';
  const actionStyle = 'margin-top:31px; padding: 0px;';

  const [isSubmitted, setIsSubmitted] = useState(false);
  const ppeMasterData = usePpeData();
  const requiredPpe = getRequiredPpe(ppeMasterData as never);
  const isOpen = useSelector(getIsModalOpen);
  const { setIsModalOpen } = useClientState();

  if (!isOpen) {
    return <ContentLoader />;
  }

  return (
    <Modal size="855px" height="510px" open={isOpen}>
      <ModalTitle id="new_jsa_modal_title" styles={titleStyle} onClose={() => setIsModalOpen(false)}>
        Create a new JSA
      </ModalTitle>
      <ModalContent>
        <JsaForm
          shouldAutosave={false}
          mutationName="CreateJsa"
          onSuccess={() => setIsModalOpen(false)}
          onSubmitting={() => setIsSubmitted(true)}
          initialValues={{
            jsaApplicationTitle: '',
            workOrderId: '',
            version: 1,
            status: statusAttributes.draft.id,
            ppe: requiredPpe,
          }}
        >
          <Form>
            <ModalContentText styles={contentStyle}>
              Give your JSA an identifiable title so you can search for it later. Enter your Work Order
              number so we can fill in some of the JSA details for you.
            </ModalContentText>
            <GridContainer>
              <GridItem style={{ paddingTop: '11px', paddingBottom: '11px' }} xs={12}>
                <TextField
                  id="jsa_application_title"
                  name="jsaApplicationTitle"
                  label="JSA application title"
                  fullWidth
                />
              </GridItem>
              <GridItem style={{ paddingTop: '11px', paddingBottom: '11px' }} xs={12}>
                <TextField
                  id="work_order_id"
                  name="workOrderId"
                  label="Work Order no."
                  tooltip="WorkOrderNumber"
                  fullWidth
                />
              </GridItem>
            </GridContainer>
            <ModalActions styles={actionStyle}>
              <GridContainer>
                <GridItem xs={6}>
                  <Button
                    id="new_jsa_cancel"
                    preset="secondary"
                    onClick={() => setIsModalOpen(false)}
                    themeColour="grey"
                    fullWidth
                  >
                    Cancel
                  </Button>
                </GridItem>
                <GridItem xs={6}>
                  <AsyncButton
                    id="new_jsa_create"
                    actionInProgress={isSubmitted}
                    text="CREATE NEW JSA"
                  />
                </GridItem>
              </GridContainer>
            </ModalActions>
          </Form>
        </JsaForm>
      </ModalContent>
    </Modal>
  );
});

CreateJsaModal.displayName = 'CreateJsaModal';

export default CreateJsaModal;
