import React from 'react';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import HazardIcon from '../Icon/HazardIcon/HazardIcon';
import PpeIcon from '../Icon/PpeIcon/PpeIcon';

export const StyledCardContent = styled(CardContent)`
  && {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 60px;
    padding: 0 18px;
  }
`;

export const StyledPpeIcon = styled(({ isSelected, ...rest }) => <PpeIcon {...rest} />)`
  width: 80px;
  height: auto;
  max-height: 80px;

  && path {
    fill: ${({ isSelected, theme }) =>
      isSelected ? theme.colors.greyScale['95'] : theme.colors.complementary.blue};
  }
`;

export const CardLabelWrapper = styled.div`
  margin-left: 10px;
`;

export const IconWraper = styled.div`
  width: 50px;
  height: 50px;
`;

export const StyledHazardIcon = styled(({ isSelected, ...rest }) => <HazardIcon {...rest} />)`
  width: 50px;
  height: 50px;

  && path {
    fill: ${({ theme }) => theme.colors.primary.darkBlue};
  }
`;

export const CardLabel = styled(({ cardType, isSelected, label, ...rest }) => <p {...rest} />)`
  font-family: 'Nunito Sans';
  width: auto;
  font-weight: 600;
  margin: 0;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.greyScale['0A0D0D']};
`;

export const StyledCard = styled(({ cardType, isSelected, tooltip, ...rest }) => <Card {...rest} />)`
  display: inline-block;
  height: 60px;
  box-shadow: 0 1px 5px 0 rgba(33, 61, 88, 0.4);
  border: 2px solid #213d58;
  background-color: ${({ theme }) => theme.colors.complementary.yellow} !important;
`;
