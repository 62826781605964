import styled from 'styled-components';
import Typography from '../Typography/Typography';

export const StyledHazardOverlaySubTitle = styled(Typography).attrs(() => ({
  variant: 'subtitle1',
  id: 'add_hazards_overlay_subtitle',
}))`
  &.MuiTypography-subtitle1 {
    color: ${({ theme }) => theme.colors.findColor('greyScale_95')};
  }
  text-align: center;
`;

export const GotoTopAligner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`;

export const FadeContainer = styled.div`
  animation: fadeIn 0.75s;
  -webkit-animation: fadeIn 0.75s;
  -moz-animation: fadeIn 0.75s;
  -o-animation: fadeIn 0.75s;
  -ms-animation: fadeIn 0.75s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
