import React from 'react';
import { Form } from 'formik';
import PermitsToWork from './PermitsToWork';
import IsolationsRequired from './IsolationsRequired';
import SupportingDocuments from './SupportingDocuments';
import InteractionWithUtilities from './InteractionWithUtilities';
import WorksafeNotification from './WorksafeNotification';
import { useJsaQuery } from '../../hooks';
import { ContentLoader } from '../../components';
import JsaForm from '../JsaForm/JsaForm';
import setInitialValues from '../JsaForm/setInitialValues';

const WorkpackForm = function () {
  const { data, loading } = useJsaQuery();
  const initialValues = setInitialValues(data);

  if (!data) {
    return <ContentLoader />;
  }

  return (
    // @ts-check
    <JsaForm initialValues={initialValues} isLoading={loading}>
      <Form>
        <PermitsToWork data={data} />
        <IsolationsRequired />
        <WorksafeNotification />
        <SupportingDocuments />
        <InteractionWithUtilities />
      </Form>
    </JsaForm>
  );
};

export default WorkpackForm;
