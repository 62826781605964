import React, { HTMLAttributes } from 'react';
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import { components } from 'react-select';
import TextInput from '../TextInput/TextInput';
import Typography from '../Typography/Typography';

interface OptionType {
  label: string;
  value: string;
}

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>;

export function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

export const Control = function (props: any) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextInput
      as={TextField}
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
};

export const Option = function (props: any) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
};

export const Placeholder = function (props: any) {
  return (
    <Typography variant="caption" color="textSecondary">
      <components.Placeholder {...props} />
    </Typography>
  );
};

export const SingleValue = function ({
  children,
  innerProps,
  selectProps,
}: SingleValueProps<OptionType>) {
  return (
    <Typography
      className={selectProps.classes.singleValue}
      {...innerProps}
      data-testid={`${selectProps['data-testid']}-value`}
    >
      {children}
    </Typography>
  );
};

export const ValueContainer = function (props: any) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
};

export const Menu = function (props: any) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
      style={{ zIndex: 900 }}
    >
      {props.children}
    </Paper>
  );
};
