import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  isModalOpen: false,
};

const jsaModalSlice = createSlice({
  name: 'jsaModalSlice',
  initialState,
  reducers: {
    setIsOpen: (state: any, action: any) => {
      state.isModalOpen = action.payload;
    },
  },
});

export const getIsModalOpen = createSelector(
  (state: any) => state.jsaModalSlice.isModalOpen,
  (isModalOpen) => isModalOpen
);

export default jsaModalSlice;
