import React from 'react';
import styled from 'styled-components';
import { TextField as MuiTextField } from 'formik-material-ui';
import MuiInputAdornment from '@material-ui/core/InputAdornment';
import Icon from '../Icon/Icon';
import InputAdornmentButton from '../Button/InputAdornmentButton';

export const InputAdornment = styled(MuiInputAdornment)``;

export const NextAdornment = styled(MuiInputAdornment)`
  margin-left: 0;
  height: 50px;
  max-height: unset;
  & svg {
    height: 16px;
  }
`;

export const StyledIcon = styled(Icon)`
  width: 24px;
`;

export const StyledInputAdornment = styled(InputAdornment)`
  & .MuiFormControl-fullWidth {
    background-color: red !important;
  }
`;

export const StyledControlsSuggestionInput = styled(({ fullWidth, size, ...rest }) => (
  <MuiTextField {...rest} />
))`
  width: ${({ size, fullWidth }) => {
    if (fullWidth) {
      return '100%';
    }
    return size !== 'large' ? '239px' : '503px';
  }};

  & .MuiOutlinedInput-multiline {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 0px;
    padding-left: 0px;
    background-color: blue;
  }

  &
    .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused.Mui-focused.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline {
    background-color: white;
  }

  &
    .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline.MuiOutlinedInput-inputMultiline {
    background-color: transparent;
    border: none;
    padding-top: 12px;
    padding-left: 15px;

    &:focus {
      box-shadow: none;
      background-color: white;
    }
  }

  & .Mui-error .MuiInputBase-input {
    background-color: rgba(255, 142, 129, 0.15);
  }

  & p.Mui-error {
    margin-left: 0;
    font-size: 14px;
    color: ${(props) => props.theme.colors.complementary.red};
  }

  & .MuiInputBase-input {
    height: 50px;
    padding: 0px;
    padding-left: 16px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.greyScale['6']};
    border: 1px solid ${(props) => props.theme.colors.greyScale['10']};
    border-right: 0;
    color: #0a0d0d;
    font-size: 18px;
    letter-spacing: 0.2px;
    line-height: 24px;

    &:focus {
      background-color: ${(props) => props.theme.colors.greyScale['0']};
    }
  }

  & .MuiInputBase-root {
    background-color: ${(props) => props.theme.colors.greyScale['6']};
    border-radius: 5px;
  }
  & .MuiInputBase-root.Mui-focused {
    background-color: ${(props) => props.theme.colors.greyScale['0']};
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.35);
  }
  & .MuiOutlinedInput-adornedEnd {
    padding-right: 0px;
  }
`;

export const StyledInputAdornmentButton = styled(InputAdornmentButton)`
  margin-right: 6px;
  box-shadow: none;
`;
