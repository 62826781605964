import styled from 'styled-components';
import GridItem from '../Grid/GridItem';
import Typography from '../Typography/Typography';

export const StyledMatrixGrid = styled(GridItem)`
  display: flex;
  border-bottom: 1px solid #e1e5e5;
  padding-bottom: 10px;
  margin-bottom: 9px;
`;

export const StyledThermoGrid = styled(GridItem)`
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;

  & svg {
    width: 80%;
  }
`;
export const StyledDescription = styled(Typography)`
  margin-bottom: 30px;
`;

export const StyledHowSection = styled(GridItem)`
  display: flex;
  margin-bottom: 20px;
  line-height: 24px;
`;

export const StyledIconDiv = styled(GridItem)`
  margin-right: 15px;
  margin-top: -4px;
`;

export const StyledModalActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 31px;
  min-width: 357px;
`;
