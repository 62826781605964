import React from 'react';
import styled from 'styled-components';
import GridItem from '../../components/Grid/GridItem';

export const FormFieldWrapper = styled(GridItem)`
  padding-top: 7.5px !important;
`;

export const Divider = styled(({ margin, ...rest }) => <div {...rest} />)`
  margin: ${({ margin }) => margin || '30px 0 20px 0'};
  width: 1012px;
  border: 0.5px solid #e1e5e5;
  background-color: #e1e5e5;
`;

export const AddButtonWrapper = styled(GridItem)`
  padding-top: 7.5px !important;
`;
