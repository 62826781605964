import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FieldArray, useFormikContext, getIn } from 'formik';
import styled from 'styled-components';
import AddButton from '../Button/AddButton';
import ControlsSuggestionField from '../ControlsSuggestionField/ControlsSuggestionField';
import DragItem from '../DragItem/DragItem';
import CheckBoxBorder from '../CheckBoxBorder/CheckBoxBorder';

const StyledUl = styled.ul`
  list-style: none;
  li::before {
    content: '•';
    color: #b6bebf;
    display: inline-block;
    width: 6px;
    position: absolute;
    margin-top: 15px;
    margin-left: -35px;
  }
`;

const ControlMeasuresList = function ({ suggestedControls, ...rest }: any) {
  const { values } = useFormikContext();
  let controlMeasures = getIn(values, 'controls.controlMeasures');
  const checkBoxs: any = [];

  if (controlMeasures === '') {
    controlMeasures = [];
  }

  suggestedControls.map((control: string) => {
    if (controlMeasures.length > 0) {
      let checked = false;
      controlMeasures.map((item: any) => {
        if (item.value === control) {
          checked = true;
        }
        return null;
      });
      if (checked) {
        checkBoxs.push({ checked: true, label: control });
      } else {
        checkBoxs.push({ checked: false, label: control });
      }
    } else {
      checkBoxs.push({ checked: false, label: control });
    }
    return null;
  });
  return (
    <div>
      {checkBoxs.length > 0 && (
        <ul>
          {checkBoxs.map((control: any, index: number) => (
            <div key={index}>
              <CheckBoxBorder checked={control.checked} name={control.label} label={control.label} />
            </div>
          ))}
        </ul>
      )}

      <FieldArray
        name="controls.controlMeasures"
        render={(arrayHelpers) => (
          <StyledUl>
            {controlMeasures.map((item: any, index: any) => {
              let placeholder = '';
              const itemIndex = suggestedControls.findIndex(
                (controlMeasure: any) => controlMeasure === item.value
              );

              if (item.value === '') {
                placeholder = 'Explain in a simple way what you are going to do';
              }

              return (
                itemIndex === -1 && (
                  <li key={index}>
                    <DragItem
                      fieldArrayName="controls.controlMeasures"
                      items={controlMeasures}
                      onDragEnd={() => ({})}
                      itemType="CONTROL_MEASURE"
                      index={index}
                      key={`controlMeasuresDragger-${item.id}`}
                    >
                      <ControlsSuggestionField
                        id={item.id}
                        placeholder={placeholder}
                        name={`controls.controlMeasures.${index}.value`}
                        index={index}
                        controlMeasures={controlMeasures}
                        {...rest}
                      />
                    </DragItem>
                  </li>
                )
              );
            })}
            <AddButton
              text="Add a control"
              onClick={() => {
                const id = uuidv4();
                const newValue = { id, value: '' };
                arrayHelpers.push(newValue);
              }}
            />
          </StyledUl>
        )}
      />
    </div>
  );
};

export default ControlMeasuresList;
