import React from 'react';
import { useFormikContext, getIn } from 'formik';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Button from '../Button/Button';
import GoToTopButton from '../Button/GoToTopButton';
import { GotoTopAligner } from './styles';
import OverlayContext from '../Overlay/OverlayContext';
import OverlayActions from '../Overlay/OverlayActions';

const HazardOverlayActions = function ({ onClose, editingHazards }: any) {
  const { submitForm, values } = useFormikContext();
  const { contentRef } = React.useContext(OverlayContext);
  const draftSelectedHazards = getIn(values, 'draftSelectedHazards');
  draftSelectedHazards.forEach((obj: any) => {
    delete obj.keyTerms;
  });
  const isDisabled = !editingHazards && draftSelectedHazards.length === 0;
  const { trigger } = React.useContext(OverlayContext);

  const addHazardsText = React.useCallback(() => {
    if (draftSelectedHazards.length === 1) {
      return 'Add 1 Hazard';
    }
    if (draftSelectedHazards.length > 1) {
      return `Add ${draftSelectedHazards.length} Hazards`;
    }
    return 'Add Hazards';
  }, [draftSelectedHazards]);

  return (
    <OverlayActions trigger={trigger}>
      <GridContainer>
        <GridItem xs={2} />
        <GridItem xs={4}>
          <Button
            id="hazard_overlay_cancel_button"
            fullWidth
            preset="secondary"
            themeColour="grey"
            onClick={onClose}
          >
            Cancel
          </Button>
        </GridItem>
        <GridItem xs={4}>
          <Button
            id="hazard_overlay_submit_button"
            fullWidth
            type="button"
            onClick={() => submitForm()}
            preset="primary"
            themeColour="blue"
            disabled={isDisabled}
          >
            {editingHazards === true ? 'Update Hazards' : addHazardsText()}
          </Button>
        </GridItem>
        <GridItem xs={2}>
          <GotoTopAligner>
            <GoToTopButton nodeRef={contentRef} />
          </GotoTopAligner>
        </GridItem>
      </GridContainer>
    </OverlayActions>
  );
};

export default HazardOverlayActions;
