import { useEffect, useContext } from 'react';
import { getIn } from 'formik';
import get from 'lodash/get';
import useDebounce from './useDebounce';
import useMasterData from './useMasterData';
import { JsaFormContext } from '../features/JsaForm/JsaForm';

function useFacilityControlField(fieldPath: string, facilityFieldPath: string) {
  const formikContext: any = useContext(JsaFormContext);
  const { data: facilityControlData } = useMasterData();

  const field1Value = getIn(formikContext.values, 'emergencyDetails.facilityControlRoom.id');
  const debouncedValues = useDebounce(field1Value, 300);

  useEffect(() => {
    if (field1Value && facilityControlData) {
      const facilityControls = get(facilityControlData, 'facilityControl.items', []);
      const selectedFacility = facilityControls.find(({ fields }: any) => fields.code === field1Value);
      if (selectedFacility) {
        const fieldValue = getIn(selectedFacility, facilityFieldPath);

        fieldValue && formikContext.setFieldValue(fieldPath, fieldValue);
      }
    } else if (field1Value === null) {
      formikContext.setFieldValue(fieldPath, '');
    }
  }, [debouncedValues]);

  return null;
}

export default useFacilityControlField;
