import React from 'react';
import styled from 'styled-components';
import {
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
  FormControl,
  FormLabel,
} from '@material-ui/core';

export const ErrorContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  flex-direction: column;
`;

export const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  :hover {
    background: ${({ color, theme }) => color || theme.colors.greyScale['10']};
  }
`;

export const StyledSpinner = styled(CircularProgress)`
  & svg {
    color: ${(props) => props.theme.colors.findColor('mediumBlue')};
  }
`;

export const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40vh;
`;

export const StyledFormLabel = styled(({ ...rest }) => <FormLabel {...rest} />)`
  margin-top: 0;
  margin-bottom: 0;
`;

export const StyledFormControl = styled(({ ...rest }) => <FormControl {...rest} />)`
  display: flex;
  align-items: center;
  flex-firection: row;
`;

export const StyledField = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  fieldset {
    flex-direction: row;
    position: relative;
    left: 30px;
  }

  .MuiTypography-body1 {
    font-size: 16px;
  }

  .MuiFormControlLabel-root {
    margin-right: 33px;
  }

  .MuiFormLabel-root {
    height: 22px;
    width: 74px;
    color: #43575a;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    padding-right: 20px;
  }
`;

export const StyledTableRowHead = styled(TableRow)`
  .MuiTableCell-head {
    color: #586a6d;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.4px;
    line-height: 16px;
    text-align: center;
    padding: 16px;
    text-align: left;
    border-top: 2px solid #e1e5e5;
    border-bottom: 2px solid #e1e5e5;
  }
`;

export const StyledTableBody = styled(TableBody)`
  &.MuiTableBody-root tr:nth-child(odd) {
    background-color: #eef0f0;
  }
  &.MuiTableBody-root tr:nth-child(odd):hover {
    background: ${({ color, theme }) => color || theme.colors.greyScale['10']};
  }
  .MuiTableCell-root {
    border-bottom: none;
    font-weight: 550;
  }
  .MuiTablePagination-caption {
    display: none;
  }
`;

export const StyledPaginationTable = styled.table`
  width: 100%;
  .MuiTablePagination-caption {
    display: none;
  }
  .MuiTablePagination-input {
    position: absolute;
    float: right;
    right: -23px;
  }
  .MuiTableCell-root {
    border-bottom: none;
    font-weight: 550;
  }
  .startLine {
    position: absolute;
    float: left;
    left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    flex-shrink: 0;
    b {
      font-weight: bold;
    }
  }
  .endLine {
    position: absolute;
    float: right;
    right: 165px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .MuiTablePagination-select {
    height: 19px;
    width: 123px;
    color: #1c7b97;
    font-family: 'Nunito Sans';
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  }
  .MuiSelect-icon {
    color: #1c7b97;
  }
  .MuiTablePagination-toolbar {
    padding-left: 0px;
    padding-top: 9px;
  }
  td {
    display: block;
  }
`;

export const StyledTitleCell = styled(TableCell)`
  color: #1c7b97;
`;
