import React from 'react';
import { useFormikContext } from 'formik';
import SummaryWorkDetailsSidebar from './SummaryWorkDetailsSidebar';
import SummaryWorkDetailsContent from './SummaryWorkDetailsContent';
import {
  SummarySection,
  SummarySectionContent,
  SummarySectionContentInner,
  Divider,
  StyledLogo,
  CommentButtonWrapper,
} from './style';
import SummarySectionTitle from '../../components/SummarySectionTitle/SummarySectionTitle';
import Launcher from '../../components/ChatWindow/Launcher';

const SummaryWorkDetails = function ({ data }: any) {
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <SummarySection hasSidebar workDetails>
        <Divider className="portrait-print-only" position="top" />
        <SummaryWorkDetailsSidebar data={data} />
        <Divider className="portrait-print-only" position="bottom" />
        <SummarySectionContent workDetails>
          <SummarySectionTitle workDetails sectionNumber="1">
            Work Details
            <div className="portrait-print-only">
              <StyledLogo name="JobcareLogo" />
            </div>
          </SummarySectionTitle>
          <SummarySectionContentInner workDetails>
            <SummaryWorkDetailsContent data={data} />
          </SummarySectionContentInner>
        </SummarySectionContent>
      </SummarySection>
      <CommentButtonWrapper className="screen-only">
        <Launcher
          section={1}
          setFieldValue={setFieldValue}
          data={data}
          displayName="WORK DETAILS"
          name="WorkDetails"
        />
      </CommentButtonWrapper>
    </>
  );
};

export default SummaryWorkDetails;
