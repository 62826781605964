import { createGlobalStyle } from 'styled-components';
import colors from './colors';
import muiTheme from './muiTheme';

export const JobCareTheme = {
  ...muiTheme,
  colors,
  fonts: {
    default: 'Nunito Sans, Open Sans, sans-serif',
  },
  iconCard: {
    scale: 1,
  },
};

export type JobCareThemeType = typeof JobCareTheme;

export type StyleProps = {
  theme: JobCareThemeType;
};

export const GlobalStyle = createGlobalStyle`
  @media screen {
    body {
      overflow: auto !important;
      background-color: ${(props: StyleProps) => props.theme.colors.greyScale['3']};
    }
  }

  @media screen, print and (min-device-width: 1367px) and (orientation: landscape){
    .portrait-print-only{
      display: none !important;
    }
  }

  @media print and (min-device-width : 768px) and (max-device-width : 1366px) {
    .screen-landscape-only {
      display: none !important;
    }
  }

  @media print and (orientation: portrait){
    .screen-landscape-only {
      display: none !important;
    }

    li {
      line-height: 22px !important;
    }
  }

  @media print {
    .screen-only {
      display: none !important;
    }
    .no-page-break-no-width{
      page-break-inside: avoid;
      display: inline-block;
    }
    .no-page-break {
      display: inline-block;
      width: 100%;
      page-break-inside: avoid;
    }
  }
`;
