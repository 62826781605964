import React, { Fragment, useState, createRef, memo } from 'react';
import { get, map } from 'lodash';
import { connect, FieldArray, getIn } from 'formik';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router';
import Avatar from 'react-avatar';
import {
  StyledIcon,
  FormFieldEmailInputWrapper,
  FormFieldWrapper,
  FormFieldRemoveButtonWrapper,
  StyledShareJsaDivContainer,
  StyledCollaboratorDivContainer,
  StyledShareJsaTypography,
  StyledCollaboratorsListingGridItem,
  StyledCollaboratorsGridItem,
  StyledRemoveButton,
} from './styles';
import { Button, GridContainer, Typography } from '../../components';
import { Mutations, Queries } from '../../api-queries';
import { useJsaQuery, useSendGrid } from '../../hooks';
import GridItem from '../../components/Grid/GridItem';
import EmailInput from './EmailInput';
import { useAuth } from 'contexts/AuthProvider';
type Email = {
  email: string;
  role: string;
};

const ShareJsaFormDetails = memo((props: any) => {
  const { sendShareJsaEmail } = useSendGrid();
  const [emails, setEmails] = useState<Email[]>([]);
  const [initEmail, setInitEmail] = useState('');
  const [valid, setValid] = useState(true);
  const [hiddenUpdateButtonRef] = useState(createRef<HTMLButtonElement>());

  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const mutationQuery = Mutations.UpdateJsa;
  const params: { jsa?: string } = useParams();
  const { data } = useJsaQuery();
  const personInCharge = get(data, 'getJobSafetyAnalysis.workDetails.personInCharge');
  const myTeam = getIn(props.formik.values, 'teamDetails.teamContacts');
  const collaboratorsTemp = getIn(props.formik.values, 'collaboratorDetails.collaborators');
  const collaborators = collaboratorsTemp === '' ? [] : collaboratorsTemp;

  const [isDisabled, setIsDisabled] = useState(true);
  const { user } = useAuth();

  // Track updated collaborator state
  let updatedCollaborators: Email[] = [...collaborators];

  // Set first row of Work Team to be defaulted to the PICW.
  if (personInCharge) {
    myTeam[0].firstName = personInCharge.firstName;
    myTeam[0].lastName = personInCharge.lastName;
  }

  const validateEmail = (email: any) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const isUniqueCollaborator = (collaborators: Email[], collaborator: Email) => {
    const match = collaborators.filter(
      (e: Email) => e.email.toLowerCase() === collaborator.email.toLowerCase()
    );
    return match.length === 0;
  };

  const hasDuplicateCollaborators = (isValid: Email[]) => {
    let duplicateFound = false;

    isValid.forEach((collaborator) => {
      if (!isUniqueCollaborator(collaborators, collaborator)) {
        duplicateFound = true;
      }
    });

    return duplicateFound;
  };

  const removeCollaborator = (email: string) => {
    updatedCollaborators = updatedCollaborators.filter(
      (e: Email) => e.email.toLowerCase() !== email.toLowerCase()
    );
  };

  const shareJsa = (arrayHelpers: any) => {
    const isValid = emails.filter((email: any) => validateEmail(email.email));

    if (isValid.length !== emails.length) {
      // Check for any invalid emails
      setHasError(true);
      setErrorText('One or more email addresses is not valid. Please correct it.');
    } else if (hasDuplicateCollaborators(isValid)) {
      // Check if existing collaborator
      setHasError(true);
      setErrorText('This email has been added before');
    } else if (isValid.length === emails.length && isValid.length !== 0) {
      // Check if any input duplicates
      const uniqueCollaborators: Email[] = [];
      isValid.forEach((collaborator) => {
        if (isUniqueCollaborator(uniqueCollaborators, collaborator)) {
          uniqueCollaborators.push(collaborator);
        }
      });

      // Check if already in the scroll list/past share
      uniqueCollaborators.forEach((collaborator) => {
        if (isUniqueCollaborator(collaborators, collaborator)) {
          arrayHelpers.push(collaborator);
          updatedCollaborators.push(collaborator);
          sendShareJsaEmail({
            email: collaborator.email,
            sharedByName: `${user ? user.firstName : ''} ${user ? user.lastName : ''}`,
            jsaTitle: get(data, 'getJobSafetyAnalysis.jsaApplicationTitle'),
            jsaId: get(data, 'getJobSafetyAnalysis.jsaId'),
            jsaGuid: get(data, 'getJobSafetyAnalysis.id'),
          });
        }
      });

      if (hiddenUpdateButtonRef.current != null) {
        hiddenUpdateButtonRef.current.click();
      }

      setHasError(false);
      setErrorText('');
      setInitEmail('');
      setIsDisabled(true);
      setValid(true);
    } else if (emails.length !== 0) {
      setValid(false);
    }
  };

  const update = () => {
    if (hiddenUpdateButtonRef.current != null) {
      hiddenUpdateButtonRef.current.click();
    }
  };

  const [updateStatusMutation] = useMutation(mutationQuery, {
    refetchQueries: () => [
      {
        query: Queries.GET_JSA_BY_ID,
        variables: {
          id: params.jsa,
        },
      },
    ],
  });

  const pushUpdate = (_input: any) => {
    updateStatusMutation({
      variables: {
        input: _input,
      },
    });
  };

  const handleUpdate = () => {
    const input = {
      id: params.jsa,
      collaboratorDetails: {
        collaborators: updatedCollaborators,
      },
    };
    pushUpdate(input);
  };

  const getNameFromEmail = (email: string) => {
    // split by `.` and get first element
    const firstName = email.split('.')[0];

    // split name part and get last element from array
    let lastName: string | undefined = '';
    if (email.split('@')[0].split('.').length > 1) lastName = email.split('@')[0].split('.').pop();

    return `${firstName} ${lastName}`;
  };

  return (
    <>
      <GridContainer>
        <FieldArray
          name="collaboratorDetails.collaborators"
          render={(arrayHelpers: any) => (
            <>
              <FormFieldEmailInputWrapper xs={12}>
                <EmailInput
                  valid={valid}
                  initEmail={initEmail}
                  setInitEmail={setInitEmail}
                  setEmails={setEmails}
                  setIsDisabled={setIsDisabled}
                  hasError={hasError}
                  errorText={errorText}
                  setHasError={setHasError}
                  setErrorText={setErrorText}
                />
                <Button
                  preset="primary"
                  onClick={() => {
                    shareJsa(arrayHelpers);
                  }}
                  themeColour="leaf"
                  disabled={isDisabled}
                >
                  SHARE JSA
                </Button>
              </FormFieldEmailInputWrapper>

              <StyledCollaboratorsGridItem xs={12}>
                <GridContainer style={{ marginLeft: '10%', maxWidth: '82%' }}>
                  <GridItem style={{ marginLeft: '0%' }} xs={12}>
                    <GridContainer>
                      <GridItem style={{ paddingLeft: '0%' }} xs={8}>
                        <StyledCollaboratorDivContainer>
                          <Typography align="left" variant="h3">
                            Your collaborators
                          </Typography>
                        </StyledCollaboratorDivContainer>
                      </GridItem>
                      <GridItem style={{ display: 'none' }} xs={4}>
                        <StyledShareJsaDivContainer>
                          <StyledIcon name="UrlLink" />
                          <StyledShareJsaTypography variant="body1">
                            Copy JSA link
                          </StyledShareJsaTypography>
                        </StyledShareJsaDivContainer>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <StyledCollaboratorsListingGridItem xs={12}>
                    <GridContainer style={{ maxWidth: '700px' }}>
                      {map(collaborators, (collaborator: any, index: any) => {
                        const isFirst = index === 0;
                        return (
                          <Fragment key={index}>
                            <FormFieldWrapper style={{ paddingLeft: '0px' }} xs={9}>
                              <Avatar
                                style={{ paddingRight: '10px', fontWeight: 'bold' }}
                                name={getNameFromEmail(collaborator.email)}
                                size="30"
                                textSizeRatio={2.5}
                                fgColor="#000000"
                                round
                              />
                              <Typography
                                style={{ display: 'inline', color: '#213D58', fontSize: '16px' }}
                                align="left"
                                variant="body1"
                              >
                                {collaborator.email}
                              </Typography>
                            </FormFieldWrapper>
                            <FormFieldWrapper style={{ paddingRight: '0px' }} xs={2}>
                              <div style={{ display: 'flex' }}>
                                <div style={{ flexGrow: 5 }} />
                                <div>
                                  <Typography
                                    style={{
                                      display: 'inline-block',
                                      color: '#213D58',
                                      verticalAlign: 'middle',
                                      lineHeight: 'normal',
                                    }}
                                    align="right"
                                    variant="body1"
                                  >
                                    {collaborator.role}
                                  </Typography>
                                </div>
                              </div>
                            </FormFieldWrapper>
                            <FormFieldRemoveButtonWrapper xs={1}>
                              {!isFirst ? (
                                <StyledRemoveButton
                                  onClick={() => {
                                    arrayHelpers.remove(index);
                                    removeCollaborator(collaborator.email);
                                    update();
                                  }}
                                />
                              ) : (
                                <StyledRemoveButton
                                  onClick={() => {
                                    arrayHelpers.remove(index);
                                    removeCollaborator(collaborator.email);
                                    update();
                                  }}
                                />
                              )}
                            </FormFieldRemoveButtonWrapper>
                          </Fragment>
                        );
                      })}
                    </GridContainer>
                  </StyledCollaboratorsListingGridItem>
                </GridContainer>
              </StyledCollaboratorsGridItem>
            </>
          )}
        />
      </GridContainer>
      <div style={{ display: 'none' }}>
        <button
          ref={hiddenUpdateButtonRef}
          id="update_button"
          onClick={handleUpdate}
          style={{ display: 'none' }}
        >
          Hidden Update
        </button>
      </div>
    </>
  );
});

ShareJsaFormDetails.displayName = 'ShareJsaFormDetails';

export default connect(ShareJsaFormDetails);
