import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { createSelector } from 'reselect';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import flatten from 'lodash/fp/flatten';
import uniqBy from 'lodash/fp/uniqBy';
import filter from 'lodash/fp/filter';
import sortBy from 'lodash/fp/sortBy';

// Base selectors
export const getJobSafetyAnalysis = (data: any) => get(data, 'getJobSafetyAnalysis', {});
export const getWorkSteps = (data: any) => get(data, 'getJobSafetyAnalysis.workSteps', []);
export const getGlobalPpe = (data: any) => get(data, 'getJobSafetyAnalysis.ppe', []);

// Computed Selectors
export const getHazards = createSelector([getWorkSteps], (workSteps) => {
  const processHazards = flow(
    map(({ hazards }: any) => hazards),
    filter((item: any) => item),
    flatten
  )(workSteps);

  return processHazards || null;
});

export const getControls = createSelector([getHazards], (hazards) => {
  const processControls = flow(
    map(({ controls }: any) => controls),
    filter((item: any) => item !== '')
  )(hazards);

  return processControls || null;
});

// @ts-expect-error I can't work out why the overloads here don't match, its baffling - Hamish (9-Feb-2022)
export const getSelectedPpe = createSelector([getControls], (controls) => {
  const processPpe = flow(
    map(({ ppe }: any) => ppe),
    flatten,
    uniqBy('code'),
    sortBy('code')
  )(controls);

  return processPpe ? processPpe : [];
});

export const getCombinedPpe = createSelector(
  [getGlobalPpe, getSelectedPpe],
  (globalPpe, selectedPpe) => {
    let combinedArray: any[] = [];

    if (isArray(globalPpe)) {
      combinedArray = globalPpe.concat(selectedPpe);
    } else if (isArray(selectedPpe)) {
      combinedArray = selectedPpe;
    }

    const filteredAllPpe = flow(uniqBy('code'))(combinedArray);
    return filteredAllPpe || [];
  }
);
