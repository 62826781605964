import React, { useEffect, memo } from 'react';
import { useFormikContext, FieldArray, getIn } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import uniqBy from 'lodash/fp/uniqBy';
import { flow } from 'lodash';
import { useClientState } from '../../hooks';
import {
  getHazardsToSave,
  getCustomHazardsToSave,
  getStepToUpdate,
  getControlsToSave,
  getInitialLikelihoodToSave,
  getInitialSeverityToSave,
  getInitialRiskToSave,
  getResidualLikelihoodToSave,
  getResidualSeverityToSave,
  getResidualRiskToSave,
  getHazardToUpdate,
  getEditingHazards,
} from '../../redux/workStepsSlice';
import DragItem from '../../components/DragItem/DragItem';
import WorkStepInput from '../WorkStepInput/WorkStepInput';
import AddStepButton from './AddStepButton';
import WorkStepDeleteModal from './WorkStepDeleteModal';
import WorkStepListHeader from './WorkStepListHeader';
import WorkStepListSubHeader from './WorkStepListSubHeader';
import WorkStepListTheme from './WorkStepListTheme';
import { useSubmitValidation } from 'contexts/useSubmitValidation';
import { ErrorMessage } from 'components/TextField/styles';
import Icon from 'components/Icon/Icon';

const WorkStepList = memo(() => {
  const { submitForm, setFieldValue, values } = useFormikContext();
  const {
    hasUserAttemptedSubmission,
    isWorkStepErroredByIndex,
    setHazardAndControlState,
    isHazardsAndControlsEmpty,
  } = useSubmitValidation();
  const workSteps = getIn(values, 'workSteps');
  const stepToUpdate = useSelector(getStepToUpdate);
  const hazardsToSave = useSelector(getHazardsToSave);
  const categoriesWithCustomHazards = useSelector(getCustomHazardsToSave);
  const controlsToSave = useSelector(getControlsToSave);
  const initialRiskToSave = useSelector(getInitialRiskToSave);
  const initialLikelihoodToSave = useSelector(getInitialLikelihoodToSave);
  const initialSeverityToSave = useSelector(getInitialSeverityToSave);
  const residualRiskToSave = useSelector(getResidualRiskToSave);
  const residualLikelihoodToSave = useSelector(getResidualLikelihoodToSave);
  const residualSeverityToSave = useSelector(getResidualSeverityToSave);
  const { hazardToUpdateFormName } = useSelector(getHazardToUpdate);
  const editingHazards = useSelector(getEditingHazards);
  const savedHazards = getIn(values, `${stepToUpdate}.hazards`);
  const { setItemToDelete, resetWorkSteps, setHazardsToSave, setEditingHazards } = useClientState();

  useEffect(() => {
    if (hasUserAttemptedSubmission) {
      setHazardAndControlState(workSteps);
    }
  }, []);

  useEffect(() => {
    if (hasUserAttemptedSubmission) {
      setHazardAndControlState(workSteps);
    }
  }, [workSteps]);

  useEffect(() => {
    if (hazardsToSave) {
      setFieldValue(`${stepToUpdate}.hazards` as never, flow(uniqBy('code'))(hazardsToSave));
      setHazardsToSave(null);
      setEditingHazards(false);
    }
  }, [
    hazardsToSave,
    categoriesWithCustomHazards,
    stepToUpdate,
    setHazardsToSave,
    setFieldValue,
    savedHazards,
    setEditingHazards,
  ]);

  useEffect(() => {
    if (categoriesWithCustomHazards) {
      setFieldValue('categoriesWithCustomHazards' as never, categoriesWithCustomHazards);
    }
  }, [categoriesWithCustomHazards, setFieldValue]);

  useEffect(() => {
    if (controlsToSave) {
      setFieldValue(`${hazardToUpdateFormName}.controls` as never, controlsToSave);
      resetWorkSteps();
    }
  }, [controlsToSave, hazardToUpdateFormName, setFieldValue, resetWorkSteps]);

  useEffect(() => {
    if (editingHazards) {
      setFieldValue(`${stepToUpdate}.editingHazards` as never, true);
    }
  }, [editingHazards, hazardToUpdateFormName, setFieldValue, stepToUpdate]);

  useEffect(() => {
    if (initialRiskToSave) {
      setFieldValue(`${hazardToUpdateFormName}.initialRisk.risk` as never, initialRiskToSave);
      setFieldValue(
        `${hazardToUpdateFormName}.initialRisk.likelihood` as never,
        initialLikelihoodToSave
      );
      setFieldValue(`${hazardToUpdateFormName}.initialRisk.severity` as never, initialSeverityToSave);
      resetWorkSteps();
    }
  }, [
    initialRiskToSave,
    initialSeverityToSave,
    initialLikelihoodToSave,
    hazardToUpdateFormName,
    setFieldValue,
    resetWorkSteps,
  ]);

  useEffect(() => {
    if (residualRiskToSave) {
      setFieldValue(`${hazardToUpdateFormName}.residualRisk.risk` as never, residualRiskToSave);
      setFieldValue(
        `${hazardToUpdateFormName}.residualRisk.likelihood` as never,
        residualLikelihoodToSave
      );
      setFieldValue(`${hazardToUpdateFormName}.residualRisk.severity` as never, residualSeverityToSave);
      resetWorkSteps();
    }
  }, [
    residualRiskToSave,
    residualSeverityToSave,
    residualLikelihoodToSave,
    hazardToUpdateFormName,
    setFieldValue,
    resetWorkSteps,
  ]);

  function handleClose() {
    setItemToDelete(null);
  }

  const determineVisibility = (index: number) => {
    if (isHazardsAndControlsEmpty() && hasUserAttemptedSubmission) {
      return index === 0;
    } else {
      return isWorkStepErroredByIndex(index) && hasUserAttemptedSubmission;
    }
  };

  return (
    <WorkStepListTheme>
      <FieldArray
        name="workSteps"
        render={(arrayHelpers) => {
          const addStep = () => {
            const id = uuidv4();
            const newValue = { id, text: '', editingHazards: false };
            arrayHelpers.push(newValue);
          };

          return (
            <>
              <WorkStepListHeader addStep={addStep} />
              <WorkStepListSubHeader />
              {workSteps.map((item: any, index: any) => {
                const isLast = workSteps.length === index + 1;
                const isFirst = index === 0;
                /*
                 * Key must be item ID not index.
                 * This is to preserve the drag preview
                 */
                return (
                  <DragItem
                    fieldArrayName="workSteps"
                    items={workSteps}
                    onDragEnd={submitForm}
                    itemType="WORK_STEP"
                    index={index}
                    key={`WorkStepDragger-${item.id}`}
                  >
                    <WorkStepInput
                      index={index}
                      id={item.id}
                      isFirst={isFirst}
                      isLast={isLast}
                      item={item}
                      name={`workSteps.${index}`}
                    />
                    {determineVisibility(index) && (
                      <ErrorMessage>
                        <Icon name="ValidationErrorRed" />
                        This step is incomplete
                      </ErrorMessage>
                    )}
                  </DragItem>
                );
              })}
              <AddStepButton onClick={addStep} />
              <WorkStepDeleteModal handleClose={handleClose} />
            </>
          );
        }}
      />
    </WorkStepListTheme>
  );
});

WorkStepList.displayName = 'WorkStepList';

export default WorkStepList;
