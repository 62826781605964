import React from 'react';
import { connect } from 'formik';
import Accordion from '../Accordion/Accordion';
import { AccordionDetails, AccordionSummary } from '../Accordion/styles';
import Tooltip from '../Tooltip/Tooltip';
import Typography from '../Typography/Typography';
import ControlMeasuresList from './ControlMeasuresList';

const ControlMeasures = ({ hazard, suggestedControls }: any) => {
  const [expandedPanels, setExpandedPanels] = React.useState<{
    panel1: boolean;
    panel2: boolean;
  }>({ panel1: true, panel2: true });

  const handleChange = (panel: string) => () => {
    const currentValue = expandedPanels[panel];
    setExpandedPanels({
      ...expandedPanels,
      [panel]: !currentValue,
    });
  };

  return (
    <Accordion expanded={expandedPanels.panel1 === true} title="" hazard={hazard}>
      <AccordionSummary>
        <Typography variant="h3">
          Control measures{' '}
          <Tooltip
            style={{ marginLeft: '8px', marginRight: '6px' }}
            placement="bottom"
            text="HazardControlMeasures"
          />
          <div
            style={{
              width: '100px',
              display: 'inline-flex',
              height: '24px',
              float: 'right',
              right: '-5px',
              position: 'absolute',
              top: '25px',
              zIndex: 1000,
            }}
            onClick={handleChange('panel1')}
          />
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body1">
          List all the control measures you can implement to be safe (e.g. by elimination, substitution
          or engineering/admin controls).
        </Typography>
        <Typography variant="body1" paragraph>
          Here are common controls for this hazard. Select from the list and add your own controls until
          you have reduced the risk as much as possible:
        </Typography>
        <ControlMeasuresList suggestedControls={suggestedControls} />
      </AccordionDetails>
    </Accordion>
  );
};

export default connect(ControlMeasures);
