import statusAttributes from '../../constants/statusAttributes';

function getStatusAttributes(status: any) {
  if (!status) {
    return statusAttributes['draft'];
  }

  const _statusAttributes = statusAttributes[status];

  if (!_statusAttributes) {
    return statusAttributes['draft'];
  }

  return statusAttributes[status];
}

export default getStatusAttributes;
