import React from 'react';
import map from 'lodash/map';
import Select from '../Select/Select';
import MenuItem from '../Select/MenuItem';
import isolationOptions from '../../constants/isolationOptions';

const IsolationTypeInput = function (props: any) {
  return (
    <Select label="Isolation type *" {...props}>
      <MenuItem value=" " disabled>
        Choose an isolation
      </MenuItem>
      {map(isolationOptions, ({ id, label }: any, index: number) => (
        <MenuItem key={`isolationOptions-${index}`} value={id}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default IsolationTypeInput;
