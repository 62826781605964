import React from 'react';
import { StyledSummaryHazardCategory, StyledSummaryHazard, HazardGridWrapper } from './style';
import Typography from '../../components/Typography/Typography';
import SummaryHazardCard from '../../components/SummaryHazardCard/SummaryHazardCard';

const SummaryHazardCategory = function ({ hazardCategory, hazards }: any) {
  return (
    <StyledSummaryHazardCategory>
      <StyledSummaryHazard>
        <Typography variant="h4" align="left">
          {hazardCategory}
        </Typography>
      </StyledSummaryHazard>
      <HazardGridWrapper>
        {hazards.map(({ name, code }: any, key: number) => (
          <StyledSummaryHazard key={`StyledSummaryHazard-${key}`}>
            <SummaryHazardCard code={code} name={name} />
          </StyledSummaryHazard>
        ))}
      </HazardGridWrapper>
    </StyledSummaryHazardCategory>
  );
};

export default SummaryHazardCategory;
