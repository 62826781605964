import React from 'react';
import get from 'lodash/get';
import { GridItem, FormikAutocomplete } from '../../components';
import UploadInput from '../UploadInput/UploadInput';
import { StyledRemoveButton, UploadControlsGridItem, UploadControl } from './styles';

const suggestionData = [
  { id: 'Supporting Document 1', label: 'Supporting Document 1' },
  { id: 'Supporting Document 2', label: 'Supporting Document 2' },
];

const SupportingDocumentInput = function ({ index, supportDocument, arrayHelpers }: any) {
  const fileTypeLabel = get(supportDocument, 'fileType.label');
  const isInputDisabled = !fileTypeLabel;

  return (
    <>
      <GridItem xs={5}>
        <FormikAutocomplete
          id={`workPack.supportDocuments[${index}].fileType`}
          name={`workPack.supportDocuments[${index}].fileType`}
          options={suggestionData}
        />
      </GridItem>
      <UploadControlsGridItem xs={7}>
        <UploadControl>
          <UploadInput
            name={`workPack.supportDocuments[${index}]`}
            label="Upload File"
            isInputDisabled={isInputDisabled}
          />
        </UploadControl>
        <UploadControl>
          <StyledRemoveButton onClick={() => arrayHelpers.remove(index)} />
        </UploadControl>
      </UploadControlsGridItem>
    </>
  );
};

export default SupportingDocumentInput;
