import { useState, useEffect } from 'react';

export default function useScreenOrientation() {
  const isLandscape = () => window.matchMedia('(orientation:landscape)').matches;
  const [screenOrientation, setScreenOrientation] = useState(isLandscape() ? 'landscape' : 'portrait');

  const handleOrientation = () => {
    setTimeout(() => {
      setScreenOrientation(isLandscape() ? 'landscape' : 'portrait');
    }, 500);
  };

  useEffect(() => {
    window.addEventListener('orientationchange', handleOrientation);
    return () => {
      window.removeEventListener('orientationchange', handleOrientation, true);
    };
  }, []);

  return { screenOrientation, setScreenOrientation };
}
