import React from 'react';
import { useField } from 'formik';
import isEmpty from 'lodash/isEmpty';
import Typography from '../../components/Typography/Typography';
import {
  WorkStepHazardControlsList,
  StyledLinkButton,
  WorkStepHazardControlsCol,
  WorkStepHazardCol,
  MediumStyledValidationIcon,
} from './style';
import { getPpeString } from '../../components/ControlsOverlay/ppeSelectors';
import {
  getControls,
  getControlMeasures,
  getPermits,
  getPpe,
  getSelfIsolations,
  getIsolationCertificate,
} from './workStepSelectors';
import { useSubmitValidation } from 'contexts/useSubmitValidation';

const WorkStepHazardControls = function ({ name, handleClick, initialRisk }: any) {
  const { hasUserAttemptedSubmission } = useSubmitValidation();
  const [field] = useField(name);
  const controls = getControls(field);

  const controlMeasures = getControlMeasures(field);
  const permits = getPermits(field);
  const ppe = getPpe(field);
  const isolationCertificate = getIsolationCertificate(field as never);
  const selfIsolations = getSelfIsolations(field as never);
  const areControlsContentsEmpty =
    isEmpty(controlMeasures) &&
    (permits === null || permits.required === false) &&
    isEmpty(ppe) &&
    isolationCertificate === undefined &&
    selfIsolations === undefined;

  if (isEmpty(controls) || areControlsContentsEmpty) {
    return (
      <>
        <WorkStepHazardCol center={false}>
          <StyledLinkButton
            text="Add Hazard Controls"
            icon="Controls"
            onClick={handleClick}
            disabled={!initialRisk}
            field={field}
          />
          {hasUserAttemptedSubmission && <MediumStyledValidationIcon name="ValidationErrorRed" />}
        </WorkStepHazardCol>
      </>
    );
  }

  return (
    <WorkStepHazardControlsCol>
      <WorkStepHazardControlsList data-testid={`${name}.controls.controlMeasures`} onClick={handleClick}>
        {controlMeasures.map((controlMeasure: any, index: number) => (
          <li key={`controlMeasure-${index}`}>
            <Typography align="left" variant="body1">
              {controlMeasure.value}
            </Typography>
          </li>
        ))}
        {permits && permits.required === true && (
          <li>
            <Typography align="left" variant="body1">
              Apply for a {permits.name}
            </Typography>
          </li>
        )}
        {ppe && ppe.length > 0 && (
          <li>
            <Typography align="left" variant="body1">
              {getPpeString(ppe)}
            </Typography>
          </li>
        )}
        {isolationCertificate && (
          <li>
            <Typography align="left" variant="body1">
              {isolationCertificate}
            </Typography>
          </li>
        )}
        {selfIsolations && (
          <li>
            <Typography align="left" variant="body1">
              {selfIsolations}
            </Typography>
          </li>
        )}
      </WorkStepHazardControlsList>
    </WorkStepHazardControlsCol>
  );
};

export default WorkStepHazardControls;
