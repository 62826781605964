import React, { useState, useEffect, useRef, memo } from 'react';
import { Field, useFormikContext, getIn } from 'formik';
import { useKeyPress, useClientState } from '../../hooks';
import { isDoubleClick } from '../../utils';
import { StartAdornment, WorkStepInputWrapper, WorkStepTextInput, StartAdornmentContent } from './style';
import WorkStepDetails from './WorkStepDetails';
import EndAdornment from './EndAdornment';
import { useSubmitValidation } from 'contexts/useSubmitValidation';

const WorkStepInput = memo(
  ({ isFirst, isLast = false, index, expanded = false, name, ...rest }: any) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(expanded);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isEditingExisting, setIsEditingExisting] = useState<boolean>(false);
    const [inputDidMount, setInputDidMount] = useState<boolean>(false);
    const inputWrapperElement: React.RefObject<HTMLInputElement> = useRef(null);
    const enterPress = useKeyPress('Enter');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { values, dirty } = useFormikContext();
    const isDirty = dirty;
    const isMenuOpen = Boolean(anchorEl);
    const textValue = getIn(values, `${name}.text`);
    const { hasUserAttemptedSubmission, isWorkStepErroredByIndex, isHazardsAndControlsEmpty } =
      useSubmitValidation();

    const determineErrorVisibility = (index: number) => {
      if (isHazardsAndControlsEmpty() && hasUserAttemptedSubmission) {
        return index === 0;
      } else {
        return isWorkStepErroredByIndex(index) && hasUserAttemptedSubmission;
      }
    };

    const isErrored = determineErrorVisibility(index);
    const { setItemToDelete } = useClientState();

    useEffect(() => {
      if (enterPress && isEditing && textValue) {
        if (inputWrapperElement.current) {
          inputWrapperElement.current.blur();
        }
        if (!isEditingExisting) {
          setIsExpanded(true);
        }
      }
    }, [enterPress, isEditing, isEditingExisting, textValue]);

    useEffect(() => {
      if (isEditing) {
        if (inputWrapperElement.current !== null) {
          inputWrapperElement.current.focus();
        }
      }
    }, [isEditing]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const editStep = () => {
      setIsEditing(true);
      handleClose();
    };

    const deleteStep = () => {
      handleClose();
      setIsExpanded(false);
      setItemToDelete(rest.id);
    };

    return (
      <WorkStepInputWrapper isExpanded={isExpanded} isMenuOpen={isMenuOpen} isErrored={isErrored}>
        <Field name={`${name}.text`} {...rest}>
          {(fieldProps: any) => {
            const { value, onBlur } = fieldProps.field;

            function handleBlur(e: any) {
              setIsEditing(false);
              onBlur(e);
            }

            function isDisabled() {
              if (isEditing) {
                return false;
              }

              if (value && !isEditing) {
                return true;
              }

              return false;
            }

            function shouldAutoFocus() {
              if (isDirty && isLast && !inputDidMount) {
                return true;
              }

              return false;
            }

            function handleInputClick(e: any) {
              if (isDoubleClick(e)) {
                setIsEditingExisting(true);
                editStep();
              }
            }

            function handleInputFocus() {
              if (!isEditing) {
                setIsEditing(true);
                setInputDidMount(true);
              }
            }

            return (
              <WorkStepTextInput
                className="work_step_text_input"
                onClick={handleInputClick}
                onFocus={handleInputFocus}
                disabled={isDisabled()}
                autoFocus={shouldAutoFocus()}
                inputRef={inputWrapperElement}
                isExpanded={isExpanded}
                fullWidth
                {...fieldProps}
                InputProps={{
                  onBlur: handleBlur,
                  placeholder: index === 0 ? 'Type your first step here' : null,
                  startAdornment: (
                    <StartAdornment isErrored={isErrored} position="start">
                      <StartAdornmentContent isErrored={isErrored}>
                        <span>{index + 1}</span>
                      </StartAdornmentContent>
                    </StartAdornment>
                  ),
                  endAdornment: (
                    <EndAdornment
                      {...{
                        isFirst,
                        isErrored,
                        isEditing,
                        isEditingExisting,
                        setIsExpanded,
                        isMenuOpen,
                        handleClick,
                        anchorEl,
                        editStep,
                        handleClose,
                        deleteStep,
                        isExpanded,
                        value,
                      }}
                    />
                  ),
                }}
              />
            );
          }}
        </Field>
        {isExpanded && <WorkStepDetails name={name} />}
      </WorkStepInputWrapper>
    );
  }
);

WorkStepInput.displayName = 'WorkStepInput';

export default WorkStepInput;
