import styled from 'styled-components';

export const SummaryWorkStepsHazardCardWrapper = styled.div`
  height: 120px;
  width: 120px;
  border: 2px solid #f9c83a;
  border-radius: 5px;
  padding-top: 7px;
`;

export const SummaryWorkStepsHazardCardIconWrapper = styled.div`
  height: 60px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  page-break-inside: avoid;

  & svg {
    width: 60px;
    height: 60px;

    path {
      fill: #213d58;
    }
  }
`;

export const SummaryWorkStepsHazardCardTextWrapper = styled.div`
  height: 50px;
  padding-right: 10px;
  padding-left: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  && h5 {
    color: #213d58;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    text-align: center;
  }
`;
