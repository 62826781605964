import React from 'react';
import styled from 'styled-components';
import Typography from '../Typography/Typography';
import { ReactComponent as EmptyStateSvg } from '../Icon/svgs/empty-state.svg';

const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 50vh;
`;

const IconContainer = styled.div`
  border-radius: 100%;
  border: 2px solid #e1e5e5;
  height: 150px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 23px;
`;

const HazardOverlayEmptyState = function () {
  return (
    <EmptyStateContainer>
      <IconContainer>
        <EmptyStateSvg height={88} width={88} />
      </IconContainer>
      <Typography variant="body1">
        No hazards found. Try using a different term for the hazard.
      </Typography>
    </EmptyStateContainer>
  );
};

export default HazardOverlayEmptyState;
