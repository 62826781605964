import React from 'react';
import { getIn, useFormikContext } from 'formik';
import { GridItem, TextField, LinkButton, IsolationTypeInput } from '../../components';
import UploadInput from '../UploadInput/UploadInput';
import {
  StyledGridItem,
  UploadControlsGridItem,
  IsolationControlsGridItem,
  UploadControl,
  StyledRemoveButton,
  SelfIsolationRemoveWrapper,
} from './styles';
import isolationCertificate from './Isolation_Certificate.pdf';

const IsolationsRequiredInput = function ({ index, arrayHelpers }: any) {
  const { values } = useFormikContext();
  const isDisabled = getIn(values, `workPack.isolations[${index}].fileName`) !== '';

  return (
    <>
      <GridItem direction="row" container xs={5}>
        <IsolationTypeInput name={`workPack.isolations[${index}].isolationType`} disabled={isDisabled} />
      </GridItem>
      {getIn(values, `workPack.isolations[${index}].isolationType`) === 'isolationCertificate' ? (
        <IsolationControlsGridItem xs={7}>
          <UploadControl>
            <LinkButton
              text="Download template"
              icon="Download"
              href={isolationCertificate}
              download
              target="_blank"
            />
          </UploadControl>
          <UploadControl>
            <UploadInput
              data-testid={`workPack.isolations[${index}]`}
              name={`workPack.isolations[${index}]`}
              label="Upload filled permit"
            />
          </UploadControl>
          <UploadControl>
            <StyledRemoveButton onClick={() => arrayHelpers.remove(index)} />
          </UploadControl>
        </IsolationControlsGridItem>
      ) : (
        <UploadControlsGridItem xs={7} />
      )}
      {getIn(values, `workPack.isolations[${index}].isolationType`) === 'selfIsolation' ? (
        <>
          <StyledGridItem xs={10}>
            <TextField
              fullWidth
              minRows={5.75}
              maxRows={5.75}
              multiline
              id={`workPack.isolations[${index}].selfIsolation`}
              name={`workPack.isolations[${index}].selfIsolation`}
              label="Self Isolation (LOTO)"
              placeholder="Additional details"
              helperText="Self Isolations (LOTO) can only be completed by Watercare staff"
            />
          </StyledGridItem>
          <SelfIsolationRemoveWrapper xs={2}>
            <UploadControl>
              <StyledRemoveButton onClick={() => arrayHelpers.remove(index)} />
            </UploadControl>
          </SelfIsolationRemoveWrapper>
        </>
      ) : (
        <StyledGridItem xs={10} />
      )}
    </>
  );
};

export default IsolationsRequiredInput;
