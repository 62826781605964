import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledCard = styled(({ children, isErrored, ...rest }) => <div {...rest}>{children}</div>)`
  display: flex;
  align-items: center;
  border: ${({ isErrored, theme }) =>
    isErrored
      ? `2px solid ${theme.colors.complementary.red}`
      : `2px solid ${theme.colors.primary.leaf}`};
  padding: 20px;
  border-radius: 5px;
  margin: 30px 0;
  width: fit-content;
  & svg {
    margin: 0 10px;
  }
`;

export const StyledSummaryCard = styled(({ children, isErrored, ...rest }) => (
  <div {...rest}>{children}</div>
))`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border: ${({ isErrored, theme }) =>
    isErrored
      ? `2px solid ${theme.colors.complementary.red}`
      : `2px solid ${theme.colors.primary.leaf}`};
  padding: 15px 20px;
  border-radius: 5px;
  margin: 20px 0;
  width: fit-content;
  & svg {
    margin: 0 10px;
  }
  & div.summary-card-item {
    margin: 8px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const StyledLink = styled(({ route, children, ...rest }) => (
  <Link to={route} {...rest}>
    {children}
  </Link>
))`
  color: #0a0d0d;
`;
