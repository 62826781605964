import React from 'react';
import styled from 'styled-components';
import Button from '../Button/Button';

export const ConfirmText = styled.div`
  color: #43575a;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  margin-top: 20px;
`;

export const DividerText = styled.div`
  color: #6c7d80;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 3px solid #e1e5e5;
  }

  &:not(:empty)::before {
    margin-right: 15.5px;
  }

  &:not(:empty)::after {
    margin-left: 15.5px;
  }
`;

export const StyledButton = styled(({ ...rest }) => <Button {...rest} />)`
  margin-top: 30px;
`;

export const CheckboxWrapper = styled.span`
  margin-top: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
