import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Icon from '../Icon/Icon';

export const StyledMuiDialogTitle = styled(({ styles, ...rest }) => <MuiDialogTitle {...rest} />)`
  ${({ styles }) => styles}
  margin: 0;
`;
export const StyledDialogContentText = styled(({ styles, ...rest }) => <DialogContentText {...rest} />)`
  ${({ styles }) => styles}
`;

export const CloseButton = styled(IconButton)`
  top: 5px;
  color: #9e9e9e;
  right: 5px;
  position: absolute;
`;

export const StyledCloseIcon = styled(Icon)`
  width: 40px;
  height: 40px;

  & path {
    fill: ${(props) => props.theme.colors.greyScale['25']};
  }
`;

export const StyledDialog = styled(({ size, height, ...rest }) => <Dialog {...rest} />)`
  & .MuiDialog-paperWidthMd {
    max-width: 1084px;
  }
  & .MuiDialog-paper {
    width: ${({ size }) => size};
    height: ${({ height }) => height};
    border-radius: 5px;
    box-shadow: 0 3px 25px 0 rgba(33, 61, 88, 0.25);
  }
`;

export const StyledModalContent = styled(({ padding, overflowy, ...rest }) => (
  <MuiDialogContent {...rest} />
))`
  padding: ${({ padding }) => (padding === '' ? '21px 88px 60px 88px' : padding)};
  overflow: hidden;
  overflow-y: ${({ overflowy }) => (overflowy ? 'overlay' : 'hidden')};
`;

export const StyledModalActions = styled(({ styles, ...rest }) => <MuiDialogActions {...rest} />)`
  ${({ styles }) => styles}
`;
