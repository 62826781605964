import styled from 'styled-components';
import { Field } from 'formik';
import GridItem from '../Grid/GridItem';
import Typography from '../Typography/Typography';
import Button from '../Button/Button';

export const ControlsSuggestionFieldWrapper = styled.div`
  margin-bottom: 15px;
`;

// @TODO: Move this stuff out of here
export const StyledGridItem = styled(GridItem)`
  margin-top: 37px;
`;

export const StyledField = styled(Field)`
  margin-left: -10px;
  padding-top: 20px;
`;
export const StyledPpeTypography = styled(Typography)`
  padding-bottom: 23px;
`;
export const StyledPermitsTypography = styled(Typography)`
  padding-bottom: 15px;
`;

export const StyledPermitLink = styled(Button)`
  & .MuiButton-label {
    & svg {
      margin-right: 8px;
    }
  }
`;
