import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Icon from '../Icon/Icon';

export const CloseButton = styled(({ trigger, ...rest }) => <IconButton {...rest} />)`
  top: ${({ trigger }) => (trigger ? '-10px' : '5px')};
  color: #9e9e9e;
  right: 5px;
  position: absolute;
`;

export const StyledCloseIcon = styled(Icon)`
  width: 40px;
  height: 40px;

  & path {
    fill: ${(props) => props.theme.colors.greyScale['25']};
  }
`;
