import React, { CSSProperties, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { StyledLabel } from '../Label/style';
import { Control, Option, Placeholder, SingleValue, ValueContainer } from './AutocompleteComponents';
import useStyles, { StyledSelect, StyledIcon, StyledCreatableSelect } from './style';

const Autocomplete = (props: any) => {
  const {
    options,
    defaultValue,
    value,
    defaultOptions,
    onChange,
    onBlur,
    isClearable,
    isDisabled,
    isSearchable,
    isLoading,
    label,
    isCreatable,
    isFirstAid,
  } = props;

  const classes = useStyles();
  const theme = useTheme();

  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };
  const DropdownIndicator = function () {
    return <StyledIcon name="ArrowDown" />;
  };
  const defaultProps = {
    defaultOptions,
    onChange,
    isDisabled,
    isLoading,
    isClearable,
    isSearchable,
    value,
    onBlur,
    isMenuOpen: true,
  };
  const components = {
    Control,
    Option,
    Placeholder,
    SingleValue,
    DropdownIndicator,
    ValueContainer,
  };

  const reactSelectProps = {
    ...defaultProps,
    options,
    onChange,
    defaultValue,
    components,
  };

  const Label = label ? <StyledLabel as="span">{label}</StyledLabel> : null;
  const { id } = props;
  const [placeholder, setPlaceholder] = useState<string>('Start typing or select from the dropdown...');

  const SelectElement = isCreatable ? StyledCreatableSelect : StyledSelect;
  return (
    <div>
      <SelectElement
        isFirstAid={isFirstAid}
        onMenuClose={() => {
          setPlaceholder('Start typing or select from the dropdown...');
        }}
        onFocus={() => {
          setPlaceholder('');
        }}
        noOptionsMessage={() => 'No exact match found. Try checking the spelling.'}
        classes={classes}
        styles={selectStyles}
        inputId={id}
        data-testid={id}
        TextFieldProps={{
          label: Label,
          InputLabelProps: {
            htmlFor: id,
            shrink: true,
          },
        }}
        placeholder={placeholder}
        {...reactSelectProps}
      />
    </div>
  );
};

Autocomplete.defaultProps = {
  isDisabled: false,
  isFirstAid: false,
  isSearchable: true,
  isClearable: true,
  disableUnderline: true,
};

export default Autocomplete;
