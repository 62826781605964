import styled from 'styled-components';

export const StyledIcon = styled.div<{ preset: any }>`
  width: ${({ preset }) => (preset === 'tertiary' ? '24px' : '32px')};
  height: auto;
  max-height: ${({ preset }) => {
    if (preset === 'newJSA') {
      return '40px';
    }
    return preset === 'tertiary' ? '24px' : '32px';
  }};
  margin-bottom: ${({ preset }) => (preset === 'tertiary' ? '2px' : '0px')};
`;
