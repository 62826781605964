import React from 'react';
import { SelectProps as MuiSelectProps } from '@material-ui/core/Select';
import { Field } from 'formik';
import Label from '../Label/Label';
import { StyledSelect } from './styles';

export interface SelectProps extends MuiSelectProps {
  name: string;
  size?: 'large' | 'small' | undefined;
  label?: string;
  disabled?: boolean;
}
const Select = function ({ name, size, label, disabled, ...rest }: SelectProps) {
  return (
    <div>
      {label && <Label label={label} />}
      <Field
        name={name}
        disabled={disabled}
        component={StyledSelect}
        variant="outlined"
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        size={size}
        {...rest}
      />
    </div>
  );
};

export default Select;
