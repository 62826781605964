import { gql } from '@apollo/client';
import * as mutations from './graphql/mutations';
import * as queries from './graphql/queries';
import * as customQueries from './graphql/custom-queries';
import * as subscriptions from './graphql/subscriptions';

// @TODO: Update masterData query to use https://cdn.contentful.com/spaces/ixcs8cd54s3q/entries/?select=fields&access_token=hz9hjG14YDaxMUnOTKQNth1kAP23q_qytIsY8WGhdXg&sys.contentType.sys.id[in]=facility,facilityControl
export const Mutations = {
  CreateJsa: gql(mutations.createJobSafetyAnalysis),
  UpdateJsa: gql(mutations.updateJobSafetyAnalysis),
  SendEmailNotification: gql(mutations.sendEmailNotification),
};

export const Queries = {
  GET_JSA_BY_ID: gql(queries.getJobSafetyAnalysis),
  LIST_JSAS: gql(queries.listJobSafetyAnalyses),
  GET_DASHBOARD_LIST: gql(customQueries.getDashboardList),
  GET_MASTER_DATA: gql`
    query masterData {
      masterData
        @rest(
          type: "Get"
          path: "entries/?select=fields,sys&content_type=facility&access_token=hz9hjG14YDaxMUnOTKQNth1kAP23q_qytIsY8WGhdXg"
        ) {
        items
      }
      facilityControl
        @rest(
          type: "Get"
          path: "entries/?select=fields,sys&content_type=facilityControl&access_token=hz9hjG14YDaxMUnOTKQNth1kAP23q_qytIsY8WGhdXg"
        ) {
        items
      }
      medicalFacility
        @rest(
          type: "Get"
          path: "entries/?select=fields,sys&content_type=medicalFacility&access_token=hz9hjG14YDaxMUnOTKQNth1kAP23q_qytIsY8WGhdXg"
        ) {
        items
      }
      tooltip
        @rest(
          type: "Get"
          path: "entries/?select=fields&access_token=hz9hjG14YDaxMUnOTKQNth1kAP23q_qytIsY8WGhdXg&include=1&content_type=tooltips&order=fields.name"
        ) {
        items
      }
    }
  `,
  GET_HAZARD_DATA: gql`
    query hazardData {
      hazardData
        @rest(
          type: "Get"
          path: "entries/?select=fields&access_token=hz9hjG14YDaxMUnOTKQNth1kAP23q_qytIsY8WGhdXg&include=1&content_type=hazardCategories&order=fields.name"
        ) {
        items
        includes
      }
    }
  `,
  GET_OPERATING_FACILITY_DATA: gql`
    query hazardData {
      operatingFacilityData
        @rest(
          type: "Get"
          path: "entries/?select=fields,sys&access_token=hz9hjG14YDaxMUnOTKQNth1kAP23q_qytIsY8WGhdXg&include=1&content_type=operatingArea&order=fields.name"
        ) {
        items
        includes
      }
    }
  `,
  GET_PERMITS_DATA: gql`
    query hazardData {
      permitData
        @rest(
          type: "Get"
          path: "entries/?select=fields&access_token=hz9hjG14YDaxMUnOTKQNth1kAP23q_qytIsY8WGhdXg&include=1&content_type=permits&order=fields.name"
        ) {
        items
      }
    }
  `,
  GET_PERMIT_DOCUMENT_DATA: gql`
    query hazardData {
      permitDocumentData
        @rest(
          type: "Get"
          path: "entries/?select=fields&access_token=hz9hjG14YDaxMUnOTKQNth1kAP23q_qytIsY8WGhdXg&include=1&content_type=permitDocument&order=fields.name"
        ) {
        items
        includes
      }
    }
  `,
  GET_PPE_DATA: gql`
    query ppeData {
      ppeData
        @rest(
          type: "Get"
          path: "entries/?select=fields&access_token=hz9hjG14YDaxMUnOTKQNth1kAP23q_qytIsY8WGhdXg&include=1&content_type=ppe&order=fields.ppeName"
        ) {
        items
      }
    }
  `,
  GET_TEAM_ROLES_DATA: gql`
    query teamData {
      teamRolesData
        @rest(
          type: "Get"
          path: "entries/?select=fields&access_token=hz9hjG14YDaxMUnOTKQNth1kAP23q_qytIsY8WGhdXg&include=1&content_type=teamRole&order=fields.name"
        ) {
        items
        includes
      }
    }
  `,
};
export const Subscriptions = {
  OnCreateJsa: gql(subscriptions.onCreateJobSafetyAnalysis),
};
