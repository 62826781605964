import React, { useState, memo } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import MuiCheckbox from '@material-ui/core/Checkbox';
import { withCookies } from 'react-cookie';
import Modal from '../Modal/Modal';
import ModalTitle from '../Modal/ModalTitle';
import ModalContent from '../Modal/ModalContent';
import ModalContentText from '../Modal/ModalContentText';
import ModalActions from '../Modal/ModalActions';
import GridContainer from '../Grid/GridContainer';
import { GridItem, Button, ContentLoader } from '..';
import useClientState from '../../hooks/useClientState';
import { Mutations, Queries } from '../../api-queries';
import statusAttributes from '../../constants/statusAttributes';
import { StyledIcon, ThemeIcon } from '../CheckBox/styles';
import { CheckboxWrapper } from './styles';
import { useSendGrid } from 'hooks';
import { useAuth } from 'contexts/AuthProvider';

const TickIcon = <StyledIcon name="Tick" />;
const UntickIcon = <ThemeIcon name="UnTick" />;

const ConfirmModal = memo(({ isOpen, data, cookies }: any) => {
  const { sendStatusChangeEmail } = useSendGrid();
  const { setConfirmModalOpen } = useClientState();
  const { UpdateJsa } = Mutations;

  const [doNotShow, setDoNotShow] = useState(false);
  const params: { jsa?: string } = useParams();
  const { user: userData } = useAuth();

  const [updateStatusMutation] = useMutation(UpdateJsa, {
    onCompleted(data: any) {
      sendStatusChangeEmail(data);
    },
    refetchQueries: () => [
      {
        query: Queries.GET_JSA_BY_ID,
        variables: {
          id: params.jsa,
        },
      },
    ],
  });

  const pushUpdateStatus = () => {
    updateStatusMutation({
      variables: {
        input: {
          id: params.jsa,
          status: statusAttributes.readyreview.id,
          submittedBy: userData,
          submittedDate: new Date().toISOString(),
        },
      },
    });
  };

  const handleClose = () => {
    setConfirmModalOpen(false);
  };

  function onConfirm() {
    if (doNotShow) {
      cookies.set('doNotShowSubmit', true, {
        path: '/',
        maxAge: 2592000,
      });
    }

    pushUpdateStatus();
    handleClose();
  }

  function onCheckboxChange() {
    setDoNotShow(!doNotShow);
  }

  if (!data) {
    return <ContentLoader />;
  }

  const titleStyle = 'padding-top: 50px; padding-bottom: 0px;';

  return (
    <Modal open={isOpen}>
      <ModalTitle styles={titleStyle} onClose={handleClose}>
        Proceed with submission?
      </ModalTitle>
      <ModalContent padding="13px 80px 52px 80px">
        <ModalContentText styles="{text-align: center;}">
          This JSA will not be editable once submitted. If changes are later required, contact your
          Watercare representative. <br />
          <CheckboxWrapper>
            <MuiCheckbox
              style={{ height: 'fit-content', marginRight: 6 }}
              icon={UntickIcon}
              checkedIcon={TickIcon}
              id="doNotShowCheckbox"
              onChange={onCheckboxChange}
            />
            Do not show again.
          </CheckboxWrapper>
        </ModalContentText>
        <ModalActions>
          <GridContainer>
            <GridItem xs={6}>
              <Button preset="secondary" onClick={handleClose} themeColour="grey" fullWidth>
                Cancel
              </Button>
            </GridItem>
            <GridItem xs={6}>
              <Button preset="primary" themeColour="leaf" fullWidth onClick={onConfirm}>
                Yes, proceed
              </Button>
            </GridItem>
          </GridContainer>
        </ModalActions>
      </ModalContent>
    </Modal>
  );
});

ConfirmModal.displayName = 'ConfirmModal';

export default withCookies(ConfirmModal);
