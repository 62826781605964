import React from 'react';
import WorkStepHazardRisk from '../../components/WorkStepHazardRisk/WorkStepHazardRisk';
import { WorkStepHazardCol, StyledLinkButton, MediumStyledValidationIcon } from './style';
import { useSubmitValidation } from 'contexts/useSubmitValidation';

const WorkStepRisk = function ({ riskObject, name, handleClick, disabled, riskType }: any) {
  const { hasUserAttemptedSubmission } = useSubmitValidation();
  return (
    <WorkStepHazardCol>
      {riskObject ? (
        <WorkStepHazardRisk
          handleClick={handleClick}
          riskObject={riskObject}
          name={name}
          dataTestid={`${name}.${riskType}Risk.risk`}
        />
      ) : (
        <>
          <StyledLinkButton text="Set Risk" icon="Slider" onClick={handleClick} disabled={disabled} />
          {hasUserAttemptedSubmission && <MediumStyledValidationIcon name="ValidationErrorRed" />}
        </>
      )}
    </WorkStepHazardCol>
  );
};

export default WorkStepRisk;
