import React from 'react';
import map from 'lodash/map';
import { getHazards } from './summaryHazardsOnThisJobSelectors';
import SummaryHazardCategory from './SummaryHazardCategory';
import { SummarySection, SummarySectionContent } from './style';
import SummarySectionTitle from '../../components/SummarySectionTitle/SummarySectionTitle';
import Fallback from '../../components/Fallback/Fallback';

const SummaryHazardsOnThisJob = function ({ data }: any) {
  const hazards = getHazards(data);

  return (
    <SummarySection hazardsOnThisJob>
      <SummarySectionContent hazardsOnThisJob>
        <SummarySectionTitle sectionNumber="A">Hazards on this job</SummarySectionTitle>

        <Fallback value={hazards} message="Hazards on this job not completed yet" isText={false}>
          {map(hazards, (hazard: any, key) => (
            <SummaryHazardCategory
              key={`SummaryHazardCategory-${key}`}
              hazardCategory={key}
              hazards={hazard}
            />
          ))}
        </Fallback>
      </SummarySectionContent>
    </SummarySection>
  );
};

export default SummaryHazardsOnThisJob;
