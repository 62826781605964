import { useState, useEffect } from 'react';
import { Queries } from '../api-queries';
import contentfulClient from '../ContentfulApolloClient';

function usePermitData() {
  const [data, setData] = useState<any>(null);

  async function getClient() {
    const { data } = await contentfulClient.query({ query: Queries.GET_PERMITS_DATA });

    setData(data);
  }

  useEffect(() => {
    getClient();
  }, []);

  if (data) {
    return { loading: false, data };
  }

  return { loading: true, data: null };
}

export default usePermitData;
