import styled from 'styled-components';
import Typography from '../Typography/Typography';

export const StyledWorkStepHazardInitialRisk = styled.div`
  text-align: center;
`;
export const StyledTypography = styled(Typography)`
  font-size: 40px !important;
  font-weight: 300 !important;
  line-height: 38px !important;
  margin-bottom: 8px !important;
`;

export const RiskButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;

  &,
  & div {
    cursor: pointer;
  }
`;
