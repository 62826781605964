import { Auth } from 'aws-amplify';
import React, { createContext, memo, ReactNode, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

type JobcareUser = {
  firstName: string;
  lastName: string;
  email: string;
};

export type AuthContext = {
  user: JobcareUser;
  signOut: () => void;
};

export const AuthContext = createContext<AuthContext | null>(null);

type Props = {
  children: ReactNode;
};

export const AuthProvider = memo(({ children }: Props) => {
  const [user, setUser] = useState<JobcareUser | null>(null);
  const [cookies, setCookie, removeCookie] = useCookies(['jsa_redirect_url']);
  const navigate = useNavigate();
  const pathName = window.location.pathname;

  const signOut = () => {
    Auth.signOut();
    setUser(null);
    removeCookie('jsa_redirect_url');
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(({ attributes: { given_name, family_name, email } }) => {
        setUser({
          firstName: given_name,
          lastName: family_name,
          email,
        });
        if (cookies.jsa_redirect_url) {
          navigate(cookies.jsa_redirect_url);
          removeCookie('jsa_redirect_url');
        }
      })
      .catch(() => {
        setCookie('jsa_redirect_url', pathName, { path: '/' });
        Auth.federatedSignIn();
        setUser(null);
      });
  }, []);

  if (!user) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});

AuthProvider.displayName = 'AuthProvider';

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('This component must be used within a <AuthProvider> component.');
  }

  return context;
};
