import React from 'react';
import isEmpty from 'lodash/isEmpty';
import Typography from '../Typography/Typography';
import { FallbackText, EmailText } from './style';

interface FallbackProps {
  value?: any;
  message?: string | null | undefined;
  children?: any;
  paragraph?: boolean;
  isText?: boolean;
  email?: boolean;
  wordWrap?: boolean;
}

const Fallback = function ({ message, value, children, paragraph, isText, email }: FallbackProps) {
  const hasValue = !isEmpty(value);

  if (email && hasValue) {
    const indexOfAt = value.indexOf('@') + 1;
    const firstLine = value.substring(0, indexOfAt);
    const secondLine = value.substring(indexOfAt);

    if (value.length < 34) {
      return (
        <EmailText variant="body1" paragraph={paragraph}>
          {value}
        </EmailText>
      );
    }

    return (
      <>
        <EmailText variant="body1" paragraph={paragraph}>
          {firstLine}
        </EmailText>
        <EmailText variant="body1" secondLine paragraph={paragraph}>
          {secondLine}
        </EmailText>
      </>
    );
  }

  if (hasValue && children) {
    if (isText) {
      return (
        <Typography variant="body1" paragraph={paragraph}>
          {children}
        </Typography>
      );
    }
    return children;
  }

  if (hasValue && !children) {
    return (
      <Typography variant="body1" paragraph={paragraph}>
        {value}
      </Typography>
    );
  }

  return (
    <FallbackText variant="body1" paragraph={paragraph}>
      {message}
    </FallbackText>
  );
};

Fallback.defaultProps = {
  message: 'Not completed yet',
  paragraph: false,
  isText: true,
};

export default Fallback;
