import React, { useContext } from 'react';
import { DialogTitleProps } from '@material-ui/core/DialogTitle';
import Collapse from '@material-ui/core/Collapse';
import OverlayContext from './OverlayContext';
import Typography from '../Typography/Typography';
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import ModalOverlayCloseButton from '../ModalOverlayCloseButton/ModalOverlayCloseButton';
import Tooltip from '../Tooltip/Tooltip';
import { Divider, StyledDialogTitle } from './styles';

interface OverlayTitleProps extends DialogTitleProps {
  subTitle?: React.ReactNode;
  onClose: any;
  tooltip?: string;
}

const OverlayTitle = function ({ id, title, subTitle, onClose, tooltip }: OverlayTitleProps) {
  const { trigger } = useContext(OverlayContext);
  const shouldRenderSubTitle = Boolean(subTitle) && !trigger;

  return (
    <StyledDialogTitle disableTypography trigger={trigger}>
      <Typography id={id} variant="h2" align="center" gutterBottom={shouldRenderSubTitle}>
        {title}
        {tooltip && <Tooltip text={tooltip} />}
      </Typography>
      <Collapse in={shouldRenderSubTitle}>
        <GridContainer>
          <GridItem xs={2} />
          <GridItem xs={8}>{subTitle}</GridItem>
        </GridContainer>
      </Collapse>
      {onClose ? <ModalOverlayCloseButton onClose={onClose} trigger={trigger} /> : null}
      <Divider />
    </StyledDialogTitle>
  );
};

export default OverlayTitle;
