import React from 'react';
import SummarySectionTitle from '../../components/SummarySectionTitle/SummarySectionTitle';
import SummaryEmergencyDetailsContacts from './SummaryEmergencyDetailsContacts';
import SummaryEmergencyDetailsSidebar from './SummaryEmergencyDetailsSidebar';
import SummaryEmergencyDetailsLocations from './SummaryEmergencyDetailsLocations';
import { SummarySection, SummarySectionContent, SummarySectionContentInner } from './style';

const SummaryEmergencyDetails = function ({ data }: any) {
  return (
    <SummarySection hasSidebar>
      <SummarySectionContent>
        <SummarySectionTitle sectionNumber="2">Emergency Details</SummarySectionTitle>
        <SummarySectionContentInner>
          <SummaryEmergencyDetailsContacts data={data} />
          <SummaryEmergencyDetailsLocations data={data} />
        </SummarySectionContentInner>
      </SummarySectionContent>
      <SummaryEmergencyDetailsSidebar data={data} />
    </SummarySection>
  );
};

export default SummaryEmergencyDetails;
