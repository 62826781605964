/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": process.env.REACT_APP_AWS_PROJECT_REGION,
    "aws_appsync_graphqlEndpoint": process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
    "aws_appsync_region": process.env.REACT_APP_AWS_APPSYNC_REGION,
    "aws_appsync_authenticationType": process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE,
    "aws_appsync_apiKey": process.env.REACT_APP_AWS_APPSYNC_API_KEY,
    "aws_cognito_region":  process.env.REACT_APP_AWS_COGNITO_REGION,
    "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOLS_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    "identityPoolId": process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    "oauth": {
        "domain":   process.env.REACT_APP_AWS_COGNITO_DOMAIN,
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn":  process.env.REACT_APP_REDIRECT_SIGN_ID,
        "redirectSignOut": process.env.REACT_APP_REDIRECT_SIGN_OUT,
        "responseType": "token",
        "hostedUISignIn": "true"
    },
    "aws_user_files_s3_bucket": process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET,
    "aws_user_files_s3_bucket_region": process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET_REGION,
    "walkme_cdn": process.env.REACT_APP_WALKME_CDN
};

export default awsmobile;
