import React, { useMemo } from 'react';
import find from 'lodash/find';
import omit from 'lodash/omit';
import findIndex from 'lodash/findIndex';
import { Field, useFormikContext } from 'formik';
import produce from 'immer';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import IconCard from '../IconCard/IconCard';
import { StyledLabel, StyledCheckbox } from './style';
import { getStepToUpdate } from '../../redux/workStepsSlice';

const IconCardCheckbox = ({ name, arrayHelpers, item, cardType, mandatory, data, ...rest }: any) => {
  const { values }: any = useFormikContext();

  const { setFieldValue } = useFormikContext();
  const stepToUpdate = useSelector(getStepToUpdate);

  const getIsChecked = (values: any) => {
    if (cardType === 'ppe') {
      const inDraftPpe = find(values.controls.draftPpe, (value) => value.code === item.code);
      return mandatory ? true : inDraftPpe !== undefined;
    }
    return !!(
      find(values.selectedHazards, (value) => value.code === item.code) ||
      find(values.draftSelectedHazards, (value) => value.code === item.code)
    );
  };

  return (
    <Field name="firstName">
      {() => {
        const isChecked = useMemo(() => getIsChecked(values), [values]);
        const isRequired = cardType === 'ppe' ? item.required : false;

        return (
          <StyledLabel isRequired={isRequired}>
            <StyledCheckbox
              name={name}
              type="checkbox"
              value={item.code}
              checked={isChecked}
              onChange={(e) => {
                if (cardType === 'ppe' && isRequired === true) {
                  return;
                }
                if (e.target.checked) {
                  arrayHelpers.push(omit(item, ['linkID', 'tooltip']));
                } else {
                  if (cardType === 'ppe') {
                    const idDraftPpe = findIndex(
                      values.controls.draftPpe,
                      (value: any) => value.code === item.code
                    );
                    const idPpe = findIndex(values.ppe, (value: any) => value.code === item.code);
                    if (idPpe !== -1) {
                      setFieldValue(
                        'ppe' as never,
                        produce(values.ppe, (draft: any) => {
                          draft.splice(idPpe, 1);
                        })
                      );
                    } else {
                      arrayHelpers.remove(idDraftPpe);
                    }
                  } else {
                    const idDraftSelectedHazards = findIndex(
                      values.draftSelectedHazards,
                      (value: any) => value.code === item.code
                    );
                    const idSelectedHazards = findIndex(
                      values.selectedHazards,
                      (value: any) => value.code === item.code
                    );

                    if (idSelectedHazards !== -1) {
                      setFieldValue(
                        'selectedHazards' as never,
                        produce(values.selectedHazards, (draft: any) => {
                          draft.splice(idSelectedHazards, 1);
                        })
                      );
                      const hazards = get(data, `getJobSafetyAnalysis.${stepToUpdate}.hazards`, []);
                      const toConcat = hazards.filter((hazard: any) => {
                        return hazard.code === item.code;
                      });
                      const selectedHazardsToKeep = get(values, 'selectedHazardsToKeep');
                      setFieldValue(
                        'selectedHazardsToKeep' as never,
                        selectedHazardsToKeep.concat(toConcat)
                      );
                    } else {
                      arrayHelpers.remove(idDraftSelectedHazards);
                    }
                  }
                }
              }}
            />
            <IconCard
              name={item.code}
              isSelected={isChecked}
              label={item.name || item.ppeName}
              colour={item.colour}
              cardType={cardType}
              {...rest}
            />
          </StyledLabel>
        );
      }}
    </Field>
  );
};

export default IconCardCheckbox;
