import React from 'react';
import { useSelector } from 'react-redux';
import {
  StyledModalActions,
  TooltipWrapper,
  TooltipText,
  StyledActionButtons,
  StyledModalActionsButtonWrapper,
} from './styles';
import { getIsRiskMatrixOverlayOpen } from '../../redux/workStepsSlice';
import RiskMatrixModal from '../RiskMatrixModal/RiskMatrixModal';
import { useClientState } from '../../hooks';
import Icon from '../Icon/Icon';

const ModalActions = function ({ onClose, isDisabled }: any) {
  const isRiskMatrixOverlayOpen = useSelector(getIsRiskMatrixOverlayOpen);
  const { setIsRiskMatrixOverlayOpen } = useClientState();

  return (
    <StyledModalActions styles="justify-content: space-between;">
      <TooltipWrapper
        onClick={() => {
          setIsRiskMatrixOverlayOpen(true);
        }}
      >
        <Icon preset="tertiary" name="HowTo" />
        <TooltipText variant="body1">How to assess risk level</TooltipText>
      </TooltipWrapper>
      <StyledModalActionsButtonWrapper>
        <StyledActionButtons preset="secondary" themeColour="grey" onClick={onClose}>
          CANCEL
        </StyledActionButtons>
        <StyledActionButtons type="submit" preset="primary" themeColour="blue" disabled={isDisabled}>
          SET
        </StyledActionButtons>
      </StyledModalActionsButtonWrapper>
      <div style={{ width: 160 }} />
      <RiskMatrixModal
        open={isRiskMatrixOverlayOpen}
        onClose={() => setIsRiskMatrixOverlayOpen(false)}
      />
    </StyledModalActions>
  );
};

export default ModalActions;
