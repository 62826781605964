import React from 'react';
import { AlertContainer, StyledIcon } from './styles';

const Alert = function ({ status, iconName, children }: any) {
  return (
    <AlertContainer className="screen-only" status={status}>
      <StyledIcon name={iconName} status={status} />
      {children}
    </AlertContainer>
  );
};

export default Alert;
