import React, { memo } from 'react';
import { WorkDetailsForm } from '../../features';
import { Typography, PageValidationErrorCard } from '../../components';

const WorkDetails = memo(() => {
  return (
    <>
      <Typography variant="h1" gutterBottom>
        Work Details
      </Typography>
      <Typography variant="body1" gutterBottom>
        Check that all of the work details are correctly filled.
        <Typography variant="body2">
          <strong>Fields marked with (*) are mandatory.</strong>
        </Typography>
        <PageValidationErrorCard route="work-details" />
      </Typography>
      <WorkDetailsForm />
    </>
  );
});

WorkDetails.displayName = 'WorkDetails';

export default WorkDetails;
