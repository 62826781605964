import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  firstName: null,
  lastName: null,
  email: null,
  department: null,
  jobTitle: null,
  isAssignToConflictModalOpen: false,
  isSendForConfirmModalOpen: false,
  isConfirmModalOpen: false,
};

const activeUser = createSlice({
  name: 'activeUser',
  initialState,
  reducers: {
    setAssignToConflictModalOpen: (nextState: any, action: any) => {
      nextState.isAssignToConflictModalOpen = action.payload;
    },
    setSendForConfirmModalOpen: (nextState: any, action: any) => {
      nextState.isSendForConfirmModalOpen = action.payload;
    },
    setIsValidationModalOpen: (nextState: any, action: any) => {
      nextState.isValidationModalOpen = action.payload;
    },
    setConfirmModalOpen: (nextState: any, action: any) => {
      nextState.isConfirmModalOpen = action.payload;
    },
  },
});

export const getAssignToConflictModalOpen = createSelector(
  (state: any) => state.activeUser.isAssignToConflictModalOpen,
  (isAssignToConflictModalOpen) => isAssignToConflictModalOpen
);

export const getSendForConfirmModalOpen = createSelector(
  (state: any) => state.activeUser.isSendForConfirmModalOpen,
  (isSendForConfirmModalOpen) => isSendForConfirmModalOpen
);

export const getIsValidationModalOpen = createSelector(
  (state: any) => state.activeUser.isValidationModalOpen,
  (isValidationModalOpen) => isValidationModalOpen
);

export const getConfirmModalOpen = createSelector(
  (state: any) => state.activeUser.isConfirmModalOpen,
  (isConfirmModalOpen) => isConfirmModalOpen
);

export default activeUser;
