import React, { useEffect, useState } from 'react';
import { InputAdornment } from '@material-ui/core';
import { Icon } from '..';
import { StyledSearch, CloseButton } from './styles';
import { StyledCloseIcon } from '../Modal/style';

const SearchBar = function ({ setSearchTerm }: any) {
  const [debouncedTerm, setDebouncedTerm] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => setSearchTerm(debouncedTerm.toLowerCase()), 1000);
    return () => clearTimeout(timer);
  }, [setSearchTerm, debouncedTerm]);

  const searchIconAdornment = (
    <InputAdornment position="start">
      <Icon name="Search" preset="tertiary" />
    </InputAdornment>
  );

  function onChange(event: any) {
    setDebouncedTerm(event.currentTarget.value);
  }

  function onClose(event: any) {
    event.currentTarget.parentNode.parentNode.childNodes[1].value = '';
    setDebouncedTerm('');
  }

  const close = (
    <InputAdornment position="end">
      <CloseButton aria-label="close" onClick={onClose}>
        <StyledCloseIcon name="ClearText" preset="newJSA" />
      </CloseButton>
    </InputAdornment>
  );

  return (
    <StyledSearch
      variant="outlined"
      placeholder="Type hazard's name"
      InputProps={{ startAdornment: searchIconAdornment, endAdornment: close }}
      onChange={onChange}
    />
  );
};

export default SearchBar;
