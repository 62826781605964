import React from 'react';
import { useFormikContext } from 'formik';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Button from '../Button/Button';
import GoToTopButton from '../Button/GoToTopButton';
import { GotoTopAligner } from './styles';
import OverlayContext from '../Overlay/OverlayContext';
import OverlayActions from '../Overlay/OverlayActions';

const ControlsOverlayActions = function ({ onClose, isEditing }: any) {
  const { submitForm } = useFormikContext();
  const { contentRef } = React.useContext(OverlayContext);

  return (
    <OverlayActions>
      <GridContainer>
        <GridItem xs={2} />
        <GridItem xs={4}>
          <Button fullWidth preset="secondary" themeColour="grey" onClick={onClose}>
            Cancel
          </Button>
        </GridItem>
        <GridItem xs={4}>
          <Button
            data-testid="hazardControlsButton"
            fullWidth
            type="button"
            onClick={() => submitForm()}
            preset="primary"
            themeColour="blue"
          >
            {isEditing ? 'Update Hazard Controls' : 'Add Hazard Controls'}
          </Button>
        </GridItem>
        <GridItem xs={2}>
          <GotoTopAligner>
            <GoToTopButton nodeRef={contentRef} />
          </GotoTopAligner>
        </GridItem>
      </GridContainer>
    </OverlayActions>
  );
};

export default ControlsOverlayActions;
