import React from 'react';
import { StyledWorkStepHazardInitialRisk, StyledTypography, RiskButton } from './style';
import Risk from '../Chip/Risk';

function selectRiskChip(risk: any) {
  switch (risk) {
    case 1:
      return {
        chipText: 'VERY LOW',
        chipColor: 'lightGreen',
      };
    case 2:
      return {
        chipText: 'LOW',
        chipColor: 'yellow',
      };
    case 3:
      return {
        chipText: 'MEDIUM',
        chipColor: 'sunrise',
      };
    case 4:
      return {
        chipText: 'HIGH',
        chipColor: 'magenta',
      };
    case 5:
      return {
        chipText: 'VERY HIGH',
        chipColor: 'red',
      };
    default:
      return {
        chipColor: 'lightGreen',
        chipText: '',
      };
  }
}

const WorkStepHazardRisk = function ({ riskObject, dataTestid, handleClick }: any) {
  const { risk } = riskObject;
  const { chipColor, chipText } = selectRiskChip(risk);

  return (
    <StyledWorkStepHazardInitialRisk data-testid={dataTestid}>
      <RiskButton type="button" onClick={handleClick}>
        <StyledTypography variant="h2">{risk}</StyledTypography>
        <Risk
          text={chipText}
          // @ts-check
          color={chipColor}
        />
      </RiskButton>
    </StyledWorkStepHazardInitialRisk>
  );
};

export default WorkStepHazardRisk;
