import React from 'react';
import { useFormikContext, getIn } from 'formik';
import { HazardTextLabel, LabelText, HazardLabelWrapper } from './styles';

const determineRiskName = (value: number) => {
  switch (value) {
    case 1:
      return 'very low';
    case 2:
      return 'low';
    case 3:
      return 'medium';
    case 4:
      return 'high';
    case 5:
      return 'very high';
    case 0:
      return 'no input';
  }
};

const HazardLabel = function ({ name }: any) {
  const { values } = useFormikContext();
  const riskLevel = getIn(values, 'risk');
  const riskName = determineRiskName(riskLevel);

  return (
    <HazardLabelWrapper>
      <HazardTextLabel variant="h4">
        <span>Hazard:</span>
        <strong>{name}</strong>
      </HazardTextLabel>
      <LabelText variant="h4">
        Existing risk level:{' '}
        <strong>
          {riskLevel} ({riskName})
        </strong>
      </LabelText>
    </HazardLabelWrapper>
  );
};

export default HazardLabel;
