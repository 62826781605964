import React from 'react';
import { SummaryWorkStepHazardRiskWrapper } from './style';
import WorkStepHazardRisk from '../WorkStepHazardRisk/WorkStepHazardRisk';
import Typography from '../Typography/Typography';

const SummaryWorkStepHazardRisk = function ({ residualRisk, code, initialRisk, dataTestid }: any) {
  return (
    <SummaryWorkStepHazardRiskWrapper data-testid={dataTestid}>
      <WorkStepHazardRisk riskObject={residualRisk} dataTestid={`${code}.residualRisk.risk`} />
      <Typography variant="h5">{`Was ${initialRisk}`}</Typography>
    </SummaryWorkStepHazardRiskWrapper>
  );
};

export default SummaryWorkStepHazardRisk;
