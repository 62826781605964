import React from 'react';
import { useFormikContext, getIn } from 'formik';
import { DrawerContentBase } from './styles';
import DrawerHeader from './DrawerHeader';
import PpeSection from './PpeSection';

export default function DrawerContents({ onClose }: any) {
  const { values } = useFormikContext();

  const ppeMasterData = getIn(values, 'controls.ppeMasterData');

  return (
    <DrawerContentBase>
      <DrawerHeader onClose={onClose} />
      <PpeSection onClose={onClose} data={ppeMasterData} />
    </DrawerContentBase>
  );
}
