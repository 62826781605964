import React, { memo } from 'react';
import Icon from '../Icon/Icon';
import { StyledCard, StyledSummaryCard } from './styles';
import { useSubmitValidation } from 'contexts/useSubmitValidation';
import { StyledLink } from './styles';

const routeToHumanReadableMap = {
  'work-details': 'Work Details',
  'emergency-details': 'Emergency Details',
  'hazards-controls': 'Hazards & Controls',
  workpack: 'Workpack',
  summary: 'Summary',
};

type Props = {
  route: string;
  jsaId: string;
};

const PageValidationErrorCard = memo(({ route, jsaId }: Props) => {
  const {
    hasUserAttemptedSubmission,
    getRouteNamesWithErrors,
    showEmptyValidationForHazardsAndControls,
    getNumberOfErrorsForHazardsPage,
    showEmailClashMessaging,
    numberOfWorkDetailsErrors,
    numberOfEmergencyDetailsErrors,
  } = useSubmitValidation();

  if (!hasUserAttemptedSubmission) {
    return null;
  }

  if (route === 'work-details') {
    const isValid = numberOfWorkDetailsErrors === 0 && !showEmailClashMessaging;

    if (isValid) {
      return (
        <StyledSummaryCard>
          <div className="summary-card-item">
            <Icon name="GreenValidationTick" />
            <span>You have resolved all the issues on this page.</span>
          </div>
        </StyledSummaryCard>
      );
    } else if (numberOfWorkDetailsErrors > 0 && showEmailClashMessaging) {
      return (
        <StyledSummaryCard isErrored={true}>
          <div className="summary-card-item">
            <Icon name="ValidationErrorRed" />
            <span>
              You have{' '}
              <b>
                {numberOfWorkDetailsErrors} issue
                {numberOfWorkDetailsErrors > 1 && 's'}
              </b>{' '}
              on this page, please resolve them before submitting your JSA.
            </span>
          </div>
          <div className="summary-card-item">
            <Icon name="ValidationErrorRed" />
            <span>{showEmailClashMessaging}</span>
          </div>
        </StyledSummaryCard>
      );
    } else if (numberOfWorkDetailsErrors === 0 && showEmailClashMessaging) {
      return (
        <StyledSummaryCard isErrored={true}>
          <div className="summary-card-item">
            <Icon name="ValidationErrorRed" />
            <span>{showEmailClashMessaging}</span>
          </div>
        </StyledSummaryCard>
      );
    } else {
      return (
        <StyledSummaryCard isErrored>
          <div className="summary-card-item">
            <Icon name="ValidationErrorRed" />
            <span>
              You have{' '}
              <b>
                {numberOfWorkDetailsErrors} issue
                {numberOfWorkDetailsErrors > 1 && 's'}
              </b>{' '}
              on this page, please resolve them before submitting your JSA.
            </span>
          </div>
        </StyledSummaryCard>
      );
    }
  }

  if (route === 'emergency-details') {
    return (
      <StyledSummaryCard isErrored={numberOfEmergencyDetailsErrors > 0}>
        <div className="summary-card-item">
          <Icon
            name={numberOfEmergencyDetailsErrors > 0 ? 'ValidationErrorRed' : 'GreenValidationTick'}
          />

          {numberOfEmergencyDetailsErrors === 0 ? (
            <span>You have resolved all the issues on this page.</span>
          ) : (
            <span>
              You have{' '}
              <b>
                {numberOfEmergencyDetailsErrors} issue
                {numberOfEmergencyDetailsErrors > 1 && 's'}
              </b>{' '}
              on this step, please resolve them before submitting your JSA.
            </span>
          )}
        </div>
      </StyledSummaryCard>
    );
  }

  if (route === 'hazards-controls') {
    if (showEmptyValidationForHazardsAndControls) {
      return (
        <StyledCard isErrored>
          <Icon name="ValidationErrorRed" />
          <span>You must complete at least one step before you can submit your JSA.</span>
        </StyledCard>
      );
    }
    if (getNumberOfErrorsForHazardsPage() === 0) {
      return (
        <StyledCard>
          <Icon name={'GreenValidationTick'} />
          <span>You have solved all issues on this page.</span>
        </StyledCard>
      );
    } else {
      return (
        <StyledCard isErrored>
          <Icon name="ValidationErrorRed" />
          <span>
            You have{' '}
            <b>
              {getNumberOfErrorsForHazardsPage()} issue
              {getNumberOfErrorsForHazardsPage() > 1 && 's'}
            </b>{' '}
            on this page, please resolve them before submitting your JSA.
          </span>
        </StyledCard>
      );
    }
  }

  if (route === 'summary') {
    if (getRouteNamesWithErrors().length > 0) {
      return (
        <StyledSummaryCard isErrored>
          {getRouteNamesWithErrors().map((route, index) => {
            return (
              <div className="summary-card-item" key={index}>
                <Icon name="ValidationErrorRed" />
                <span>
                  You have outstanding issues in{' '}
                  <StyledLink to={`/jsa/${jsaId}/${route}`}>{routeToHumanReadableMap[route]}</StyledLink>
                  .
                </span>
              </div>
            );
          })}
        </StyledSummaryCard>
      );
    } else {
      return null;
    }
  }

  return null;
});

PageValidationErrorCard.displayName = 'PageValidationErrorCard';

export default PageValidationErrorCard;
