import { createSelector } from 'reselect';
import get from 'lodash/get';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import flatten from 'lodash/fp/flatten';
import uniqBy from 'lodash/fp/uniqBy';
import filter from 'lodash/fp/filter';

// Base selectors
export const getControls = (data: any) => get(data, 'value.controls', {});
export const getInitialRisk = (data: any) => get(data, 'value.initialRisk', null);
export const getResidualRisk = (data: any) => get(data, 'value.residualRisk', null);
export const getWorkSteps = (data: any) => get(data, 'workSteps', []);
export const getIsolations = (data: any) => get(data, 'workPack.isolations', []);

// Computed selectors
export const getSelfIsolations = createSelector(
  [getWorkSteps],

  (workSteps) => {
    const selfIsolations = flow(
      map((workStep: any) => workStep.hazards), // [{hazardObject}, {hazardObject}, {hazardObject}]
      flatten,
      filter((item: any) => get(item, 'controls.isolations.selfIsolationValue')),
      map((item: any) => ({
        selfIsolationValue: get(item, 'controls.isolations.selfIsolationValue'),
        hazardName: get(item, 'name'),
      }))
    )(workSteps); // [workStep: {[hazards: {name, controls: {controlMeasure: [], isol}}]}]

    return selfIsolations || [];
  }
);

export const getHazardWithIsolations = createSelector([getWorkSteps], (workSteps) => {
  const hazardWithIsolations = flow(
    map(({ hazards }: any) => hazards),
    flatten,
    filter((item: any) => get(item, 'controls.isolations.required') === 'Yes'),
    filter((item: any) => get(item, 'controls.isolations.type') === 'isolationCertificate'),
    uniqBy('code'),
    map((item: any) => get(item, 'name'))
  )(workSteps);

  return hazardWithIsolations || [];
});
