import React from 'react';
import { DialogContentTextProps } from '@material-ui/core/DialogContentText';
import { StyledDialogContentText } from './style';

export interface ModalContentTextProps extends DialogContentTextProps {
  styles?: any;
}

const ModalContentText = function ({ children, styles }: ModalContentTextProps) {
  return <StyledDialogContentText styles={styles}>{children}</StyledDialogContentText>;
};

export default ModalContentText;
