import React from 'react';
import RenderChildByType from '../utils/RenderChildByType';
import {
  StyledButton,
  StyledAccordion,
  StyledExpandIcon,
  AccordionSummary,
  AccordionDetails,
} from './styles';

export const ExpandIcon = <StyledExpandIcon name="ArrowDown" />;

interface MyProps {
  [key: string]: any;
  borderTop?: boolean;
  borderBottom?: boolean;
  trigger?: boolean;
  hazard?: any;
  paddingTop?: string;
  paddingBottom?: string;
  title?: string;
  click?: any;
}

export const Accordion: React.FC<MyProps> = function ({
  children,
  trigger,
  paddingTop,
  paddingBottom,
  borderTop,
  borderBottom,
  hazard,
  title,
  click,
  ...rest
}) {
  let icon = ExpandIcon;
  if (rest.hazardinstructions === 'true') {
    icon = rest.hideShowButton;
  }
  if (click) {
    icon = (
      <StyledButton onClick={click}>
        <StyledExpandIcon name="ArrowUp" />
      </StyledButton>
    );
    if (rest.hazardinstructions === 'true') {
      icon = (
        <StyledButton onClick={click}>
          <StyledExpandIcon name="ArrowDown" />
        </StyledButton>
      );
    }
  }

  return (
    <StyledAccordion
      trigger={trigger}
      hazard={hazard}
      square
      borderTop={borderTop}
      borderBottom={borderBottom}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      title={title}
      {...rest}
    >
      <RenderChildByType childType={AccordionSummary} expandIcon={icon}>
        {children}
      </RenderChildByType>
      <RenderChildByType childType={AccordionDetails}>{children}</RenderChildByType>
    </StyledAccordion>
  );
};

export default Accordion;
