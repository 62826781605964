import React, { memo } from 'react';
import styled from 'styled-components';
import { EmergencyDetailsForm } from '../../features';
import {
  Typography,
  GridContainer,
  GridItem,
  EmergencyNotice,
  PageValidationErrorCard,
} from '../../components';

export const EmergencyNoticeWrapper = styled.div`
  padding-top: 35px;
  padding-bottom: 35px;
`;

const EmergencyDetails = memo(() => {
  return (
    <>
      <GridContainer>
        <GridItem xs={8}>
          <Typography variant="h1" gutterBottom>
            Emergency Details
          </Typography>
          <Typography variant="body1">
            Injuries, incidents and close calls must be reported to your Watercare Representative without
            delay. For serious events, the incident scene must not be altered (unless to make it safe)
            and all work in the vicinity of the event must cease until permission to proceed has been
            granted by your Watercare Representative.{' '}
            <Typography variant="body2" paragraph>
              <strong>Fields marked with (*) are mandatory.</strong>
            </Typography>
          </Typography>
          <PageValidationErrorCard route="emergency-details" />
        </GridItem>
        <GridItem xs={1} />
        <GridItem xs={2}>
          <EmergencyNoticeWrapper>
            <EmergencyNotice />
          </EmergencyNoticeWrapper>
        </GridItem>
      </GridContainer>

      <EmergencyDetailsForm />
    </>
  );
});

EmergencyDetails.displayName = 'EmergencyDetails';

export default EmergencyDetails;
