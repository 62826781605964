import React from 'react';
import Icon from '../../Icon/Icon';

const TextMessage = (props) => {
  const activeName = props.userData.given_name + ' ' + props.userData.family_name;
  return (
    <div className={`sc-message--text ${props.sentOrReceived}`}>
      <div style={{ alignItems: 'center' }}>
        <p className="sc-message-name">{props.name}</p>
        {activeName !== props.name && props.resolved === false && (
          <div className="resolveWrapper" onClick={() => props.onClickResolve(props)}>
            <div style={{ textAlign: 'right', paddingRight: '60px' }}>
              <Icon className="tickIcon" name="NormalTick" />
            </div>
            <p className="resolve">Resolve</p>
          </div>
        )}
        {activeName !== props.name && props.resolved === true && <p className="resolved">Resolved</p>}
      </div>
      <div>
        <p className="sc-message-subheading-text">Section {props.section}</p>
        <p className="sc-message-subheading-text date">{props.date}</p>
      </div>
      <p className="sc-message-comment">{props.text}</p>
      {activeName === props.name && (
        <p className="sc-message-link" onClick={() => props.onMessageDeleted(props)}>
          Delete
        </p>
      )}
    </div>
  );
};

export default TextMessage;
