import styled from 'styled-components';

export const StyledSummaryHazardCard = styled.div`
  width: 96px;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
`;
export const StyledSummaryHazardCardIconWrapper = styled.div`
  height: 60px;
  width: 96px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 60px;
    height: 60px;

    path {
      fill: #213d58;
    }
  }
`;

export const StyledSummaryHazardCardTextWrapper = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  && {
    h5 {
      color: #0a0d0d;
      font-size: 12px;
      font-weight: 600;
      line-height: 15px;
      text-align: center;
    }
  }
`;
