import { useEffect } from 'react';
import { connect } from 'formik';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { useDebounce, usePrevious } from '../../hooks';

// @ts-check
const AutoSave = function ({ formik: { values, status }, onSave }) {
  const debouncedValues = useDebounce(values, 100);
  const previousValues = usePrevious(debouncedValues);

  useEffect(() => {
    // @ts-check
    if (
      previousValues &&
      Object.keys(previousValues).length &&
      !isEqual(previousValues, debouncedValues)
    ) {
      if (get(status, 'isDragging')) {
        return;
      }
      onSave(debouncedValues);
    }
  }, [debouncedValues, onSave, previousValues, status]);

  return null;
};

export default connect(AutoSave);
