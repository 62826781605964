import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import usePrevious from './usePrevious';

function useFieldReset(fieldPath: string, condition?: boolean, resetValue?: string) {
  const { setFieldValue } = useFormikContext();
  const previousValues = usePrevious(condition);

  useEffect(() => {
    if (previousValues === false) {
      if (condition) {
        setFieldValue(fieldPath as never, resetValue);
      }
    }
  }, [condition]);

  return null;
}

export default useFieldReset;
