import React from 'react';
import { Form, getIn, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { Divider } from './styles';
import SliderSection from './SliderSection';
import ModalActions from './ModalActions';
import HazardLabel from './HazardLabel';
import { useClientState } from '../../hooks';
import { getHazardToUpdate } from '../../redux/workStepsSlice';
import GridItem from '../Grid/GridItem';

function setInitialValues(savedValue: any) {
  if (savedValue) {
    return {
      ...savedValue,
      likelihoodUsed: true,
      severityUsed: true,
    };
  }

  return {
    likelihood: -1,
    severity: -1,
    risk: 0,
    likelihoodUsed: false,
    severityUsed: false,
  };
}

function getSavedValue(hazard: any, riskModalName: string) {
  const initialRisk = getIn(hazard, 'initialRisk');
  const residualRisk = getIn(hazard, 'residualRisk');

  if (riskModalName === 'initialRisk') {
    return initialRisk;
  }
  if (riskModalName === 'residualRisk') {
    if (initialRisk && !residualRisk) {
      return initialRisk;
    }

    return residualRisk;
  }
}

const RiskSliderModalContent = function ({ riskModalName }: any) {
  const { hazard } = useSelector(getHazardToUpdate);
  const savedValue = getSavedValue(hazard, riskModalName);
  const initialValues = setInitialValues(savedValue);
  const [disabledSubmit, setDisabledSubmit] = React.useState(true);

  const {
    setIsInitialRiskOverlayOpen,
    setInitialLikelihood,
    setInitialSeverity,
    setInitialRisk,
    setIsResidualRiskOverlayOpen,
    setResidualLikelihood,
    setResidualSeverity,
    setResidualRisk,
  } = useClientState();

  function handleSubmit({ likelihood, risk, severity }: any) {
    if (riskModalName === 'initialRisk') {
      setIsInitialRiskOverlayOpen(false);
      setInitialLikelihood(likelihood);
      setInitialSeverity(severity);
      setInitialRisk(risk);
    } else if (riskModalName === 'residualRisk') {
      setIsResidualRiskOverlayOpen(false);
      setResidualLikelihood(likelihood);
      setResidualSeverity(severity);
      setResidualRisk(risk);
    }
  }

  function handleCancel() {
    if (riskModalName === 'initialRisk') {
      setIsInitialRiskOverlayOpen(false);
    } else if (riskModalName === 'residualRisk') {
      setIsResidualRiskOverlayOpen(false);
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <>
        <GridItem style={{ width: '100%', paddingTop: 7.5 }}>
          <HazardLabel name={hazard && hazard.name} />
          <Divider />
        </GridItem>
        <GridItem style={{ width: '100%' }}>
          <Form style={{ width: '100%' }}>
            <SliderSection setDisabledSubmit={setDisabledSubmit} />
            <ModalActions onClose={handleCancel} isDisabled={disabledSubmit} />
          </Form>
        </GridItem>
      </>
    </Formik>
  );
};

export default RiskSliderModalContent;
