import styled from 'styled-components';

export const SummaryWorkStepHazardRiskWrapper = styled.div`
  && h5 {
    color: #43575a;
    font-size: 13px;
    font-weight: bold;
    line-height: 21px;
    margin-top: 10px;
  }
`;
