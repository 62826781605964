import React from 'react';
import styled from 'styled-components';
import ExpansionPanel from '@material-ui/core/Accordion';
import ExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import ExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import Icon from '../Icon/Icon';

export const StyledAccordion = styled(
  ({ borderBottom, paddingTop, paddingBottom, borderTop, overlay, ...rest }) => (
    <ExpansionPanel {...rest} />
  )
)`
  & .MuiAccordionSummary-content {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 12px;
  }

  & .MuiAccordionSummary-root.Mui-expanded {
    min-height: 0px;
  }

  & .MuiAccordionSummary-content.Mui-expanded {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 12px;
  }

  & .MuiAccordionSummary-expandIcon {
    padding: 0px;
    margin-top: 20px;
  }

  & .MuiAccordionDetails-root {
    padding-left: 0px;
  }

  @media screen and (min-width: 1920px) {
    padding-top: ${({ trigger, title, hazard }) => {
      if (trigger && hazard !== undefined) {
        if (hazard.permitName !== '' || hazard.isolationRequired) {
          return '2%';
        }
        if (title === 'controlMeasures') {
          return '140px';
        }
        if (title === 'ppe') {
          return '35px';
        }

        return '0px';
      }
    }};
    padding-bottom: ${({ trigger, title, hazard }) => {
      if (trigger && hazard !== undefined) {
        if (hazard.permitName !== '' && hazard.isolationRequired) {
          return '0px';
        }
        if (hazard.permitName !== '' || hazard.isolationRequired) {
          return '2%';
        }
        if (title === 'controlMeasures') {
          return '35px';
        }
        if (title === 'ppe') {
          return '0px';
        }

        return '0px';
      }
    }};
  }

  @media screen and (max-width: 1536px) {
    padding-top: ${({ trigger, title, hazard }) => {
      if (trigger && hazard !== undefined) {
        if (title === 'controlMeasures') {
          if (hazard.permitName !== '' && hazard.isolationRequired) {
            return '2%';
          }
          return '140px';
        }
        if (title === 'ppe') {
          if (hazard.permitName !== '' && hazard.isolationRequired) {
            return '0px';
          }
          return '35px';
        }
      }
      return '0px';
    }};

    padding-bottom: ${({ trigger, title, hazard }) => {
      if (trigger && hazard !== undefined) {
        if (
          title === 'controlMeasures' &&
          !(hazard.permitName !== '' || hazard.isolationRequired) &&
          !(hazard.permitName !== '' && hazard.isolationRequired)
        ) {
          return '35px';
        }
        return '0px';
      }
    }};
  }

  border-bottom: ${({ borderBottom, theme }) =>
    borderBottom ? `2px solid${theme.colors.greyScale['10']}` : 'none'};

  border-top: ${({ borderTop, theme }) =>
    borderTop ? `2px solid${theme.colors.greyScale['10']}` : 'none'};

  background-color: ${({ theme }) => theme.colors.greyScale['3']};

  &::before {
    display: none;
  }
`;

StyledAccordion.defaultProps = {
  controls: false,
};

export const AccordionSummary = styled(ExpansionPanelSummary)`
  padding-left: 0px;
  color: ${(props) => props.theme.colors.primary.darkBlue};
  font-size: 24px;
  line-height: 32px;
`;
export const AccordionDetails = styled(ExpansionPanelDetails)`
  display: block;
  margin-top: 22px;
`;

export const AccordionSubText = styled.div`
  margin-bottom: 30px;
`;

export const StyledExpandIcon = styled(Icon)`
  & path {
    fill: ${(props) => props.theme.colors.greyScale['50']};
  }
`;
export const StyledButton = styled(Button)`
  box-shadow: none;
  background-color: transparent;

  &:hover {
    box-shadow: none;
    background-color: transparent;
  }
`;
