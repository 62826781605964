import React from 'react';
import styled from 'styled-components';
import { Slider as MuiSlider } from '@material-ui/core';
import Label from '../Label/Label';

export const StyledLabel = styled(Label)`
  height: 22px;
  color: #213d58;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 22px;
`;
export const StyledSlider = styled(({ colour, ...rest }) => <MuiSlider {...rest} />)`
  width: 100%;
  padding: 13px 0 !important;

  & .MuiSlider-rail {
    height: 8px;
    width: 100%;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.greyScale['10']};
    box-shadow: inset 0 1px 3px 0 rgba(33, 61, 88, 0.15);
  }

  & .MuiSlider-track {
    height: 8px;
    border-radius: 4px;
    color: ${({ colour, theme }) => {
      switch (colour) {
        case 5:
          return theme.colors.findColor('red');
        case 4:
          return theme.colors.findColor('magenta');
        case 3:
          return theme.colors.findColor('sunrise');
        case 2:
          return theme.colors.findColor('yellow');
        case 1:
          return theme.colors.findColor('leaf');
        case 0:
          return theme.colors.findColor('greyScale_25');
      }
    }};
  }

  & .MuiSlider-thumb {
    height: 20px;
    width: 20px;
    box-shadow: 0 1px 4px 0 rgba(33, 61, 88, 0.4);
    color: ${({ colour, theme }) => {
      switch (colour) {
        case 5:
          return theme.colors.findColor('red');
        case 4:
          return theme.colors.findColor('magenta');
        case 3:
          return theme.colors.findColor('sunrise');
        case 2:
          return theme.colors.findColor('yellow');
        case 1:
          return theme.colors.findColor('leaf');
        case 0:
          return theme.colors.findColor('greyScale_25');
      }
    }};
  }

  & .MuiSlider-markLabel {
    color: ${({ theme }) => theme.colors.greyScale['50']};
    font-family: 'Nunito Sans';
    font-size: 16px;
    height: 26px;
    margin-top: 5px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    top: 26px !important;
  }

  & .MuiSlider-markLabelActive {
    color: ${({ theme }) => theme.colors.greyScale['95']};
    font-family: 'Nunito Sans';
    font-size: 16px;
    height: 26px;
    margin-top: 5px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    top: 26px !important;
  }

  & .MuiSlider-mark {
    color: ${({ theme }) => theme.colors.greyScale['10']};
    height: 8px;
    width: 8px;
    border-radius: 50%;
  }

  & .MuiSlider-markActive {
    background-color: ${({ colour, theme }) => {
      switch (colour) {
        case 5:
          return theme.colors.findColor('red');
        case 4:
          return theme.colors.findColor('magenta');
        case 3:
          return theme.colors.findColor('sunrise');
        case 2:
          return theme.colors.findColor('yellow');
        case 1:
          return theme.colors.findColor('leaf');
        case 0:
          return theme.colors.findColor('greyScale_25');
      }
    }};
  }
`;
