import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChatWindow from './ChatWindow';
import { ReactComponent as LauncherIcon } from './logo-no-bg.svg';
import LauncherIconActive from './close-icon.png';

const MessageCount = (props) => {
  if (props.count === 0 || props.isOpen === true) {
    return null;
  }
  return <div className={'sc-new-messages-count'}>{props.count}</div>;
};

class ChatDialog extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }

  static propTypes = {
    onMessageWasReceived: PropTypes.func,
    onMessageWasSent: PropTypes.func,
    newMessagesCount: PropTypes.number,
    isOpen: PropTypes.bool,
    handleClick: PropTypes.func,
    mute: PropTypes.bool,
    showEmoji: PropTypes.bool,
  };

  static defaultProps = {
    newMessagesCount: 0,
    showEmoji: true,
  };

  handleClick() {
    if (this.props.handleClick) {
      this.props.handleClick();
    }

    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const isOpen = this.props.hasOwnProperty('isOpen') ? this.props.isOpen : this.state.isOpen;
    const classList = ['sc-launcher', isOpen ? 'opened' : ''];
    const messages = this.props.data.getJobSafetyAnalysis.summaryComments[this.props.name];
    const unresolvedCount = messages
      ? messages.filter((message) => message.resolved === false).length
      : 0;
    return (
      <div id="sc-launcher">
        <div className={classList.join(' ')} onClick={this.handleClick.bind(this)}>
          <MessageCount count={unresolvedCount} isOpen={isOpen} />
          {isOpen ? (
            <img src={LauncherIconActive} className="sc-open-icon" />
          ) : (
            <LauncherIcon className="sc-closed-icon" />
          )}
        </div>
        <ChatWindow
          onClickResolve={this.props.onClickResolve}
          onMessageDeleted={this.props.onMessageDeleted}
          messageList={messages || []}
          onUserInputSubmit={this.props.onMessageWasSent}
          onFilesSelected={this.props.onFilesSelected}
          agentProfile={this.props.agentProfile}
          isOpen={isOpen}
          onClose={this.handleClick.bind(this)}
          showEmoji={this.props.showEmoji}
          section={this.props.section}
          data={this.props.data}
        />
      </div>
    );
  }
}

export default ChatDialog;
