import React, { useState } from 'react';
import { Form } from 'formik';
import omit from 'lodash/omit';
import cleaner from 'deep-cleaner';
import JsaForm from '../JsaForm/JsaForm';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import ModalTitle from '../../components/Modal/ModalTitle';
import ModalContent from '../../components/Modal/ModalContent';
import ModalContentText from '../../components/Modal/ModalContentText';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import TextField from '../../components/TextField/TextField';
import ModalActions from '../../components/Modal/ModalActions';
import statusAttributes from '../../constants/statusAttributes';
import { useAuth } from 'contexts/AuthProvider';
import AsyncButton from 'components/Button/AsyncButton';

const CreateJsaModal = function ({ isModalOpen, handleModalClose, jsaValues }: any) {
  const {
    user: { email },
  } = useAuth();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { jsaApplicationTitle } = jsaValues;
  const jsaWrittenByEmail =
    jsaValues.workDetails && jsaValues.workDetails.jsaWrittenBy
      ? jsaValues.workDetails.jsaWrittenBy.email
      : undefined;
  const shouldCleanWrittenBy =
    email && jsaWrittenByEmail && email.toLowerCase() !== jsaWrittenByEmail.toLowerCase();

  const jsaValuesCleaned = cleaner(jsaValues, [
    'initialRisk',
    'residualRisk',
    'isolations',
    'permits',
    'supportDocuments',
    'summaryComments',
    'plannedWorkDate',
    'teamContacts',
    'collaborators',
    shouldCleanWrittenBy ? 'jsaWrittenBy' : '',
  ]);

  const initialValues = {
    ...omit(jsaValuesCleaned, [
      'id',
      'jsaId',
      'workOrderId',
      'assignedTo',
      'assignedToDate',
      'reviewedBy',
      'reviewedByDate',
      'createdBy',
      'confirmedBy',
      'confirmedDate',
      'submittedBy',
      'submittedDate',
    ]),
    jsaApplicationTitle: `${jsaApplicationTitle} (Copy)`,
    version: 1,
    status: statusAttributes.draft.id,
  };

  return (
    <Modal open={isModalOpen}>
      <ModalTitle onClose={handleModalClose}>Duplicate JSA application</ModalTitle>
      <ModalContent>
        <JsaForm
          shouldAutosave={false}
          mutationName="CreateJsa"
          onSuccess={handleModalClose}
          initialValues={initialValues}
          onSubmitting={() => setIsSubmitted(true)}
        >
          <Form>
            <ModalContentText>
              Give your JSA application a title so you can search for it later. Enter your Work Order
              number or Access Code so we can fill in some application details for you.
            </ModalContentText>
            <GridContainer>
              <GridItem xs={12}>
                <TextField
                  id="jsaApplicationTitle"
                  name="jsaApplicationTitle"
                  label="JSA Application Title"
                  fullWidth
                />
              </GridItem>
              <GridItem xs={12}>
                <TextField id="workOrderId" name="workOrderId" label="Work Order ID" fullWidth />
              </GridItem>
            </GridContainer>
            <ModalActions>
              <GridContainer>
                <GridItem xs={6}>
                  <Button preset="secondary" onClick={handleModalClose} themeColour="grey" fullWidth>
                    Cancel
                  </Button>
                </GridItem>
                <GridItem xs={6}>
                  <AsyncButton
                    id="new_jsa_create"
                    actionInProgress={isSubmitted}
                    text="CREATE NEW JSA"
                  />
                </GridItem>
              </GridContainer>
            </ModalActions>
          </Form>
        </JsaForm>
      </ModalContent>
    </Modal>
  );
};

export default CreateJsaModal;
