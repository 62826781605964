import React, { memo, ReactNode, useEffect, useState } from 'react';
import { useFormikContext, getIn } from 'formik';
import { useSubmitValidation } from '../../contexts/useSubmitValidation';
import { ErrorMessage, ErrorMessageWrapper } from '../TextField/styles';
import Icon from '../Icon/Icon';

type Props = {
  name: string;
  children: ReactNode;
  validationLabel: string;
  isPICOfWork?: boolean;
};

const FieldValidationWrapper = memo(({ name, children, validationLabel, isPICOfWork }: Props) => {
  const [isErrored, setIsErrored] = useState(false);
  const { values } = useFormikContext();
  const { hasUserAttemptedSubmission } = useSubmitValidation();
  const currentValue = getIn(values, name);

  useEffect(() => {
    if (hasUserAttemptedSubmission && !currentValue) {
      setIsErrored(true);
    } else if (hasUserAttemptedSubmission && currentValue) {
      setIsErrored(false);
    }
  }, [hasUserAttemptedSubmission, currentValue]);

  return (
    <ErrorMessageWrapper isPICOfWork isErrored={isErrored}>
      {children}
      {isErrored && (
        <ErrorMessage>
          <Icon name="ValidationErrorRed" />
          {isPICOfWork ? validationLabel : `Missing ${validationLabel || 'field value'}`}
        </ErrorMessage>
      )}
    </ErrorMessageWrapper>
  );
});

FieldValidationWrapper.displayName = 'FieldValidationWrapper';

export default FieldValidationWrapper;
