import styled from 'styled-components';
import React from 'react';
import Typography from '../Typography/Typography';

export const FallbackText = styled(Typography)`
  && {
    color: #ad3769;
    font-size: 14px;
    font-style: italic;
    line-height: 24px;

    @media print {
      padding-top: 3px;
      font-size: 12px !important;
    }
  }
`;
export const EmailText = styled(({ secondLine, ...rest }) => <Typography {...rest} />)`
  && {
    @media print {
      font-size: 12px !important;
    }
    font-size: 14px;
    line-height: 18px;
  }
`;
