import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import WorkStepMenu from './WorkStepMenu';
import {
  StyledIcon,
  StyledInputAdornmentButton,
  InputAdornment,
  DoneAdornment,
  EmptyWrapper,
} from './style';

const EndAdornment = function ({
  isFirst,
  isEditing,
  isEditingExisting,
  isErrored,
  setIsExpanded,
  isMenuOpen,
  handleClick,
  anchorEl,
  editStep,
  handleClose,
  deleteStep,
  isExpanded,
  value,
}: any) {
  if (isEditing) {
    return (
      <DoneAdornment position="end">
        <StyledInputAdornmentButton
          disabled={!value}
          onMouseDown={() => {
            if (!isEditingExisting) {
              setIsExpanded(true);
            }
          }}
        >
          DONE
        </StyledInputAdornmentButton>
      </DoneAdornment>
    );
  }
  if (value) {
    return (
      <InputAdornment position="end">
        <WorkStepMenu
          {...{
            isFirst,
            isErrored,
            isMenuOpen,
            handleClick,
            anchorEl,
            editStep,
            handleClose,
            deleteStep,
          }}
        />
        <IconButton
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          <StyledIcon name={isExpanded ? 'ArrowUp' : 'ArrowDown'} preset="tertiary" />
        </IconButton>
      </InputAdornment>
    );
  }
  if (!value) {
    return (
      <EmptyWrapper>
        <InputAdornment className="empty" position="end">
          <WorkStepMenu
            {...{
              isFirst,
              isMenuOpen,
              isErrored,
              handleClick,
              anchorEl,
              editStep,
              handleClose,
              deleteStep,
            }}
          />
        </InputAdornment>
      </EmptyWrapper>
    );
  }

  return null;
};

export default EndAdornment;
