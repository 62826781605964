import React from 'react';
import styled from 'styled-components';
import MuiTypography, { TypographyProps } from '@material-ui/core/Typography';

const variantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'h6',
  subtitle2: 'h6',
  body1: 'div',
  body2: 'div',
  inherit: 'div',
};

// We need to disbale the next line to allow the component prop to be omitted from the spread operator
// This allow us to use a div as the root component for all of our Typography instances
// eslint-disable-next-line
const StyledHeading = styled(({ ...rest }) => (
  <MuiTypography variantMapping={variantMapping} {...rest} />
))`
  font-family: 'Nunito Sans';
  color: ${({ theme }) => theme.colors.findColor('darkBlue')};

  &.MuiTypography-h1 {
    font-size: 40px;
    font-weight: 300;
    line-height: 54px;
  }

  &.MuiTypography-h2 {
    font-size: 32px;
    font-weight: 300;
    line-height: 43px;
  }
  &.MuiTypography-h3 {
    font-size: 24px;
    line-height: 32px;

    @media print {
      font-size: 20px !important;
    }
  }

  &.MuiTypography-h4 {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
  }

  &.MuiTypography-h5 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 22px;
  }

  &.MuiTypography-subtitle1 {
    font-size: 20px;
    line-height: 28px;
  }

  &.MuiTypography-subtitle2 {
    font-size: 20px;
    line-height: 29px;
    color: ${({ theme }) => theme.colors.greyScale['95']};
  }

  &.MuiTypography-body1 {
    color: ${({ theme }) => theme.colors.findColor('veryDarkCyan')};
    font-size: 16px;
    line-height: 26px;
    ${({ style }) => style};
    @media print {
      font-size: ${({ controls, number }) =>
        controls ? '12px !important' : number ? '20px' : '14px !important'};
      line-height: ${({ controls }) => (controls ? '18px !important' : '22px !important')};
    }
  }
  &.MuiTypography-paragraph {
    margin-bottom: 20px !important;
  }
  &.MuiTypography-body2 {
    color: ${({ theme }) => theme.colors.findColor('veryDarkCyan')};
    font-size: 14px;
    line-height: 24px;
  }
  &.MuiTypography-body3 {
    color: ${({ theme }) => theme.colors.findColor('greyScale_65')};
    font-size: 14px;
    line-height: 26px;
  }

  &.MuiTypography-caption {
    color: ${({ theme }) => theme.colors.findColor('greyScale_40')};
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
  }
`;

/*
 * Extending TypographyProps but limiting variant to variants we have styled.
 */
export interface Props extends TypographyProps {
  variant:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'subtitle1'
    | 'body1'
    | 'inherit'
    | 'subtitle2'
    | 'caption'
    | 'body2';
  controls?: boolean;
  number?: boolean;
}

const Typography = (props: Props) => {
  return <StyledHeading {...props} />;
};

export default Typography;
