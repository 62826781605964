import React from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'formik';
import JsaForm from '../JsaForm/JsaForm';
import setInitialValues from '../JsaForm/setInitialValues';
import { useJsaQuery, useClientState } from '../../hooks';
import HazardOverlay from '../../components/HazardOverlay/HazardOverlay';
import ControlsOverlay from '../../components/ControlsOverlay/ControlsOverlay';
import RiskSliderModal from '../../components/RiskSliderModal/RiskSliderModal';
import {
  getIsControlsOverlayOpen,
  getIsHazardOverlayOpen,
  getIsInitialRiskOverlayOpen,
  getIsResidualRiskOverlayOpen,
} from '../../redux/workStepsSlice';
import WorkStepList from './WorkStepList';
import ContentLoader from '../../components/ContentLoader/ContentLoader';

const HazardsAndControlsForm = function () {
  const { data, loading } = useJsaQuery();
  const initialValues = setInitialValues(data);
  const isControlsOverlayOpen = useSelector(getIsControlsOverlayOpen);
  const isHazardOverlayOpen = useSelector(getIsHazardOverlayOpen);
  const isInitialRiskOverlayOpen = useSelector(getIsInitialRiskOverlayOpen);
  const isResidualRiskOverlayOpen = useSelector(getIsResidualRiskOverlayOpen);

  const {
    setIsControlsOverlayOpen,
    setIsHazardOverlayOpen,
    setIsInitialRiskOverlayOpen,
    setIsResidualRiskOverlayOpen,
  } = useClientState();

  if (!data) {
    return <ContentLoader />;
  }

  return (
    <>
      <JsaForm initialValues={initialValues} isLoading={loading}>
        <Form>
          <WorkStepList />
        </Form>
      </JsaForm>
      <HazardOverlay open={isHazardOverlayOpen} onClose={() => setIsHazardOverlayOpen(false)} />
      <ControlsOverlay open={isControlsOverlayOpen} onClose={() => setIsControlsOverlayOpen(false)} />
      <RiskSliderModal
        riskModalName="initialRisk"
        open={isInitialRiskOverlayOpen}
        data={data}
        onClose={() => setIsInitialRiskOverlayOpen(false)}
      />
      <RiskSliderModal
        riskModalName="residualRisk"
        open={isResidualRiskOverlayOpen}
        data={data}
        onClose={() => setIsResidualRiskOverlayOpen(false)}
      />
    </>
  );
};

export default HazardsAndControlsForm;
