import React, { ReactNode, createContext } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import deepReplaceInObject from 'deep-replace-in-object';
import cleaner from 'deep-cleaner';
import { Mutations, Queries } from '../../api-queries';
import { useClientState } from '../../hooks';
import { deepFindByKey } from '../../utils';
import AutoSave from '../FormikAutosave/FormikAutosave';
import { useAuth } from 'contexts/AuthProvider';

export const JsaFormContext = createContext({});

const CreateJsaModalSchema = Yup.object().shape({
  jsaApplicationTitle: Yup.string().required('Required'),
  workOrderId: Yup.number(),
});

const constructUpdateFormData = (values: any) => {
  const convertedValue = deepReplaceInObject('', null, values);
  const removeIsNew = cleaner(convertedValue, '__isNew__');
  const removeTypename = cleaner(removeIsNew, '__typename');
  return {
    input: removeTypename,
  };
};

const constructNewFormData = (
  { workOrderId = null, ...formValues }: any,
  firstName: string,
  lastName: string,
  email: string
) => {
  const timeStamp = new Date().toISOString();

  const inputValues = {
    ...formValues,
    workOrderId,
    createdDate: timeStamp,
    updatedDate: timeStamp,
    createdBy: {
      firstName,
      lastName,
      email,
    },
  };

  const replacedValue = deepReplaceInObject('', null, inputValues);
  const cleanValues = cleaner(replacedValue, ['__typename', '__isNew__', 'work_order_id']);

  return {
    input: cleanValues,
  };
};

interface Props {
  children: ReactNode;
  shouldAutosave?: boolean;
  mutationName?: 'CreateJsa' | 'UpdateJsa';
  shouldRefetchQueries?: boolean;
  onSuccess?: () => any;
  initialValues: any;
  onSubmitting?: () => any;
  isLoading?: boolean;
}

const JsaForm = function ({
  children,
  shouldAutosave,
  mutationName,
  onSuccess,
  onSubmitting,
  initialValues,
  isLoading,
}: Props) {
  const {
    user: { firstName, lastName, email },
  } = useAuth();
  const mutationQuery = mutationName === 'CreateJsa' ? Mutations.CreateJsa : Mutations.UpdateJsa;
  const constructFormData =
    mutationName === 'CreateJsa' ? constructNewFormData : constructUpdateFormData;

  const { jsa: jsaId }: any = useParams();
  const navigate = useNavigate();

  const [mutation] = useMutation(mutationQuery, {
    onCompleted: ({ createJobSafetyAnalysis }) => {
      if (createJobSafetyAnalysis && createJobSafetyAnalysis.id) {
        navigate(`/jsa/${createJobSafetyAnalysis.id}/work-details`);
      }
    },
    refetchQueries: () => {
      if (mutationName === 'CreateJsa') {
        return [
          {
            query: Queries.GET_DASHBOARD_LIST,
            variables: {
              limit: 1000,
            },
          },
        ];
      }
      return [
        {
          query: Queries.GET_JSA_BY_ID,
          variables: {
            id: jsaId,
          },
        },
      ];
    },
  });

  const { setIsSaving } = useClientState();

  const submitData = (values: any, removekeyPairValues?: any) => {
    onSubmitting && onSubmitting();
    const formData = constructFormData(values, firstName, lastName, email);
    setIsSaving(true);

    mutation({
      variables: formData,
    })
      .then(() => {
        setIsSaving(false);
        onSuccess && onSuccess();
        if (removekeyPairValues) {
          removekeyPairValues();
        }
      })
      .catch(() => {
        setIsSaving(false);
      });
  };

  return (
    <Formik
      validationSchema={mutationName === 'CreateJsa' ? CreateJsaModalSchema : null}
      initialValues={initialValues}
      onSubmit={(values) => {
        submitData(values);
      }}
    >
      {(formikProps) => {
        const { values, setValues } = formikProps;

        const contextValue = formikProps;

        const removekeyPairValues = () => {
          const hasLocalUri = deepFindByKey(values, 'localUri');
          const hasMimeType = deepFindByKey(values, 'mimeType');
          const hasTypeName = deepFindByKey(values, '__typename');

          if (hasLocalUri || hasMimeType || hasTypeName) {
            const cleansedValue = cleaner(values, ['localUri', 'mimeType', '__typename']);
            setValues(cleansedValue);
          }
        };

        return (
          <>
            <JsaFormContext.Provider value={contextValue}>{children}</JsaFormContext.Provider>
            {shouldAutosave && !isLoading && (
              <AutoSave
                formik={{ values: '', status: '' }}
                onSave={() => {
                  submitData(values, removekeyPairValues);
                }}
              />
            )}
          </>
        );
      }}
    </Formik>
  );
};

JsaForm.defaultProps = {
  shouldAutosave: true,
  mutationName: 'UpdateJsa',
  shouldRefetchQueries: false,
  isLoading: false,
};

export default JsaForm;
