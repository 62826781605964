import React, { useState } from 'react';
import { get } from 'lodash';
import IconCardInput from './IconCardInput';
import IconCardButton from '../IconCardButton/IconCardButton';

// @ts-check
const AddHazardCard = function ({ newItem }: any) {
  const [isInputCardVisible, setIsInputCardVisibile] = useState(false);

  const handleOnDone = (name: string, value: string) => {
    if (get(newItem, 'button.onClick')) {
      newItem.button.onClick(name, value);
    }
    setIsInputCardVisibile(false);
  };

  if (isInputCardVisible && newItem.isVisible) {
    return (
      <IconCardInput
        name={get(newItem, 'input.name')}
        placeholder={get(newItem, 'input.placeholder')}
        onDone={handleOnDone}
        onClose={() => setIsInputCardVisibile(false)}
      />
    );
  }
  return (
    <IconCardButton
      name="AddLight"
      label={get(newItem, 'button.label')}
      onClick={() => setIsInputCardVisibile(true)}
    />
  );
};

export default AddHazardCard;
