import React from 'react';
import styled from 'styled-components';
import CheckBox from '../CheckBox/CheckBox';

const StyledDiv = styled.div`
  background-color: ${(props) => props.theme.colors.complementary.white};
  padding-left: 1px;
  margin-top: 15px;
  height: 50px;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0 rgba(33, 61, 88, 0.4);
  .MuiCheckbox-root {
    position: absolute;
    left: -10px;
  }
  .MuiFormControlLabel-root {
    width: 100%;
    height: 100%;
  }
`;

const CheckedStyledDiv = styled.div`
  padding-left: 1px;
  margin-top: 15px;
  height: 46px;
  border: 2px solid #40b3db;
  border-radius: 5px;
  background-color: #cfedf1;
  box-shadow: 0 1px 5px 0 rgba(33, 61, 88, 0.4);
  .MuiCheckbox-root {
    position: absolute;
    left: -10px;
  }
  .MuiFormControlLabel-root {
    width: 100%;
    height: 100%;
  }
`;

interface Props {
  name: string;
  checked?: boolean;
  label: string;
}

const CheckBoxBorder: React.FC<Props> = function ({ checked, name, label }) {
  return checked ? (
    <CheckedStyledDiv>
      <CheckBox checked={checked} name={name} label={label} />
    </CheckedStyledDiv>
  ) : (
    <StyledDiv>
      <CheckBox checked={checked} name={name} label={label} />
    </StyledDiv>
  );
};

export default CheckBoxBorder;
