import React from 'react';
import { StyledDrawer } from './styles';

export default function TemporaryDrawer(props: any) {
  return (
    <div>
      <StyledDrawer anchor="right" open={props.open}>
        {props.children}
      </StyledDrawer>
    </div>
  );
}
