import React from 'react';
import styled from 'styled-components';
import Button from './Button';
import LinkButton from './LinkButton';

export const StyledButton = styled(Button).attrs(() => ({
  preset: 'tertiary',
  themeColour: 'blue',
}))`
  & .MuiButton-label {
    & svg {
      margin-right: 8px;
    }
  }
`;

const GoToTopButton = function ({ nodeRef, ...rest }: any) {
  const scrollToRef = (nodeRef: Element) => {
    if (nodeRef) {
      nodeRef.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  return <LinkButton text="Go to top" icon="GoTop" onClick={() => scrollToRef(nodeRef)} {...rest} />;
};

export default GoToTopButton;
