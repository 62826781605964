/*
 * Idea taken from here: https://stackoverflow.com/questions/42674473/get-all-keys-of-a-deep-object-in-javascript
 */
import map from 'lodash/map';

function getDeepKeys(obj: any) {
  let keys: any[] = [];

  const getKeyString = (key: any, subkey: any) => `${key}.${subkey}`;

  map(obj, (value, key) => {
    keys.push(key);

    if (typeof obj[key] === 'object') {
      const subkeys = getDeepKeys(obj[key]);

      keys = keys.concat(subkeys.map((subkey: any) => getKeyString(key, subkey)));
    }
  });

  return keys;
}

function deepFindByKey(theObject: any, keyToFind: string) {
  const keys = getDeepKeys(theObject);

  return keys.find((key) => key.includes(keyToFind));
}

export default deepFindByKey;
