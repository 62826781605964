import React from 'react';
import styled from 'styled-components';

export const DragItemWrapper = styled(({ isDragging, ...rest }) => <div {...rest} />)`
  ${({ isDragging }) =>
    isDragging &&
    `
  background: #e1e5e5;
  box-shadow: inset 0 1px 5px 0 rgba(33, 61, 88, 0.35);
  border-radius: 5px;
`}
`;
