import React from 'react';
import { get } from 'lodash';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Modal from '../Modal/Modal';
import ModalTitle from '../Modal/ModalTitle';
import ModalContent from '../Modal/ModalContent';
import ModalContentText from '../Modal/ModalContentText';
import ModalActions from '../Modal/ModalActions';
import GridContainer from '../Grid/GridContainer';
import { GridItem, Button, ContentLoader } from '..';
import useClientState from '../../hooks/useClientState';
import { Mutations, Queries } from '../../api-queries';
import { useSendGrid } from 'hooks';
import { useAuth } from 'contexts/AuthProvider';

const AssignToConflictModal = function ({ isOpen, data }: any) {
  const { setAssignToConflictModalOpen } = useClientState();
  const { sendStatusChangeEmail } = useSendGrid();
  const firstNameForm = get(data, 'getJobSafetyAnalysis.assignedTo.firstName', '');
  const lastNameForm = get(data, 'getJobSafetyAnalysis.assignedTo.lastName', '');
  const {
    user: { firstName, lastName, email },
  } = useAuth();
  const mutationQuery = Mutations.UpdateJsa;
  const params: { jsa?: string } = useParams();

  const [updateStatusMutation] = useMutation(mutationQuery, {
    onCompleted(data: any) {
      sendStatusChangeEmail(data);
    },
    refetchQueries: () => [
      {
        query: Queries.GET_JSA_BY_ID,
        variables: {
          id: params.jsa,
        },
      },
    ],
  });

  const pushUpdate = (_input: any) => {
    updateStatusMutation({
      variables: {
        input: _input,
      },
    });
  };

  const handleClose = () => {
    setAssignToConflictModalOpen(false);
  };

  function onConfirm() {
    const date = new Date().toISOString();
    const input = {
      id: params.jsa,
      assignedTo: {
        firstName,
        lastName,
        email,
      },
      assignedToDate: date,
    };

    pushUpdate(input);
    setAssignToConflictModalOpen(false);
  }

  if (!data) {
    return <ContentLoader />;
  }

  return (
    <Modal open={isOpen}>
      <ModalTitle onClose={handleClose}>Please note</ModalTitle>
      <ModalContent>
        <ModalContentText>
          This JSA is currently assigned to{' '}
          <strong>
            {firstNameForm} {lastNameForm}
          </strong>
          . Do you wish to replace this person?
        </ModalContentText>
        <ModalActions>
          <GridContainer>
            <GridItem xs={6}>
              <Button preset="secondary" onClick={handleClose} themeColour="grey" fullWidth>
                Cancel
              </Button>
            </GridItem>
            <GridItem xs={6}>
              <Button preset="primary" themeColour="red" fullWidth onClick={onConfirm}>
                Yes
              </Button>
            </GridItem>
          </GridContainer>
        </ModalActions>
      </ModalContent>
    </Modal>
  );
};

export default AssignToConflictModal;
