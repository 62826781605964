import React from 'react';
import { SummarySidebar, StyledTypography, TitleIdWrapper } from './style';
import SummaryContentBlock from './SummaryContentBlock';
import {
  getJsaApplicationTitle,
  getWorkOrderId,
  getPersonInChargeName,
  getPersonInChargeNumber,
  getPersonInChargeEmail,
  // getAssignedToName,
  // getAssignedToDate,
  getReviewedByName,
  getReviewedByDate,
  getConfirmedDate,
  getConfirmedByName,
  getJsaId,
} from './summaryWorkDetailsSelectors';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Typography from '../../components/Typography/Typography';
import Fallback from '../../components/Fallback/Fallback';

const SummaryWorkDetailsSidebar = function ({ data }: any) {
  const jsaApplicationTitle = getJsaApplicationTitle(data);
  const workOrderId = getWorkOrderId(data);
  const personInChargeName = getPersonInChargeName(data as never);
  const personInChargeNumber = getPersonInChargeNumber(data as never);
  const personInChargeEmail = getPersonInChargeEmail(data as never);
  const reviewedByName = getReviewedByName(data as never);
  const reviewedByDate = getReviewedByDate(data);
  const confirmedByDate = getConfirmedDate(data);
  const confirmedByName = getConfirmedByName(data as never);
  const jsaId = getJsaId(data);

  return (
    <SummarySidebar id="sidebar" className="screen-landscape-only" workDetails>
      <GridContainer>
        <GridItem xs={12}>
          <StyledTypography variant="h4">{jsaApplicationTitle}</StyledTypography>
          <TitleIdWrapper>
            <Typography variant="body1">{jsaId}</Typography>
            <Typography variant="body1">Work Order #{workOrderId}</Typography>
          </TitleIdWrapper>
        </GridItem>
        <GridItem xs={12}>
          <SummaryContentBlock title="Person in charge of the work (PICW)">
            <Fallback value={personInChargeName} message="Name not completed yet" />
            <Fallback value={personInChargeNumber} message="Number not completed yet" />
            <Fallback email value={personInChargeEmail} message="Email not completed yet" />
          </SummaryContentBlock>
        </GridItem>
        <GridItem xs={12}>
          <SummaryContentBlock title="Reviewed by">
            <Fallback value={reviewedByName} message="Not assigned yet" />
            <Fallback value={reviewedByDate} message="Not assigned yet" />
          </SummaryContentBlock>
        </GridItem>
        <GridItem xs={12}>
          <SummaryContentBlock title="Confirmed by">
            <Fallback value={confirmedByName} message="Not confirmed yet" />
            <Fallback value={confirmedByDate} message="Not confirmed yet" />
          </SummaryContentBlock>
        </GridItem>
      </GridContainer>
    </SummarySidebar>
  );
};

export default SummaryWorkDetailsSidebar;
