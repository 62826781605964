import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'formik';
import { get } from 'lodash';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import parse from 'html-react-parser';
import SummaryWorkDetails from './SummaryWorkDetails';
import SummaryEmergencyDetails from './SummaryEmergencyDetails';
import SummaryHazardsOnThisJob from './SummaryHazardsOnThisJob';
import SummaryHazardsAndControls from './SummaryHazardsAndControls';
import SummaryWorkpack from './SummaryWorkpack';
import { SummaryWrapper, StyledSummaryAlertWrapper } from './style';
import SummaryPpeThatYouNeed from './SummaryPpeThatYouNeed';
import AssignToConflictModal from '../../components/AssignToConflictModal/AssignToConflictModal';
import {
  getAssignToConflictModalOpen,
  getSendForConfirmModalOpen,
  getConfirmModalOpen,
  getIsValidationModalOpen,
} from '../../redux/activeUserSlice';
import setInitialValues from '../JsaForm/setInitialValues';
import JsaForm from '../JsaForm/JsaForm';
import Alert from '../../components/Alert/Alert';
import { StyledTypography } from '../../components/Alert/styles';
import SendForConfirmModal from '../../components/SendForConfirmModal/SendforConfirmModal';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import ValidationModal from '../../components/ValidationModal/ValidationModal';

const Summary = memo(({ data }: any) => {
  const status = get(data, 'getJobSafetyAnalysis.status', '');
  const displayBanner = !!(
    status === 'review' ||
    status === 'readyreview' ||
    status === 'agreed' ||
    status === 'cancelled' ||
    status === 'waitingconfirmation'
  );
  const initialValues = setInitialValues(data);
  const assignToConflictModalIsOpen = useSelector(getAssignToConflictModalOpen);
  const sendForConfirmModalIsOpen = useSelector(getSendForConfirmModalOpen);
  const isValidationModalOpen = useSelector(getIsValidationModalOpen);
  const confirmModalIsOpen = useSelector(getConfirmModalOpen);

  let iconName: string;
  switch (status) {
    case 'review':
      iconName = 'Review';
      break;
    case 'readyreview':
      iconName = 'ReadyReview';
      break;
    case 'agreed':
      iconName = 'Confirmed';
      break;
    default:
      iconName = 'Review';
      break;
  }

  function getName(fieldName: string) {
    return `${get(data, `getJobSafetyAnalysis.${fieldName}.firstName`)} ${get(
      data,
      `getJobSafetyAnalysis.${fieldName}.lastName`
    )}`;
  }

  function getDate(fieldName: string) {
    const date = get(data, `getJobSafetyAnalysis.${fieldName}`);
    return date ? format(parseISO(date), 'd MMM yyyy, h:mm a') : '';
  }

  function getSummaryText() {
    let textHTML;
    let name;
    let date;
    let email;
    switch (status) {
      case 'review':
        name = getName('assignedTo');
        date = getDate('assignedToDate');
        textHTML = parse(`Under review by <strong>${name}</strong> since ${date}`);
        break;
      case 'readyreview':
        textHTML = parse('Ready for review');
        break;
      case 'waitingconfirmation':
        if (!getDate('toConfirmDate')) {
          name = getName('reviewedBy');
          date = getDate('reviewedByDate');
          textHTML = parse(`Reviewed by <strong>${name}</strong> on ${date}`);
        } else {
          email = get(data, 'getJobSafetyAnalysis.toConfirmEmail');
          textHTML = parse(
            `Sent to <strong>${email}</strong> for confirmation on ${getDate('toConfirmDate')}`
          );
        }
        break;
      case 'agreed':
        name = getName('confirmedBy');
        date = getDate('confirmedDate');
        textHTML = parse(`Confirmed by <strong>${name}</strong> on ${date}`);
        break;
      case 'cancelled':
        name = getName('cancelledBy');
        date = getDate('cancelledDate');
        textHTML = parse(`JSA Cancelled by <strong>${name}</strong> on ${date}`);
        break;
    }

    return textHTML;
  }

  return (
    <JsaForm initialValues={initialValues}>
      <Form>
        {displayBanner === true && (
          <StyledSummaryAlertWrapper className="screen-only">
            <Alert status={status} iconName={iconName}>
              <StyledTypography variant="body1" status={status}>
                {getSummaryText()}
              </StyledTypography>
            </Alert>
          </StyledSummaryAlertWrapper>
        )}
        <SummaryWrapper>
          <SummaryWorkDetails data={data} />
          <SummaryHazardsOnThisJob data={data} />
          <SummaryPpeThatYouNeed data={data} />
          <SummaryEmergencyDetails data={data} />
          <SummaryHazardsAndControls data={data} />
          <SummaryWorkpack data={data} />
          <AssignToConflictModal isOpen={assignToConflictModalIsOpen} data={data} />
          <SendForConfirmModal isOpen={sendForConfirmModalIsOpen} data={data} />
          <ConfirmModal isOpen={confirmModalIsOpen} data={data} />
          <ValidationModal isOpen={isValidationModalOpen} />
        </SummaryWrapper>
      </Form>
    </JsaForm>
  );
});

Summary.displayName = 'Summary';

export default Summary;
