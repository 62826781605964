import { createTheme } from '@material-ui/core/styles';

const breakpointValues = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1920,
};

const theme = createTheme({
  breakpoints: { values: breakpointValues },
  spacing: 5,
  typography: {
    fontFamily: 'Nunito Sans, Roboto, sans-serif',
  },
  overrides: {
    MuiExpansionPanel: {
      root: {
        marginTop: '0px !important',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '42px',
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginBottom: '0px',
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: '#40b3db',
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: 'transparent',
      },
    },
    MuiTab: {
      root: {
        height: '4px',
      },
    },
    MuiPopover: {
      paper: {
        marginTop: '15px',
      },
    },
    MuiTypography: {
      body2: {
        color: 'rgba(0,0,0,0.87)',
        fontSize: '14px',
        lineHeight: '19px',
        textAlign: 'left',
      },
      body1: {
        color: '#0A0D0D',
        fontSize: '18px',
        letterSpacing: '0.2px',
        lineHeight: '24px',
      },
    },
    MuiChip: {
      root: {
        height: '20px',
      },
    },
    MuiPaper: {
      elevation24: {
        boxShadow: 'none',
      },
      elevation1: {
        boxShadow: 'none',
      },
    },
    MuiStepConnector: {
      root: {
        display: 'none',
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiMenuItem: {
      root: {
        '&$selected': {
          backgroundColor: '#cfedf1',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: '#40B3DB',
        },
      },
    },
  },
});

export default theme;
