import React from 'react';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import ModalTitle from '../Modal/ModalTitle';
import ModalContent from '../Modal/ModalContent';
import Typography from '../Typography/Typography';
import GridItem from '../Grid/GridItem';
import Icon from '../Icon/Icon';
import {
  StyledMatrixGrid,
  StyledThermoGrid,
  StyledDescription,
  StyledHowSection,
  StyledIconDiv,
  StyledModalActions,
} from './styles';
import { ReactComponent as RiskLevelThermometer } from './risk-level-thermometer.svg';

const RiskMatrixModal = function ({ open, onClose }: any) {
  return (
    <Modal open={open} size="1031px" height="754px">
      <ModalTitle styles="padding-top: 50px;" onClose={onClose}>
        How to assess your risk level
      </ModalTitle>

      <ModalContent overflowy>
        <StyledDescription variant="body1">
          The purpose of assessing a risk is to understand the possible harm that a hazard may cause and
          the chance of that harm occurring. This helps you decide what types of controls are needed to
          keep people safe. Follow these steps to assess your risk level properly:{' '}
        </StyledDescription>

        <StyledHowSection>
          <StyledIconDiv>
            <Icon name="Droplet1" />
          </StyledIconDiv>
          <GridItem>
            Consider the type of work you are doing and the work environment. E.g. working at height, the
            higher you go, the greater the risk.
          </GridItem>
        </StyledHowSection>

        <StyledHowSection>
          <StyledIconDiv>
            <Icon name="Droplet2" />
          </StyledIconDiv>
          <GridItem>
            Imagine someone was doing the work with no safety controls in place. What is the chance
            something might happen, and how bad could their injury be? Could more than one person be
            hurt?
          </GridItem>
        </StyledHowSection>

        <StyledHowSection>
          <StyledIconDiv>
            <Icon name="Droplet3" />
          </StyledIconDiv>
          <GridItem>
            Rate what you think the risk is by moving the sliders up or down the risk sliders.
          </GridItem>
        </StyledHowSection>

        <StyledHowSection style={{ marginBottom: 40 }}>
          <StyledIconDiv>
            <Icon name="Droplet4" />
          </StyledIconDiv>
          <GridItem>Set the risk. This will give a score of 1 to 5.</GridItem>
        </StyledHowSection>

        <StyledMatrixGrid xs={12}>
          <GridItem xs={6}>
            <Typography variant="h5">LIKELIHOOD</Typography>
          </GridItem>
          <GridItem xs={6}>
            <Typography variant="h5">POTENTIAL SEVERITY</Typography>
          </GridItem>
        </StyledMatrixGrid>

        <StyledMatrixGrid xs={12}>
          <GridItem xs={6}>
            <Typography variant="h4">Almost Certain</Typography>
            <Typography variant="body1">Commonly occurs</Typography>
          </GridItem>
          <GridItem xs={6}>
            <Typography variant="h4">Minimal</Typography>
            <Typography variant="body1">Non-injury or First Aid Injury (FAI)</Typography>
          </GridItem>
        </StyledMatrixGrid>

        <StyledMatrixGrid xs={12}>
          <GridItem xs={6}>
            <Typography variant="h4">Likely</Typography>
            <Typography variant="body1">Could easily happen</Typography>
          </GridItem>
          <GridItem xs={6}>
            <Typography variant="h4">Minor</Typography>
            <Typography variant="body1">Medical treatment injury</Typography>
          </GridItem>
        </StyledMatrixGrid>

        <StyledMatrixGrid xs={12}>
          <GridItem xs={6}>
            <Typography variant="h4">Possible</Typography>
            <Typography variant="body1">Could happen or has been known to happen</Typography>
          </GridItem>
          <GridItem xs={6}>
            <Typography variant="h4">Moderate</Typography>
            <Typography variant="body1">Medical treatment injury with Lost of Time (LTI)</Typography>
          </GridItem>
        </StyledMatrixGrid>

        <StyledMatrixGrid xs={12}>
          <GridItem xs={6}>
            <Typography variant="h4">Unlikely</Typography>
            <Typography variant="body1">Hasn&rsquo;t happened yet but could happen</Typography>
          </GridItem>
          <GridItem xs={6}>
            <Typography variant="h4">Major</Typography>
            <Typography variant="body1">Injury requiring hospitalisation/notifiable event</Typography>
          </GridItem>
        </StyledMatrixGrid>

        <StyledMatrixGrid xs={12}>
          <GridItem xs={6}>
            <Typography variant="h4">Rare</Typography>
            <Typography variant="body1">
              Very unlikely but could happen in exceptional circumstances
            </Typography>
          </GridItem>
          <GridItem xs={6}>
            <Typography variant="h4">Catastropic</Typography>
            <Typography variant="body1">Fatality or multiple fatalities</Typography>
          </GridItem>
        </StyledMatrixGrid>

        <StyledThermoGrid>
          <GridItem xs={12}>
            <RiskLevelThermometer />
          </GridItem>
        </StyledThermoGrid>

        <GridItem xs={12}>
          <Typography variant="body1">
            <strong>Note:</strong> You need to assess the risk level for each hazard based on your own
            experience. If unsure consult with an experienced colleague.
          </Typography>
        </GridItem>

        <StyledModalActions>
          <Button style={{ minWidth: 357 }} preset="secondary" themeColour="grey" onClick={onClose}>
            OK, got it
          </Button>
        </StyledModalActions>
      </ModalContent>
    </Modal>
  );
};

Modal.defaultProps = {
  maxWidth: 'md',
  onClose: () => ({}),
  open: false,
  size: '1031px',
};

export default RiskMatrixModal;
