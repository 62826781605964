const isolationOptions = {
  isolationCertificate: {
    id: 'isolationCertificate',
    label: 'Isolation Certificate',
    actionText: 'Apply for Isolation Certificate',
  },
  selfIsolation: {
    id: 'selfIsolation',
    label: 'Self-Isolation (LOTO)',
  },
};

export default isolationOptions;
