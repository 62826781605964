import styled from 'styled-components';
import React from 'react';

export const StyledCheckbox = styled.input`
  display: none;
`;

export const StyledLabel = styled(({ isRequired, ...rest }) => <label {...rest} />)`
  cursor: ${({ isRequired }) => (isRequired ? 'default' : 'pointer')};
`;

export const StyledCardInputGroup = styled(({ cardType, ...rest }) => <div {...rest} />)`
  display: grid;
  grid-template-columns: ${({ cardType }) =>
    cardType === 'ppe' ? '150px 150px 150px 150px 150px' : 'repeat(auto-fill, 150px)'};
  grid-gap: 26px;
  color: #444;
  padding-top: 9px;
  padding-bottom: 22px;
`;

export const StyledCardInputContainer = styled.div`
  height: 200px;
`;
export const StyledCardInput = styled.div`
  box-shadow: 0 2px 12px 0 rgba(33, 61, 88, 0.3);
  border: 2px solid #40b3db;
  border-radius: 4px;
  .MuiPaper-root {
    box-shadow: none;
    width: 100%;
  }
  textarea {
    color: #0a0d0d;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    padding: 0 10px;
    &.small {
      font-size: 14px;
    }
  }
  button {
    color: #213d58;
    background-color: ${({ theme }) => theme.colors.primary.mediumBlue};
    width: 100%;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 26px;
    border-radius: 0;
    &:hover {
      background-color: ${({ theme }) => theme.colors.mixHoverColor('mediumBlue')};
    }
    &:disabled {
      background-color: #e1e5e5;
      color: #b6bebf;
    }
  }
`;
