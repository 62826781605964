import React from 'react';
import map from 'lodash/map';
import Select from '../Select/Select';
import MenuItem from '../Select/MenuItem';

const OperatingAreaInput = function (props: any) {
  return (
    <Select style={{ display: 'flex' }} label="Operating Area *" {...props}>
      <MenuItem value=" " disabled>
        Choose an operating area
      </MenuItem>
      {map(props.operatingareas, ({ label }: any, index: number) => (
        <MenuItem key={`operatingAreas-${index}`} value={label}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default OperatingAreaInput;
