import React, { useEffect } from 'react';
import { Typography } from '../../components';
import { JsaList } from '../../features';
import { DashboardWrapper, DashboardContent, DashboardActions, StyledButton } from './style';
import useClientState from '../../hooks/useClientState';
import DashboardLayout from '../../features/DashboardLayout/DashboardLayout';
import { useAuth } from 'contexts/AuthProvider';
import { useSubmitValidation } from 'contexts/useSubmitValidation';

const Dashboard = () => {
  const { setIsModalOpen } = useClientState();
  const { user } = useAuth();

  const { setCurrentJsaId } = useSubmitValidation();

  useEffect(() => {
    setCurrentJsaId(null);
  }, []);

  const getUserGreeting = (userGivenName: any) => {
    const d = new Date();
    const timeInHours = d.getHours();

    if (timeInHours >= 5 && timeInHours < 12) {
      return `Good Morning, ${userGivenName}`;
    }
    if (timeInHours >= 12 && timeInHours < 17) {
      return `Good Afternoon, ${userGivenName}`;
    }
    return `Good Evening, ${userGivenName}`;
  };

  return (
    <DashboardLayout>
      <DashboardWrapper>
        <DashboardContent>
          {user && user.firstName ? (
            <Typography id="dashboard_greeting" align="center" variant="h2" gutterBottom>
              {getUserGreeting(user.firstName)}
            </Typography>
          ) : (
            <Typography align="center" variant="h1" gutterBottom>
              Loading user
            </Typography>
          )}

          <DashboardActions>
            <StyledButton id="new_jsa_button" onClick={() => setIsModalOpen(true)}>
              Create New JSA
            </StyledButton>
          </DashboardActions>
        </DashboardContent>
      </DashboardWrapper>
      <JsaList />
    </DashboardLayout>
  );
};

export default Dashboard;
