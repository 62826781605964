import React from 'react';
import { DialogTitleProps } from '@material-ui/core/DialogTitle';
import Typography from '../Typography/Typography';
import { StyledMuiDialogTitle, CloseButton, StyledCloseIcon } from './style';

export interface ModalTitleProps extends DialogTitleProps {
  children: React.ReactNode;
  onClose: any;
  styles?: any;
}

const ModalTitle = function ({ children, styles, onClose, ...rest }: ModalTitleProps) {
  return (
    <StyledMuiDialogTitle styles={styles} {...rest}>
      <Typography variant="h2" align="center">
        {children}
      </Typography>
      {onClose ? (
        <CloseButton aria-label="close" onClick={onClose}>
          <StyledCloseIcon name="Close" preset="newJSA" />
        </CloseButton>
      ) : null}
    </StyledMuiDialogTitle>
  );
};

ModalTitle.defaultProps = {
  disableTypography: true,
};

export default ModalTitle;
