import { createSelector } from 'reselect';
import get from 'lodash/get';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import flatten from 'lodash/fp/flatten';
import uniqBy from 'lodash/fp/uniqBy';
import groupBy from 'lodash/fp/groupBy';
import filter from 'lodash/fp/filter';

// Base selectors
export const getJobSafetyAnalysis = (data: any) => get(data, 'getJobSafetyAnalysis', {});
export const getWorkSteps = (data: any) => get(data, 'getJobSafetyAnalysis.workSteps', []);

// Computed selectors
export const getHazards = createSelector([getWorkSteps], (workSteps) => {
  const processHazards = flow(
    map(({ hazards }: any) => hazards),
    filter((item: any) => item),
    flatten,
    uniqBy('code'),
    groupBy('category.name')
  )(workSteps);

  return processHazards || null;
});
