import React from 'react';
import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import MuiToolbar from '@material-ui/core/Toolbar';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import TrayNav from '../TrayNav/TrayNav';
import AppHeaderTabs from '../AppHeaderTabs/AppHeaderTabs';
import AppToolbar from '../AppToolbar/AppToolbar';
import { ReactComponent as JobCareLogo } from './svg/jobcare.svg';
import wave from './svg/wave-single.svg';
import CreateJsaModal from '../CreateJsaModal/CreateJsaModal';
import { getIsModalOpen } from '../../redux/jsaModalSlice';

const StyledAppBar = styled(({ shouldRenderTabs, ...rest }) => (
  <AppBar shouldrendertabs={shouldRenderTabs.toString()} {...rest} />
))`
  background-color: ${({ theme }) => theme.colors.misc.headerBlue};
  box-shadow: none;
  position: relative;
  height: ${({ shouldRenderTabs }) => (shouldRenderTabs ? 'auto' : '150px')};

  & .MuiToolbar-root {
    justify-content: space-between;
    display: flex;
  }
`;

const AppHeaderImageWrapper = styled.div`
  position: absolute;
  height: 200px;
  width: 200px;
  position: absolute;
  left: 50%;
  margin-left: -100px;
  top: 30px;
`;

const AppHeaderWave = styled.div`
  width: 100%;
  height: 10px;
  background-image: url(${wave});
  background-repeat: repeat-x;
  top: 150px;
  position: relative;
`;

const OverlayPlaceholder = styled.div`
  height: auto;
  width: 101px;
`;

export declare interface AppHeaderProps {
  shouldRenderTabs?: boolean;
}

const AppHeader: React.FC<AppHeaderProps> = ({ shouldRenderTabs = false }) => {
  const isOpen = useSelector(getIsModalOpen);
  const location = useLocation();
  return (
    <>
      <StyledAppBar shouldRenderTabs={shouldRenderTabs} className="screen-only" position="static">
        {(location.pathname === '/' || location.pathname === '/dashboard') && <AppHeaderWave />}
        <MuiToolbar>
          {isOpen && <CreateJsaModal />}
          <TrayNav />
          {shouldRenderTabs ? (
            <AppHeaderTabs />
          ) : (
            <AppHeaderImageWrapper>
              <JobCareLogo />
            </AppHeaderImageWrapper>
          )}
          <OverlayPlaceholder />
        </MuiToolbar>
      </StyledAppBar>
      {shouldRenderTabs && <AppToolbar />}
    </>
  );
};

export default AppHeader;
