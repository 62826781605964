// prettier-ignore
const risk = [
  [3, 4, 4, 5, 5, 3],
  [2, 3, 4, 4, 5, 2],
  [2, 2, 3, 4, 4, 2],
  [1, 2, 3, 3, 4, 1],
  [1, 2, 2, 3, 4, 1],
  [1, 2, 2, 3, 4, 0],
];

function mapSliderToSeverity(sliderNumber: number) {
  switch (sliderNumber) {
    case 0:
      return 0;
    case 25:
      return 1;
    case 50:
      return 2;
    case 75:
      return 3;
    case 99:
      return 4;
    default:
      return 5;
  }
}

function mapSliderToLikelihood(sliderNumber: number) {
  switch (sliderNumber) {
    case 0:
      return 4;
    case 25:
      return 3;
    case 50:
      return 2;
    case 75:
      return 1;
    case 99:
      return 0;
    default:
      return 5;
  }
}

function calculateRisk(likelihood: number, severity: number) {
  const convertedLikelihood = mapSliderToLikelihood(likelihood);
  const convertedSeverity = mapSliderToSeverity(severity);
  return risk[convertedLikelihood][convertedSeverity];
}

export default calculateRisk;
