import React from 'react';
import { Typography } from '@material-ui/core';
import SummaryContentBlock from './SummaryContentBlock';
import {
  getServiceProvider,
  getWatercareRepresentativeName,
  getWatercareRepresentativeNumber,
  getWatercareRepresentativeEmail,
  getWorkLocationProcessArea,
  getSiteName,
  getSiteAddress,
  getStartDate,
  getEndDate,
  getOperatingArea,
  getJsaWrittenByName,
  getJsaWrittenByNumber,
  getWrittenByEmailAddress,
  // getAssignedToDate,
  // getAssignedToName,
  getReviewedByDate,
  getReviewedByName,
  getConfirmedByName,
  getConfirmedDate,
  getJsaApplicationTitle,
  getJsaId,
  getPersonInChargeEmail,
  getPersonInChargeName,
  getPersonInChargeNumber,
  getWorkOrderId,
  getDescriptionOfWork,
  getScopeOfWork,
} from './summaryWorkDetailsSelectors';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Fallback from '../../components/Fallback/Fallback';
import {
  ArrowAndEndDateWrapper,
  DateWrapper,
  StyledTypography,
  TitleIdWrapper,
  TitleWrapper,
  PrintPortraitContainer,
  StyledIcon,
} from './style';
import { Icon } from '../../components';

const SummaryWorkDetailsContent = function ({ data }: any) {
  const serviceProvider = getServiceProvider(data);
  const watercareRepresentativeName = getWatercareRepresentativeName(data);
  const watercareRepresentativeNumber = getWatercareRepresentativeNumber(data);
  const watercareRepresentativeEmail = getWatercareRepresentativeEmail(data);
  const siteName = getSiteName(data);
  const siteAddress = getSiteAddress(data);
  const workLocationProcessArea = getWorkLocationProcessArea(data);
  const startDate = getStartDate(data);
  const endDate = getEndDate(data);
  const operatingArea = getOperatingArea(data);
  const jsaWrittenByName = getJsaWrittenByName(data);
  const jsaWrittenByNumber = getJsaWrittenByNumber(data);
  const emailAddress = getWrittenByEmailAddress(data);
  const personInChargeName = getPersonInChargeName(data);
  const personInChargeNumber = getPersonInChargeNumber(data);
  const personInChargeEmail = getPersonInChargeEmail(data);
  // const assignedToName = getAssignedToName(data)
  // const assignedToDate = getAssignedToDate(data)
  const reviewedByName = getReviewedByName(data);
  const reviewedByDate = getReviewedByDate(data);
  const confirmedByDate = getConfirmedDate(data);
  const confirmedByName = getConfirmedByName(data);
  const scopeOfWork = getScopeOfWork(data);
  const descriptionOfWork = getDescriptionOfWork(data);
  const jsaApplicationTitle = getJsaApplicationTitle(data);
  const workOrderId = getWorkOrderId(data);
  const jsaId = getJsaId(data);

  return (
    <>
      <div className="screen-landscape-only">
        <GridContainer>
          <GridItem xs={12}>
            <SummaryContentBlock wide title="Service Provider">
              <Fallback value={serviceProvider} />
            </SummaryContentBlock>
          </GridItem>
          <GridItem xs={6}>
            <SummaryContentBlock title="Written by">
              <Fallback value={jsaWrittenByName} message="Name not completed yet" />
              <Fallback value={jsaWrittenByNumber} message="Number not completed yet" />
              <Fallback email value={emailAddress} message="Email not completed yet" />
            </SummaryContentBlock>
          </GridItem>
          <GridItem xs={6}>
            <SummaryContentBlock title="Watercare representative">
              <Fallback value={watercareRepresentativeName} message="Name not completed yet" />
              <Fallback value={watercareRepresentativeNumber} message="Number not completed yet" />
              <Fallback email value={watercareRepresentativeEmail} message="Email not completed yet" />
            </SummaryContentBlock>
          </GridItem>
          <GridItem xs={6}>
            <SummaryContentBlock title="Work site">
              <Fallback value={siteName} message="Site name not completed yet">
                <strong>{siteName}</strong>
              </Fallback>
              <Fallback value={siteAddress} message="Site address not completed yet" />
              <Fallback value={operatingArea} message="Operating area not completed yet" />
            </SummaryContentBlock>
          </GridItem>

          <GridItem xs={6}>
            <SummaryContentBlock title="Work location / process area">
              <Fallback value={workLocationProcessArea} />
            </SummaryContentBlock>
          </GridItem>

          <GridItem xs={12}>
            <DateWrapper>
              <SummaryContentBlock title="Start date">
                <Fallback value={startDate} />
              </SummaryContentBlock>
              <ArrowAndEndDateWrapper>
                <Icon name="ConnectorArrow" />
                <SummaryContentBlock title="End date">
                  <Fallback value={endDate} />
                </SummaryContentBlock>
              </ArrowAndEndDateWrapper>
            </DateWrapper>
          </GridItem>
          <GridItem xs={12}>
            <SummaryContentBlock wide title="Description of work">
              <Fallback value={descriptionOfWork} />
            </SummaryContentBlock>
          </GridItem>
          <GridItem xs={12}>
            <SummaryContentBlock wide title="Scope of work">
              <Fallback value={scopeOfWork} />
            </SummaryContentBlock>
          </GridItem>
        </GridContainer>
      </div>

      <div className="portrait-print-only">
        <PrintPortraitContainer>
          <TitleWrapper xs={12}>
            <StyledTypography variant="h4">{jsaApplicationTitle}</StyledTypography>
            <TitleIdWrapper>
              <Typography variant="body1">{jsaId}</Typography>
              <StyledIcon name="TitleDot" />
              <Typography variant="body1">Work Order #{workOrderId}</Typography>
            </TitleIdWrapper>
          </TitleWrapper>

          <GridItem xs={6}>
            <SummaryContentBlock title="Written by">
              <Fallback value={jsaWrittenByName} />
              <Fallback value={jsaWrittenByNumber} />
              <Fallback email value={emailAddress} />
            </SummaryContentBlock>
          </GridItem>
          <GridItem xs={6}>
            <SummaryContentBlock title="Person in charge of the work (PICW)">
              <Fallback value={personInChargeName} message="Name not completed yet" />
              <Fallback value={personInChargeNumber} message="Number not completed yet" />
              <Fallback email value={personInChargeEmail} message="Email not completed yet" />
            </SummaryContentBlock>
          </GridItem>
          <GridItem xs={12}>
            <SummaryContentBlock title="Watercare representative">
              <Fallback value={watercareRepresentativeName} message="Name not completed yet" />
              <Fallback value={watercareRepresentativeNumber} message="Number not completed yet" />
              <Fallback email value={watercareRepresentativeEmail} message="Email not completed yet" />
            </SummaryContentBlock>
          </GridItem>
          <GridItem xs={6}>
            <SummaryContentBlock title="Reviewed by">
              <Fallback value={reviewedByName} message="Not assigned yet" />
              <Fallback value={reviewedByDate} message="Not assigned yet" />
            </SummaryContentBlock>
          </GridItem>
          <GridItem xs={6}>
            <SummaryContentBlock title="Confirmed by">
              <Fallback value={confirmedByName} message="Not confirmed yet" />
              <Fallback value={confirmedByDate} message="Not confirmed yet" />
            </SummaryContentBlock>
          </GridItem>
          <GridItem xs={6}>
            <SummaryContentBlock title="Work site">
              <Fallback value={siteName} message="Site name not completed yet">
                <strong>{siteName}</strong>
              </Fallback>
              <Fallback value={siteAddress} message="Site address not completed yet" />
              <Fallback value={operatingArea} message="Operating area not completed yet" />
            </SummaryContentBlock>
          </GridItem>
          <GridItem xs={6}>
            <SummaryContentBlock title="Work location / process area">
              <Fallback value={workLocationProcessArea} />
            </SummaryContentBlock>
          </GridItem>
          <GridItem xs={6}>
            <DateWrapper>
              <SummaryContentBlock title="Start date">
                <Fallback value={startDate} />
              </SummaryContentBlock>
              <ArrowAndEndDateWrapper>
                <Icon name="ConnectorArrow" />
                <SummaryContentBlock title="End date">
                  <Fallback value={endDate} />
                </SummaryContentBlock>
              </ArrowAndEndDateWrapper>
            </DateWrapper>
          </GridItem>
          <GridItem xs={12}>
            <SummaryContentBlock wide title="Description of work">
              <Fallback value={descriptionOfWork} />
            </SummaryContentBlock>
          </GridItem>
          <GridItem xs={12}>
            <SummaryContentBlock wide title="Scope of work">
              <Fallback value={scopeOfWork} />
            </SummaryContentBlock>
          </GridItem>
        </PrintPortraitContainer>
      </div>
    </>
  );
};

export default SummaryWorkDetailsContent;
