import React from 'react';
import { Formik, getIn } from 'formik';
import filter from 'lodash/filter';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { isEmpty } from 'lodash';
import Overlay from '../Overlay/Overlay';
import ControlsOverlayContent from './ControlsOverlayContent';
import ControlsOverlayActions from './ControlsOverlayActions';
import ControlsOverlaySubTitle from './ControlsOverlaySubTitle';
import { useClientState } from '../../hooks';
import { getHazardToUpdate } from '../../redux/workStepsSlice';
import usePpeData from '../../hooks/usePpeMasterData';

const constructFormData = ({ controls }: any = []) => ({
  controlMeasures: filter(controls.controlMeasures, ({ value }: any) => value !== ''),
  permits: controls.permits,
  ppe: controls.ppe,
  isolations: controls.isolations,
});

function constructSavedValues(savedValue: any) {
  if (savedValue) {
    return {
      draftPpe: savedValue.ppe || [],
      ...savedValue,
    };
  }
  return {};
}

export const setInitialValues = (
  permitCode?: any,
  permitName?: any,
  ppeMasterData?: any,
  savedValue?: any
) => {
  const baseValues = {
    controlMeasures: [{ id: uuidv4(), value: '' }],
    ppe: [],
    draftPpe: [],
    isolations: {
      required: '',
      type: ' ', // empty string with space by default to show placeholder in select
      selfIsolationValue: '',
    },
    ppeMasterData, // store for easy access
  };

  if (permitCode) {
    return {
      controls: {
        ...baseValues,
        permits: { required: false, name: permitName, code: permitCode },
        ...constructSavedValues(savedValue),
      },
    };
  }

  return {
    controls: {
      ...baseValues,
      ...constructSavedValues(savedValue),
    },
  };
};

function getSavedValue(hazard: any) {
  return getIn(hazard, 'controls');
}

const ControlsOverlay = function (props: any) {
  const { setIsControlsOverlayOpen, setControlsToSave } = useClientState();
  const { hazard } = useSelector(getHazardToUpdate);
  const permitName = get(hazard, 'permitName');
  const permitCode = get(hazard, 'permitCode');
  const ppeMasterData = usePpeData();
  const savedValue = getSavedValue(hazard);
  const initialValues = setInitialValues(permitCode, permitName, ppeMasterData, savedValue);
  const controlMeasures = get(hazard, 'controls.controlMeasures');
  const permits = get(hazard, 'controls.permits');
  const ppe = get(hazard, 'controls.ppe');
  const isolationCertificate = get(hazard, 'controls.isolationCertificate');
  const selfIsolations = get(hazard, 'controls.selfIsolations');
  let isEditing = !(
    isEmpty(controlMeasures) &&
    (permits === null || permits === undefined || permits === '') &&
    isEmpty(ppe) &&
    isolationCertificate === undefined &&
    selfIsolations === undefined
  );

  if (
    isEmpty(controlMeasures) === false &&
    permits !== undefined &&
    permits.required === false &&
    isEmpty(ppe) === false &&
    isolationCertificate === undefined &&
    selfIsolations === undefined
  ) {
    isEditing = false;
  }

  function handleSubmit(values: any) {
    const formData = constructFormData(values);
    setIsControlsOverlayOpen(false);
    setControlsToSave(formData);
  }

  return (
    <Overlay
      title="What do we need to do to make it safe?"
      subTitle={<ControlsOverlaySubTitle />}
      tooltip="List the controls to make this safe."
      {...props}
    >
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <>
          <ControlsOverlayContent />
          <ControlsOverlayActions onClose={props.onClose} isEditing={isEditing} />
        </>
      </Formik>
    </Overlay>
  );
};

export default ControlsOverlay;
