function getPermitDocumentUrl(permitName: string, jsonResponse: any) {
  let url: string | undefined;
  if (permitName && jsonResponse.loading === false) {
    const assetArray = jsonResponse.data.permitDocumentData.includes.Asset;
    assetArray.forEach(({ fields }: any) => {
      if (permitName === fields.title) {
        url = fields.file.url;
      }
    });
  }
  return url;
}
export default getPermitDocumentUrl;
