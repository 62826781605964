import React from 'react';
import { isEmpty } from 'lodash';
import {
  SummarySubSection,
  SummarySubSectionTitle,
  WorkpackList,
  WorkpackListIsolationCertificate,
  FileNameWrapper,
} from './style';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Fallback from '../../components/Fallback/Fallback';
import {
  getIsolationCertificates,
  getSelfIsolations,
  getOnlyCompleteIsolations,
} from './summaryWorkpackSelectors';
import { getHazardWithIsolations } from '../WorkpackForm/workpackFormSelectors';
import SummaryItemLink from './SummaryItemLink';

const SummaryWorkpackIsolationsRequired = function ({ data }: any) {
  const isolations = getOnlyCompleteIsolations(data as never);
  const isolationCertificates = getIsolationCertificates(data as never);
  const selfIsolations = getSelfIsolations(data as never);
  const hazardWithIsolations = getHazardWithIsolations(data);
  const shouldRenderWorkStepIsolations = !isEmpty(selfIsolations) || !isEmpty(hazardWithIsolations);

  return (
    <SummarySubSection>
      <GridContainer>
        <GridItem xs={12}>
          <SummarySubSectionTitle>Isolations required</SummarySubSectionTitle>
        </GridItem>
        <GridItem xs={8}>
          <Fallback
            value={isolations}
            message={
              shouldRenderWorkStepIsolations
                ? 'Isolations not completed yet'
                : 'Isolations required not added'
            }
            isText={false}
          >
            <WorkpackList>
              {isolationCertificates.map((item: any, index: number) => {
                return (
                  <WorkpackListIsolationCertificate key={`isolationCertificates-${index}`}>
                    <SummaryItemLink item={item} key={index} />
                  </WorkpackListIsolationCertificate>
                );
              })}
              {selfIsolations.map((item: any, index: number) => (
                <li key={`selfIsolations-${index}`}>
                  <div>
                    <Fallback value={item.label} message="Not set yet" />
                    <Fallback value={item.selfIsolation} message="Not completed yet">
                      <FileNameWrapper>{item.selfIsolation}</FileNameWrapper>
                    </Fallback>
                  </div>
                </li>
              ))}
            </WorkpackList>
          </Fallback>
        </GridItem>
      </GridContainer>
    </SummarySubSection>
  );
};

export default SummaryWorkpackIsolationsRequired;
