import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import Card from '@material-ui/core/Card';
import HazardIcon from '../Icon/HazardIcon/HazardIcon';
import PpeIcon from '../Icon/PpeIcon/PpeIcon';
import { scaleIconCard } from '../../styles/style-utils';

export const DeleteIcon = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  margin: 0;
  display: inline-block;
  vertical-align: middle;
  border-bottom: solid 16px #213d58;
  border-right: solid 16px #213d58;
  border-left: solid 16px transparent;
  border-top: solid 16px transparent;

  & svg {
    width: 16px;
    height: 16px;
    position: absolute;
    right: -16px;
    bottom: -15px;
    margin-bottom: 0;
  }

  & path {
    fill: #e1e5e5;
  }
`;

export const ToolTipWrapper = styled.div`
  ${({ theme }) => scaleIconCard('width', 48, theme)}
  ${({ theme }) => scaleIconCard('height', 48, theme)}
  position: absolute;
  top: 0;
  right: 0;
`;

export const StyledPpeIcon = styled(({ isSelected, colour, ...rest }) => <PpeIcon {...rest} />)`
  width: 80px;
  height: auto;
  max-height: 80px;

  && path {
    fill: ${({ isSelected, colour, theme }) => {
      return isSelected ? theme.colors.primary.darkBlue : colour;
    }};
  }
`;

export const IconWraper = styled.div`
  ${({ theme }) => scaleIconCard('width', 100, theme)}
  ${({ theme }) => scaleIconCard('height', 100, theme)}

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 4px;
`;

export const CardLabelWrapper = styled.div`
  ${({ theme }) => scaleIconCard('width', 120, theme)}
  ${({ theme }) => scaleIconCard('height', 66, theme)}
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardLabel = styled(({ cardType, isSelected, colour, label, ...rest }) => <p {...rest} />)`
  font-family: 'Nunito Sans';
  font-weight: 600;
  margin: 0;
  text-align: center;
  font-size: ${({ label }) => {
    if (label) return label.length >= 40 ? '14px' : '16px';
  }};
  color: ${({ isSelected, isRequired, theme, colour, cardType }) => {
    if (cardType === 'ppe') {
      if (colour !== '#1c7b97' && isSelected) {
        return 'white';
      }
      return theme.colors.greyScale['0A0D0D'];
    }
    return isSelected ? theme.colors.primary.darkBlue : theme.colors.greyScale['0A0D0D'];
  }};
`;
export const StyledHazardIcon = styled(({ isSelected, ...rest }) => <HazardIcon {...rest} />)`
  ${({ theme }) => scaleIconCard('width', 100, theme)}
  ${({ theme }) => scaleIconCard('height', 100, theme)}

  && path {
    fill: ${({ isSelected, theme }) =>
      isSelected ? theme.colors.primary.darkBlue : theme.colors.complementary.blue};
  }
`;

export const StyledCard = styled(({ canDelete, cardType, isSelected, tooltip, colour, ...rest }) => (
  <Card {...rest} />
))`
  position: relative;
  overflow: hidden;
  cursor: ${({ canDelete }) => (canDelete ? 'pointer' : 'auto')};
  ${({ theme }) => scaleIconCard('width', 150, theme)}
  ${({ theme }) => scaleIconCard('height', 200, theme)}
  box-shadow: 0 1px 5px 0 rgba(33, 61, 88, 0.4);

  & .MuiCardContent-root {
    ${({ theme }) => scaleIconCard('padding-left', 15, theme)}
    ${({ theme }) => scaleIconCard('padding-bottom', 15, theme)}
    ${({ theme }) => scaleIconCard('padding-top', 15, theme)}
    padding-right: ${({ tooltip, theme }) => {
      const size = tooltip ? 5 : 15;
      const scaledSize = Math.floor(size * theme.iconCard.scale);

      return `${scaledSize}px`;
    }};
  }

  background-color: ${({ isSelected, theme, cardType, colour }) => {
    if (isSelected) {
      if (cardType === 'hazard') {
        return theme.colors.complementary.yellow;
      }
      if (cardType === 'ppe') {
        if (colour !== '#1c7b97') {
          return colour;
        }
        return theme.colors.complementary.lightestBlue;
      }
      return '#fff';
    }
  }};

  box-shadow: ${({ isSelected, theme, cardType }) => {
    if (isSelected) {
      if (cardType === 'hazard') {
        return `inset 0 0 0 2px ${theme.colors.primary.darkBlue}`;
      }
      if (cardType === 'ppe') {
        return `inset 0 0 0 3px ${theme.colors.primary.darkBlue}`;
      }
      return '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)';
    }
  }};

  & .delete-icon-wrapper {
    opacity: ${() => (isMobile ? '1' : '0')};
  }

  &:hover {
    .delete-icon-wrapper {
      opacity: 1;
    }
  }
`;
export const StyledCardButton = styled(({ ...rest }) => <Card {...rest} />)`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  ${({ theme }) => scaleIconCard('width', 150, theme)}
  ${({ theme }) => scaleIconCard('height', 200, theme)}

  .MuiCardContent-root {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    ${({ theme }) => scaleIconCard('padding-left', 15, theme)}
    ${({ theme }) => scaleIconCard('padding-bottom', 15, theme)}
    ${({ theme }) => scaleIconCard('padding-top', 15, theme)}
    padding-right: ${({ theme }) => {
      const size = 15;
      const scaledSize = Math.floor(size * theme.iconCard.scale);

      return `${scaledSize}px`;
    }};
  }

  background-color: transparent;
  box-shadow: none;
  border-width: 2px;
  border-style: dashed;
  border-color: ${({ theme }) => theme.colors.greyScale['10']};
  border-radius: 5px;

  font-size: 16px;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.greyScale['50']};
  line-height: 22px;
  p {
    font-weight: bold !important;
  }
  svg {
    width: 40px;
    height: 40px;
    max-height: 40px;
    max-weight: 40px;
    transition: 0.1s;
    path {
      fill: ${({ theme }) => theme.colors.greyScale['25']};
    }
  }
  &:hover {
    border: 2px solid #40b3db;
    box-shadow: 0 2px 12px 0 rgba(33, 61, 88, 0.3);
    color: ${({ theme }) => theme.colors.complementary.blue};
    svg {
      transform: scale(1.2);
      transition: all 0.1s ease;
      path {
        fill: ${({ theme }) => theme.colors.complementary.blue};
      }
    }
  }
`;
