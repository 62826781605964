import React from 'react';
import map from 'lodash/map';
import Select from '../Select/Select';
import MenuItem from '../Select/MenuItem';

const TeamRoleInput = (props: any) => {
  if (!props.teamroles.length) {
    return null;
  }

  return (
    <Select style={{ width: '100%' }} label="Role" defaultValue="" {...props}>
      <MenuItem value="" disabled>
        Choose one
      </MenuItem>
      {map(props.teamroles, ({ label }: any, index: number) => (
        <MenuItem key={`teamroles-${index}`} value={label}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default TeamRoleInput;
