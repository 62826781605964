import React, { Fragment, useEffect, memo } from 'react';
import { connect, FieldArray, getIn, useFormikContext } from 'formik';
import map from 'lodash/map';
import get from 'lodash/get';
import { useMasterData, useFacilityControlField } from '../../hooks';
import AddButton from '../../components/Button/AddButton';
import {
  RemoveWrapper,
  StyledRemoveButton,
  FormFieldWrapper,
  AddButtonWrapper,
  TopFieldWrapper,
} from './style';
import { Accordion } from '../../components/Accordion/Accordion';
import { AccordionSummary, AccordionDetails } from '../../components/Accordion/styles';
import GridContainer from '../../components/Grid/GridContainer';
import FormikAutocomplete from '../../components/Autocomplete/FormikAutocomplete';
import TextField from '../../components/TextField/TextField';
import FieldValidationWrapper from 'components/FieldValidationWrapper/FieldValidationWrapper';
import { useSubmitValidation } from 'contexts/useSubmitValidation';

const validate = (event: any) => {
  if (event.target.value.indexOf('or') !== -1) {
    return event.preventDefault();
  }
  if ((event.keyCode >= 65 && event.keyCode <= 90) || (event.keyCode > 105 && event.keyCode <= 122)) {
    return event.preventDefault();
  }
};

const EmergencyContacts = memo(() => {
  const [expandedPanels, setExpandedPanels] = React.useState<{
    panel1: boolean;
    panel2: boolean;
  }>({ panel1: true, panel2: true });

  const handleChange = (panel: string) => () => {
    const currentValue = expandedPanels[panel];

    setExpandedPanels({
      ...expandedPanels,
      [panel]: !currentValue,
    });
  };

  const { hasUserAttemptedSubmission, setEmergencyDetailsState } = useSubmitValidation();
  const { values } = useFormikContext();
  const emergencyDetails = getIn(values, 'emergencyDetails');

  useEffect(() => {
    if (hasUserAttemptedSubmission) {
      setEmergencyDetailsState(emergencyDetails);
    }
  }, []);

  useEffect(() => {
    if (hasUserAttemptedSubmission) {
      setEmergencyDetailsState(emergencyDetails);
    }
  }, [emergencyDetails]);

  const emergencyContacts = getIn(values, 'emergencyDetails.emergencyContacts');

  const { data: masterData } = useMasterData();

  const suggestionData = get(masterData, 'facilityControl.items', []).map(({ fields }: any) => ({
    id: fields.code,
    label: `${fields.facilityControlRoom} `,
  }));

  useFacilityControlField(
    'emergencyDetails.facilityControlRoomNumber',
    'fields.facilityControlRoomNumber'
  );

  return (
    <Accordion borderTop expanded={expandedPanels.panel1 === true} onChange={handleChange('panel1')}>
      <AccordionSummary>Emergency contacts</AccordionSummary>
      <AccordionDetails>
        <GridContainer>
          <TopFieldWrapper xs={8}>
            <FieldValidationWrapper
              validationLabel="control room"
              name="emergencyDetails.facilityControlRoom"
            >
              <FormikAutocomplete
                id="emergencyDetails.facilityControlRoom"
                name="emergencyDetails.facilityControlRoom"
                options={suggestionData}
                label="Facility control *"
              />
            </FieldValidationWrapper>
          </TopFieldWrapper>
          <TopFieldWrapper xs={4}>
            <TextField
              fullWidth
              isRequired
              validationLabel="contact number"
              onKeyDown={validate}
              id="emergencyDetails.facilityControlRoomNumber"
              name="emergencyDetails.facilityControlRoomNumber"
              label="Contact no. *"
              placeholder="Mobile or landline no."
            />
          </TopFieldWrapper>

          <FieldArray
            name="emergencyDetails.emergencyContacts"
            render={(arrayHelpers: any) => (
              <>
                {map(emergencyContacts, (emergencyContact: any, index: any) => {
                  const isFirst = index === 0;
                  return (
                    <Fragment key={index}>
                      <FormFieldWrapper xs={4}>
                        <TextField
                          fullWidth
                          name={`emergencyDetails.emergencyContacts[${index}].firstName`}
                          id={`emergencyDetails.emergencyContacts[${index}].firstName`}
                          label={index === 0 ? 'Emergency team' : ''}
                          tooltip="EmergencyTeam"
                          placeholder="First Name"
                        />
                      </FormFieldWrapper>
                      <FormFieldWrapper xs={4}>
                        <TextField
                          fullWidth
                          id={`emergencyDetails.emergencyContacts[${index}].lastName`}
                          name={`emergencyDetails.emergencyContacts[${index}].lastName`}
                          label={index === 0 ? '\u00A0' : ''}
                          placeholder="Last Name"
                        />
                      </FormFieldWrapper>
                      <FormFieldWrapper xs={4}>
                        <RemoveWrapper>
                          <TextField
                            fullWidth
                            id={`emergencyDetails.emergencyContacts[${index}].contactNumber`}
                            name={`emergencyDetails.emergencyContacts[${index}].contactNumber`}
                            onKeyDown={validate}
                            label={isFirst ? 'Contact no.' : ''}
                            placeholder="Mobile or landline no."
                          />
                          {!isFirst && (
                            <StyledRemoveButton
                              onClick={() => arrayHelpers.remove(index)} // remove a item from the list
                            />
                          )}
                        </RemoveWrapper>
                      </FormFieldWrapper>
                    </Fragment>
                  );
                })}

                <AddButtonWrapper xs={12}>
                  <AddButton
                    text="Add Contact"
                    onClick={() =>
                      arrayHelpers.push({
                        firstName: '',
                        lastName: '',
                        contactNumber: '',
                      })
                    }
                  />
                </AddButtonWrapper>
              </>
            )}
          />
        </GridContainer>
      </AccordionDetails>
    </Accordion>
  );
});

EmergencyContacts.displayName = 'EmergencyContacts';

export default connect(EmergencyContacts);
