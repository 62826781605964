import React, { useState } from 'react';
import Menu from '../../components/Menu/Menu';
import MenuItem from '../../components/Menu/MenuItem';
import MenuButton from '../../components/Menu/MenuButton';
import CloneJsaModal from './CloneJsaModal';
import MyTeamModal from '../MyTeamModal/MyTeamModal';
import ShareJsaModal from '../ShareJsaModal/ShareJsaModal';
import CancelJsaModal from '../CancelJsaModal/CancelJsaModal';
import { useAuth } from 'contexts/AuthProvider';

const JsaContextMenu = function ({ jsaValues }: any) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDuplicateOpen, setIsDuplicateOpen] = useState(false);
  const [isMyTeamOpen, setIsMyTeamOpen] = useState(false);
  const [isShareJsaOpen, setIsShareJsaOpen] = useState(false);
  const [isCancelJsaOpen, setIsCancelJsaOpen] = useState(false);
  const { status } = jsaValues;

  const { user } = useAuth();

  const handleAssociated = (item, user) => {
    if (user !== null) {
      const associated = false;
      if (item.createdBy) {
        if (item.createdBy.email !== null && user.email !== null) {
          if (item.createdBy.email.toLowerCase() === user.email.toLowerCase()) {
            return true;
          }
        }
      }
      if (item.assignedTo) {
        if (item.assignedTo.email !== null && user.email !== null) {
          if (item.assignedTo.email.toLowerCase() === user.email.toLowerCase()) {
            return true;
          }
        }
      }
      if (item.confirmedBy) {
        if (item.confirmedBy.email !== null && user.email !== null) {
          if (item.confirmedBy.email.toLowerCase() === user.email.toLowerCase()) {
            return true;
          }
        }
      }
      if (item.reviewedBy) {
        if (item.reviewedBy.email !== null && user.email !== null) {
          if (item.reviewedBy.email.toLowerCase() === user.email.toLowerCase()) {
            return true;
          }
        }
      }
      if (item.submittedBy) {
        if (item.submittedBy.email !== null && user.email !== null) {
          if (item.submittedBy.email.toLowerCase() === user.email.toLowerCase()) {
            return true;
          }
        }
      }
      if (item.toConfirmEmail) {
        if (item.toConfirmEmail.email !== null && user.email !== null) {
          if (item.toConfirmEmail.toLowerCase() === user.email.toLowerCase()) {
            return true;
          }
        }
      }
      if (item.workDetails) {
        if (item.workDetails.personInCharge) {
          if (item.workDetails.personInCharge.email && user.email !== null) {
            if (item.workDetails.personInCharge.email.toLowerCase() === user.email.toLowerCase()) {
              return true;
            }
          }
        }
        if (item.workDetails.watercareRepresentative) {
          if (item.workDetails.watercareRepresentative.email && user.email !== null) {
            if (
              item.workDetails.watercareRepresentative.email.toLowerCase() === user.email.toLowerCase()
            ) {
              return true;
            }
          }
        }
      }
      if (item.collaboratorDetails) {
        const result = item.collaboratorDetails.collaborators
          ? item.collaboratorDetails.collaborators.filter(
              (collaborator: any) => collaborator.email.toLowerCase() === user.email.toLowerCase()
            )
          : [];
        if (result.length > 0) {
          return true;
        }
      }
      return associated;
    }
    return false;
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleDuplicationClose() {
    setIsDuplicateOpen(false);
  }

  function handleMyTeamClose() {
    setIsMyTeamOpen(false);
  }

  function handleShareJsaClose() {
    setIsShareJsaOpen(false);
  }

  function handleCancelJsaClose() {
    setIsCancelJsaOpen(false);
  }

  return (
    <div style={{ paddingRight: 20 }}>
      <MenuButton onClick={handleClick} />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <span>
          <MenuItem
            onClick={() => {
              setIsMyTeamOpen(true);
              handleClose();
            }}
          >
            Work Team &amp; Visitors
          </MenuItem>
        </span>
        <span>
          <MenuItem
            onClick={() => {
              setIsDuplicateOpen(true);
              handleClose();
            }}
          >
            Duplicate JSA
          </MenuItem>
        </span>
        <span>
          <MenuItem
            onClick={() => {
              setIsShareJsaOpen(true);
              handleClose();
            }}
          >
            Share JSA
          </MenuItem>
        </span>
        <span>
          <MenuItem
            disabled={
              status === 'agreed' || status === 'cancelled' || !handleAssociated(jsaValues, user)
            }
            onClick={() => {
              setIsCancelJsaOpen(true);
              handleClose();
            }}
          >
            Cancel JSA
          </MenuItem>
        </span>
      </Menu>
      <MyTeamModal isModalOpen={isMyTeamOpen} handleModalClose={handleMyTeamClose} />
      <CloneJsaModal
        isModalOpen={isDuplicateOpen}
        handleModalClose={handleDuplicationClose}
        jsaValues={jsaValues}
      />
      <ShareJsaModal
        isModalOpen={isShareJsaOpen}
        handleModalClose={handleShareJsaClose}
        jsaValues={jsaValues}
      />
      <CancelJsaModal
        isModalOpen={isCancelJsaOpen}
        handleModalClose={handleCancelJsaClose}
        jsaValues={jsaValues}
      />
    </div>
  );
};

export default JsaContextMenu;
