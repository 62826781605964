import React, { useState, useRef, useEffect } from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { Button as MuiButton, TextField as MuiTextField } from '@material-ui/core';
import IconCard from '../IconCard/IconCard';
import { StyledCardInput, StyledCardInputContainer } from './style';

// @ts-check
const IconCardInput = function ({ name, placeholder, onDone, onClose, ...rest }: any) {
  const [value, setValue] = useState<any>('');
  const wrapperRef = useRef(null);

  const handleOnClick = () => {
    if (onDone) {
      onDone(name, value);
    }
  };
  const handleClickOutside = (event: { target: any }) => {
    // @ts-check
    if (onClose && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      onClose();
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
  return (
    <StyledCardInputContainer ref={wrapperRef}>
      <StyledCardInput>
        <IconCard name="CustomHazard" {...rest}>
          <MuiTextField
            name={name}
            fullWidth
            multiline
            minRows="3"
            maxRows="3"
            placeholder={placeholder}
            autoFocus
            inputProps={{
              maxLength: 50,
              className: classNames({ small: value.length > 40 }),
            }}
            value={value}
            onChange={(e) => setValue(e.target.value.replace(/\n/g, ''))}
          />
        </IconCard>
        <MuiButton disabled={isEmpty(value)} onClick={handleOnClick}>
          Done
        </MuiButton>
      </StyledCardInput>
    </StyledCardInputContainer>
  );
};

export default IconCardInput;
