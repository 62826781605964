import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import workStepsSlice from '../redux/workStepsSlice';
import jsaFormSlice from '../redux/jsaFormSlice';
import activeUserSlice from '../redux/activeUserSlice';
import jsaModalSlice from '../redux/jsaModalSlice';
export interface Result {
  resetWorkSteps: any;
  setCustomHazardsToSave: any;
  setHazardsToSave: any;
  setHazardToUpdate: any;
  setIsControlsOverlayOpen: any;
  setIsInitialRiskOverlayOpen: any;
  setIsResidualRiskOverlayOpen: any;
  setInitialLikelihood: any;
  setInitialSeverity: any;
  setInitialRisk: any;
  setResidualLikelihood: any;
  setResidualSeverity: any;
  setResidualRisk: any;
  setIsRiskMatrixOverlayOpen: any;
  setIsHazardOverlayOpen: any;
  setIsSaving: any;
  setItemToDelete: any;
  setStepToUpdate: any;
  setControlsToSave: any;
  setIsPpeDrawerOpen: any;
  setAssignToConflictModalOpen: any;
  setSendForConfirmModalOpen: any;
  setConfirmModalOpen: any;
  setIsValidationModalOpen: any;
  setEditingHazards: any;
  setIsModalOpen: any;
}

function useClientState(): Result {
  const dispatch = useDispatch();

  const setIsModalOpen = useCallback(
    (isModalOpen: boolean) => {
      dispatch(jsaModalSlice.actions.setIsOpen(isModalOpen));
    },
    [dispatch]
  );

  const setAssignToConflictModalOpen = useCallback(
    (isAssignToConflictModalOpen: boolean) => {
      dispatch(activeUserSlice.actions.setAssignToConflictModalOpen(isAssignToConflictModalOpen));
    },
    [dispatch]
  );
  const setSendForConfirmModalOpen = useCallback(
    (isSendForConfirmModalOpen: boolean) => {
      dispatch(activeUserSlice.actions.setSendForConfirmModalOpen(isSendForConfirmModalOpen));
    },
    [dispatch]
  );

  const setIsValidationModalOpen = useCallback(
    (isValidationModalOpen: boolean) => {
      dispatch(activeUserSlice.actions.setIsValidationModalOpen(isValidationModalOpen));
    },
    [dispatch]
  );

  const setConfirmModalOpen = useCallback(
    (isConfirmModalOpen: boolean) => {
      dispatch(activeUserSlice.actions.setConfirmModalOpen(isConfirmModalOpen));
    },
    [dispatch]
  );

  const setIsRiskMatrixOverlayOpen = useCallback(
    (isRiskMatrixOverlayOpen: boolean) => {
      dispatch(workStepsSlice.actions.setIsRiskMatrixOverlayOpen(isRiskMatrixOverlayOpen));
    },
    [dispatch]
  );

  const setIsSaving = useCallback(
    (isSaving: boolean) => {
      dispatch(jsaFormSlice.actions.setIsSaving(isSaving));
    },
    [dispatch]
  );

  const setItemToDelete = useCallback(
    (itemToDelete: null | string) => {
      dispatch(workStepsSlice.actions.setItemToDelete(itemToDelete));
    },
    [dispatch]
  );

  const setResidualRisk = useCallback(
    (residualRisk: null | number) => {
      dispatch(workStepsSlice.actions.setResidualRisk(residualRisk));
    },
    [dispatch]
  );
  const setInitialRisk = useCallback(
    (initialRisk: null | number) => {
      dispatch(workStepsSlice.actions.setInitialRisk(initialRisk));
    },
    [dispatch]
  );
  const setInitialSeverity = useCallback(
    (initialSeverity: null | number) => {
      dispatch(workStepsSlice.actions.setInitialSeverity(initialSeverity));
    },
    [dispatch]
  );
  const setResidualSeverity = useCallback(
    (residualSeverity: null | number) => {
      dispatch(workStepsSlice.actions.setResidualSeverity(residualSeverity));
    },
    [dispatch]
  );
  const setInitialLikelihood = useCallback(
    (initialLikelihood: null | number) => {
      dispatch(workStepsSlice.actions.setInitialLikelihood(initialLikelihood));
    },
    [dispatch]
  );
  const setResidualLikelihood = useCallback(
    (residualLikelihood: null | number) => {
      dispatch(workStepsSlice.actions.setResidualLikelihood(residualLikelihood));
    },
    [dispatch]
  );

  const setIsInitialRiskOverlayOpen = useCallback(
    (isInitialRiskOverlayOpen: boolean) => {
      dispatch(workStepsSlice.actions.setIsInitialRiskOverlayOpen(isInitialRiskOverlayOpen));
    },
    [dispatch]
  );
  const setIsResidualRiskOverlayOpen = useCallback(
    (isResidualRiskOverlayOpen: boolean) => {
      dispatch(workStepsSlice.actions.setIsResidualRiskOverlayOpen(isResidualRiskOverlayOpen));
    },
    [dispatch]
  );
  const setIsPpeDrawerOpen = useCallback(
    (isPpeDrawerOpen: boolean) => {
      dispatch(workStepsSlice.actions.setIsPpeDrawerOpen(isPpeDrawerOpen));
    },
    [dispatch]
  );

  const setIsHazardOverlayOpen = useCallback(
    (isHazardOverlayOpen: boolean) => {
      dispatch(workStepsSlice.actions.setIsHazardOverlayOpen(isHazardOverlayOpen));
    },
    [dispatch]
  );

  const setHazardsToSave = useCallback(
    (hazardsToSave: boolean) => {
      dispatch(workStepsSlice.actions.setHazardsToSave(hazardsToSave));
    },
    [dispatch]
  );

  const setCustomHazardsToSave = useCallback(
    (categoriesWithCustomHazards: boolean) => {
      dispatch(workStepsSlice.actions.setCustomHazardsToSave(categoriesWithCustomHazards));
    },
    [dispatch]
  );

  const setEditingHazards = useCallback(
    (editingHazards: boolean) => {
      dispatch(workStepsSlice.actions.setEditingHazards(editingHazards));
    },
    [dispatch]
  );

  const setControlsToSave = useCallback(
    (controlsToSave: boolean) => {
      dispatch(workStepsSlice.actions.setControlsToSave(controlsToSave));
    },
    [dispatch]
  );

  const setStepToUpdate = useCallback(
    (stepToUpdate: boolean) => {
      dispatch(workStepsSlice.actions.setStepToUpdate(stepToUpdate));
    },
    [dispatch]
  );

  const setHazardToUpdate = useCallback(
    (hazardToUpdate: boolean) => {
      dispatch(workStepsSlice.actions.setHazardToUpdate(hazardToUpdate));
    },
    [dispatch]
  );

  const setIsControlsOverlayOpen = useCallback(
    (isHazardOverlayOpen: boolean) => {
      dispatch(workStepsSlice.actions.setIsControlsOverlayOpen(isHazardOverlayOpen));
    },
    [dispatch]
  );

  const resetWorkSteps = useCallback(() => {
    dispatch(workStepsSlice.actions.resetWorkSteps());
  }, [dispatch]);

  return {
    resetWorkSteps,
    setControlsToSave,
    setHazardsToSave,
    setHazardToUpdate,
    setIsControlsOverlayOpen,
    setIsInitialRiskOverlayOpen,
    setInitialLikelihood,
    setInitialSeverity,
    setInitialRisk,
    setIsRiskMatrixOverlayOpen,
    setIsHazardOverlayOpen,
    setIsSaving,
    setItemToDelete,
    setStepToUpdate,
    setIsResidualRiskOverlayOpen,
    setResidualLikelihood,
    setResidualSeverity,
    setResidualRisk,
    setIsPpeDrawerOpen,
    setAssignToConflictModalOpen,
    setSendForConfirmModalOpen,
    setConfirmModalOpen,
    setEditingHazards,
    setCustomHazardsToSave,
    setIsModalOpen,
    setIsValidationModalOpen,
  };
}

export default useClientState;
