import React from 'react';
import styled from 'styled-components';
import Typography from '../Typography/Typography';

export const StyledSummaryPpeCard = styled.div`
  width: 110px;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
`;

export const StyledSummaryPpeCardIconWrapper = styled(({ colour, ...rest }) => <div {...rest} />)`
  height: 80px;
  width: 110px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 70px;
    height: 70px;

    path {
      fill: ${({ colour }) => (colour !== '#1c7b97' ? colour : '#213d58')};
    }
  }
`;

export const StyledSummaryPpeCardTextWrapper = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  && {
    h5 {
      color: #0a0d0d;
      font-size: 12px;
      font-weight: 600;
      line-height: 15px;
      text-align: center;
    }
  }
`;

export const StyledPpeText = styled(Typography)`
  font-weight: 600;
  line-height: 17px !important;
  text-align: center;
  letter-spacing: 0.17px;
  margin-top: 8px;
`;
