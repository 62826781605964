import React from 'react';
import styled from 'styled-components';
import Button from './Button';

const StyledInputAdornmentButton = styled(Button)`
  height: 40px;
  width: 100px;
  border-radius: 5px;
  &:disabled {
    .MuiButton-label {
      color: ${(props) => props.theme.colors.findColor('greyScale_25')};
    }
  }
`;

interface Props {
  onClick?: () => any;
  onMouseDown?: () => any;
  disabled?: boolean;
}

const InputAdornmentButton: React.FC<Props> = function ({ disabled, ...rest }) {
  return (
    <StyledInputAdornmentButton
      preset="primary"
      themeColour="blue"
      size="large"
      disabled={disabled}
      {...rest}
    />
  );
};

export default InputAdornmentButton;
