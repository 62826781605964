import { useEffect, useContext } from 'react';
import { getIn } from 'formik';
import get from 'lodash/get';
import useDebounce from './useDebounce';
import useMasterData from './useMasterData';
import { JsaFormContext } from '../features/JsaForm/JsaForm';
import useOperatingFacilityData from './useOperatingFacilityData';

function useFacilityField(
  fieldPath: string,
  facilityFieldPath: string,
  selector?: (selectedFacility: any, facilities: any, facilityControls: any) => any | undefined
) {
  const formikContext: any = useContext(JsaFormContext);
  const { data: masterData } = useMasterData();
  const { data: operatingAreaData } = useOperatingFacilityData();
  const field1Value = getIn(formikContext.values, 'workDetails.siteName.id');
  const debouncedValues = useDebounce(field1Value, 300);

  useEffect(() => {
    if (field1Value && masterData) {
      const facilities = get(masterData, 'masterData.items', []);
      const medicalFacilities = get(masterData, 'medicalFacility.items', []);
      const facilityControls = get(masterData, 'facilityControl.items', []);
      const operatingAreas = get(operatingAreaData, 'operatingFacilityData.items', []);
      // @TODO: Type this
      const selectedFacility = facilities.find(({ fields }: any) => fields.code === field1Value);

      if (selectedFacility) {
        const selectedMedical = selectedFacility.fields.medicalFacility;
        const selectOperating = selectedFacility.fields.operatingArea;

        if (selectedMedical) {
          const selectedMedicalInfo = medicalFacilities.find(
            ({ sys }: any) => sys.id === selectedMedical.sys.id
          );

          const value = {
            id: selectedMedicalInfo.fields.code,
            label: `${selectedMedicalInfo.fields.jobcareName}`,
            value: '',
          };

          formikContext.setFieldValue('emergencyDetails.nearestMedicalFacility', value);
          formikContext.setFieldValue(
            'emergencyDetails.nearestMedicalFacilityContactDetails',
            selectedMedicalInfo.fields.contactNumber
          );
        }
        if (selectOperating) {
          const selectedOperatingArea = operatingAreas.find(
            ({ sys }: any) => sys.id === selectOperating.sys.id
          );

          const value = `${selectedOperatingArea.fields.name}`;

          formikContext.setFieldValue('workDetails.operatingArea', value);
        }
        let fieldValue;

        if (selector) {
          fieldValue = selector(selectedFacility, facilities, facilityControls);
        } else {
          fieldValue = getIn(selectedFacility, facilityFieldPath);
        }

        fieldValue && formikContext.setFieldValue(fieldPath, fieldValue);
      }
    } else if (field1Value === null) {
      formikContext.setFieldValue(fieldPath, '');
      formikContext.setFieldValue('emergencyDetails.nearestMedicalFacility', '');
      formikContext.setFieldValue('emergencyDetails.nearestMedicalFacilityContactDetails', '');
      formikContext.setFieldValue('workDetails.operatingArea', '');
    }
  }, [debouncedValues]);

  return null;
}

export default useFacilityField;
