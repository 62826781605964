import styled from 'styled-components';
import Typography from '../Typography/Typography';

export const StyledHazardChip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const GotoTopAligner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`;

export const StyledControlsOverlaySubTitle = styled(Typography).attrs(() => ({
  variant: 'subtitle1',
}))`
  &.MuiTypography-subtitle1 {
    color: ${({ theme }) => theme.colors.findColor('greyScale_95')};
    margin-bottom: 20px;
  }
  text-align: center;
`;
export const StyledPpeString = styled(Typography)`
  padding-bottom: 23px;
`;
