import React from 'react';
import FileIcon from './../Icons/FileIcon';

const FileMessage = (props) => {
  const activeName = props.userData.given_name + ' ' + props.userData.family_name;

  return (
    <div>
      <p className="sc-message-name fileAuthor">{props.author}</p>
      <div style={{ display: 'flex' }}>
        <a
          target="__blank"
          className="sc-message--file"
          href={`https://${props.file.bucket}.s3-${props.file.region}.amazonaws.com/${props.file.key}`}
        >
          <FileIcon />
          <p>{props.fileName}</p>
        </a>
        <p className=" file">{props.date}</p>
      </div>
      {activeName === props.author && (
        <p className="sc-message-link" onClick={() => props.onMessageDeleted(props)}>
          Delete
        </p>
      )}
    </div>
  );
};

export default FileMessage;
