import React, { memo, Fragment, useEffect, useState } from 'react';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import orderBy from 'lodash/fp/orderBy';
import get from 'lodash/get';
import { useQuery } from '@apollo/client';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import {
  Table,
  TablePagination,
  TableCell,
  TableHead,
  RadioGroup,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import { Queries } from '../../api-queries';
import StatusChip from '../../components/StatusChip/StatusChip';
import { Radio, Pagination } from '../../components';
import {
  StyledTableRow,
  StyledField,
  StyledTableRowHead,
  StyledTableBody,
  StyledPaginationTable,
  StyledTitleCell,
  SpinnerContainer,
  StyledSpinner,
  StyledFormControl,
  StyledFormLabel,
  ErrorContainer,
} from './style';
import { ReactComponent as EmptyStateSvg } from '../../components/Icon/svgs/empty-state.svg';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthProvider';

interface JsaInterface {
  id?: string;
  status?: string;
  createdDate?: string;
  workOrderId?: string;
  siteName?: string;
  descriptionOfWorks?: string;
  __typename?: string;
}

const JsaList = memo(() => {
  const [page, setPage] = useState(0);
  const [isErrored, setIsErrored] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [myJSA, setMyJSA] = useState(true);
  const [jsaList, setJsaList] = useState<any>();
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  function handleRowClick(jsa: JsaInterface) {
    const { id, status } = jsa;
    if (status !== 'draft') {
      navigate(`/jsa/${id}/summary`);
    } else {
      navigate(`/jsa/${id}/work-details`);
    }
  }

  function getFullName(data: any) {
    const firstName = data.firstName ? data.firstName : '';
    const lastName = data.lastName ? data.lastName : '';
    return `${firstName} ${lastName}`;
  }

  const {
    loading,
    error: queryError,
    data,
    fetchMore,
  } = useQuery(Queries.GET_DASHBOARD_LIST, {
    fetchPolicy: 'network-only',
    variables: {
      sort: 'jsaId:DESC',
      limit: 1000,
      filter: {
        status: {
          notContains: 'cancelled',
        },
      },
    },
  });

  useEffect(() => {
    if (data) {
      if (!jsaList) {
        setJsaList(data);
      }
    }
  }, [data]);

  useEffect(() => {
    if (jsaList) {
      if (jsaList.listJobSafetyAnalyses.nextToken !== null) {
        try {
          fetchMore({
            variables: { nextToken: jsaList.listJobSafetyAnalyses.nextToken },
            updateQuery: (prev: any, { fetchMoreResult }) => {
              if (!fetchMoreResult) return jsaList;
              const newList = {
                listJobSafetyAnalyses: {
                  items: [
                    ...jsaList.listJobSafetyAnalyses.items,
                    ...fetchMoreResult.listJobSafetyAnalyses.items,
                  ],
                  nextToken: fetchMoreResult.listJobSafetyAnalyses.nextToken,
                  __typename: 'JobSafetyAnalysisConnection',
                },
              };
              setJsaList(newList);
              return newList;
            },
          });
        } catch (e) {
          setIsErrored(true);
        }
      }
    }
  }, [jsaList]);

  function handleChangeJSA(value: any) {
    setMyJSA(value.currentTarget.value === 'true');
    setPage(0);
  }
  if (loading && !jsaList) {
    return (
      <SpinnerContainer>
        <StyledSpinner size={80} />
        <p>Loading JSA list...</p>
      </SpinnerContainer>
    );
  }

  if (isErrored || queryError) {
    return (
      <ErrorContainer>
        <EmptyStateSvg height={88} width={88} />
        <Typography>
          Oh no! It looks like something went wrong on our end, try refreshing the page.
        </Typography>
      </ErrorContainer>
    );
  }

  let items = get(jsaList, 'listJobSafetyAnalyses.items', []);

  items = items.filter((item: any) => {
    return item.status !== 'cancelled';
  });

  if (myJSA && user !== null) {
    items = items.filter((item: any) => {
      const associated = false;
      if (item.createdBy) {
        if (item.createdBy.email !== null && user.email !== null) {
          if (item.createdBy.email.toLowerCase() === user.email.toLowerCase()) {
            return true;
          }
        }
      }
      if (item.assignedTo) {
        if (item.assignedTo.email !== null && user.email !== null) {
          if (item.assignedTo.email.toLowerCase() === user.email.toLowerCase()) {
            return true;
          }
        }
      }
      if (item.confirmedBy) {
        if (item.confirmedBy.email !== null && user.email !== null) {
          if (item.confirmedBy.email.toLowerCase() === user.email.toLowerCase()) {
            return true;
          }
        }
      }
      if (item.reviewedBy) {
        if (item.reviewedBy.email !== null && user.email !== null) {
          if (item.reviewedBy.email.toLowerCase() === user.email.toLowerCase()) {
            return true;
          }
        }
      }
      if (item.submittedBy) {
        if (item.submittedBy.email !== null && user.email !== null) {
          if (item.submittedBy.email.toLowerCase() === user.email.toLowerCase()) {
            return true;
          }
        }
      }
      if (item.toConfirmEmail) {
        if (item.toConfirmEmail.email !== null && user.email !== null) {
          if (item.toConfirmEmail.toLowerCase() === user.email.toLowerCase()) {
            return true;
          }
        }
      }
      if (item.workDetails) {
        if (item.workDetails.personInCharge) {
          if (item.workDetails.personInCharge.email && user.email !== null) {
            if (item.workDetails.personInCharge.email.toLowerCase() === user.email.toLowerCase()) {
              return true;
            }
          }
        }
        if (item.workDetails.watercareRepresentative) {
          if (item.workDetails.watercareRepresentative.email && user.email !== null) {
            if (
              item.workDetails.watercareRepresentative.email.toLowerCase() === user.email.toLowerCase()
            ) {
              return true;
            }
          }
        }
      }
      if (item.collaboratorDetails) {
        const result = item.collaboratorDetails.collaborators
          ? item.collaboratorDetails.collaborators.filter(
              (collaborator: any) => collaborator.email.toLowerCase() === user.email.toLowerCase()
            )
          : [];
        if (result.length > 0) {
          return true;
        }
      }
      return associated;
    });
  }

  const renderConfirmedDate = (confirmedDate: string | null) => {
    if (!confirmedDate) return '';
    return format(parseISO(confirmedDate), 'd MMM yyyy');
  };

  let count = 0;

  return (
    <>
      <StyledField>
        <span>
          Showing result{' '}
          {page * rowsPerPage + rowsPerPage > items.length ? (
            <b style={{ fontWeight: 'bold' }}>
              {page * rowsPerPage + 1}-{items.length}
            </b>
          ) : (
            <b style={{ fontWeight: 'bold' }}>
              {page * rowsPerPage + 1}-{page * rowsPerPage + rowsPerPage}
            </b>
          )}{' '}
          out of {' ' + items.length}.
        </span>
        <StyledFormControl component="fieldset">
          <StyledFormLabel component="p">Show me:</StyledFormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={`${myJSA}`}
            onChange={handleChangeJSA}
            row
          >
            <FormControlLabel value="true" control={<Radio />} label="My JSA" />
            <FormControlLabel value="false" control={<Radio />} label="All JSA" />
          </RadioGroup>
        </StyledFormControl>
      </StyledField>
      <Table>
        <TableHead>
          <StyledTableRowHead>
            <TableCell>JSA #</TableCell>
            <TableCell>TITLE</TableCell>
            <TableCell>OPERATING AREA</TableCell>
            <TableCell>WRITTEN BY</TableCell>
            <TableCell>PERSON IN CHARGE</TableCell>
            <TableCell>WO #</TableCell>
            <TableCell>STATUS</TableCell>
            <TableCell>WATERCARE REPRESENTATIVE</TableCell>
            <TableCell>CREATED DATE</TableCell>
            <TableCell>CONFIRMED DATE</TableCell>
          </StyledTableRowHead>
        </TableHead>
        <StyledTableBody>
          {flow(
            orderBy(['createdDate'], ['desc']),
            map((row: any) => {
              count++;
              return (
                <Fragment key={`${row.jsaId}-${count}`}>
                  {count - 1 >= page * rowsPerPage && count - 1 < page * rowsPerPage + rowsPerPage && (
                    <StyledTableRow onClick={() => handleRowClick(row)}>
                      <TableCell scope="row">{row.jsaId}</TableCell>
                      <StyledTitleCell>{row.jsaApplicationTitle}</StyledTitleCell>
                      <TableCell>{(row.workDetails && row.workDetails.operatingArea) || ''}</TableCell>
                      <TableCell>
                        {row.createdBy && `${row.createdBy.firstName} ${row.createdBy.lastName}`}
                      </TableCell>
                      <TableCell>
                        {row.workDetails &&
                          row.workDetails.personInCharge &&
                          getFullName(row.workDetails.personInCharge)}
                      </TableCell>
                      <TableCell>{row.workOrderId}</TableCell>
                      <TableCell>
                        <StatusChip value={row.status} />
                      </TableCell>
                      <TableCell>
                        {row.workDetails &&
                          row.workDetails.watercareRepresentative &&
                          getFullName(row.workDetails.watercareRepresentative)}
                      </TableCell>
                      <TableCell>{format(parseISO(row.createdDate), 'd MMM yyyy')}</TableCell>
                      <TableCell>{renderConfirmedDate(row.confirmedDate)}</TableCell>
                    </StyledTableRow>
                  )}
                </Fragment>
              );
            })
          )(items)}
        </StyledTableBody>
      </Table>

      <StyledPaginationTable>
        <tfoot>
          <tr>
            <TablePagination
              rowsPerPageOptions={[
                { label: '10 results per page', value: 10 },
                { label: '25 results per page', value: 25 },
                { label: '50 results per page', value: 50 },
              ]}
              count={items.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'show' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={Pagination}
            />
          </tr>
        </tfoot>
      </StyledPaginationTable>
    </>
  );
});

JsaList.displayName = 'JsaList';

export default JsaList;
