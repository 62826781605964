import React from 'react';
import { DotWrapper } from './style';

interface workOrder {
  workOrderId: string;
}

const WorkOrder = function (props: workOrder) {
  return <DotWrapper> WO #{props.workOrderId}</DotWrapper>;
};

export default WorkOrder;
