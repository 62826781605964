import React from 'react';
import styled from 'styled-components';
import AddIcon from '@material-ui/icons/Add';
import Button from '../../components/Button/Button';

const StyledButton = styled(Button)`
  border: 2px dashed lightgray;
  height: 50px;
  background-color: white;
  border-radius: 4px;
  width: 100%;

  & .MuiButton-label {
    color: #0a0d0d;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.2px;
    line-height: 24px;
  }

  & path {
    fill: #b6bebf;
  }

  &:hover {
    border: 2px solid lightgray;
    cursor: pointer;

    & .MuiButton-label {
      color: #213d58;
    }

    & path {
      fill: #213d58;
    }
  }
`;

interface Props {
  onClick?: (e?: any) => any;
}

const AddStepButton: React.FC<Props> = function ({ ...rest }) {
  return (
    <StyledButton id="add_a_step_button" preset="tertiary" fullWidth {...rest}>
      <AddIcon />
      Add a Step
    </StyledButton>
  );
};

export default AddStepButton;
