import React from 'react';
import { Select as MuiSelect } from 'formik-material-ui';
import styled from 'styled-components';
import Icon from '../Icon/Icon';

export const StyledSelect = styled(({ size, ...rest }) => (
  <MuiSelect
    {...rest}
    IconComponent={({ ...rest }) => <Icon {...rest} name="ArrowDown" preset="tertiary" />}
  />
))`
  height: 50px;
  //TO-DO fix to be responsive to grid
  width: 380.91px;
  background-color: white;

  & #select-test-input {
    color: ${(props) => props.theme.colors.greyScale['95']};
    letter-spacing: 0.2px;
    line-height: 24px;
    font-size: 18px;
    display: inline-block;
  }

  & svg {
    height: 24px;
    width: 24px;
    color: ${(props) => props.theme.colors.greyScale['50']};
  }

  & #menu-test-input {
    margin-top: 5%;
  }
`;
