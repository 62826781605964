import React from 'react';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import Modal from '../Modal/Modal';
import ModalTitle from '../Modal/ModalTitle';
import ModalContent from '../Modal/ModalContent';
import ModalContentText from '../Modal/ModalContentText';
import ModalActions from '../Modal/ModalActions';
import GridContainer from '../Grid/GridContainer';
import { GridItem, Button } from '..';
import useClientState from '../../hooks/useClientState';

const HazardsOverlayDeleteModal = function ({ isOpen, toggleDeleteModal }: any) {
  const { values } = useFormikContext();
  const { setIsHazardOverlayOpen, setHazardsToSave } = useClientState();
  const selectedHazards = get(values, 'selectedHazards', []);
  const draftSelectedHazards = get(values, 'draftSelectedHazards', []);
  const selectedHazardsToKeep = get(values, 'selectedHazardsToKeep', []);

  function handleRemove() {
    const concatenatedHazards = selectedHazards.concat(draftSelectedHazards);
    setHazardsToSave(concatenatedHazards);
    toggleDeleteModal();
    setIsHazardOverlayOpen(false);
  }

  function handleCancel() {
    const concatenatedHazards = selectedHazards.concat(selectedHazardsToKeep, draftSelectedHazards);
    setHazardsToSave(concatenatedHazards);
    toggleDeleteModal();
    setIsHazardOverlayOpen(false);
  }

  return (
    <Modal open={isOpen}>
      <ModalTitle onClose={() => toggleDeleteModal()}>Remove hazard(s)?</ModalTitle>
      <ModalContent>
        <ModalContentText>
          By removing this hazard, any risk assessment and controls will be removed.
        </ModalContentText>
        <ModalActions>
          <GridContainer>
            <GridItem xs={6}>
              <Button preset="secondary" onClick={() => handleCancel()} themeColour="grey" fullWidth>
                Cancel
              </Button>
            </GridItem>
            <GridItem xs={6}>
              <Button preset="primary" themeColour="red" fullWidth onClick={handleRemove}>
                Remove
              </Button>
            </GridItem>
          </GridContainer>
        </ModalActions>
      </ModalContent>
    </Modal>
  );
};

export default HazardsOverlayDeleteModal;
