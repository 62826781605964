import { createSelector } from 'reselect';
import get from 'lodash/get';

// Base selectors
export const getJobSafetyAnalysis = (data: any) => get(data, 'getJobSafetyAnalysis', {});
export const getWorkSteps = (data: any) => get(data, 'getJobSafetyAnalysis.workSteps', []);
export const getWorkStep = (data: any) => get(data, 'workStep', {});
export const getWorkStepNumber = (data: any) => get(data, 'stepNumber', null);
export const getControlMeasures = (controls: any) => get(controls, 'controlMeasures', []);
export const getIsolations = (controls: any) => get(controls, 'isolations', []);
export const getPermits = (controls: any) => get(controls, 'permits', []);
export const getInitialRisk = (data: any) => get(data, 'initialRisk.risk', null);
export const getResidualRisk = (data: any) => get(data, 'residualRisk', null);
export const getPpe = (data: any) => get(data, 'ppe', null);

// Computed selectors
export const getPpeNames = createSelector([getPpe], (ppe) => {
  if (ppe && ppe !== '') {
    return ppe.map((ppeItem: any) => ppeItem.ppeName);
  }
  return [];
});

export const getControlMeasuresSelector = createSelector([getControlMeasures], (controlMeasures) => {
  // DynamoDB does not save empty string or arrays.
  // Empty string returned when property not in DynamoDB record.
  if (controlMeasures && controlMeasures !== '') {
    return controlMeasures;
  }
  return [];
});

export const getPpeString = createSelector([getPpeNames], (ppeNames) => {
  if (ppeNames.length > 0) {
    const ppeNamesString =
      ppeNames.length === 1
        ? ppeNames[0]
        : `${ppeNames.slice(0, -1).join(', ')}, and ${ppeNames.slice(-1)}`;

    return `You have selected: ${ppeNamesString}.`;
  }
});

export const getWorkStepsAndControls = createSelector([getWorkSteps], (workSteps) => {
  if (workSteps.length) {
    return workSteps.filter((workStep: any) => workStep.text);
  }

  return [];
});

export const getWorkStepText = createSelector(
  [getWorkStep, getWorkStepNumber],
  (workStep, workStepNumber) => `${workStepNumber}. ${workStep.text}`
);

export const getWorkStepHazards = createSelector([getWorkStep], (workStep) => workStep.hazards || []);

export const getRequiredIsolations = createSelector([getIsolations], (isolation) => {
  if (isolation.required === 'Yes') {
    return isolation;
  }
});

export const getIsolationActionString = createSelector([getRequiredIsolations], (requiredIsolation) => {
  if (requiredIsolation && requiredIsolation.type === 'isolationCertificate') {
    return 'Apply for Isolation Certificate';
  }
  if (
    requiredIsolation &&
    requiredIsolation.type === 'selfIsolation' &&
    requiredIsolation.selfIsolationValue
  ) {
    return `Self-Isolation (LOTO): ${requiredIsolation.selfIsolationValue}`;
  }
});

export const getRequiredPermitName = createSelector([getPermits], (permits) => {
  if (permits && permits.required) {
    return `Apply for a ${permits.name}`;
  }
});
