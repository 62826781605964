const workFlowRoutes = [
  { label: 'Work Details', headerLabel: '1  Work Details', route: 'work-details' },
  {
    label: 'Emergency Details',
    headerLabel: '2  Emergency Details',
    route: 'emergency-details',
  },
  {
    label: 'Hazards & Controls',
    headerLabel: '3  Hazards & Controls',
    route: 'hazards-controls',
  },
  { label: 'Workpack', headerLabel: '4  Workpack', route: 'workpack' },
  { label: 'Summary', headerLabel: '5  Summary', route: 'summary' },
];

export default workFlowRoutes;
