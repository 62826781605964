import styled from 'styled-components';
import React from 'react';
import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import { GridContainer, GridItem } from '../../components';

export const StyledButton = styled(Button)`
  margin-top: 52px;
`;

export const StyledIcon = styled(Icon)`
  margin-top: 70px;
  margin-left: -12px;
  margin-right: -13px;
  width: 24px;
`;

export const JSAEmailItemWrapper = styled(GridItem)`
  margin-top: 11.5px !important;
`;

export const JsaWrittenByWrapper = styled(GridContainer)`
  padding-top: 17.5px;
  margin-top: 13px;
`;

export const SiteAddressWrapper = styled(GridContainer)`
  margin-top: 12px;
`;

export const PersonInChargeWrapper = styled(GridContainer)`
  padding-top: 17.5px;
`;
export const PersonInChargeLabel = styled(GridItem)`
  padding-bottom: 0px !important;
`;

export const RadioWrapper = styled(({ paddingBottom, ...rest }) => <GridItem {...rest} />)`
  padding-top: 20px !important;
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? '0' : '30px')} !important;
`;
export const WatercareRepWrapper = styled(GridItem)`
  padding-top: 0 !important;
  margin-top: 12.5px;
`;

export const WatercareRepSameWrapper = styled(GridItem)`
  padding-top: 10px !important;
  padding-bottom: 18.5px !important;
`;

export const FormFieldWrapper = styled(GridItem)`
  padding-top: 30px !important;
`;
export const TopFieldWrapper = styled(GridItem)`
  padding-bottom: 0px !important;
  margin-top: -12.5px !important;
`;
