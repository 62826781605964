import React from 'react';
import { withRouter } from '../../utils/withRouter';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import PrintIcon from '@material-ui/icons/Print';
import parseISO from 'date-fns/parseISO';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { useMatch, useParams } from 'react-router-dom';
import { getIsSaving } from '../../redux/jsaFormSlice';
import StatusChip from '../../components/StatusChip/StatusChip';
import JsaContextMenu from '../JsaContextMenu/JsaContextMenu';
import {
  StyledMuiToolbar,
  StyledAppToolbar,
  JsaNumber,
  StyledIconButton,
  LastSaved,
  VerticalAligner,
} from './style';
import WorkOrder from './WorkOrder';
import { useJsaQuery } from '../../hooks';

const AppToolbar = function () {
  const { data } = useJsaQuery();
  const { jsa } = useParams();
  const isSaving = useSelector(getIsSaving);
  const isPrintSummaryEnabled = useMatch(`/jsa/${jsa}/summary`);

  if (!data) {
    return null;
  }

  const jsaValues = get(data, 'getJobSafetyAnalysis');
  const { jsaId, jsaApplicationTitle, updatedDate, status, workOrderId } = jsaValues;
  const lastSaved = updatedDate ? formatDistanceToNow(parseISO(updatedDate), { addSuffix: true }) : '';
  const lastSavedText = isSaving ? 'Saving...' : `Last saved ${lastSaved || ''}`;

  return (
    <StyledAppToolbar className="screen-only">
      <StyledMuiToolbar>
        <VerticalAligner>
          <h2>{jsaApplicationTitle}</h2>
          <LastSaved>{lastSavedText || ''}</LastSaved>
        </VerticalAligner>
        <VerticalAligner>
          <JsaNumber>
            {jsaId}
            &nbsp;
            {workOrderId && <WorkOrder workOrderId={workOrderId} />}
          </JsaNumber>
          <StatusChip value={status} />
          {isPrintSummaryEnabled ? (
            <StyledIconButton
              onClick={() => {
                window.print();
              }}
            >
              <PrintIcon />
            </StyledIconButton>
          ) : (
            <StyledIconButton disabled color="primary">
              <PrintIcon />
            </StyledIconButton>
          )}
          <JsaContextMenu jsaValues={jsaValues} />
        </VerticalAligner>
      </StyledMuiToolbar>
    </StyledAppToolbar>
  );
};

export default withRouter(AppToolbar);
