import React, { memo } from 'react';
import Modal from '../../components/Modal/Modal';
import ModalTitle from '../../components/Modal/ModalTitle';
import ModalContent from '../../components/Modal/ModalContent';
import Button from '../../components/Button/Button';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import ModalActions from '../../components/Modal/ModalActions';
import ModalContentText from '../../components/Modal/ModalContentText';
import setInitialValues from '../JsaForm/setInitialValues';
import { useMutation } from '@apollo/client';
import { Mutations, Queries } from '../../api-queries';
import { Form } from 'formik';
import JsaForm from '../JsaForm/JsaForm';
import { useNavigate } from 'react-router';
import { InputVariable } from '../WorkflowLayout/model';
import { useSendGrid } from '../../hooks';
import { useAuth } from 'contexts/AuthProvider';

const CancelJsaModal = memo(({ isModalOpen, handleModalClose, jsaValues }: any) => {
  const { user } = useAuth();
  const titleStyle = 'padding-top:50px; padding-bottom: 0px;';
  const updateJsa = Mutations.UpdateJsa;
  const initialValues = setInitialValues(jsaValues);
  const navigate = useNavigate();
  const { sendStatusChangeEmail } = useSendGrid();

  const [updateStatusMutation] = useMutation(updateJsa, {
    onCompleted(updateJsa) {
      handleModalClose();
      navigate(`/jsa/${jsaValues.id}/summary`);
      sendStatusChangeEmail(updateJsa);
    },
    refetchQueries: () => [
      {
        query: Queries.GET_JSA_BY_ID,
        variables: {
          id: jsaValues.id,
        },
      },
    ],
  });

  const pushUpdate = (_input: any) => {
    updateStatusMutation({
      variables: {
        input: _input,
      },
    });
  };

  const handleUpdate = () => {
    const inputVariable: InputVariable = {};
    inputVariable.id = jsaValues.id;
    inputVariable.status = 'cancelled';
    inputVariable.cancelledBy = user;
    inputVariable.cancelledDate = new Date().toISOString();
    pushUpdate(inputVariable);
  };
  return (
    <Modal size="855px" height="310px" open={isModalOpen}>
      <ModalTitle onClose={handleModalClose} styles={titleStyle}>
        Cancel JSA?
      </ModalTitle>
      <ModalContent>
        <JsaForm
          shouldAutosave={false}
          mutationName="UpdateJsa"
          initialValues={initialValues}
          onSuccess={handleModalClose}
        >
          <Form>
            <ModalContentText>
              This JSA will no longer be editable and visible on the dashboard once cancelled. Do you
              want to proceed?
            </ModalContentText>
            <ModalActions>
              <GridContainer>
                <GridItem xs={6}>
                  <Button preset="secondary" onClick={handleModalClose} themeColour="grey" fullWidth>
                    NO, GO BACK
                  </Button>
                </GridItem>
                <GridItem xs={6}>
                  <Button onClick={handleUpdate} color="primary" fullWidth>
                    YES, PROCEED
                  </Button>
                </GridItem>
              </GridContainer>
            </ModalActions>
          </Form>
        </JsaForm>
      </ModalContent>
    </Modal>
  );
});

CancelJsaModal.displayName = 'CancelJsaModal ';

export default CancelJsaModal;
