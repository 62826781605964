import React from 'react';
import styled from 'styled-components';
import Button from '../../components/Button/Button';
import GridItem from '../../components/Grid/GridItem';
import RemoveButton from '../../components/RemoveButton/RemoveButton';
import Label from '../../components/Label/Label';
import TextField from '../../components/TextField/TextField';

export const StyledButton = styled(({ selfIsolation, ...rest }) => <Button {...rest} />)`
  margin-top: ${({ selfIsolation }) => (selfIsolation ? '32px' : '10px')};
  margin-left: 15px;

  & .MuiButton-label {
    & svg {
      margin-right: 5px;
    }
  }
`;

export const StyledGridItem = styled(GridItem)`
  margin-top: 0px;
`;

export const StyledGridItemRight = styled(GridItem)`
  display: flex;
  justify-content: flex-end;
  align-self: center;
`;

export const RemoveWrapper = styled.div`
  position: relative;
`;

export const StyledGridItemCenter = styled(GridItem)`
  display: flex;
  align-items: center;
`;

export const StyledRemoveButton = styled(RemoveButton)`
  font-family: 'Nunito Sans';
  font-size: 16px;
  color: ${(props) => props.theme.colors.primary.mediumBlue};

  & path {
    fill: ${(props) => props.theme.colors.primary.mediumBlue};
  }
`;

export const UploadControlsGridItem = styled(({ ...rest }) => <GridItem {...rest} />)`
  display: flex;
  align-items: center;
`;

export const IsolationControlsGridItem = styled(({ ...rest }) => <GridItem {...rest} />)`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const SelfIsolationRemoveWrapper = styled(GridItem)`
  & button {
    margin-top: 15px;
  }
`;

export const UploadControl = styled.div`
  padding-right: 10px;
  text-align: right;

  &:last-of-type {
    padding-right: 0;
    flex-grow: 1;
  }
`;

export const UtilityInputGroup = styled(({ isHidden, ...rest }) => <div {...rest} />)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  width: 100%;

  ${({ isHidden }) =>
    isHidden &&
    `
      visibility: hidden;
    `}
`;

export const UtilityInputLabel = styled(Label)`
  padding: 0;
  display: flex;
  align-items: center;
`;

export const UtilityInputSelectWrapper = styled(Label)`
  padding: 0;
  display: flex;
  align-items: center;
`;

export const UtilityInputWrapper = styled.div`
  grid-column-start: 2;
  grid-column-end: 5;
`;

export const UtilityStyledInput = styled(TextField)`
  && {
    background: white;
    border: 1px solid #e1e5e5;
    border-radius: 5px;

    input {
      border: none;
    }
  }
`;

export const TooltipWrapper = styled.div`
  margin-top: -20px;
  margin-bottom: -32px;
  margin-left: 185px;
`;

export const LinkButtonWrapper = styled.div`
  margin-top: 31px;
`;
