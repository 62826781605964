import React, { useEffect } from 'react';
import { Summary as SummaryPaper } from '../../features';
import {
  GridContainer,
  GridItem,
  Typography,
  ContentLoader,
  PageValidationErrorCard,
} from '../../components';
import useJsaQuery from '../../hooks/useJsaQuery';
import { useSubmitValidation } from 'contexts/useSubmitValidation';
import { useParams } from 'react-router-dom';

const Summary = function () {
  const {
    setHazardAndControlState,
    setWorkDetailsState,
    setEmergencyDetailsState,
    setUserDefinedEmails,
  } = useSubmitValidation();
  const { data, loading } = useJsaQuery();

  const { jsa: jsaId }: any = useParams();

  useEffect(() => {
    if (data) {
      setHazardAndControlState(data.getJobSafetyAnalysis.workSteps);
      setWorkDetailsState(data.getJobSafetyAnalysis.workDetails);
      setEmergencyDetailsState(data.getJobSafetyAnalysis.emergencyDetails);
      setUserDefinedEmails({
        'workDetails.jsaWrittenBy.email': data.getJobSafetyAnalysis.workDetails.jsaWrittenBy.email,
        'workDetails.personInCharge.email': data.getJobSafetyAnalysis.workDetails.personInCharge.email,
        'workDetails.watercareRepresentative.email':
          data.getJobSafetyAnalysis.workDetails.watercareRepresentative.email,
      });
    }
  }, [loading]);

  if (loading) {
    return <ContentLoader />;
  }

  return (
    <GridContainer>
      <GridItem xs={8} className="screen-only">
        <Typography variant="h1" gutterBottom>
          Summary
        </Typography>
        <PageValidationErrorCard jsaId={jsaId} route="summary" />
      </GridItem>
      <GridItem xs={12}>
        <SummaryPaper data={data} />
      </GridItem>
    </GridContainer>
  );
};

export default Summary;
