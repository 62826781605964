import React from 'react';
import { MenuProps } from '@material-ui/core/Menu';
import { StyledMenu } from './style';

const Menu = function (props: MenuProps) {
  return <StyledMenu {...props} />;
};

Menu.defaultProps = {
  keepMounted: true,
  elevation: 0,
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

export default Menu;
