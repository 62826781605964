import React from 'react';
import { useFormikContext, useField, getIn } from 'formik';
import MuiCheckbox from '@material-ui/core/Checkbox';
import { v4 as uuidv4 } from 'uuid';
import { ThemeIcon, StyledIcon, StyledFormControlLabel } from './styles';

const TickIcon = <StyledIcon name="Tick" />;
const UntickIcon = <ThemeIcon name="UnTick" />;

const CheckBox = function ({ id, label, checked, ...rest }: any) {
  const [field] = useField(rest);
  const { setFieldValue } = useFormikContext();
  const { values } = useFormikContext();

  const handleChange = (event: any, value: any) => {
    setFieldValue(field.name as never, value);
    const controlMeasure = getIn(values, 'controls.controlMeasures');
    let controlMeasures = JSON.parse(JSON.stringify(controlMeasure));

    if (controlMeasure === '') {
      controlMeasures = [];
    }

    if (checked) {
      const itemToDelete = controlMeasures.findIndex(
        (controlMeasure: any) => controlMeasure.value === label
      );
      controlMeasures.splice(itemToDelete, 1);
      setFieldValue('controls.controlMeasures' as never, controlMeasures);
    } else if (checked === false) {
      const control = { id: uuidv4(), value: label };
      if (
        controlMeasures[controlMeasures.length - 1] &&
        controlMeasures[controlMeasures.length - 1].value === ''
      ) {
        const temp = { id: controlMeasures[controlMeasures.length - 1].id, value: '' };
        controlMeasures[controlMeasures.length - 1] = control;
        controlMeasures.push(temp);
      } else {
        controlMeasures.push(control);
      }
      setFieldValue('controls.controlMeasures' as never, controlMeasures);
    }
  };

  return (
    <StyledFormControlLabel
      control={
        <MuiCheckbox
          {...field}
          id={id}
          data-testid={`${id}-value`}
          checkedIcon={TickIcon}
          icon={UntickIcon}
          checked={checked || Boolean(field.value)}
          onChange={handleChange}
        />
      }
      label={label}
    />
  );
};

export default CheckBox;
