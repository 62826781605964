import React, { Fragment } from 'react';
import { connect, FieldArray, getIn } from 'formik';
import map from 'lodash/map';
import get from 'lodash/get';
import AddButton from '../../components/Button/AddButton';
import {
  RemoveWrapper,
  StyledRemoveButton,
  FormFieldWrapper,
  AddButtonWrapper,
} from '../EmergencyDetailsForm/style';
import { Accordion } from '../../components/Accordion/Accordion';
import { AccordionSummary, AccordionDetails, AccordionSubText } from '../../components/Accordion/styles';
import GridContainer from '../../components/Grid/GridContainer';
import TextField from '../../components/TextField/TextField';
import useTeamRolesData from '../../hooks/useTeamRolesData';
import TeamRoleInput from '../../components/TeamRoleInput/TeamRoleInput';
import { useJsaQuery } from '../../hooks';

const WorkTeam = function (props: any) {
  const [expandedPanels, setExpandedPanels] = React.useState<{
    panel1: boolean;
    panel2: boolean;
  }>({ panel1: true, panel2: true });

  const handleChange = (panel: string) => () => {
    // @ts-check
    const currentValue = expandedPanels[panel];

    setExpandedPanels({
      ...expandedPanels,
      [panel]: !currentValue,
    });
  };

  const { data } = useJsaQuery();
  const personInCharge = get(data, 'getJobSafetyAnalysis.workDetails.personInCharge');
  const myTeam = getIn(props.formik.values, 'teamDetails.teamContacts');
  const { data: teamRolesData } = useTeamRolesData();
  const teamRoles = get(teamRolesData, 'teamRolesData.items', []).map(({ fields }: any) => ({
    label: fields.name,
  }));

  // Set first row of Work Team to be defaulted to the PICW..
  if (personInCharge) {
    myTeam[0].firstName = personInCharge.firstName;
    myTeam[0].lastName = personInCharge.lastName;
  }

  return (
    <Accordion expanded={expandedPanels.panel1 === true} onChange={handleChange('panel1')}>
      <AccordionSummary>Work Team &amp; Visitors</AccordionSummary>
      <AccordionDetails style={{ marginTop: 12 }}>
        <AccordionSubText>
          Fill your work team with the people who will get the job done, including any site visitors.
        </AccordionSubText>
        <GridContainer>
          <FieldArray
            name="teamDetails.teamContacts"
            render={(arrayHelpers: any) => (
              <>
                {map(myTeam, (teamContact: any, index: any) => {
                  const isFirst = index === 0;
                  return (
                    <Fragment key={index}>
                      <FormFieldWrapper xs={4}>
                        <TextField
                          disabled={isFirst}
                          fullWidth
                          name={`teamDetails.teamContacts[${index}].firstName`}
                          id={`teamDetails.teamContacts[${index}].firstName`}
                          label={index === 0 ? 'First name' : undefined}
                          placeholder="First name"
                        />
                      </FormFieldWrapper>
                      <FormFieldWrapper xs={4}>
                        <TextField
                          disabled={isFirst}
                          fullWidth
                          id={`teamDetails.teamContacts[${index}].lastName`}
                          name={`teamDetails.teamContacts[${index}].lastName`}
                          label={index === 0 ? 'Last name' : undefined}
                          placeholder="Last name"
                        />
                      </FormFieldWrapper>
                      <FormFieldWrapper xs={4}>
                        <RemoveWrapper>
                          <TeamRoleInput
                            disabled={isFirst}
                            name={`teamDetails.teamContacts[${index}].role`}
                            teamroles={teamRoles}
                            label={isFirst ? 'Role' : undefined}
                            placeholder="Choose role"
                          />
                          {!isFirst && (
                            <StyledRemoveButton
                              onClick={() => arrayHelpers.remove(index)} // remove a item from the list
                            />
                          )}
                        </RemoveWrapper>
                      </FormFieldWrapper>
                    </Fragment>
                  );
                })}

                <AddButtonWrapper xs={12}>
                  <AddButton
                    text="Add Member"
                    onClick={() =>
                      arrayHelpers.push({
                        firstName: '',
                        lastName: '',
                        role: 'Team Member',
                      })
                    }
                  />
                </AddButtonWrapper>
              </>
            )}
          />
        </GridContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default connect(WorkTeam);
