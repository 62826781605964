import React, { useEffect, useState } from 'react';
import { Form, useFormikContext, getIn } from 'formik';
import { get, union, unionBy, find, isEmpty } from 'lodash';
import { useHazardData } from '../../hooks';
import { getCategoriesWithHazards } from './selectors';
import HazardCategory from './HazardCategory';
import HazardOverlayEmptyState from './HazardOverlayEmptyState';
import OverlayContent from '../Overlay/OverlayContent';
import SearchBar from '../SearchBar/SearchBar';
import { filterBySearchTerm } from './utils';
import { FadeContainer } from './styles';

const HazardOverlayContent = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [shouldSetScrollTrigger, setShouldSetScrollTrigger] = useState(true);
  const { data } = useHazardData();
  const categoriesWithHazards = getCategoriesWithHazards(data as never);
  const { values, setFieldValue } = useFormikContext();
  const valuesCategoriesWithCustomHazards = getIn(values, 'categoriesWithCustomHazards', []);
  const categoriesWithCustomHazards = isEmpty(valuesCategoriesWithCustomHazards)
    ? []
    : valuesCategoriesWithCustomHazards;
  const listOfCategories = union(
    categoriesWithHazards.map((category: any) => category.code),
    categoriesWithCustomHazards.map((category: any) => category.code)
  );

  let numberOfCategories = 0;

  useEffect(() => {
    const { innerWidth: width } = window;
    numberOfCategories === width % 200
      ? setShouldSetScrollTrigger(false)
      : setShouldSetScrollTrigger(true);
  }, [searchTerm, shouldSetScrollTrigger, numberOfCategories]);

  const handleOnAddCategory = ({ name, value, category }: any) => {
    const existingCustomCategory = categoriesWithCustomHazards.find(
      (item: { code: any }) => item.code === category.code
    );

    const newHazard = [
      {
        code: name,
        name: value,
        category: {
          code: category.code,
          name: category.name,
        },
      },
    ];
    const newCustomCategories = categoriesWithCustomHazards.filter(
      (item: { code: any }) => item.code !== category.code
    );
    newCustomCategories.push({
      code: category.code,
      name: category.name,
      hazards: union(get(existingCustomCategory, 'hazards', []), newHazard),
    });
    setFieldValue('categoriesWithCustomHazards' as never, newCustomCategories);
    setFieldValue(
      'draftSelectedHazards' as never,
      union(get(values, 'draftSelectedHazards', []), newHazard)
    );
  };

  return (
    <OverlayContent shouldSetScrollTrigger={shouldSetScrollTrigger}>
      <SearchBar setSearchTerm={setSearchTerm} />
      <Form>
        {listOfCategories.map((categoryCode, index: number) => {
          const categoryWithCustomHazards = find(categoriesWithCustomHazards, { code: categoryCode });
          const categoryWithHazards = find(categoriesWithHazards, { code: categoryCode });
          let category = {
            ...categoryWithCustomHazards,
            ...categoryWithHazards,
            hazards: unionBy(
              get(categoryWithHazards, 'hazards', []),
              get(categoryWithCustomHazards, 'hazards', []),
              'code'
            ),
          };

          if (category && searchTerm) {
            category = filterBySearchTerm(category, searchTerm);
          }

          if (category.hazards.length > 0) {
            numberOfCategories++;
            return (
              <FadeContainer key={index}>
                <HazardCategory
                  key={category.code}
                  hazardCategory={category}
                  onAddCategory={(name: any, value: any) =>
                    handleOnAddCategory({ name, value, category })
                  }
                  searchTerm={searchTerm}
                />
              </FadeContainer>
            );
          }
          return null;
        })}

        {!numberOfCategories && searchTerm !== '' && <HazardOverlayEmptyState />}
      </Form>
    </OverlayContent>
  );
};

export default HazardOverlayContent;
