import React from 'react';
import Modal from '../../components/Modal/Modal';
import ModalTitle from '../../components/Modal/ModalTitle';
import ModalContent from '../../components/Modal/ModalContent';
import MyTeamForm from '../MyTeamForm/MyTeamForm';
import { ModalContentText } from '../../components';
import { Divider } from '../MyTeamForm/styles';

const MyTeamModal = function ({ isModalOpen, handleModalClose }: any) {
  const titleStyle = 'padding-top:50px; padding-bottom: 0px;';

  return (
    <Modal size="1084px" open={isModalOpen}>
      <ModalTitle onClose={handleModalClose} styles={titleStyle}>
        Work Team &amp; Visitors
      </ModalTitle>
      <ModalContent padding="19px 30px 60px 30px" overflowy>
        <ModalContentText styles="max-width:705px; margin: auto; color:#0A0D0D">
          I declare that I have read and understood this JSA and agree to follow the plan, use the agreed
          hazard controls to work safely.
        </ModalContentText>
        <Divider />
        <MyTeamForm handleModalClose={handleModalClose} />
      </ModalContent>
    </Modal>
  );
};

export default MyTeamModal;
