const shortFileName = (name: string, limit: number) => {
  const extension = name.substring(name.lastIndexOf('.'));
  let nameWithoutExtension = name.substring(0, name.lastIndexOf('.'));

  if (name.length > limit) {
    nameWithoutExtension = `${nameWithoutExtension.substring(0, limit)}...`;
  }
  return nameWithoutExtension + extension;
};

export default shortFileName;
