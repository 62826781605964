import React from 'react';

type OverlayContextProps = {
  trigger: boolean;
  setTrigger: any;
  contentRef: any;
  setContentRef: any;
};

const OverlayContext = React.createContext<OverlayContextProps>({
  trigger: false,
  setTrigger: () => ({}),
  contentRef: null,
  setContentRef: () => ({}),
});

export default OverlayContext;
