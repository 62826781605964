import React from 'react';
import HazardIcon from '../Icon/HazardIcon/HazardIcon';
import Typography from '../Typography/Typography';
import {
  StyledSummaryHazardCard,
  StyledSummaryHazardCardIconWrapper,
  StyledSummaryHazardCardTextWrapper,
} from './style';

const SummaryHazardCard = function ({ code, name }: any) {
  return (
    <StyledSummaryHazardCard>
      <StyledSummaryHazardCardIconWrapper>
        <HazardIcon viewBox="0 0 100 100" name={code} />
      </StyledSummaryHazardCardIconWrapper>
      <StyledSummaryHazardCardTextWrapper>
        <Typography variant="h5">{name}</Typography>
      </StyledSummaryHazardCardTextWrapper>
    </StyledSummaryHazardCard>
  );
};

export default SummaryHazardCard;
