import React, { Component } from 'react';
import Message from './Messages';

class MessageList extends Component {
  componentDidUpdate(_prevProps, _prevState) {
    this.scrollList.scrollTop = this.scrollList.scrollHeight;
  }

  render() {
    return (
      <div className="sc-message-list" ref={(el) => (this.scrollList = el)}>
        {this.props.messages.map((message, i) => {
          return (
            <Message
              onClickResolve={this.props.onClickResolve}
              onMessageDeleted={this.props.onMessageDeleted}
              section={this.props.section}
              message={message}
              key={i}
              data={this.props.data}
            />
          );
        })}
      </div>
    );
  }
}

export default MessageList;
