import React from 'react';
import { Menu, MenuButton, MenuItem } from '..';
import { useLockBodyScroll } from '../../hooks';

/*
 * We wrap MenuItems in <div> to fix forwardRefs bug
 * https://stackoverflow.com/questions/56307332/how-to-use-custom-functional-components-within-material-ui-menu
 */
const ControlsSuggestionMenuContent = function ({ toggleDeleteModal, editMeasure }: any) {
  useLockBodyScroll();
  return (
    <div>
      <MenuItem onClick={editMeasure}>Edit Control Measure</MenuItem>
      <MenuItem onClick={toggleDeleteModal}>Delete this Control Measure</MenuItem>
    </div>
  );
};

const ControlsSuggestionMenu = function (props: any) {
  const { isMenuOpen, anchorEl, handleClose, handleClick } = props;
  return (
    <>
      <MenuButton className="work-step-menu-button" onClick={handleClick} />
      <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleClose}>
        {isMenuOpen && <ControlsSuggestionMenuContent {...props} />}
      </Menu>
    </>
  );
};

export default ControlsSuggestionMenu;
