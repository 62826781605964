const statusAttributes: any = {
  draft: {
    color: 'leaf',
    text: 'DRAFT',
    id: 'draft',
  },
  submitted: {
    color: 'lightGreen',
    text: 'SUBMITTED',
    id: 'submitted',
  },
  readyreview: {
    color: 'lightGreen',
    text: 'READY FOR REVIEW',
    id: 'readyreview',
  },
  review: {
    color: 'lightBlue',
    text: 'UNDER REVIEW',
    id: 'review',
  },
  agreed: {
    color: 'mediumBlue',
    text: 'CONFIRMED',
    id: 'agreed',
  },
  waitingconfirmation: {
    color: 'lightBlue',
    text: 'AWAITING CONFIRMATION',
    id: 'waitingconfirmation',
  },
  amended: {
    color: 'darkCyan',
    text: 'AMENDED',
    id: 'amended',
  },
  closed: {
    color: 'darkBlue',
    text: 'CLOSED',
    id: 'closed',
  },
  changesRequired: {
    color: 'yellow',
    text: 'CHANGES REQUIRED',
    id: 'changesRequired',
  },
  cancelled: {
    color: 'tomato',
    text: 'CANCELLED',
    id: 'cancelled',
  },
};

export default statusAttributes;
