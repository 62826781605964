import React from 'react';
import { Field, getIn, useFormikContext } from 'formik';
import { uniqueId } from 'lodash';
import { StyledLabel, StyledSlider } from './styles';
import { setAlignmentForEdgeLabels, setBoldForActiveLabel } from './helper';
import Tooltip from '../Tooltip/Tooltip';

const Slider = function ({ name, color, label, isFieldDisabled, tooltip, ...rest }: any) {
  const { values, setFieldValue } = useFormikContext();
  const id = uniqueId(`${name}-`);
  const fieldColour = isFieldDisabled === false ? 0 : color;
  const usedName = `${name}Used`;
  const value = getIn(values, name);

  setAlignmentForEdgeLabels();
  setBoldForActiveLabel();

  return (
    <Field name={name}>
      {() => (
        <div style={{ width: '100%' }}>
          {label && (
            <>
              <StyledLabel htmlFor={id} label={label} />
              <Tooltip style={{ margin: '0px 6px 4px 0px' }} placement="top" text={tooltip} />
            </>
          )}
          <StyledSlider
            {...rest}
            value={value}
            step={null}
            valueLabelDisplay="off"
            onChange={(event: any, value: any) => {
              if (getIn(values, usedName) === false) {
                setFieldValue(usedName as never, true); // @TODO: Move this logic into form
              }

              setFieldValue(name as never, value);
            }}
            colour={fieldColour}
          />
        </div>
      )}
    </Field>
  );
};

export default Slider;
