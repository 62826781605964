import React from 'react';
import uniqueId from 'lodash/uniqueId';
import {
  getWorkStepText,
  getWorkStepHazards,
  getControlMeasuresSelector,
  getInitialRisk,
  getResidualRisk,
  getPpeString,
  getIsolationActionString,
  getRequiredPermitName,
} from './summaryHazardsAndControlsSelectors';
import {
  StyledSummaryHazardsAndControlsWorkStep,
  StyledSummaryHazardsAndControlsWorkStepTitle,
  StyledSummaryHazardsAndControlsWorkStepDetails,
  StyledSummaryHazardsAndControlsWorkStepRisk,
} from './style';
import Typography from '../../components/Typography/Typography';
import SummaryWorkStepsHazardCard from '../../components/SummaryWorkStepsHazardCard/SummaryWorkStepsHazardCard';
import Fallback from '../../components/Fallback/Fallback';
import SummaryWorkStepHazardRisk from '../../components/SummaryWorkStepHazardRisk/SummaryWorkStepHazardRisk';

const SummaryHazardsAndControlsWorkStep = ({ dataTestid, ...props }: any) => {
  const workStepText = getWorkStepText(props);
  const workStepHazards = getWorkStepHazards(props);

  return (
    <StyledSummaryHazardsAndControlsWorkStep data-testid={props.dataTestid}>
      <StyledSummaryHazardsAndControlsWorkStepTitle>
        <Typography variant="h4">{workStepText}</Typography>
      </StyledSummaryHazardsAndControlsWorkStepTitle>

      {workStepHazards.map(({ name, code, controls, ...rest }: any, key: number) => {
        const controlMeasures = getControlMeasuresSelector(controls);
        const isolationActionString = getIsolationActionString(controls as never);
        const permits = getRequiredPermitName(controls);
        const residualRisk = getResidualRisk(rest);
        const initialRisk = getInitialRisk(rest);
        const ppeString = getPpeString(controls as never);

        return (
          <StyledSummaryHazardsAndControlsWorkStepDetails key={`workStepHazard-${key}`}>
            <SummaryWorkStepsHazardCard
              className="no-page-break-no-width"
              code={code}
              name={name}
              dataTestid={`${dataTestid}.HazardCard-${key}`}
            />
            <Fallback value={controlMeasures} message="Controls not completed yet" isText={false}>
              <ul data-testid={`${dataTestid}.controlMeasures-${key}`}>
                {controlMeasures.map((controlMeasure: any) => (
                  <li key={uniqueId('controlMeasure-')}>
                    <Typography controls variant="body1">
                      {controlMeasure.value}
                    </Typography>
                  </li>
                ))}
                {ppeString && (
                  <li key={uniqueId('ppeString-')}>
                    <Typography controls variant="body1">
                      {ppeString}
                    </Typography>
                  </li>
                )}
                {isolationActionString && (
                  <li key={uniqueId('isolationActionString')}>
                    <Typography controls variant="body1">
                      {isolationActionString}
                    </Typography>
                  </li>
                )}
                {permits && (
                  <li key={uniqueId('permits-')}>
                    <Typography controls variant="body1">
                      {permits}
                    </Typography>
                  </li>
                )}
              </ul>
            </Fallback>
            <StyledSummaryHazardsAndControlsWorkStepRisk className="no-page-break-no-width">
              <div>
                <Fallback
                  value={residualRisk && initialRisk ? 'true' : ''}
                  message="Risk not completed yet"
                  isText={false}
                >
                  <SummaryWorkStepHazardRisk
                    residualRisk={residualRisk}
                    code={code}
                    initialRisk={initialRisk}
                    dataTestid={`${dataTestid}.risk-${key}`}
                  />
                </Fallback>
              </div>
            </StyledSummaryHazardsAndControlsWorkStepRisk>
          </StyledSummaryHazardsAndControlsWorkStepDetails>
        );
      })}
    </StyledSummaryHazardsAndControlsWorkStep>
  );
};

export default SummaryHazardsAndControlsWorkStep;
