import get from 'lodash/get';

export const lookupHazard = (hazard: any = '', hazardList: any = []) => {
  const currentHazardsId = get(hazard, 'sys.id');

  return hazardList.find((hazard: any) => hazard.linkID === currentHazardsId);
};

// Includes search term should be if the search term exists either at the start or end of a word, not in between.
// Eg. Rat in Rats should be fine, but rat in PenetRATion or AeRATed should not be valid.
// If the search term it is in the middle, then it will have a length of 2.
// Otherwise, if it's either a prefix or suffix, it will only have a length of 1 & a matched word would be 0.
function includesSearchTerm(word: string, searchTerm: string) {
  if (!word.includes(searchTerm)) return false;
  const wordComponentsMinusSearchTerm = word.split(searchTerm).filter((components) => components !== '');

  return wordComponentsMinusSearchTerm.length <= 1;
}

function arrayContainsSearchTerm(keyTerms: string[], searchTerm: string) {
  let found = false;

  keyTerms.forEach((keyTerm: string) => {
    if (includesSearchTerm(keyTerm, searchTerm)) {
      found = true;
    }
  });

  return found;
}

export function filterBySearchTerm(category: any, searchTerm: string) {
  const temp = category;
  temp.hazards = category.hazards.filter((hazard: any) => {
    let containsSearchTerm = false;
    if (hazard.keyTerms) {
      if (includesSearchTerm(hazard.name.toLowerCase(), searchTerm)) {
        containsSearchTerm = true;
      } else {
        const lowercaseKeyTerms = hazard.keyTerms.map((key: string) => key.toLowerCase());
        containsSearchTerm = arrayContainsSearchTerm(lowercaseKeyTerms, searchTerm);
      }
    }

    return containsSearchTerm;
  });

  return temp;
}
