import { createSelector } from 'reselect';
import get from 'lodash/get';

// Base selectors
export const getControls = (data: any) => get(data, 'value.controls', {});
export const getInitialRisk = (data: any) => get(data, 'value.initialRisk', null);
export const getResidualRisk = (data: any) => get(data, 'value.residualRisk', null);
export const getHazardControls = (data: any) => get(data, 'value.controls', null);

// Computed selectors
export const getControlMeasures = createSelector(
  [getControls],
  ({ controlMeasures }) => controlMeasures || []
);

export const getPermits = createSelector([getControls], ({ permits }) => permits || null);

export const getPpe = createSelector([getControls], ({ ppe }) => ppe || []);

export const getIsolations = createSelector([getControls], ({ isolations }) => {
  if (get(isolations, 'required') === 'Yes') {
    return isolations;
  }

  return null;
});

export const getIsolationCertificate = createSelector([getIsolations], (isolations) => {
  if (isolations) {
    if (isolations.type === 'isolationCertificate') {
      return 'Apply for Isolation Certificate';
    }

    return null;
  }
});

export const getSelfIsolations = createSelector([getIsolations], (isolations) => {
  if (isolations) {
    if (isolations.type === 'selfIsolation' && isolations.selfIsolationValue) {
      return `Self-Isolation (LOTO): ${isolations.selfIsolationValue}`;
    }
    return null;
  }
});
