import { useMutation } from '@apollo/client';
import { Mutations } from '../api-queries';
import { format } from 'date-fns';
import emailEnvironmentVariables from '../emailEnvironmentVariables';

type StatusChangeEmailParams = {
  to: string;
  templateId: string;
  versionIdOverride: string;
  firstName: string;
  subject: string;
  dateTime?: string;
};

type ShareJsaParams = {
  email: string;
  sharedByName: string;
  jsaTitle: string;
  jsaId: string;
  jsaGuid: string;
};

const {
  submittedJsa,
  reviewJsa,
  changesRequiredJsa,
  sentBack,
  confirmed,
  awaitingConfirmation,
  cancelJsa,
} = emailEnvironmentVariables;

export const useSendGrid = () => {
  const [sendEmailMutation] = useMutation(Mutations.SendEmailNotification);
  const sendStatusChangeEmail = (
    {
      updateJobSafetyAnalysis: {
        createdBy,
        submittedBy,
        reviewedBy,
        confirmedBy,
        status,
        jsaId,
        jsaApplicationTitle,
        id,
        submittedDate,
        workDetails,
        cancelledDate,
        assignedTo,
        toConfirmEmail,
      },
    },
    confirmerEmailAddress = null
  ) => {
    let personInCharge = { email: '', firstName: '' };
    let watercareRepresentative = { email: '', firstName: '' };
    let _confirmedBy = { email: '', firstName: '' };
    let jsaWrittenBy = { email: '', firstName: '' };

    if (workDetails !== null) {
      personInCharge = workDetails.personInCharge;
      watercareRepresentative = workDetails.watercareRepresentative;
      _confirmedBy = confirmedBy;
      jsaWrittenBy = workDetails.jsaWrittenBy;
    }

    const send = ({
      to,
      templateId,
      versionIdOverride,
      firstName,
      subject,
      dateTime = format(new Date(), 'eeee, d LLLL uuuu'),
    }: StatusChangeEmailParams) => {
      sendEmailMutation({
        variables: {
          input: {
            to,
            from: 'Jobcare.Notifications@water.co.nz',
            templateId,
            versionIdOverride,
            dynamic_template_data: {
              firstName,
              JsaNumber: jsaId,
              JsaTitle: jsaApplicationTitle,
              subject,
              CurrentDateTime: dateTime,
              guid: id,
            },
          },
        },
      });
    };

    const catchDupesThenSend = (emailsToSend: StatusChangeEmailParams[]) => {
      const haveSentTo: string[] = [];
      emailsToSend.map((email: StatusChangeEmailParams) => {
        const { to } = email;
        if (email && to && haveSentTo.indexOf(to.toLocaleLowerCase()) === -1) {
          send(email);
          haveSentTo.push(to.toLocaleLowerCase());
        }
      });
    };

    switch (status) {
      case 'readyreview':
        catchDupesThenSend([
          {
            to: submittedBy.email,
            firstName: submittedBy.firstName || '',
            versionIdOverride: String(submittedJsa.version),
            templateId: String(submittedJsa.id),
            subject: jsaId,
          },
          {
            to: personInCharge.email,
            firstName: personInCharge.firstName || '',
            versionIdOverride: String(submittedJsa.version),
            templateId: String(submittedJsa.id),
            subject: jsaId,
          },
          {
            to: watercareRepresentative.email || '',
            firstName: watercareRepresentative.firstName || '',
            versionIdOverride: String(reviewJsa.version),
            templateId: String(reviewJsa.id),
            subject: jsaId,
          },
        ]);

        break;
      case 'waitingconfirmation':
        if (confirmerEmailAddress) {
          catchDupesThenSend([
            {
              to: confirmerEmailAddress,
              firstName: '',
              versionIdOverride: String(awaitingConfirmation.version),
              templateId: String(awaitingConfirmation.id),
              subject: jsaId,
            },
          ]);
        }
        break;
      case 'changesRequired':
        catchDupesThenSend([
          {
            to: submittedBy.email || '',
            firstName: submittedBy.firstName || '',
            versionIdOverride: String(changesRequiredJsa.version),
            templateId: String(changesRequiredJsa.id),
            subject: jsaId,
            dateTime: format(new Date(submittedDate), 'eeee, d LLLL uuuu'),
          },
          {
            to: createdBy.email || '',
            firstName: createdBy.firstName || '',
            versionIdOverride: String(changesRequiredJsa.version),
            templateId: String(changesRequiredJsa.id),
            subject: jsaId,
            dateTime: format(new Date(submittedDate), 'eeee, d LLLL uuuu'),
          },
          {
            to: personInCharge.email || '',
            firstName: personInCharge.firstName || '',
            versionIdOverride: String(changesRequiredJsa.version),
            templateId: String(changesRequiredJsa.id),
            subject: jsaId,
            dateTime: format(new Date(submittedDate), 'eeee, d LLLL uuuu'),
          },
        ]);
        break;
      // When the person sent to confirm hits SEND BACK
      case 'review':
        catchDupesThenSend([
          {
            to: reviewedBy.email || '',
            firstName: reviewedBy.firstName,
            versionIdOverride: String(sentBack.version),
            templateId: String(sentBack.id),
            subject: jsaId,
          },
        ]);
        break;
      // When the confirmer hits CONFIRM
      case 'agreed':
        catchDupesThenSend([
          {
            to: personInCharge.email || '',
            firstName: personInCharge.firstName || '',
            versionIdOverride: String(confirmed.version),
            templateId: String(confirmed.id),
            subject: jsaId,
          },
          {
            to: confirmedBy.email || '',
            firstName: confirmedBy.firstName || '',
            versionIdOverride: String(confirmed.version),
            templateId: String(confirmed.id),
            subject: jsaId,
          },
          {
            to: watercareRepresentative.email || '',
            firstName: watercareRepresentative.firstName || '',
            versionIdOverride: String(confirmed.version),
            templateId: String(confirmed.id),
            subject: jsaId,
          },
          {
            to: _confirmedBy.email || '',
            firstName: _confirmedBy.firstName || '',
            versionIdOverride: String(confirmed.version),
            templateId: String(confirmed.id),
            subject: jsaId,
          },
        ]);
        break;
      case 'cancelled':
        catchDupesThenSend([
          {
            to: createdBy.email || '',
            firstName: createdBy.firstName || '',
            versionIdOverride: String(cancelJsa.version),
            templateId: String(cancelJsa.id),
            subject: jsaId,
            dateTime: format(new Date(cancelledDate), 'eeee, d LLLL uuuu'),
          },
          {
            to: jsaWrittenBy.email || '',
            firstName: jsaWrittenBy.firstName || '',
            versionIdOverride: String(cancelJsa.version),
            templateId: String(cancelJsa.id),
            subject: jsaId,
            dateTime: format(new Date(cancelledDate), 'eeee, d LLLL uuuu'),
          },
          {
            to: (submittedBy && submittedBy.email) || '',
            firstName: (submittedBy && submittedBy.firstName) || '',
            versionIdOverride: String(cancelJsa.version),
            templateId: String(cancelJsa.id),
            subject: jsaId,
            dateTime: format(new Date(cancelledDate), 'eeee, d LLLL uuuu'),
          },
          {
            to: personInCharge.email || '',
            firstName: personInCharge.firstName || '',
            versionIdOverride: String(cancelJsa.version),
            templateId: String(cancelJsa.id),
            subject: jsaId,
            dateTime: format(new Date(cancelledDate), 'eeee, d LLLL uuuu'),
          },
          {
            to: watercareRepresentative.email || '',
            firstName: watercareRepresentative.firstName || '',
            versionIdOverride: String(cancelJsa.version),
            templateId: String(cancelJsa.id),
            subject: jsaId,
            dateTime: format(new Date(cancelledDate), 'eeee, d LLLL uuuu'),
          },
          {
            to: (assignedTo && assignedTo.email) || '',
            firstName: (assignedTo && assignedTo.firstName) || '',
            versionIdOverride: String(cancelJsa.version),
            templateId: String(cancelJsa.id),
            subject: jsaId,
            dateTime: format(new Date(cancelledDate), 'eeee, d LLLL uuuu'),
          },
          {
            to: (reviewedBy && reviewedBy.email) || '',
            firstName: (reviewedBy && reviewedBy.firstName) || '',
            versionIdOverride: String(cancelJsa.version),
            templateId: String(cancelJsa.id),
            subject: jsaId,
            dateTime: format(new Date(cancelledDate), 'eeee, d LLLL uuuu'),
          },
          {
            to: toConfirmEmail || '',
            firstName: '',
            versionIdOverride: String(cancelJsa.version),
            templateId: String(cancelJsa.id),
            subject: jsaId,
            dateTime: format(new Date(cancelledDate), 'eeee, d LLLL uuuu'),
          },
        ]);
    }
  };

  const sendShareJsaEmail = ({ email, sharedByName, jsaTitle, jsaId, jsaGuid }: ShareJsaParams) => {
    if (email) {
      sendEmailMutation({
        variables: {
          input: {
            to: email,
            from: 'Jobcare.Notifications@water.co.nz',
            templateId: emailEnvironmentVariables.shareJsa.id,
            versionIdOverride: emailEnvironmentVariables.shareJsa.version,
            dynamic_template_data: {
              firstName: sharedByName,
              JsaNumber: jsaId,
              JsaTitle: jsaTitle,
              subject: jsaId,
              CurrentDateTime: format(new Date(), 'eeee, d LLLL uuuu'),
              guid: jsaGuid,
            },
          },
        },
      });
    } else {
      throw new Error(`No email address was provided to the sendShareJsaEmail functon.`);
    }
  };

  return {
    sendShareJsaEmail,
    sendStatusChangeEmail,
  };
};

export default useSendGrid;
