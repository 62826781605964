import { createSelector } from 'reselect';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// Base selectors
export const getJobSafetyAnalysis = (data: any) => get(data, 'getJobSafetyAnalysis', {});
export const getUtilities = (data: any) => get(data, 'getJobSafetyAnalysis.workPack.utilities', {});
export const getEnergyArrangements = (data: any) =>
  String(get(data, 'getJobSafetyAnalysis.workPack.energyArrangements', ''));
export const getSupportingDocuments = (data: any) =>
  get(data, 'getJobSafetyAnalysis.workPack.supportDocuments', []);

// Computed selectors

export const getElectricity = createSelector([getUtilities], (utilities) => utilities.electricity);
export const getGas = createSelector([getUtilities], (utilities) => utilities.gas);
export const getStormwater = createSelector([getUtilities], (utilities) => utilities.stormwater);
export const getTelco = createSelector([getUtilities], (utilities) => utilities.telco);
export const getWastewater = createSelector([getUtilities], (utilities) => utilities.wastewater);
export const getWater = createSelector([getUtilities], (utilities) => utilities.water);

export const getFilteredSupportingDocuments = createSelector(
  [getSupportingDocuments],
  (supportingDocuments) => {
    if (!isEmpty(supportingDocuments)) {
      return supportingDocuments.filter((item: any) => item.fileType.id !== '');
    }
  }
);
