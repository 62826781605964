import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from '../Icon/Icon';
import Button from './Button';

const StyledButton = styled(({ ...rest }) => <Button {...rest} />)`
  box-shadow: none;
  & .MuiButton-label {
    display: flex;
    align-items: center;
  }

  svg {
    width: 24px;
    height: 24px;
    margin-right: 12px;

    path,
    polygon {
      fill: #213d58;
      stroke: #213d58;
    }
  }

  &:hover {
    svg {
      margin-right: 4px;
    }
    & path,
    & polygon {
      fill: white;
      stroke: white;
    }
  }
`;

interface Props {
  onClick?: () => any;
  text?: string;
}

const ReviewButton: React.FC<Props> = function ({ onClick }) {
  const [isHover, setIsHover] = useState(false);

  return (
    <StyledButton
      id="summary_review_button"
      fullWidth
      preset="primary"
      themeColour="greyScale_10"
      onClick={onClick}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      {isHover ? (
        <>
          <Icon name="Undo" />
          <div>Undo Review</div>
        </>
      ) : (
        <>
          <Icon name="NormalTick" />
          <div>Review Is Done</div>
        </>
      )}
    </StyledButton>
  );
};

export default ReviewButton;
