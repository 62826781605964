import React, { useRef, useEffect, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useFormikContext } from 'formik';
import { DragItemWrapper } from './style';
import { moveItemInArray } from '../../utils';

interface DragItemProps {
  itemType: string;
  onDragEnd: () => void;
  children: React.ReactNode;
  index: number;
  items: [];
  fieldArrayName: string;
}

const DragItem = function ({
  index,
  itemType,
  children,
  onDragEnd,
  items,
  fieldArrayName,
}: DragItemProps) {
  const { setStatus, setFieldValue } = useFormikContext();

  const moveWorkStep = useCallback(
    (dragIndex: any, hoverIndex: any) => {
      const updatedWorkSteps = moveItemInArray(items, dragIndex, hoverIndex);
      setFieldValue(fieldArrayName as never, updatedWorkSteps);
    },
    [items, setFieldValue, fieldArrayName]
  );

  const ref = useRef(null);

  const [, dropConnector] = useDrop({
    accept: itemType,

    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      // @ts-check
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      // @ts-check
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      // @ts-check
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveWorkStep(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // @ts-check
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, dragConnector] = useDrag({
    item: { type: itemType, id: index, index },
    end: () => {
      onDragEnd();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    setStatus({ isDragging });
  }, [isDragging, setStatus]);

  const opacity = isDragging ? 0 : 1;

  dragConnector(dropConnector(ref));

  return (
    <DragItemWrapper isDragging={isDragging}>
      <div ref={ref} style={{ opacity }}>
        {children}
      </div>
    </DragItemWrapper>
  );
};

export default DragItem;
