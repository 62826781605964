import React, { ChangeEvent, useEffect, useState, memo } from 'react';
import { useFormikContext, getIn } from 'formik';
import { Storage } from 'aws-amplify';
import {
  StyledAttachementIcon,
  FileName,
  StyledLabel,
  SpinDiv,
  StyledAhref,
  SaveFileDiv,
  StyledCircularProgress,
} from './styles';
import Icon from '../../components/Icon/Icon';
import { humanFileSize, shortFileName } from '../../utils';

const UploadInput = memo(({ name, label }: any) => {
  const [signedDownloadUrl, setSignedDownloadUrl] = useState('');
  const [isUploading, setIsUpoading] = useState(false);
  const { values, setFieldValue } = useFormikContext();
  const jsaId = getIn(values, 'id');
  const filePath = getIn(values, `${name}.file.key`);
  const fileName = shortFileName(getIn(values, `${name}.fileName`), 15);
  const spinFileName = shortFileName(getIn(values, `${name}.fileName`), 10);
  const fileSize = getIn(values, `${name}.fileSize`);
  const fileId = getIn(values, `${name}.id`);

  useEffect(() => {
    (async function () {
      if (filePath) {
        if (fileName.endsWith('.pdf') || fileName.includes('.pdf')) {
          setSignedDownloadUrl(await Storage.get(filePath, { contentType: 'application/pdf' }));
        } else {
          setSignedDownloadUrl(await Storage.get(filePath));
        }
      }
    })();
  }, [filePath]);

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const {
      target: { files },
    } = event;

    if (files) {
      const fileForUpload = files[0];
      const fileExtension = fileForUpload.name.substring(fileForUpload.name.lastIndexOf('.'));
      const key = `jsa/${jsaId}/${fileId}${fileExtension}`;

      setIsUpoading(true);

      await Storage.put(key, fileForUpload, {
        serverSideEncryption: 'AES256',
      }).then(() => {
        setFieldValue(`${name}.file.key`, key);
        setFieldValue(`${name}.fileName`, fileForUpload.name);
        setFieldValue(`${name}.fileSize`, humanFileSize(fileForUpload.size, true));
        setIsUpoading(false);
      });
    }
  };

  if (filePath) {
    return (
      <SaveFileDiv>
        <Icon name="NormalTick" />
        <StyledAhref href={signedDownloadUrl} target="_blank">{`${fileName} (${fileSize})`}</StyledAhref>
      </SaveFileDiv>
    );
  }

  if (isUploading) {
    return (
      <SpinDiv>
        <StyledCircularProgress size={25} thickness={4} />
        <FileName>
          {spinFileName} {fileSize && `(${fileSize})`}
        </FileName>
      </SpinDiv>
    );
  }

  return (
    <StyledLabel>
      <StyledAttachementIcon name="Attachment" />
      {label}
      <input type="file" onChange={handleChange} value="" />
    </StyledLabel>
  );
});

UploadInput.displayName = 'UploadInput';

export default UploadInput;
