import React from 'react';
import { ReactComponent as Droplet } from './droplet-light-blue.svg';
import { SummarySectionTitleIconWrapper, Text } from './styles';

const SummarySectionTitleIcon = function ({ children }: any) {
  return (
    <SummarySectionTitleIconWrapper>
      <Droplet />
      <Text>{children}</Text>
    </SummarySectionTitleIconWrapper>
  );
};

export default SummarySectionTitleIcon;
