import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import ClearText from '../Icon/svgs/clear-text_padding.svg';
import Icon from '../Icon/Icon';

export const StyledIcon = styled(Icon)`
  width: 24px;
  padding-right: 15px;
  path {
    fill: ${(props) => props.theme.colors.findColor('greyScale_25')};
  }
`;

export const StyledSelect = styled(({ ...rest }) => <Select {...rest} />)`
  & legend {
    width: 0 !important;
  }

  & [class*='indicatorSeparator'] {
    display: none;
  }
  & svg[class*='css'] {
    display: none;
  }

  & [class*='indicatorContainer'] {
    padding: 0px;
    & svg {
      height: 30px;
      width: 30px;
    }
  }
  & .Mui-focused {
    & svg[class*='css'] {
      display: block;
      background: url(${ClearText}) no-repeat center;
      & path {
        display: none;
      }
    }
  }
  &
    .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl {
    border-radius: 5px;
  }

  & .MuiFormLabel-root {
    transition: none;
    transform: unset;
    transform-origin: unset;
    position: unset;

    & + .MuiInputBase-formControl {
      margin-top: 8px;
      border-radius: 5px;
    }

    & + .MuiInput-underline:before {
      left: unset;
      right: unset;
      bottom: unset;
      content: unset;
      position: unset;
      transition: unset;
      border-bottom: unset;
      pointer-events: none;
    }
  }
`;

export const StyledCreatableSelect = styled(({ isFirstAid, ...rest }) => (
  <CreatableSelect menuPlacement={isFirstAid ? 'top' : 'auto'} {...rest} />
))`
  & .css-26l3qy-menu {
    z-index: 900;
  }
  & svg[class*='css'] {
    display: none;
  }
  & .Mui-focused {
    & svg[class*='css'] {
      display: block;
      background: url(${ClearText}) no-repeat center;
      & path {
        display: none;
      }
    }
  }
  & .css-1w9j89e-menu {
    width: 80%;
    bottom: 55%;
    right: 0;

    @media screen and (max-width: 1366px) {
      width: 50%;
    }

    @media screen (min-width: 1367px) and (max-width: 1920px) {
      width: 70%;
    }
  }

  & legend {
    width: 0 !important;
  }

  & [class*='indicatorSeparator'] {
    display: none;
  }

  & [class*='indicatorContainer'] {
    padding: 0px;
    & svg {
      height: 30px;
      width: 30px;
    }
  }
  &
    .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl {
    border-radius: 5px;
  }

  & .MuiFormLabel-root {
    transition: none;
    transform: unset;
    transform-origin: unset;
    position: unset;

    & + .MuiInputBase-formControl {
      margin-top: 8px;
      border-radius: 5px;
    }

    & + .MuiInput-underline:before {
      left: unset;
      right: unset;
      bottom: unset;
      content: unset;
      position: unset;
      transition: unset;
      border-bottom: unset;
      pointer-events: none;
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: 250,
      minWidth: 290,
    },
    label: {
      background: 'green',
    },
    input: {
      display: 'flex',
      padding: 0,
      height: 'auto',
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    singleValue: {
      fontSize: 16,
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing(2),
    },
  })
);

export default useStyles;
