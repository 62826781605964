import React from 'react';
import styled from 'styled-components';
import { Button } from '../../components';

export const DashboardWrapper = styled.div`
  margin: 60px;
  display: flex;
  justify-content: center;
`;

export const DashboardContent = styled.div`
  margin-top: 22px;
  & h1 {
    text-align: center;
  }
`;

export const DashboardActions = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
`;

export const StyledButton = styled(({ ...rest }) => <Button {...rest} />)`
  margin-top: 15px;
  min-width: 327px;
`;
