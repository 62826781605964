import React, { memo, ReactNode } from 'react';
import CommonLayout from '../CommonLayout/CommonLayout';

type DashboardLayoutProps = {
  children: ReactNode;
};

const DashboardLayout = memo(({ children }: DashboardLayoutProps) => {
  return <CommonLayout>{children}</CommonLayout>;
});

DashboardLayout.displayName = 'DashboardLayout';

export default DashboardLayout;
