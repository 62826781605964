import { createSelector } from 'reselect';
import get from 'lodash/get';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import flatten from 'lodash/fp/flatten';
import uniqBy from 'lodash/fp/uniqBy';
import filter from 'lodash/fp/filter';
import { lookupHazard } from './utils';

// Base selectors
export const getHazardsData = (data: any) => get(data, 'hazardData.includes.Entry', []);
export const getHazardsCategoriesData = (data: any) => get(data, 'hazardData.items', []);
export const getWorkSteps = (data: any) => get(data, 'jsaData.getJobSafetyAnalysis.workSteps', []);
export const getDraftSelectedHazards = (data: any) => get(data, 'draftSelectedHazards', []);

// Computed selectors
export const getUniqueDraftSelectedHazards = createSelector(
  [getDraftSelectedHazards],
  (draftSelectedHazards) => {
    const processSelectedHazards = flow(uniqBy('code'))(draftSelectedHazards);
    return processSelectedHazards || [];
  }
);

export const getSelectedHazards = createSelector([getWorkSteps], (workSteps) => {
  const processHazards = flow(
    map(({ hazards }: any) => hazards),
    filter((item: any) => item),
    flatten,
    uniqBy('code')
  )(workSteps);
  return processHazards || [];
});

export const getHazardsList = createSelector([getHazardsData], (hazardsData) =>
  hazardsData.map(({ sys, fields }: any) => ({
    code: fields.code,
    name: fields.name,
    linkID: sys.id,
    tooltip: fields.tooltip,
    permitName: fields.permit,
    permitCode: fields.permitCode,
    isolationRequired: fields.isolationRequired,
    keyTerms: fields.keyTerms,
  }))
);

export const getHazardsCategories = createSelector(
  [getHazardsCategoriesData],

  (hazardsCategoriesData) =>
    hazardsCategoriesData.map(({ fields }: any) => ({
      code: fields.code,
      name: fields.name,
      hazards: fields.hazards,
    }))
);

export const getCategoriesWithHazards = createSelector(
  [getHazardsCategories, getHazardsList],

  (hazardsCategories, hazardsList) =>
    hazardsCategories.map(({ hazards, ...hazardCategory }: any) => ({
      ...hazardCategory,
      hazards: hazards
        .map((hazard: any) => ({
          category: {
            name: hazardCategory.name,
            code: hazardCategory.code,
          },
          ...lookupHazard(hazard, hazardsList),
        }))
        .sort((a: any, b: any) => (a.name > b.name ? 1 : -1)),
    }))
);
