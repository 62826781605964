import styled from 'styled-components';
import { Button } from '..';

export const StyledAgreedButton = styled(Button)`
  box-sizing: border-box;
  height: 40px;
  width: 100px;
  min-width: 100px;
  margin-top: ${(props: any) => (props.$isfirst ? '30px' : '0px')};
`;

export const StyledAgreedText = styled.div`
  min-width: 100px;
  text-align: center;
  color: #43575a;
  font-family: 'Nunito Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;

  svg {
    height: 24px;
    width: 24px;
  }
`;

export const StyledAgreedTick = styled.div<{ isFirst?: boolean }>`
  font-size: 15px;
  font-weight: bold;
  color: #1c7b97;
  margin-top: ${(props) => (props.isFirst ? '30px' : '0px')};
  display: flex;
  justify-content: center;
  align-items: center;
`;
