import styled from 'styled-components';
import React from 'react';
import Typography from '../Typography/Typography';
import Button from '../Button/Button';
import ModalActions from '../Modal/ModalActions';

export const HazardLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`;

export const Divider = styled.hr`
  border: 0;
  height: 0;
  border-top: 1px solid #e1e5e5;
  margin-block-end: 0;
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
`;
export const LabelText = styled(Typography)`
  && {
    font-weight: normal;
    width: 327px;
  }
`;

export const HazardTextLabel = styled(Typography)`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 5px;

  && {
    font-weight: normal;
  }
`;

export const SliderSectionWrapper = styled.div`
  padding-bottom: 22px;
  width: 100%;
`;
export const SliderRow = styled.div`
  display: flex;
  justify-content: left;

  &:last-of-type {
    padding-top: 30px;
  }

  width: 100%;
`;

export const StyledActionButtons = styled(Button)`
  min-width: 100px !important;
  padding-left: 17.5px !important;
  padding-right: 15.5px !important;
  height: 40px;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  left: 20px;
  margin-left: -10px;
  align-items: center;
  cursor: pointer;
`;

export const TooltipText = styled(Typography)`
  font-size: 14px !important;
  line-height: 21px;
  margin-top: 1px;
  margin-left: 6px;

  @media screen and (max-width: 768px) {
    font-size: 12px !important;
    min-width: 140px;
  }
`;

export const StyledModalActions = styled(({ styles, ...rest }) => (
  <ModalActions styles={styles} {...rest} />
))``;

export const StyledModalActionsButtonWrapper = styled.div`
  display: flex;
  width: 220px;
  margin-left: 0 !important;
  justify-content: space-between;

  && button {
    width: 100px;
    margin: 0;
  }
`;
