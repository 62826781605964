import styled from 'styled-components';

export const ModalText = styled.p`
  color: #0a0d0d;
  font-family: 'Nunito Sans';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  padding-left: 10vw;
  padding-right: 10vw;
  margin-top: 0px;
  margin-bottom: 44px;
`;
export const Footer = styled.div`
  height: 90px;
  width: 100%;
  background-color: #cfedf1;
`;
export const Wave = styled.div`
  width: 100%;
  height: 9px;
  transform: scaleY(-1);
  background-image: url(/static/media/wave-single.224d291c.svg);
  background-repeat: repeat-x;
  position: relative;
`;
