import React, { useEffect, useState, memo } from 'react';
import { Storage } from 'aws-amplify';
import { FileNameWrapper } from './style';
import Fallback from '../../components/Fallback/Fallback';

type FileType = {
  label: string;
};

type Props = {
  item: {
    label: string;
    fileName: string;
    filePath: string;
    file: string;
    fileType: FileType;
  };
};

const SummaryItemLink = memo(({ item: { label, fileName, filePath, file, fileType } }: Props) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  useEffect(() => {
    (async function () {
      if (fileName.endsWith('.pdf') || fileName.includes('.pdf')) {
        setFileUrl(
          await Storage.get(filePath ? filePath : file.key, { contentType: 'application/pdf' })
        );
      } else {
        setFileUrl(await Storage.get(filePath ? filePath : file.key));
      }
    })();
  }, []);

  if (!fileUrl) {
    return null;
  }

  return (
    <div>
      <Fallback value={label ? label : fileType.label} message="Not set yet" />
      <Fallback value={fileName} message="Not uploaded yet">
        <FileNameWrapper href={fileUrl} target="_blank" rel="noopener noreferrer">
          {fileName}
        </FileNameWrapper>
      </Fallback>
    </div>
  );
});

SummaryItemLink.displayName = 'SummaryItemLink';

export default SummaryItemLink;
