import { useMutation } from '@apollo/client';
import { useSendGrid, useClientState } from 'hooks';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TextField } from '..';
import { Mutations, Queries } from '../../api-queries';
import Button from '../Button/Button';
import ContentLoader from '../ContentLoader/ContentLoader';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Modal from '../Modal/Modal';
import ModalActions from '../Modal/ModalActions';
import ModalContent from '../Modal/ModalContent';
import ModalTitle from '../Modal/ModalTitle';
import { ConfirmText, DividerText, StyledButton } from './styles';
import { useAuth } from 'contexts/AuthProvider';

const SendForConfirmModal = function ({ isOpen, data }: any) {
  const { user } = useAuth();
  const mutationQuery = Mutations.UpdateJsa;
  const { sendStatusChangeEmail } = useSendGrid();
  const params: { jsa?: string } = useParams();
  const { setSendForConfirmModalOpen } = useClientState();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [confirmEmail, setConfirmEmail] = useState(null);
  const id = get(data, 'getJobSafetyAnalysis.jsaId');

  const handleClose = () => {
    setSendForConfirmModalOpen(false);
  };

  const [updateStatusMutation] = useMutation(mutationQuery, {
    onCompleted(data: any) {
      sendStatusChangeEmail(data, confirmEmail);
    },
    refetchQueries: () => [
      {
        query: Queries.GET_JSA_BY_ID,
        variables: {
          id: params.jsa,
        },
      },
    ],
  });

  const pushUpdate = (_input: any) => {
    updateStatusMutation({
      variables: {
        input: _input,
      },
    });
  };

  function onSend() {
    const input = {
      id: params.jsa,
      toConfirmEmail: confirmEmail,
      toConfirmDate: new Date().toISOString(),
    };

    pushUpdate(input);
    setButtonDisabled(true);

    handleClose();
  }

  function onConfirm() {
    pushUpdate({
      id: params.jsa,
      status: 'agreed',
      confirmedBy: user,
      confirmedDate: new Date().toISOString(),
    });

    setSendForConfirmModalOpen(false);
  }

  function handleTextInput(event: any) {
    setConfirmEmail(event.target.value.toLowerCase());
    if (event.target.value) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }

  if (!data) {
    return <ContentLoader />;
  }

  const titleStyle = 'padding-top:50px; padding-bottom: 0px;';
  const contentStyle = 'padding-top:7px;';

  return (
    <Modal open={isOpen}>
      <ModalTitle id="confirm_modal_title" styles={titleStyle} onClose={handleClose}>
        Confirm or Send JSA
      </ModalTitle>
      <ModalContent padding="13px 80px 52px 80px">
        <ModalActions styles={contentStyle}>
          <GridContainer>
            <GridItem xs={12}>
              <Button
                id="confirm_button"
                disabled={!buttonDisabled}
                preset="primary"
                themeColour="leaf"
                fullWidth
                onClick={onConfirm}
              >
                Confirm {id}
              </Button>
              <ConfirmText id="confirm_text">
                The PICW or JSA Writer will receive a confirmation email afterwards.
              </ConfirmText>
              <DividerText id="divider_text">OR SEND TO</DividerText>
              <TextField
                label="Confirmer's email address"
                id="to_confirm_email_input"
                name="toConfirmEmail"
                placeholder="eg. address@email.com"
                fullWidth
                onChange={handleTextInput}
              />
              <StyledButton
                id="send_button"
                disabled={buttonDisabled}
                preset="primary"
                themeColour="leaf"
                fullWidth
                onClick={onSend}
              >
                SEND {id}
              </StyledButton>
              <StyledButton
                id="cancel_button"
                preset="secondary"
                onClick={handleClose}
                themeColour="grey"
                fullWidth
              >
                CANCEL
              </StyledButton>
            </GridItem>
          </GridContainer>
        </ModalActions>
      </ModalContent>
    </Modal>
  );
};

export default SendForConfirmModal;
