import React, { Fragment } from 'react';
import { IconButton } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import { PaginationDiv, PaginationUl, PaginationNav, PaginationItem } from './style';

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  })
);

const Pagination = (props: any) => {
  const { count, page, rowsPerPage, onPageChange } = props;
  const classes = useStyles1();
  const theme = useTheme();

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleChange = (event: React.MouseEvent<HTMLAnchorElement>) => {
    onPageChange(null, parseInt(event.currentTarget.innerHTML) - 1);
  };

  const totalPages: number[] = [];
  for (let i = 0; i < Math.ceil(count / rowsPerPage); i++) {
    totalPages.push(i);
  }

  return (
    <>
      <p className="startLine">
        <b>
          Page
          {' ' + String(page + 1)}
        </b>
        {` of ${totalPages.length}`}
      </p>
      <p className="endLine">Show:</p>
      <PaginationDiv className={classes.root}>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          <p className={page === 0 ? 'disableTextL' : 'textL'}>Previous</p>
        </IconButton>
        <PaginationNav aria-label="pagination navigation">
          <PaginationUl>
            {totalPages.map((item: any) => {
              let paginationFragment;
              if (totalPages.length <= 7) {
                paginationFragment = (
                  <>
                    {' '}
                    <li className={item === page ? 'active' : ''}>
                      <PaginationItem as="a" onClick={handleChange}>
                        {item + 1}
                      </PaginationItem>
                    </li>
                  </>
                );
              } else if (item === 0 || item === totalPages.length - 1) {
                paginationFragment = (
                  <>
                    {' '}
                    <li className={item === page ? 'active' : ''}>
                      <PaginationItem as="a" onClick={handleChange}>
                        {item + 1}
                      </PaginationItem>
                    </li>
                  </>
                );
              } else if (page <= 3) {
                if (item < 4) {
                  paginationFragment = (
                    <>
                      {' '}
                      <li className={item === page ? 'active' : ''}>
                        <PaginationItem as="a" onClick={handleChange}>
                          {item + 1}
                        </PaginationItem>
                      </li>
                    </>
                  );
                } else if (item === 4) {
                  paginationFragment = (
                    <>
                      <li className={item === page ? 'active' : ''}>
                        <PaginationItem as="a" onClick={handleChange}>
                          {item + 1}
                        </PaginationItem>
                      </li>
                      <p>. . .</p>
                    </>
                  );
                }
              } else if (page > totalPages.length - 5) {
                if (item > totalPages.length - 5) {
                  paginationFragment = (
                    <>
                      {' '}
                      <li className={item === page ? 'active' : ''}>
                        <PaginationItem as="a" onClick={handleChange}>
                          {item + 1}
                        </PaginationItem>
                      </li>
                    </>
                  );
                } else if (item === totalPages.length - 5) {
                  paginationFragment = (
                    <>
                      <p>. . .</p>
                      <li className={item === page ? 'active' : ''}>
                        <PaginationItem as="a" onClick={handleChange}>
                          {item + 1}
                        </PaginationItem>
                      </li>
                    </>
                  );
                }
              } else if (item === page - 1) {
                paginationFragment = (
                  <>
                    <p>. . .</p>
                    <li className={item === page ? 'active' : ''}>
                      <PaginationItem as="a" onClick={handleChange}>
                        {item + 1}
                      </PaginationItem>
                    </li>
                  </>
                );
              } else if (item === page + 1) {
                paginationFragment = (
                  <>
                    <li className={item === page ? 'active' : ''}>
                      <PaginationItem as="a" onClick={handleChange}>
                        {item + 1}
                      </PaginationItem>
                    </li>
                    <p>. . .</p>
                  </>
                );
              } else if (item === page) {
                paginationFragment = (
                  <>
                    {' '}
                    <li className={item === page ? 'active' : ''}>
                      <PaginationItem as="a" onClick={handleChange}>
                        {item + 1}
                      </PaginationItem>
                    </li>
                  </>
                );
              }
              return <Fragment key={item}>{paginationFragment}</Fragment>;
            })}
          </PaginationUl>
        </PaginationNav>

        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {' '}
          <p className={page >= Math.ceil(count / rowsPerPage) - 1 ? 'disableTextR' : 'textR'}>Next</p>
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
      </PaginationDiv>
    </>
  );
};

export default Pagination;
