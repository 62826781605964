import React from 'react';
import {
  getNearestMedicalFacility,
  getNearestMedicalFacilityContactDetails,
  getEvacuationPoint,
  getFirstAidKitLocation,
} from './summaryEmergencyDetailsSelectors';
import SummaryContentBlock from './SummaryContentBlock';
import { SummarySubSection, SummarySubSectionTitle } from './style';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Fallback from '../../components/Fallback/Fallback';

const SummaryEmergencyDetailsLocations = function ({ data }: any) {
  let nearestMedicalFacility = getNearestMedicalFacility(data);
  if (nearestMedicalFacility) {
    nearestMedicalFacility = nearestMedicalFacility.label;
  }
  const nearestMedicalFacilityContactDetails = getNearestMedicalFacilityContactDetails(data);
  const evacuationPoint = getEvacuationPoint(data);
  const firstAidKitLocation = getFirstAidKitLocation(data);

  return (
    <SummarySubSection>
      <GridContainer>
        <GridItem xs={12}>
          <SummarySubSectionTitle>Emergency Locations</SummarySubSectionTitle>
        </GridItem>
        <GridItem xs={8}>
          <SummaryContentBlock title="Nearest medical facility and contact details">
            <Fallback value={nearestMedicalFacility} message="Medical facility not completed yet" />
            <Fallback
              value={nearestMedicalFacilityContactDetails}
              message="Medical facility contact details not completed yet"
            />
          </SummaryContentBlock>
        </GridItem>
        <GridItem xs={8}>
          <SummaryContentBlock title="Evacuation point">
            <Fallback value={evacuationPoint} />
          </SummaryContentBlock>
        </GridItem>
        <GridItem xs={8}>
          <SummaryContentBlock title="First aid kit location">
            <Fallback value={firstAidKitLocation} />
          </SummaryContentBlock>
        </GridItem>
      </GridContainer>
    </SummarySubSection>
  );
};

export default SummaryEmergencyDetailsLocations;
