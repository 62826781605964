import React from 'react';
import SummarySectionTitle from '../../components/SummarySectionTitle/SummarySectionTitle';
import SummaryWorkpackSidebar from './SummaryWorkpackSidebar';
import SummaryWorkpackPermitsToWork from './SummaryWorkpackPermitsToWork';
import SummaryWorkpackIsolationsRequired from './SummaryWorkpackIsolationsRequired';
import SummaryWorkpackSupportingDocuments from './SummaryWorkpackSupportingDocuments';
import SummaryWorkpackWorkSafe from './SummaryWorkpackWorkSafe';
import { SummarySection, SummarySectionContent, SummarySectionContentInner } from './style';

const SummaryWorkpack = function ({ data }: any) {
  return (
    <SummarySection hasSidebar workpack>
      <SummarySectionContent workpack>
        <SummarySectionTitle sectionNumber="4">Workpack</SummarySectionTitle>
        <SummarySectionContentInner>
          <SummaryWorkpackPermitsToWork data={data} />
          <SummaryWorkpackIsolationsRequired data={data} />
          <SummaryWorkpackWorkSafe data={data} />
          <SummaryWorkpackSupportingDocuments data={data} />
        </SummarySectionContentInner>
      </SummarySectionContent>
      <SummaryWorkpackSidebar data={data} />
    </SummarySection>
  );
};

export default SummaryWorkpack;
