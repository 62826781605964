import React, { memo } from 'react';
import { connect, getIn } from 'formik';
import Autocomplete from './Autocomplete';
import { UpdateJobSafetyAnalysisInput } from '../../API';
interface OuterProps {
  id: string;
  name: string;
  options: any;
  label?: string;
  isRequired?: boolean;
  validationLabel?: string;
  isCreatable?: boolean;
  isDisabled?: boolean;
  isFirstAid?: boolean;
}

/**
 * Formik wrapper on the Select component. Refer Select component for props.
 */
const FormikAutocomplete = memo(
  ({ name, formik, isDisabled, isCreatable = true, isFirstAid = false, ...rest }: any) => {
    const { handleBlur, setFieldValue } = formik;
    const error = getIn(formik.errors, name);
    const touch = getIn(formik.touched, name);
    const value = getIn(formik.values, name);
    const errorMessage = touch && error;

    const onChangeHandler = (valuesToPersist) => {
      if (valuesToPersist && valuesToPersist.value) {
        setFieldValue(name, { id: valuesToPersist.label, label: valuesToPersist.label });
      } else {
        setFieldValue(name, valuesToPersist);
      }
    };

    const onClear = () => {
      setFieldValue(name, null);
    };

    const selectProps = {
      errorMessage,
      value,
      defaultValue: value,
      onChange: onChangeHandler,
      onClear,
      onBlur: handleBlur,
      name,
      isDisabled,
      isCreatable,
      isFirstAid,
      ...rest,
    };

    return <Autocomplete {...selectProps} />;
  }
);

FormikAutocomplete.displayName = 'FormikAutocomplete';

export default connect<OuterProps, UpdateJobSafetyAnalysisInput>(FormikAutocomplete);
