import React from 'react';
import styled from 'styled-components';

export const TextFieldWrapper = styled(({ alignLabel, isErrored, ...rest }) => <div {...rest} />)`
  ${({ alignLabel }) =>
    alignLabel &&
    `
    display: flex;
    align-items: center;
`}
  & .MuiInputBase-input {
    box-sizing: border-box;
    border: 1px solid #C4CBCC
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    ${({ isErrored, theme }) => isErrored && `border: 1px solid ${theme.colors.complementary.red};`}
  }
  & .MuiInputBase-input:focus {
    ${({ isErrored, theme }) => isErrored && `border: 1px solid ${theme.colors.complementary.red};`}
  }
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.complementary.darkRed};
  display: flex;
  flex-direction: row;
  align-item: center;
  margin: 5px 0 5px 0;
  font-size: 14px;
  position: relative;
  right: 7px;
  & svg {
    height: 16px;
  }
`;

export const ErrorMessageWrapper = styled(({ isPICOfWork, isErrored, children, ...rest }) => (
  <div {...rest}>{children}</div>
))`
  margin-bottom: ${({ isPICOfWork, isErrored }) => isPICOfWork && isErrored && '15px'};
`;
