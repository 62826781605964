import React from 'react';
import { produce } from 'immer';
import { ThemeProvider } from 'styled-components';

import { JobCareTheme, JobCareThemeType } from '../../styles/theme';

const WorkStepListTheme = function ({ children }: any) {
  const scaledTheme = produce(JobCareTheme, (draft: JobCareThemeType) => {
    draft.iconCard.scale = 0.8;
  });

  return <ThemeProvider theme={scaledTheme}>{children}</ThemeProvider>;
};

export default WorkStepListTheme;
