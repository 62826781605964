import { TextField } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

export const StyledSearch = styled(({ ...rest }) => <TextField {...rest} />)`
  padding: 12px 0 30px 0;

  & .MuiOutlinedInput-root {
    box-sizing: border-box;
    height: 50px;
    width: 95vw;
    border-radius: 25px;
    background-color: #ffffff;
  }

  & .MuiInputBase-input {
    font-size: 18px;
  }

  & .MuiOutlinedInput-adornedEnd {
    padding-right: 5px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: none;
  }

  & .MuiInputAdornment-positionStart svg {
    width: 20px;
    height: 20px;
    margin-top: 2px;

    path,
    polygon {
      fill: #8b9799;
      stroke: #8b9799;
    }
  }
`;
export const CloseButton = styled(IconButton)`
  color: #9e9e9e;
  right: 5px;
  position: absolute;
  & svg {
    width: 24px;
    height: 24px;
    margin-top: 2px;
  }
  & path {
    fill: white !important;
  }
`;
