import React from 'react';
import {
  SummarySubSection,
  SummarySubSectionTitle,
  SupportingDocumentsLi,
  WorkpackListSupportingDocuments,
} from './style';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Fallback from '../../components/Fallback/Fallback';
import { getFilteredSupportingDocuments } from './SummaryWorkpackSidebarSelectors';
import SummaryItemLink from './SummaryItemLink';

const SummaryWorkpackSupportingDocuments = function ({ data }: any) {
  const supportingDocuments = getFilteredSupportingDocuments(data);

  return (
    <SummarySubSection>
      <GridContainer>
        <GridItem xs={12}>
          <SummarySubSectionTitle>Supporting documents</SummarySubSectionTitle>
        </GridItem>
        <GridItem xs={8}>
          <Fallback value={supportingDocuments} message="Supporting documents not added" isText={false}>
            <WorkpackListSupportingDocuments>
              {supportingDocuments &&
                supportingDocuments.map((item: any, index: number) => {
                  return (
                    <SupportingDocumentsLi key={`supportingDocuments-${index}`}>
                      <SummaryItemLink item={item} key={index} />
                    </SupportingDocumentsLi>
                  );
                })}
            </WorkpackListSupportingDocuments>
          </Fallback>
        </GridItem>
      </GridContainer>
    </SummarySubSection>
  );
};

export default SummaryWorkpackSupportingDocuments;
