import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from '..';
// interface props {
//   text?: string
// }

const RemoveButton = function (props: any) {
  return (
    <IconButton {...props} aria-label="delete">
      {!props.disabled ? (
        <Icon name="Trash" preset="tertiary" />
      ) : (
        <Icon name="TrashInactive" preset="tertiary" />
      )}
      {props.text}
    </IconButton>
  );
};

export default RemoveButton;
