import React from 'react';
import { SummaryContentBlockWrapper, SummaryContentBlockTitle } from './style';

interface MyProps {
  title?: string;
  children?: any;
  wide?: boolean;
  className?: string;
}

const SummaryContentBlock = function ({ title, children, wide }: MyProps) {
  return (
    <SummaryContentBlockWrapper title={title} wide={wide}>
      <SummaryContentBlockTitle variant="h5">{title}</SummaryContentBlockTitle>
      {children || 'Not completed yet'}
    </SummaryContentBlockWrapper>
  );
};

SummaryContentBlock.defaultProps = {
  wide: false,
};

export default SummaryContentBlock;
