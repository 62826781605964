import { useEffect, useContext } from 'react';
import { getIn } from 'formik';
import useDebounce from './useDebounce';
import { JsaFormContext } from '../features/JsaForm/JsaForm';

function useAutoPopulate(field1Path: string, field2Path: string, condition?: boolean) {
  const formikContext = useContext(JsaFormContext);
  // @ts-check
  const field1Value = getIn(formikContext.values, field1Path);
  const debouncedValues = useDebounce(field1Value, 300);

  useEffect(() => {
    if (field1Value && condition) {
      // @ts-check
      formikContext.setFieldValue(field2Path, field1Value);
    }
  }, [debouncedValues, condition]);

  return null;
}

export default useAutoPopulate;
