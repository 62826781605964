import { ApolloClient, InMemoryCache } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';

const {
  REACT_APP_SPACE_ID: SPACE_ID,
  REACT_APP_ACCESS_TOKEN: ACCESS_TOKEN,
  REACT_APP_ENVIRONMENT: ENVIRONMENT,
} = process.env;

const contentfulUrl = () => {
  switch (ENVIRONMENT) {
    case 'Default':
    case 'DEV':
    case 'QA':
    case 'STG':
      return `https://cdn.contentful.com/spaces/${SPACE_ID}/environments/qa/`;
    default:
      return `https://cdn.contentful.com/spaces/${SPACE_ID}/`;
  }
};

const contentfulApolloClient = new ApolloClient({
  link: new RestLink({
    uri: contentfulUrl(),
    headers: {
      Authorization: `Bearer ${ACCESS_TOKEN}`,
    },
  }),
  cache: new InMemoryCache(),
});

export default contentfulApolloClient;
