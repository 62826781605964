import { TextField, MenuItem } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { GridContainer, RemoveButton, ModalContentText } from '../../components';
import GridItem from '../../components/Grid/GridItem';
import Typography from '../../components/Typography/Typography';
import Icon from '../../components/Icon/Icon';

export const StyledInput = styled(({ valid, ...rest }) => <TextField {...rest} />)`
  margin-top: 5px;
  margin-bottom: 25px;
  margin-right: 16px;
  margin-left: 10%;
  max-width: 500px;

  & .MuiOutlinedInput-root {
    box-sizing: border-box;
    height: 50px;

    border-radius: 5px;
    background-color: #ffffff;
    border-color: #16738f;
    border: ${({ valid }) => (valid === false ? '2px solid red' : '0px')};
  }

  & .MuiInputBase-input {
    font-size: 18px;
  }

  & .MuiOutlinedInput-adornedEnd {
    padding-right: 5px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: none;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-top: 2px;

    path,
    polygon {
      fill: #8b9799;
      stroke: #8b9799;
    }
  }

  &
    .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd {
    width: 50vw;
    font-size: 18px;
  }

  & .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
    padding-right: 30px;
    padding-left: 10px;
    background-color: #ffffff;
  }

  & .MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconOpen {
    width: 30px;
    height: 30px;
    margin-top: -7px;
  }

  & .MuiSvgIcon-root.MuiSelect-icon {
    width: 30px;
    height: 30px;
    margin-top: -7px;
  }

  & p.Mui-error {
    margin-left: 0;
    font-size: 14px;
    color: ${(props) => props.theme.colors.complementary.red};
  }

  &
    .MuiInputBase-root.MuiOutlinedInput-root.Mui-error.Mui-error.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
    background: rgba(255, 142, 129, 0.15);
  }

  & .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
    background: rgba(0, 0, 0, 0);
  }
`;

export const StyledMenuItem = styled(({ ...rest }) => <MenuItem {...rest} />)`
    &:hover {
      font-size: 16px;
      background-color: ${(props) => props.theme.colors.complementary.lightestBlue};
    }
  }`;

export const StyledGridContainer = styled(GridContainer)`
  margin-top: 10px;
`;
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      '&:before': {
        // normal
        backgroundColor: '#FF0000',
      },
      '&:after': {
        // focused
        backgroundColor: '#FF0000',
      },
      '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
        // hover
        backgroundColor: '#FF0000',
      },
    },
  })
);

export const FormFieldWrapper = styled(GridItem)`
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  line-height: 44px;
`;

export const TypographyWrapper = styled(GridItem)`
  dispaly: inline !important;
`;

export const FormFieldRemoveButtonWrapper = styled(GridItem)`
  padding-top: 0px !important;
  padding-bottom: 0px !important;
`;

export const FormFieldEmailInputWrapper = styled(GridItem)`
  backgroundcolor: #ffffff;
  width: 100%;
  &
    .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused.Mui-focused.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
    outline: none !important;
    border: 1px #40b3db;
    border-radius: 5px;
    box-shadow: 0 2px 12px 0 rgba(33, 61, 88, 0.3);
  }
`;

export const Divider = styled(({ margin, ...rest }) => <div {...rest} />)`
  margin: ${({ margin }) => margin || '30px 0 20px 0'};
  width: 1012px;
  border: 0.5px solid #e1e5e5;
  background-color: #e1e5e5;
`;

export const AddButtonWrapper = styled(GridItem)`
  padding-top: 7.5px !important;
`;

export const StyledShareJsaDivContainer = styled.div`
  display: flex;
  margin-top: 15px;
  margin-left: 15px;
  padding-left: 35px;
`;

export const StyledCollaboratorDivContainer = styled.div`
  margin-top: 15px;
`;

export const StyledShareJsaTypography = styled(Typography)`
  height: 100%;
  display: inline;
  margin-left: 5px;
  margin-top: 5px;
  color: #1c7b97 !important;
  font-size: 16px;
  line-height: normal;
  vertical-align: middle;
`;
export const StyledCollaboratorsGridItem = styled(GridItem)`
  background-color: #f0f0f0;
  box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  padding-bottom: 50px !important;
`;

export const StyledDivIcon = styled.div`
  display: flex;
  width: 18px;
  padding-right: 14px;
`;

export const StyledFadeDiv = styled.div`
  position: fixed;
  bottom: 18%;
  width: 700px;
  height: 30px;
  background: linear-gradient(180deg, rgba(246, 247, 247, 0) 0%, #f6f7f7 100%);
  @media screen and (max-width: 1367px) and (min-width: 1365px) {
    bottom: 26%;
  }
  @media screen and (max-width: 1024px) {
    bottom: 32%;
  }
`;

export const StyledCollaboratorsListingGridItem = styled(GridItem)`
  padding-left: 0%;
  margin-left: 0%;
  overflow-y: auto;
  height: 200px;
  width: 100%;
`;
export const StyledRemoveButton = styled(({ ...rest }) => <RemoveButton {...rest} />)`
  line-height: '24px';
  margin-left: '5px';
  padding-top: '10px';
  padding-bottom: '10px';
  margin-right: '0px';
`;

export const StyledModalContentText = styled(({ ...rest }) => <ModalContentText {...rest} />)`
color: #43575A;
font-weight: bold; 
font-size: 16px; 
padding-top: 20px; 
padding-left: 10%; 
margin-bottom: 0px"
`;

export const StyledIcon = styled(({ ...rest }) => <Icon {...rest} />)`
  width: 24px;
`;
