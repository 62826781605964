import React from 'react';
import { getIn, useFormikContext } from 'formik';
import Accordion from '../Accordion/Accordion';
import { AccordionDetails, AccordionSummary } from '../Accordion/styles';
import { StyledPpeTypography } from '../ControlsSuggestionField/styles';
import AddButton from '../Button/AddButton';
import Tooltip from '../Tooltip/Tooltip';
import useClientState from '../../hooks/useClientState';
import { getPpeString } from './ppeSelectors';
import { StyledPpeString } from './styles';

const PpeAccordion = function ({ hazard }: any) {
  const { values } = useFormikContext();
  const [expandedPanels, setExpandedPanels] = React.useState<{
    panel1: boolean;
    panel2: boolean;
  }>({ panel1: true, panel2: true });

  const { setIsPpeDrawerOpen } = useClientState();

  const handleChange = (panel: string) => () => {
    // @ts-check
    const currentValue = expandedPanels[panel];
    setExpandedPanels({
      ...expandedPanels,
      [panel]: !currentValue,
    });
  };
  const savedPpe = getIn(values, 'controls.ppe');

  return (
    <Accordion borderTop expanded={expandedPanels.panel1 === true} title="ppe" hazard={hazard}>
      <AccordionSummary>
        Personal Protective Equipment (PPE){' '}
        <Tooltip
          style={{ marginLeft: '8px', marginRight: '6px' }}
          placement="right"
          text="Personal Protective Equipment"
        />
        <div
          style={{
            width: '100px',
            display: 'inline-flex',
            height: '24px',
            float: 'right',
            right: '-5px',
            position: 'absolute',
            top: '25px',
            zIndex: 1000,
          }}
          onClick={handleChange('panel1')}
        />
      </AccordionSummary>
      <AccordionDetails>
        <StyledPpeTypography variant="body1">
          Choose extra PPE needed for this hazard.
        </StyledPpeTypography>
        {savedPpe && savedPpe.length > 0 && (
          <StyledPpeString variant="body1">{getPpeString(savedPpe)}</StyledPpeString>
        )}
        <AddButton text="Add PPE" onClick={() => setIsPpeDrawerOpen(true)} />
      </AccordionDetails>
    </Accordion>
  );
};

export default PpeAccordion;
