import React from 'react';
import { ReactComponent as BreathingApparatus } from '../svgs/ppe/breathing-apparatus.svg';
import { ReactComponent as CalRatedClothing } from '../svgs/ppe/protective-clothing2.svg';
import { ReactComponent as Default } from '../svgs/ppe/default.svg';
import { ReactComponent as DustMask } from '../svgs/ppe/dust-mask.svg';
import { ReactComponent as EyeProtection } from '../svgs/ppe/eye-protection.svg';
import { ReactComponent as FaceShield } from '../svgs/ppe/face-shield.svg';
import { ReactComponent as FireSuppression } from '../svgs/ppe/fire-extinguisher.svg';
import { ReactComponent as FirstAidKit } from '../svgs/ppe/first-aid-kit.svg';
import { ReactComponent as FootProtection } from '../svgs/ppe/foot-protection.svg';
import { ReactComponent as GasDetection } from '../svgs/ppe/gas-detection.svg';
import { ReactComponent as HandProtection } from '../svgs/ppe/hand-protection.svg';
import { ReactComponent as SafetyHelmet } from '../svgs/ppe/head-protection.svg';
import { ReactComponent as HearingProtection } from '../svgs/ppe/hearing-protection.svg';
import { ReactComponent as HighVisibility } from '../svgs/ppe/high-visibility-clothing.svg';
import { ReactComponent as LifeVest } from '../svgs/ppe/life-vest.svg';
import { ReactComponent as PortableRadio } from '../svgs/ppe/portable-radio.svg';
import { ReactComponent as PortableVentilation } from '../svgs/ppe/portable-ventilation.svg';
import { ReactComponent as ProtectiveClothing } from '../svgs/ppe/protective-clothing.svg';
import { ReactComponent as Respirator } from '../svgs/ppe/respirator.svg';
import { ReactComponent as SafetyHarness } from '../svgs/ppe/safety-harness.svg';
import { ReactComponent as TripodWinch } from '../svgs/ppe/tripod-winch.svg';
import { ReactComponent as WeldingMask } from '../svgs/ppe/welding-mask.svg';
import { ReactComponent as LongLongClothing } from '../svgs/ppe/long-long-clothing.svg';

import styled from 'styled-components';

export const ppeNames = {
  BreathingApparatus,
  CalRatedClothing,
  DustMask,
  Default,
  EyeProtection,
  FaceShield,
  FireSuppression,
  FirstAidKit,
  FootProtection,
  GasDetection,
  HandProtection,
  SafetyHelmet,
  HearingProtection,
  HighVisibility,
  LifeVest,
  PortableRadio,
  PortableVentilation,
  ProtectiveClothing,
  Respirator,
  SafetyHarness,
  TripodWinch,
  WeldingMask,
  LongLongClothing,
};

export interface PpeIconProps {
  name: string;
  viewBox?: string;
}

// eslint-disable-next-line @typescript-eslint/no-use-before-define
export const StyledPpeIcon = styled(({ ...rest }) => <PpeIcon {...rest} />)`
  width: 100px;
  height: 100px;
`;

const PpeIcon = ({ name, ...rest }: PpeIconProps) => {
  if (ppeNames[name]) {
    return <StyledPpeIcon as={ppeNames[name]} {...rest} />;
  }
  return <StyledPpeIcon as={ppeNames['Default']} {...rest} />;
};

export default PpeIcon;
