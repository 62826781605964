import { v4 as uuidv4 } from 'uuid';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import cleaner from 'deep-cleaner';
import mapEmptyDate from '../../utils/mapEmptyDate';

const selectComboInputDefault = {
  id: '',
  label: '',
};

const workPackUtilityDefault = {
  isChecked: false,
  value: selectComboInputDefault,
};

const getWorkPackSupportDocuments = (data: any) => {
  const initialValue = get(data, 'getJobSafetyAnalysis.workPack.supportDocuments');

  if (isArray(initialValue)) {
    return initialValue;
  }
};

const getWorkPackUtilities = (data: any) => {
  const electricity = get(
    data,
    'getJobSafetyAnalysis.workPack.utilities.electricity',
    workPackUtilityDefault
  );
  const gas = get(data, 'getJobSafetyAnalysis.workPack.utilities.gas', workPackUtilityDefault);
  const water = get(data, 'getJobSafetyAnalysis.workPack.utilities.water', workPackUtilityDefault);
  const wastewater = get(
    data,
    'getJobSafetyAnalysis.workPack.utilities.wastewater',
    workPackUtilityDefault
  );
  const stormwater = get(
    data,
    'getJobSafetyAnalysis.workPack.utilities.stormwater',
    workPackUtilityDefault
  );
  const telco = get(data, 'getJobSafetyAnalysis.workPack.utilities.telco', workPackUtilityDefault);

  return {
    electricity,
    gas,
    water,
    wastewater,
    stormwater,
    telco,
  };
};

// @TODO: Use reselect to simplify the selecting values
const setInitialValues = (dataFromQuery: any) => {
  const data = cleaner(dataFromQuery, [
    '__typename',
    'createdDate',
    'updatedDate',
    'version',
    '__isNew__',
  ]);

  const getEmergencyContacts = () => {
    const initialValue = get(data, 'getJobSafetyAnalysis.emergencyDetails.emergencyContacts');

    if (isArray(initialValue)) {
      return initialValue;
    }

    return [
      {
        firstName: '',
        lastName: '',
        contactNumber: '',
      },
    ];
  };

  const getMyTeam = () => {
    const initialValue = get(data, 'getJobSafetyAnalysis.teamDetails.teamContacts');

    if (isArray(initialValue)) {
      return initialValue;
    }

    return [
      {
        firstName: '',
        lastName: '',
        email: '',
        role: 'PICW',
        agreed: false,
        agreedDate: '',
      },
      {
        firstName: '',
        lastName: '',
        email: '',
        role: 'Team Member',
        agreed: false,
        agreedDate: '',
      },
      {
        firstName: '',
        lastName: '',
        email: '',
        role: 'Team Member',
        agreed: false,
        agreedDate: '',
      },
    ];
  };

  const getWorkSteps = () => {
    const initialValue = get(data, 'getJobSafetyAnalysis.workSteps');

    if (isArray(initialValue)) {
      return initialValue;
    }

    return [
      {
        id: uuidv4(),
        text: '',
        hazards: null,
        editingHazards: false,
      },
      {
        id: uuidv4(),
        text: '',
        hazards: null,
        editingHazards: false,
      },
      {
        id: uuidv4(),
        text: '',
        hazards: null,
        editingHazards: false,
      },
    ];
  };

  return {
    id: get(data, 'getJobSafetyAnalysis.id'),
    ppe: get(data, 'getJobSafetyAnalysis.ppe'),
    workSteps: getWorkSteps(),
    categoriesWithCustomHazards: get(data, 'getJobSafetyAnalysis.categoriesWithCustomHazards', []),
    workDetails: {
      personInChargeOfWork: get(data, 'getJobSafetyAnalysis.workDetails.personInChargeOfWork', ''),
      serviceProvider: get(data, 'getJobSafetyAnalysis.workDetails.serviceProvider', ''),
      jsaWrittenBy: {
        firstName: get(data, 'getJobSafetyAnalysis.workDetails.jsaWrittenBy.firstName', ''),
        lastName: get(data, 'getJobSafetyAnalysis.workDetails.jsaWrittenBy.lastName', ''),
        contactNumber: get(data, 'getJobSafetyAnalysis.workDetails.jsaWrittenBy.contactNumber', ''),
        email: get(data, 'getJobSafetyAnalysis.workDetails.jsaWrittenBy.email', ''),
      },
      personInCharge: {
        firstName: get(data, 'getJobSafetyAnalysis.workDetails.personInCharge.firstName', ''),
        lastName: get(data, 'getJobSafetyAnalysis.workDetails.personInCharge.lastName', ''),
        contactNumber: get(data, 'getJobSafetyAnalysis.workDetails.personInCharge.contactNumber', ''),
        email: get(data, 'getJobSafetyAnalysis.workDetails.personInCharge.email', ''),
      },
      watercareRepresentative: {
        firstName: get(data, 'getJobSafetyAnalysis.workDetails.watercareRepresentative.firstName', ''),
        lastName: get(data, 'getJobSafetyAnalysis.workDetails.watercareRepresentative.lastName', ''),
        contactNumber: get(
          data,
          'getJobSafetyAnalysis.workDetails.watercareRepresentative.contactNumber',
          ''
        ),
        email: get(data, 'getJobSafetyAnalysis.workDetails.watercareRepresentative.email', ''),
      },
      siteName: get(data, 'getJobSafetyAnalysis.workDetails.siteName', null),
      siteAddress: get(data, 'getJobSafetyAnalysis.workDetails.siteAddress', ''),
      operatingArea: get(data, 'getJobSafetyAnalysis.workDetails.operatingArea', ''),
      workLocationProcessArea: get(data, 'getJobSafetyAnalysis.workDetails.workLocationProcessArea', ''),
      plannedWorkDate: {
        startDate: mapEmptyDate(data, 'getJobSafetyAnalysis.workDetails.plannedWorkDate.startDate'),
        endDate: mapEmptyDate(data, 'getJobSafetyAnalysis.workDetails.plannedWorkDate.endDate'),
      },
      descriptionOfWork: get(data, 'getJobSafetyAnalysis.workDetails.descriptionOfWork', ''),
      scopeOfWork: get(data, 'getJobSafetyAnalysis.workDetails.scopeOfWork', ''),
    },
    emergencyDetails: {
      facilityControlRoom: get(data, 'getJobSafetyAnalysis.emergencyDetails.facilityControlRoom', ''),
      facilityControlRoomNumber: get(
        data,
        'getJobSafetyAnalysis.emergencyDetails.facilityControlRoomNumber',
        ''
      ),
      evacuationPoint: get(data, 'getJobSafetyAnalysis.emergencyDetails.evacuationPoint', ''),
      firstAidKitLocation: get(data, 'getJobSafetyAnalysis.emergencyDetails.firstAidKitLocation', null),

      nearestMedicalFacility: get(
        data,
        'getJobSafetyAnalysis.emergencyDetails.nearestMedicalFacility',
        ''
      ),
      nearestMedicalFacilityContactDetails: get(
        data,
        'getJobSafetyAnalysis.emergencyDetails.nearestMedicalFacilityContactDetails',
        ''
      ),
      emergencyContacts: getEmergencyContacts(),
    },

    teamDetails: {
      teamContacts: getMyTeam(),
    },

    workPack: {
      supportDocuments: getWorkPackSupportDocuments(data),
      permits: get(data, 'getJobSafetyAnalysis.workPack.permits', []),
      isolations: get(data, 'getJobSafetyAnalysis.workPack.isolations', []),
      utilities: getWorkPackUtilities(data),
      energyArrangements: get(data, 'getJobSafetyAnalysis.workPack.energyArrangements', ''),
      worksafeNotification: get(data, 'getJobSafetyAnalysis.workPack.worksafeNotification', ''),
    },
    summaryComments: {
      WorkDetails: get(data, 'getJobSafetyAnalysis.summaryComments.WorkDetails', []),
      EmergencyDetails: get(data, 'getJobSafetyAnalysis.summaryComments.EmergencyDetails', []),
      HazardsControls: get(data, 'getJobSafetyAnalysis.summaryComments.HazardsControls', []),
      Workpack: get(data, 'getJobSafetyAnalysis.summaryComments.Workpack', []),
    },
    collaboratorDetails: {
      collaborators: get(data, 'getJobSafetyAnalysis.collaboratorDetails.collaborators', []),
    },
  };
};

export default setInitialValues;
