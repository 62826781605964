import styled from 'styled-components';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import Icon from '../Icon/Icon';

export const StyledLabel = styled.div`
  margin-bottom: -12px;
`;

export const FieldWrapper = styled.div`
  position: relative;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const InputIcon = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  right: 10px;
  top: 30px;
  pointer-events: none;
`;

export const StyledDatePicker = styled(MuiDatePicker)`
  width: 100%;

  & .MuiInput-root {
    width: 100%;
    height: 50px;
    border: 1px solid ${(props) => props.theme.colors.greyScale['10']};
    border-radius: 5px;
    cursor: pointer;
    background-color: white;
  }

  & .MuiInputBase-input {
    padding-left: 14px;
    color: #0a0d0d;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;
  }

  &
    .MuiInputBase-root.MuiInput-root.Mui-focused.Mui-focused.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd {
    border: 2px solid ${(props) => props.theme.colors.primary.mediumBlue};
    background-color: white;
  }
`;

export const StyledIcon = styled(Icon)`
  width: 24px;
`;
