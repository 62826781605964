import { useState, useEffect, useRef } from 'react';

export default function useComponentVisible(initialIsVisible: any) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef<HTMLDivElement>(null);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = (event: any) => {
    let nextLevel = true;
    if (ref.current && event.target.className !== null) {
      if (ref.current.className === event.target.className) {
        setIsComponentVisible(true);
        return true;
      }
      if (!event.target.parentNode) nextLevel = false;
    } else {
      nextLevel = false;
    }
    if (ref.current && nextLevel) {
      if (ref.current.className === event.target.parentNode.className) {
        setIsComponentVisible(true);
        return true;
      }
      if (!event.target.parentNode.parentNode) nextLevel = false;
    }
    if (ref.current && nextLevel) {
      if (ref.current.className === event.target.parentNode.parentNode.className) {
        setIsComponentVisible(true);
        return true;
      }
      if (!event.target.parentNode.parentNode.parentNode) nextLevel = false;
    }
    if (ref.current && nextLevel) {
      if (ref.current.className === event.target.parentNode.parentNode.parentNode.className) {
        setIsComponentVisible(true);
        return true;
      }
      if (!event.target.parentNode.parentNode.parentNode.parentNode) nextLevel = false;
    }
    if (ref.current && nextLevel) {
      if (ref.current.className === event.target.parentNode.parentNode.parentNode.parentNode.className) {
        setIsComponentVisible(true);
        return true;
      }
      if (!event.target.parentNode.parentNode.parentNode.parentNode.parentNode) nextLevel = false;
    }
    if (ref.current && nextLevel) {
      if (
        ref.current.className ===
        event.target.parentNode.parentNode.parentNode.parentNode.parentNode.className
      ) {
        setIsComponentVisible(true);
        return true;
      }
    }
    setIsComponentVisible(false);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}
