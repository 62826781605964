import React from 'react';
import { useFormikContext, getIn } from 'formik';
import InputAdornment from '@material-ui/core/InputAdornment';
import { GridItem, CheckBox, FormikAutocomplete } from '../../components';
import {
  StyledGridItemCenter,
  UtilityInputGroup,
  UtilityInputLabel,
  UtilityInputWrapper,
  UtilityStyledInput,
} from './styles';
import { useFieldReset } from '../../hooks';

interface UtilityInputProps {
  name: string;
  checkboxLabel: string;
  valueLabel: string;
  options?: any;
  hasInput?: boolean;
}

const UtilityInputRow = function ({
  name,
  checkboxLabel,
  valueLabel,
  options,
  hasInput,
}: UtilityInputProps) {
  const { values } = useFormikContext();
  const isChecked = getIn(values, `${name}.isChecked`);

  useFieldReset(`${name}.value`, !isChecked, '');

  return (
    <>
      <StyledGridItemCenter xs={4}>
        <CheckBox name={`${name}.isChecked`} label={checkboxLabel} />
      </StyledGridItemCenter>
      <StyledGridItemCenter xs={5}>
        {hasInput && (
          <UtilityInputGroup isHidden={!isChecked}>
            <UtilityInputLabel htmlFor={`${name}.value`} label={valueLabel} />
            <UtilityInputWrapper>
              {options ? (
                <FormikAutocomplete id={`${name}.value`} name={`${name}.value`} options={options} />
              ) : (
                <UtilityStyledInput
                  alignLabel
                  id={`${name}.value`}
                  name={`${name}.value.id`}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div>kPa</div>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </UtilityInputWrapper>
          </UtilityInputGroup>
        )}
      </StyledGridItemCenter>
      <GridItem xs={3} />
    </>
  );
};

export default UtilityInputRow;
