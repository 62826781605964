import { createSelector } from 'reselect';
import get from 'lodash/get';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import flatten from 'lodash/fp/flatten';
import uniqBy from 'lodash/fp/uniqBy';
import filter from 'lodash/fp/filter';
import sortBy from 'lodash/fp/sortBy';

// Base selectors
export const getJobSafetyAnalysis = (data: any) => get(data, 'getJobSafetyAnalysis', {});
export const getWorkSteps = (data: any) => get(data, 'data.getJobSafetyAnalysis.workSteps', []);
export const getPermitMasterData = (data: any) => get(data, 'permitData.items', null);

// Computed selectors
export const getHazards = createSelector([getWorkSteps], (workSteps) => {
  const processHazards = flow(
    map(({ hazards }: any) => hazards),
    flatten,
    uniqBy('code')
  )(workSteps);

  return processHazards || null;
});

export const getControls = createSelector([getHazards], (hazards) => {
  const processControls = flow(map(({ controls }: any) => controls))(hazards);

  return processControls || null;
});

export const getPermits = createSelector([getControls], (controls) => {
  const processPermits = flow(map((control: any) => get(control, 'permits')))(controls);

  return processPermits || null;
});

export const getCleanPermits = createSelector([getPermits], (permits) => {
  const reducedPermits = flow(filter((permit: any) => permit !== undefined))(permits);

  return reducedPermits || null;
});

export const getRequiredPermits = createSelector([getCleanPermits], (permits) => {
  const requiredPermits = flow(
    filter((permit: any) => permit.code !== ''),
    uniqBy('code'),
    sortBy('name'),
    filter((permit: any) => permit !== '' && permit.required === true)
  )(permits);

  return requiredPermits || null;
});

export const getPermitFileTypeOptions = createSelector([getPermitMasterData], (permitMasterData) => {
  if (permitMasterData) {
    return permitMasterData.map(({ fields }: any) => ({
      id: fields.code,
      label: fields.name,
      value: fields.code,
    }));
  }

  return [];
});
