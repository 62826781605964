import React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { StyledHazardChip, StyledControlsOverlaySubTitle } from './styles';
import HazardChip from '../HazardChip/HazardChip';
import { getHazardToUpdate } from '../../redux/workStepsSlice';

const ControlsOverlaySubTitle = function () {
  const { hazard } = useSelector(getHazardToUpdate);
  const hazardName = get(hazard, 'name');
  const hazardCode = get(hazard, 'code');

  return (
    <div>
      <StyledControlsOverlaySubTitle gutterBottom>
        If you cannot get rid of (eliminate) the hazard, then you must control it. The higher the risk,
        the more effective your controls must be.
      </StyledControlsOverlaySubTitle>
      <StyledHazardChip>
        {hazardName && hazardCode && <HazardChip name={hazardCode} label={hazardName} />}
      </StyledHazardChip>
    </div>
  );
};

export default ControlsOverlaySubTitle;
