import React from 'react';
import { uniqueId } from 'lodash';
import { FieldArray } from 'formik';
import IconCardCheckbox from './IconCardCheckbox';
import { StyledCardInputGroup } from './style';
import AddHazardCard from './AddHazardCard';
import useJsaQuery from '../../hooks/useJsaQuery';

const IconCardInputGroup = ({ items, name, cardType, mandatory, newItem }: any) => {
  const { data } = useJsaQuery();
  return (
    <FieldArray name={name} validateOnChange={false}>
      {(arrayHelpers) => (
        <StyledCardInputGroup>
          {items.map((item: any) => (
            <IconCardCheckbox
              name={name}
              key={uniqueId('IconCardCheckbox-')}
              item={item}
              arrayHelpers={arrayHelpers}
              cardType={cardType}
              mandatory={mandatory}
              data={data}
            />
          ))}
          {cardType !== 'ppe' && <AddHazardCard newItem={newItem} />}
        </StyledCardInputGroup>
      )}
    </FieldArray>
  );
};

export default IconCardInputGroup;
