import React, { memo } from 'react';
import map from 'lodash/map';
import chunk from 'lodash/chunk';
import {
  SummarySection,
  SummarySectionContentPpe,
  StyledSummaryPpe,
  StyledSummaryPpeGridItem,
  StyledSummaryPpeGridContainer,
} from './style';
import SummarySectionTitle from '../../components/SummarySectionTitle/SummarySectionTitle';
import { getCombinedPpe } from './SummaryPpeThatYouNeedSelectors';
import Fallback from '../../components/Fallback/Fallback';
import SummaryPpeCard from '../../components/SummaryPpeCard/SummaryPpeCard';
import usePpeData from '../../hooks/usePpeMasterData';
import { getRequiredPpe } from '../../components/PpeDrawer/ppeSelectors';

const SummaryPpeThatYouNeed = memo(({ data }: any) => {
  const ppeMasterData = usePpeData();
  const requiredPpe = getRequiredPpe(ppeMasterData as never);
  const ppe = getCombinedPpe(data).length === 0 ? requiredPpe : getCombinedPpe(data);
  const sortPpe = ppe.sort((a, b) => b.required - a.required);
  const chunkPpe = chunk(sortPpe, 4);

  return (
    <SummarySection>
      <SummarySectionContentPpe>
        <SummarySectionTitle sectionNumber="B">PPE that you need</SummarySectionTitle>
        <Fallback value={ppe} message="PPE not completed yet" isText={false}>
          <StyledSummaryPpe key="StyledSummaryHazard-">
            {map(chunkPpe, (chunk: any, index: number) => (
              <StyledSummaryPpeGridContainer key={index}>
                {map(chunk, (ppe: any, innerIndex: number) => (
                  <StyledSummaryPpeGridItem key={`${index}-${innerIndex}`}>
                    <SummaryPpeCard colour={ppe.colour} code={ppe.code} name={ppe.ppeName} />
                  </StyledSummaryPpeGridItem>
                ))}
              </StyledSummaryPpeGridContainer>
            ))}
          </StyledSummaryPpe>
        </Fallback>
      </SummarySectionContentPpe>
    </SummarySection>
  );
});

SummaryPpeThatYouNeed.displayName = 'SummaryPpeThatYouNeed';

export default SummaryPpeThatYouNeed;
