import styled from 'styled-components';
import { Drawer, Divider } from '@material-ui/core';
import Typography from '../Typography/Typography';
import GridContainer from '../Grid/GridContainer';

export const DrawerContentBase = styled.div`
  width: 679px;
  margin-left: 40px;
  margin-right: 41px;
`;
export const StyledDrawer = styled(Drawer)`
  box-shadow: 0 3px 25px 0 rgba(33, 61, 88, 0.25);
`;
export const Heading = styled(Typography)`
  margin-bottom: 17px;
  margin-top: 60px;
  text-align: center;
`;

export const HeaderDivider = styled(Divider)`
  margin-top: 30px;
`;
export const ActionsDivider = styled(Divider)`
  margin-bottom: 30px;
  margin-top: 26px;
`;
export const PpeSectionBase = styled.div`
  margin-top: 32px;
`;
export const StyledGridContainer = styled(GridContainer)`
  justify-content: center;
`;
