import React from 'react';
import styled from 'styled-components';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Icon from '../Icon/Icon';

export const StyledIcon = styled(Icon)`
  position: absolute;
  height: 40px;
  width: 40px;

  & path {
    fill: ${(props) => props.theme.colors.greyScale['25']};
  }
`;

export const StyledOverlay = styled(({ fullscreen, controls, ...rest }) => <MuiDialog {...rest} />)`
  & .MuiDialog-paper {
    background-color: ${({ theme }) => theme.colors.greyScale['3']};
    overflow-y: visible;
    margin: 0px;
  }
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  width: 0px;
  margin-left: 96%;
  margin-top: 1%;

  &:hover {
    background-color: transparent;
  }
`;

export const StyledDialogContent = styled(MuiDialogContent)``;

export const Divider = styled.hr`
  border-top: 2px solid ${(props) => props.theme.colors.greyScale['10']};
`;
export const StyledDialogActions = styled(({ trigger, ...rest }) => <DialogActions {...rest} />)`
  display: block;
  padding: 16px 24px;
  padding-top: ${({ trigger }) => (trigger ? '5px' : '0px')};
  padding-bottom: ${({ trigger }) => (trigger ? '5px' : '28px')};
  box-shadow: ${({ trigger }) => (trigger ? ' 0 -1px 3px 0 rgba(33,61,88,0.35);' : '')};
  z-index: 1;

  & hr {
    margin-top: 0;
    margin-bottom: ${({ trigger }) => (trigger ? '5px' : '27.5px')};
    display: ${({ trigger }) => (trigger ? 'none' : '')};
  }
`;

export const StyledDialogActionsInner = styled(DialogActions)`
  width: 100%;
`;

export const StyledDialogTitle = styled(({ trigger, ...rest }) => <DialogTitle {...rest} />)`
  padding-top: ${({ trigger }) => (trigger ? '5px' : '60px')};
  padding-bottom: ${({ trigger }) => (trigger ? '5px' : '0px')};
  box-shadow: ${({ trigger }) => (trigger ? ' 0 1px 3px 0 rgba(33,61,88,0.35)' : '')};
  z-index: 1;

  & hr {
    margin: 0;
    display: ${({ trigger }) => (trigger ? 'none' : '')};
    margin-top: 32px;
  }

  & h2 {
    font-size: ${({ trigger }) => (trigger ? '18px !important' : '32px')};
    font-weight: ${({ trigger }) => (trigger ? 'bold !important' : '300')};
    line-height: ${({ trigger }) => (trigger ? '24px !important' : '43px')};
  }
`;
