import MuiButton from '@material-ui/core/Button';
import styled from 'styled-components';

const StatusLabel = styled(MuiButton)`
  display: inline-block;
  background-color: #57a13f;
  color: white;
  padding: 0px;
  margin-top: 0%;
  margin-left: 10px;
`;

export default StatusLabel;
