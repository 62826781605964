import { Button as MuiButton } from '@material-ui/core';
import styled from 'styled-components';
import React from 'react';
import Icon from '../Icon/Icon';

export const ButtonBase = styled(({ preset, themeColour, size, fullWidth, ...rest }) => (
  <MuiButton {...rest} />
))`
  margin-top: 5px;
  min-width: 180px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  padding-left: 20px;
  padding-right: 20px;
  text-transform: ${({ preset }) => {
    if (preset !== 'tertiary') {
      return 'capital';
    }
    return 'none';
  }};
  height: ${({ preset }) => {
    if (preset === 'tertiary') {
      return '25px';
    }
    return '50px';
  }};
  border-radius: ${({ preset }) => (preset !== 'tertiary' ? '25px' : '0px')};

  background-color: ${({ preset, themeColour, theme }) => {
    if (preset === 'primary') {
      if (themeColour === 'grey') {
        return theme.colors.findColor('greyScale_25');
      }
      return theme.colors.findColor(themeColour);
    }
  }};
  box-shadow: ${({ preset }) => (preset === 'primary' ? '0 2px 5px 0 rgba(33, 61, 88, 0.4)' : 'none')};
  border: ${({ preset, themeColour, theme }) => {
    if (preset === 'secondary') {
      let color = '';
      switch (themeColour) {
        case 'lightLeaf':
          color = theme.colors.findColor('lightLeaf');
          break;
        case 'mediumBlue':
          color = theme.colors.findColor('mediumBlue');
          break;
        case 'grey':
          color = theme.colors.findColor('greyScale_25');
          break;
      }
      return `2px solid ${color}`;
    }
  }};

  & .MuiButton-label {
    font-weight: bold;
    height: 20px;
    white-space: nowrap;
    overflow: ${({ preset }) => (preset === 'primary' || preset === 'secondary' ? 'hidden' : 'inherit')};
    text-align: left;
    text-overflow: ellipsis;
    display: ${({ preset }) => (preset === 'primary' || preset === 'secondary' ? 'block' : 'inherit')};
    font-size: ${({ preset }) => (preset === 'tertiary' ? '16px' : '15px')};

    letter-spacing: ${({ preset }) => (preset === 'tertiary' ? '0px' : '0.5px')};
    line-height: ${({ preset }) => (preset === 'tertiary' ? '24px' : '20px')};
    text-align: center;
    color: ${({ preset, themeColour, theme }) => {
      if (preset === 'secondary') {
        if (themeColour === 'grey') {
          return theme.colors.findColor('greyScale_50');
        }
        return theme.colors.findColor(themeColour);
      }
      if (preset === 'tertiary') {
        return theme.colors.findColor('blue');
      }
      if (themeColour === 'greyScale_10') {
        return theme.colors.findColor('darkBlue');
      }
      return theme.colors.findColor('greyScale_0');
    }};
  }

  &:hover {
    border: ${({ preset, themeColour, theme }) => {
      if (preset !== 'secondary') {
        return 'none';
      }
      const borderString = '2px solid';
      if (themeColour === 'grey') {
        return borderString + theme.colors.mixHoverColor('greyScale_40');
      }
      if (themeColour === 'lightLeaf') {
        return borderString + theme.colors.mixHoverColor('leaf');
      }
      if (themeColour === 'mediumBlue') {
        return borderString + theme.colors.mixHoverColor('blue');
      }
      return borderString + theme.colors.mixHoverColor(themeColour);
    }};
    background-color: ${({ preset, themeColour, theme }) => {
      if (preset === 'tertiary') {
        return 'transparent';
      }
      if (themeColour === 'grey') {
        return theme.colors.mixHoverColor('greyScale_40');
      }
      if (themeColour === 'lightLeaf') {
        return theme.colors.mixHoverColor('leaf');
      }
      if (themeColour === 'mediumBlue') {
        return theme.colors.mixHoverColor('blue');
      }
      if (themeColour === 'greyScale_10') {
        return theme.colors.mixHoverColor('dullBlue');
      }
      return theme.colors.mixHoverColor(themeColour);
    }};
    & .MuiButton-label {
      color: ${({ preset, theme }) => {
        switch (preset) {
          case 'tertiary':
            return theme.colors.mixHoverColor('darkBlue');
          default:
            return theme.colors.findColor('greyScale_0');
        }
      }};
    }
    & path {
      fill: ${({ preset, theme }) => {
        if (preset === 'tertiary') {
          return theme.colors.mixHoverColor('darkBlue');
        }
      }};
    }
  }

  & path {
    fill: ${({ preset, themeColour, theme }) => {
      if (preset === 'primary') {
        return theme.colors.findColor('greyScale_0');
      }
      if (preset === 'tertiary') {
        return theme.colors.findColor('mediumBlue');
      }
      if (themeColour === 'grey') {
        return theme.colors.findColor('greyScale_40');
      }
      return theme.colors.findColor(themeColour);
    }};
  }

  &:disabled {
    background-color: ${({ preset, theme }) =>
      preset === 'secondary' || preset === 'tertiary' ? 'none' : theme.colors.findColor('greyScale_10')};
    box-shadow: none;
    border-color: ${({ preset, theme }) =>
      preset === 'secondary' ? theme.colors.findColor('greyScale_10') : 'none'};
    & .MuiButton-label {
      color: ${({ preset, theme }) =>
        preset === 'secondary'
          ? theme.colors.findColor('greyScale_10')
          : theme.colors.findColor('greyScale_25')};
    }
    & path {
      fill: ${({ theme, preset }) => {
        if (preset === 'primary') {
          return theme.colors.findColor('greyScale_0');
        }
        return theme.colors.findColor('greyScale_25');
      }};
    }
  }
`;
export const StyledLinkButton = styled(ButtonBase)`
  padding: 6px 8px;
  min-width: unset;
  height: unset;

  & .MuiButton-label {
    & svg {
      margin-right: 5px;
    }
  }

  &:disabled {
    background-color: transparent;
  }
`;

export const StyledLinkIcon = styled(Icon)`
  padding-right: 3px;
`;
