import React from 'react';
import Modal from '../Modal/Modal';
import Typography from '../Typography/Typography';
import ModalTitle from '../Modal/ModalTitle';
import RiskSliderModalContent from './RiskSliderModalContent';
import ModalContent from '../Modal/ModalContent';
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import { Divider } from './styles';

const RiskSliderModal = function ({ open, data, onClose, riskModalName }: any) {
  let riskType;
  let bodyText;

  const contentStyle =
    "height: 43px;color: #213D58;font-family: 'Nunito Sans';font-size: 32px;font-weight: 300;letter-spacing: 0;line-height: 43px;padding-top:50px;padding-bottom:0.5px;margin: auto!important;";

  if (riskModalName === 'initialRisk') {
    riskType = 'hazard';
    bodyText =
      'Without controls, rate the realistic likelihood of an event happening. If an event happens, rate the realistic level of severity.';
  } else {
    riskType = 'residual';
    bodyText = 'With the planned controls, reassess the likelihood and severity rating.';
  }

  return (
    <Modal open={open} size="855px" height="614px">
      <ModalTitle onClose={onClose} styles={contentStyle}>
        {' '}
        Assess the
        {riskType} risk
      </ModalTitle>
      <ModalContent padding="13px 85px 60px 85px" overflowy>
        <GridContainer>
          <GridItem style={{ width: '100%' }}>
            <Typography
              style={{ letterSpacing: '0px!important', textAlign: 'center' }}
              variant="body1"
              paragraph
            >
              {bodyText}
            </Typography>
            <Divider />
          </GridItem>
          <RiskSliderModalContent data={data} riskModalName={riskModalName} />
        </GridContainer>
      </ModalContent>
    </Modal>
  );
};

Modal.defaultProps = {
  maxWidth: 'md',
  onClose: () => ({}),
  open: false,
  size: '679px',
};

export default RiskSliderModal;
