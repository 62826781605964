import React from 'react';
import { NextAdornment, StyledInputAdornmentButton } from '../ControlsSuggestionInput/styles';
import ControlsSuggestionMenu from '../ControlsSuggestionInput/ControlsSuggestionMenu';

const EndAdornment = function ({
  isEditing,
  value,
  isMenuOpen,
  isErrored,
  handleClick,
  anchorEl,
  handleClose,
  toggleDeleteModal,
  editMeasure,
}: any) {
  if (isEditing && !isMenuOpen) {
    return (
      <NextAdornment position="end">
        <StyledInputAdornmentButton disabled={!value}>DONE</StyledInputAdornmentButton>
      </NextAdornment>
    );
  }
  return (
    <NextAdornment position="end">
      <ControlsSuggestionMenu
        {...{
          isMenuOpen,
          handleClick,
          isErrored,
          anchorEl,
          handleClose,
          toggleDeleteModal,
          editMeasure,
        }}
      />
    </NextAdornment>
  );
};

export default EndAdornment;
