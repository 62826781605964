import React, { useMemo } from 'react';
import { useFormikContext, getIn } from 'formik';
import GridItem from '../Grid/GridItem';
import Button from '../Button/Button';
import { ActionsDivider, StyledGridContainer } from './styles';

const DrawerActions = function ({ onClose, handleSubmit }: any) {
  const { values } = useFormikContext();
  const [selectedHazards, setSelectedHazards] = React.useState([]);
  useMemo(() => {
    const draftPpe = getIn(values, 'controls.draftPpe');
    const filterPpe = draftPpe.filter((ppe: any) => !ppe.required);
    setSelectedHazards(filterPpe);
  }, [values]);

  const isDisabled = !(selectedHazards.length > 0);

  const addPPEText = React.useCallback(
    () => (!(selectedHazards.length > 0) ? 'ADD PPE' : `ADD ${selectedHazards.length} PPE`),
    [selectedHazards]
  );

  return (
    <>
      <ActionsDivider />
      <StyledGridContainer>
        <GridItem xs={6}>
          <Button fullWidth preset="secondary" themeColour="grey" onClick={onClose}>
            Cancel
          </Button>
        </GridItem>
        <GridItem xs={6}>
          <Button
            fullWidth
            type="submit"
            onClick={handleSubmit}
            preset="primary"
            themeColour="blue"
            disabled={isDisabled}
          >
            {addPPEText()}
          </Button>
        </GridItem>
      </StyledGridContainer>
    </>
  );
};

export default DrawerActions;
