import React from 'react';
import { ErrorMessage as FormikErrorMessage } from 'formik';
import { ErrorMessageText } from './styles';

const ErrorMessage = function (props: any) {
  return (
    <FormikErrorMessage {...props}>
      {(msg: any) => (
        <div>
          <ErrorMessageText>{msg}</ErrorMessageText>
        </div>
      )}
    </FormikErrorMessage>
  );
};

export default ErrorMessage;
