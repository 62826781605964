import React from 'react';
import styled from 'styled-components';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '../Icon/Icon';

const ItemText = styled(({ ...rest }) => <ListItemText {...rest} />)`
  & .MuiTypography-root {
    color: ${(props) => (props.color ? props.color : props.theme.colors.greyScale['3'])};
    text-transform: ${(props) => (props.color === '#40B3DB' ? 'uppercase' : 'none')};
  }
`;

const ItemIcon = styled(({ ...rest }) => <Icon {...rest} />)`
  && path {
    fill: ${({ color, theme }) => color || theme.colors.greyScale['3']};
  }
`;
interface TrayNavListItemProps {
  id?: string;
  text: string;
  iconName?:
    | 'Close'
    | 'Home'
    | 'KnowledgeBase'
    | 'NewJsa'
    | 'Notifications'
    | 'Search'
    | 'TakeFive'
    | 'Toolbox'
    | string;
  variant?: 'linkItem' | 'closeItem';
  handleClick?: any;
}

const TrayNavListItem: React.FC<TrayNavListItemProps> = ({
  text,
  iconName,
  id,
  variant,
  handleClick,
}) => {
  const color = variant === 'closeItem' ? '#40B3DB' : 'white';

  return (
    <ListItem button id={id} key={text} onClick={handleClick}>
      {iconName && (
        <ListItemIcon>
          <ItemIcon name={iconName} color={color} />
        </ListItemIcon>
      )}
      <ItemText primary={text} color={color} />
    </ListItem>
  );
};

TrayNavListItem.defaultProps = {
  variant: 'linkItem',
};

export default TrayNavListItem;
