// Helper function to manually set the active bold text to be only the one under the slider thumb.
export function setBoldForActiveLabel() {
  const activeSliderLabels = document.querySelectorAll('.MuiSlider-markLabel');
  const markLabelElements: any[] = [];
  activeSliderLabels.forEach((label) => {
    markLabelElements.push(label as HTMLElement);
  });

  markLabelElements.forEach((element) => {
    if (element.nextSibling.className.includes('Active')) {
      element.classList.remove('MuiSlider-markLabelActive');
    } else if (element.previousSibling.className.includes('Active'))
      element.classList.add('MuiSlider-markLabelActive');
  });
}

// Helper function to manually set alignment of the Edge labels (Rare/Almost Certain & Minimal/Catastrophic) to be flushed with the rest of the container.
export function setAlignmentForEdgeLabels() {
  const sliderValues = document.querySelectorAll('.MuiSlider-markLabel');
  const firstLikelihoodTick = sliderValues[0] as HTMLElement;
  const lastLikelihoodTick = sliderValues[4] as HTMLElement;
  const firstSeverityTick = sliderValues[5] as HTMLElement;
  const lastSeverityTick = sliderValues[9] as HTMLElement;

  if (firstLikelihoodTick) firstLikelihoodTick.style.left = '16px';
  if (lastLikelihoodTick) lastLikelihoodTick.style.left = '92.5%';
  if (firstSeverityTick) firstSeverityTick.style.left = '28px';
  if (lastSeverityTick) lastSeverityTick.style.left = '93.5%';
}
